import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Button, Table } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [Actin, setActin] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetUsers()
    datass()
  }, [])

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.GetUser,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.userResult)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid1 = data => {
    sessionStorage.setItem("UserId", data._id)
    history.push("/ViewUser")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    const gs = {
      status: "inactive",
    }
    axios
      .put(URLS.GetUserID + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetUsers()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    const gs = {
      status: "active",
    }

    axios
      .put(URLS.GetUserID + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetUsers()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetUserSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.userResult)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="User list" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {isLoading == true ? (
                    <>
                      <div
                        style={{ zIndex: "9999999999999", height: "420px" }}
                        className="text-center mt-5 pt-5"
                      >
                        <img src={gig} height="140px"></img>
                        <div>Loading......</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>USER ID</th>
                              <th>Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Age</th>
                              <th>Address</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>
                                  <Link
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                  >
                                    {data.userCode}
                                  </Link>
                                </td>
                                <td>{data.name}</td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.age}</td>
                                <td>{data.address}</td>
                                <td>
                                  {data.status == "active" ? (
                                    <>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger ">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {Roles?.userListEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          Actinid1(data)
                                        }}
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="info"
                                      >
                                        <i
                                          style={{ fontSize: " 14px" }}
                                          className="fas fa-eye"
                                        ></i>
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {Roles?.userListBlock === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "active" ? (
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="danger"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-lock"
                                          ></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            manageDelete1(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="success"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-check"
                                          ></i>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>{" "}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
