import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { Link, useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const [zones, setZones] = useState([])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    datass()
    GetZones()
  }, [])

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetEnquiry,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setZones(res.data.enquirys)
          setIsLoading(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = zones.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(zones.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [forms, setforms] = useState([])

  const handlechanges = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetEnquirySearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setZones(res.data.enquirys)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Deletedep(data)
    }
  }

  const Deletedep = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(
        URLS.DeleteEnquiry + "/" + remid,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetZones()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const ZoneIdz = data => {
    sessionStorage.setItem("prescriptionid", data._id)
    history.push("/EditPrescription")
  }

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const [form1, setform1] = useState([])

  const getpopup = data => {
    tog_small()
    setform1(data)
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas

    const dataArray = {
      assignedToId: form1.assignedToId,
      status: form1.status,
    }

    axios
      .put(URLS.EditEnquiry + form1._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetZones()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [staff, setstaff] = useState([])

  useEffect(() => {
    GetStaff()
  }, [])

  const GetStaff = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaff,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setstaff(res.data.staff)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Enquiry" />
          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        {/* <Col>
                          <Link to="/AddPrescription">
                            <Button color="primary">
                              Add Enquiry <i className="bx bx-plus-circle"></i>
                            </Button>
                          </Link>
                        </Col> */}
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={forms.search}
                              onChange={handlechanges}
                              className="form-control"
                              placeholder="Search.."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Date / time</th>
                              <th>User Name</th>
                              <th>Type Of Service </th>
                              <th>Test Name</th>
                              <th>Description</th>
                              <th>Image </th>
                              <th>Assigned To</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.logCreatedDate.slice(0, 10)}</td>
                                <td>{data.userName}</td>
                                <td>{data.topicName}</td>
                                <td>{data.title}</td>
                                <td>{data.description}</td>
                                <td>
                                  {" "}
                                  <img
                                    src={URLS.Base + data.image}
                                    width="100px"
                                  ></img>
                                </td>{" "}
                                <td>
                                  {data.assignedName == null || undefined ? (
                                    <>-</>
                                  ) : (
                                    <>{data.assignedName}</>
                                  )}
                                </td>
                                <td>
                                  {data.status == "Solved" ? (
                                    <>
                                      <span className="badge bg-success">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="badge bg-warning">
                                      {data.status}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {Roles?.enquiryEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "Solved" ? (
                                        ""
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            getpopup(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="success"
                                          outline
                                        >
                                          <i className="bx bx-edit "></i>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {Roles?.enquiryDelete === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          manageDelete(data)
                                        }}
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        color="danger"
                                        outline
                                      >
                                        <i className="bx bx-trash"></i>
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Enquiry Resign
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <label>
                    Select Status <span className="text-danger">*</span>
                  </label>

                  <select
                    className="form-select"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    required
                    name="status"
                    value={form1.status}
                  >
                    <option value=""> Select</option>
                    <option value="Solved"> Solved</option>
                    <option value="Pending"> Re Assign To </option>
                  </select>
                </div>

                {form1.status == "Pending" ? (
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Re Assign To</label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form1.assignedToId}
                        name="assignedToId"
                        onChange={e => {
                          handleChange1(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {staff.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.firstName} {data.lastName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
