import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Label,
  Form,
  Table,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import nodata from "../../assets/images/nodata.gif"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  const history = useHistory()

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, "0")
  const day = String(today.getDate()).padStart(2, "0")

  const formattedDate = `${year}-${month}-${day}`

  useEffect(() => {
    GetHospitals()
    datass()
  }, [])

  const GetHospitals = () => {
    var token = datas

    const Parameters = {
      date: formattedDate,
      zoneId: "",
      atpId: "",
      atpType: "",
      slot: "",
    }

    axios
      .post(URLS.GetAtpSlotWiseMonitring, Parameters, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.booking)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid1 = (datas, data) => {
    sessionStorage.setItem("AtpMoniteringid", datas.id)
    sessionStorage.setItem("AtpMoniteringdate", data.date)
    history.push("/ViewAtpMonitering")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
    const token = datas
    axios
      .post(
        URLS.GetAtpSlotWiseMonitring + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setActin(res.data.booking)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    date: "",
    zoneId: "",
    atpType: "",
    atpId: "",
    slot: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetAtpsFiliter()
  }

  const GetAtpsFiliter = () => {
    var token = datas
    const data = {
      atpType: filters.atpType,
      zoneId: filters.zoneId,
      atpId: filters.atpId,
      date: filters.date,
      slot: filters.slot,
    }
    axios
      .post(URLS.GetAtpSlotWiseMonitring, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.booking)
        setfilter(false)
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  useEffect(() => {
    GetCities()
    GetAtps()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [city, setcity] = useState([])

  const GetCities = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcity(res.data.zones)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Atp, setAtp] = useState([])

  const GetAtps = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtp,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setAtp(res.data.atp)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    GetSlots()
  }, [])

  const [Slot, setSlot] = useState([])

  const GetSlots = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtptimeslot,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setSlot(res.data.slotatp.timings)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="ATP Slot Wise Monitoring"
          />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-declaration-input10">
                            Date Wise
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-Declaration-input10"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="date"
                            value={filters.date}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Zone <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            value={filters.zoneId}
                            name="zoneId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            {city.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Atp Type <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            value={filters.atpType}
                            name="atpType"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="Regular"> Regular </option>
                            <option value="Buddy"> Buddy </option>
                            <option value="Emergency"> Emergency </option>
                          </select>
                        </div>
                      </Col>

                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Atp Wise
                          </Label>

                          <select
                            className="form-select"
                            value={filters.atpId}
                            name="atpId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>

                            {Atp.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.firstName}
                                  {data.lastName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>

                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Slot Wise
                          </Label>

                          <select
                            className="form-select"
                            value={filters.slot}
                            name="slot"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            {Slot.map((data, key) => {
                              return (
                                <option
                                  key={key}
                                  value={data.fromtime + "-" + data.totime}
                                >
                                  {data.fromtime}-{data.totime}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>

                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}

          <Row>
            <Col>
              <Button
                className="m-1 mb-2"
                onClick={() => {
                  setfilter(!filter)
                }}
                color="info"
              >
                <i className="fas fa-filter"></i> Filter
              </Button>{" "}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              {isLoading == true ? (
                <>
                  <Card>
                    {" "}
                    <CardBody>
                      <div
                        style={{ zIndex: "9999999999999", height: "420px" }}
                        className="text-center mt-5 pt-5"
                      >
                        <img src={gig} height="140px"></img>
                        <div>Loading......</div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <>
                  {lists.length > 0 ? (
                    <>
                      <div>
                        {lists.map((data, key) => (
                          <Row className="mt-3" key={key}>
                            <Col>
                              <Card>
                                <CardBody>
                                  <Row>
                                    <Col>
                                      <div>
                                        <span style={{ fontSize: "16px" }}>
                                          {" "}
                                          Slot : {data.date} / {data.slot}{" "}
                                        </span>

                                        <span style={{ float: "right" }}>
                                          Slot No : {data.bookingsdata.length}
                                        </span>
                                      </div>{" "}
                                    </Col>
                                  </Row>
                                  <div
                                    className="table-rep-plugin mt-4 table-responsive"
                                    style={{ height: "480px" }}
                                  >
                                    <Table
                                      hover
                                      className="table table-bordered mb-4"
                                    >
                                      <thead>
                                        <tr className="text-center">
                                          <th>SlNo</th>
                                          <th>ATP Id</th>
                                          <th>ATP Name</th>
                                          <th>ATP Number</th>
                                          <th>ATP Type</th>
                                          <th>ATP Zone</th>
                                          <th>Actin Booking Id</th>
                                          <th>Type of Service</th>
                                          <th>Slot / Timming</th>
                                          <th>Collection / Time</th>
                                          <th>Reached at HCU / Time</th>
                                          <th>HCU Id / Name / Location</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.bookingsdata.map((datas, i) => (
                                          <tr className="text-center" key={i}>
                                            <td>{i + 1}</td>
                                            <td>{datas.atpCode}</td>
                                            <td>{datas.atpName}</td>
                                            <td>{datas.phone}</td>
                                            <td>{datas.atpType}</td>
                                            <td>{datas.zoneName}</td>
                                            <td>{datas.bookingCode}</td>
                                            <td>{datas.itemType}</td>
                                            <td>
                                              {datas.slotDate} -{" "}
                                              {datas.slotTime}
                                            </td>
                                            <td>
                                              {datas.sampleCollectDate} -
                                              {datas.sampleCollectTime}
                                            </td>
                                            <td>
                                              {datas.sampleReachedDate}-
                                              {datas.sampleReachedTime}
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.hcuId}-{datas.hospitalName}
                                              -{datas.hcuLocation}
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.status == "canceled" ||
                                              datas.status == "failed" ? (
                                                <>
                                                  <span className="badge bg-danger">
                                                    {datas.status}
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  {datas.status ==
                                                  "completed" ? (
                                                    <>
                                                      <span className="badge bg-success">
                                                        {datas.status}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <span className="badge bg-warning">
                                                      {datas.status}
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        ))}
                      </div>

                      <div
                        className="d-flex mt-3 mb-1"
                        style={{ float: "right" }}
                      >
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"pagination"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"disabled"}
                          activeClassName={"active"}
                          total={lists.length}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <div
                            style={{ zIndex: "9999999999999", height: "440px" }}
                            className="text-center mt-5 pt-5"
                          >
                            <img src={nodata} height="300px"></img>
                          </div>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
