import React, { useState, useEffect, useCallback } from "react"
import { Map, GoogleApiWrapper, Polygon } from "google-maps-react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import axios from "axios"
import { useHistory } from "react-router-dom"

function Addzones(props) {
  const [zones, setZones] = useState([])
  const [newZone, setNewZone] = useState([])
  const [form, setform] = useState([])
  const [states, setstates] = useState([])
  const [District, setDistrict] = useState([])
  const [City, setCity] = useState([])
  const [modal_small, setmodal_small] = useState(false)

  const history = useHistory()

  function tog_small() {
    setmodal_small(!modal_small)
  }

  useEffect(() => {
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.distResult)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const handleSubmit = e => {
    e.preventDefault()
    Addzone()
  }

  const Addzone = () => {
    var token = datas
    const Data = {
      name: form.name,
      stateId: form.stateId,
      districtId: form.districtId,
      cityId: form.cityId,
      coordinates: newZone,
    }

    axios
      .post(URLS.AddZone, Data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            history.push("/Zones")
            sessionStorage.setItem("tost", "Zone has been Added Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            setmodal_small(false)
          }
        }
      )
  }

  const handleMapClick = useCallback(
    (t, map, coord) => {
      const { latLng } = coord
      setNewZone([...newZone, { lat: latLng.lat(), lng: latLng.lng() }])
    },
    [newZone]
  )

  const handlePolygonComplete = useCallback(
    polygon => {
      const paths = polygon.getPath()
      const updatedZones = [...zones, newZone]
      paths.forEach(latLng => {
        updatedZones[updatedZones.length - 1].push({
          lat: latLng.lat(),
          lng: latLng.lng(),
        })
      })
      setZones(updatedZones)
      setNewZone([])
    },
    [zones, newZone]
  )
  const Center = { lat: 17.38714, lng: 78.491684 }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Clear = () => {
    setNewZone([])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Add Zone" />
          <Row>
            {" "}
            <Col md={12}>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>{" "}
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  {newZone.length == 0 ? (
                    ""
                  ) : (
                    <>
                      <Button
                        onClick={tog_small}
                        style={{ float: "right" }}
                        color="primary"
                        className="mb-1 m-1"
                      >
                        Add +
                      </Button>
                      <Button
                        className="mb-1 m-1"
                        style={{ float: "right" }}
                        color="info"
                        onClick={() => {
                          Clear()
                        }}
                      >
                        Clear
                      </Button>
                    </>
                  )}
                </CardHeader>
                <CardBody style={{ height: "500px" }}>
                  <Map
                    style={{ padding: "20px", height: "450px", width: "96%" }}
                    google={props.google}
                    zoom={12}
                    // center={Center}
                    initialCenter={Center}
                    onClick={handleMapClick}
                  >
                    {zones.map((zone, index) => (
                      <Polygon
                        key={index}
                        paths={zone}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#0000FF"
                        fillOpacity={0.35}
                      />
                    ))}
                    <Polygon
                      paths={newZone}
                      strokeColor="#FF0000"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#FF0000"
                      fillOpacity={0.35}
                      editable
                      draggable
                      onMouseUp={handlePolygonComplete}
                    />
                  </Map>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />

          <Modal
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            className="mt-5"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Add Zone Details
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit(e)
                }}
              >
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Zone Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Zone Name"
                        required
                        name="name"
                        value={form.name}
                        onChange={e => {
                          handleChange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label> State</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.stateId}
                        name="stateId"
                        required
                        onChange={e => {
                          Optionchange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.stateName}
                            </option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label> District</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.districtId}
                        name="districtId"
                        required
                        onChange={e => {
                          Optionchange1(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.districtName}
                            </option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label> City </Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.cityId}
                        name="cityId"
                        required
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {City.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.cityName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                </Row>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>

                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>{" "}
      </div>
    </React.Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDuddNO8iwxTtghxnmDn1URNTpl265-_9M",
})(Addzones)
