import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Modal,
  Label,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [Actin, setActin] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const history = useHistory()

  const [form1, setform1] = useState({
    rejectionReason: "",
    status: "",
  })

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetAtps()
  }, [])

  const GetAtps = () => {
    var token = datas
    axios
      .post(
        URLS.GetBuddsRequest,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.buddyRequests)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const Actinid1 = data => {
    sessionStorage.setItem("AtpidD", data.buddyId)
    history.push("/AtpView")
  }

  const Actinid2 = data => {
    sessionStorage.setItem("AtpidD", data.requestingAtpId)
    history.push("/AtpView")
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas
    var formid = form1._id
    const dataArray = {
      status: form1.status,
      rejectionReason: form1.rejectionReason,
    }

    axios
      .put(URLS.UpdateBuddsRequest + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetAtps()
            toast(res.data.message)
            setmodal_small(false)
            setform1({
              rejectionReason: "",
              status: "",
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetBuddsRequestSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.buddyRequests)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Buddy Request" />

          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col></Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>ATP ID</th>
                              <th>ATP Name</th>
                              <th>ATP Phone</th>
                              <th>Buddy Id </th>
                              <th>Buddy Name</th>
                              <th>Buddy Phone</th>
                              <th>Reject Reason</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>
                                  <Link
                                    onClick={() => {
                                      Actinid2(data)
                                    }}
                                  >
                                    {data.requestingAtpCode}
                                  </Link>
                                </td>

                                <td>{data.requestingAtpName} </td>
                                <td>{data.requestingAtpPhone}</td>

                                <td>
                                  <Link
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                  >
                                    {data.buddyCode}
                                  </Link>
                                </td>
                                <td>{data.buddyName}</td>
                                <td>{data.buddyPhone}</td>
                                <td>{data.rejectionReason}</td>

                                <td>
                                  {data.status == "approved" ? (
                                    <>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger ">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {Roles?.leaveRequestEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "approved" ? (
                                        <>-</>
                                      ) : (
                                        <>
                                          {" "}
                                          <Button
                                            onClick={() => {
                                              getpopup(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="success"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="bx bx-edit"
                                            ></i>
                                          </Button>
                                        </>
                                      )}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label> Status</Label> <span className="text-danger">*</span>
                  <select
                    value={form1.status}
                    name="status"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Reject</option>
                  </select>
                </div>

                {form1.status == "rejected" ? (
                  <>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Reject Reason <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form1.rejectionReason}
                        name="rejectionReason"
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
