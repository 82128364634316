import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Button,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import check from "../../assets/images/check.jpg"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function State() {
  const [isLoading, setIsLoading] = useState(true)

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [modal_small3, setmodal_small3] = useState(false)

  function tog_small3() {
    setmodal_small3(!modal_small3)
  }

  const [form, setform] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const getpopup1 = () => {
    tog_small1()
  }

  const getpopup3 = () => {
    tog_small3()
  }

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [forms, setforms] = useState([])
  const [forms1, setforms1] = useState([])
  const [forms2, setforms2] = useState([])
  const [forms3, setforms3] = useState([])

  useEffect(() => {
    GetOneActins()
  }, [])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("Hospitalids")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetIdHospital, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.hospital)
        setforms1(res.data.hospital.healthStandardObj)
        setforms2(res.data.hospital.healthSchemeObj)
        setform(res.data.hospital)
        setforms3(res.data.hospital.serviceListObj)
        setIsLoading(false)
      })
  }

  const [files, setFiles] = useState([])
  const [files1, setFiles1] = useState([])
  const [files2, setFiles2] = useState([])
  const [files3, setFiles3] = useState([])
  const [files4, setFiles4] = useState([])
  const [files6, setFiles6] = useState([])
  const [files7, setFiles7] = useState([])
  const [files8, setFiles8] = useState([])
  const [files9, setFiles9] = useState([])
  const [files10, setFiles10] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler2 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler4 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles4(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const changeHandler6 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles6(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler7 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles7(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler8 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles8(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler9 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles9(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler10 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles10(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()

    dataArray.append("title1", form.title1)
    dataArray.append("title2", form.title2)
    dataArray.append("title3", form.title3)
    dataArray.append("title4", form.title4)
    dataArray.append("title5", form.title5)
    dataArray.append("validUpTo1", form.validUpTo1)
    dataArray.append("validUpTo2", form.validUpTo2)
    dataArray.append("validUpTo3", form.validUpTo3)
    dataArray.append("validUpTo4", form.validUpTo4)
    dataArray.append("validUpTo5", form.validUpTo5)

    // dataArray.append("title6", form.title6)
    // dataArray.append("title7", form.title7)
    // dataArray.append("title8", form.title8)
    // dataArray.append("title9", form.title9)
    // dataArray.append("title10", form.title10)
    // dataArray.append("validUpTo6", form.validUpTo6)
    // dataArray.append("validUpTo7", form.validUpTo7)
    // dataArray.append("validUpTo8", form.validUpTo8)
    // dataArray.append("validUpTo9", form.validUpTo9)
    // dataArray.append("validUpTo10", form.validUpTo10)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("document1", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("document2", files1[i])
    }

    for (let i = 0; i < files2.length; i++) {
      dataArray.append("document3", files2[i])
    }

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("document4", files3[i])
    }

    for (let i = 0; i < files4.length; i++) {
      dataArray.append("document5", files4[i])
    }

    // for (let i = 0; i < files6.length; i++) {
    //   dataArray.append("document6", files6[i])
    // }

    // for (let i = 0; i < files7.length; i++) {
    //   dataArray.append("document7", files7[i])
    // }

    // for (let i = 0; i < files8.length; i++) {
    //   dataArray.append("document8", files8[i])
    // }

    // for (let i = 0; i < files9.length; i++) {
    //   dataArray.append("document9", files9[i])
    // }

    // for (let i = 0; i < files10.length; i++) {
    //   dataArray.append("document10", files10[i])
    // }

    axios
      .put(URLS.UpdatedocsHospital + "/" + forms._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small1(false)
            GetOneActins()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="HCU Profile" />
          <Row>
            {" "}
            <Col md={12}>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>{" "}
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row className="mb-5">
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            HCU Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "6",
                            })}
                            onClick={() => {
                              toggle1("6")
                            }}
                          >
                            Bank Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "7",
                            })}
                            onClick={() => {
                              toggle1("7")
                            }}
                          >
                            Documents
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "8",
                            })}
                            onClick={() => {
                              toggle1("8")
                            }}
                          >
                            Promotions
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={activeTab1}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="5">
                          <Row>
                            <Col lg={4}>
                              <ul className="list-unstyled vstack gap-3 mb-0">
                                <div className="KKL">
                                  <img
                                    src={URLS.Base + forms.hospitalLogo}
                                    height="192px"
                                    width="90%"
                                  ></img>
                                </div>
                                <li>
                                  <div style={{ float: "right" }}>
                                    <h6>
                                      <i
                                        style={{ fontSize: "20px" }}
                                        className="bx bx-smile  text-warning"
                                      ></i>{" "}
                                      {forms.smiles}
                                    </h6>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <i className="bx bxs-buildings font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        HCU Name:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.hospitalName}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-clinic font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Hospital Code:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.hospitalCode}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                {/* <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-money font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Identity Number:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.legalEntityNumber}
                                      </span>
                                    </div>
                                  </div>
                                </li> */}
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-book font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        HCU Billing Contact Number:
                                      </h6>
                                      {forms.billingPhone}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-book font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Home Tests Contact Number:
                                      </h6>
                                      {forms.homeTestPhone}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-building-house font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        HCU Investigation Contact Number:
                                      </h6>
                                      {forms.investigationPhone}
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="fas fa-hospital-user font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Consultation Contact Number:
                                      </h6>
                                      {forms.consultationPhone}
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-home font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Home Service Contact Number:
                                      </h6>
                                      {forms.servicePhone}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-message-alt font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Email
                                      </h6>
                                      <span className="text-muted">
                                        {forms.email}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-map-pin font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Location
                                      </h6>
                                      <span className="text-muted">
                                        {forms.location}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bxs-bookmarks font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Status :
                                      </h6>
                                      <span className="text-muted">
                                        {" "}
                                        {forms.status}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                            <Col lg={8}>
                              <Row>
                                <Col md={6}>
                                  <h5 className="mb-3">HCU Details:</h5>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Date Of Joining:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.dateOfJoining}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list ">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Valid Upto :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.dateOfValidity}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Health Scheme :
                                            </h6>
                                            <p className="text-muted">
                                              {forms2.map((data, i) => (
                                                <>
                                                  <li key={i}>{data.value}</li>
                                                </>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Health Standard :
                                            </h6>
                                            <p className="text-muted">
                                              {forms1.map((data, i) => (
                                                <>
                                                  <li key={i}>{data.value}</li>
                                                </>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              HCU Speciality Type:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.hospitalSpecialityType}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Mode of Treatment :
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {forms.treatmentMode}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Legal Entity Name:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.legalEntityName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Legal Entity Registration Number:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.legalEntityNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Legal Entity Registration Date:
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {forms.legalEntityDate}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Organisation Head Name:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.organizationHeadName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Organisation Head Contact Number:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.organizationHeadPhone}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Commission Of HCU :
                                            </h6>
                                            <span>
                                              Tests at Home -{" "}
                                              {
                                                forms.investigationAtHcuCommission
                                              }
                                              %
                                            </span>{" "}
                                            <br></br>
                                            <span>
                                              Investigations at HCU -{" "}
                                              {
                                                forms.investigationAtHcuCommission
                                              }
                                              %
                                            </span>
                                            <br></br>
                                            <span>
                                              Consultations -{" "}
                                              {
                                                forms.doctorConsultationCommission
                                              }
                                              %
                                            </span>
                                            <br></br>
                                            <span>
                                              Home Services -{" "}
                                              {forms.homeServicesCommission}%
                                            </span>
                                            <br></br>
                                            <span>
                                              Tests Packages -{" "}
                                              {forms.packageCommission}%
                                            </span>
                                            <br></br>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col md={6}>
                                  <ul className="verti-timeline list-unstyled mt-5">
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Commission Type:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.commissionType}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Timing Of HCU:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.timingOfHco ==
                                              "twentyfourSeven" ? (
                                                <>24 * 7</>
                                              ) : (
                                                <>
                                                  {forms.fromtime}-
                                                  {forms.totime}{" "}
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              A+:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.aPlus}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              HCU Help Desk Number:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.helpDeskPhone}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Establishment Year:
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {forms.establishDate}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              State Name:
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {forms.stateName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              District Name:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.districtName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              City Name:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.cityName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Address
                                            </h6>
                                            <p className="text-muted">
                                              {forms.address}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Zone
                                            </h6>
                                            <p className="text-muted">
                                              {forms.zoneName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Lattitude / Longitude
                                            </h6>
                                            <p className="text-muted">
                                              {forms.latitude} /{" "}
                                              {forms.longitude}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Pin Code
                                            </h6>
                                            <p className="text-muted">
                                              {forms.pinCode}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Services provided with ACTINplus:
                                            </h6>
                                            <p className="text-muted">
                                              {forms3.map((data, i) => (
                                                <>
                                                  <li key={i}>{data.value}</li>
                                                </>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col md={6}>
                              <ul className="verti-timeline list-unstyled mt-5">
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h5 className="text-primary">
                                          HCU About:
                                        </h5>
                                        <p className="text-muted">
                                          {forms.aboutUs}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="6">
                          <Row>
                            <Col sm="12">
                              <Row>
                                <Col md={6}>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list  mt-2">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Bank Name
                                            </h6>
                                            <p className="text-muted">
                                              {forms.bankName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Account Holder Name
                                            </h6>
                                            <p className="text-muted">
                                              {forms.accountHolderName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Account Number
                                            </h6>
                                            <p className="text-muted">
                                              {forms.accountNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Branch
                                            </h6>
                                            <p className="text-muted">
                                              {forms.branchName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              IFSC code
                                            </h6>
                                            <p className="text-muted">
                                              {forms.ifscCode}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              HCU Pan Number
                                            </h6>
                                            <p className="text-muted">
                                              {forms.hcoPanNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              TDS Examination
                                            </h6>
                                            <p className="text-muted">
                                              {forms.tdsExamination}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Bank Account Type
                                            </h6>
                                            <p className="text-muted">
                                              {" "}
                                              {forms.accountType}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              GST Number
                                            </h6>
                                            <p className="text-muted">
                                              {forms.gstNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col md={6}>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-3">
                                              Cancelled Cheque
                                            </h6>
                                            <p className="text-muted">
                                              <a
                                                href={
                                                  URLS.Base +
                                                  forms.canceledCheckImg
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  src={
                                                    URLS.Base +
                                                    forms.canceledCheckImg
                                                  }
                                                  height="150px"
                                                  width="90%"
                                                ></img>
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list mt-5">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-3">
                                              HCU Pan Card
                                            </h6>
                                            <p className="text-muted">
                                              <img
                                                src={
                                                  URLS.Base + forms.hcoPanImg
                                                }
                                                height="200px"
                                              ></img>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    {/* <li className="event-list mt-5">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-3">
                                              Name Of Authorised Signatory to
                                              Bank Account
                                            </h6>
                                            <p className="text-muted">
                                              <img
                                                src={
                                                  URLS.Base +
                                                  forms.authorizedSign
                                                }
                                                height="100px"
                                              ></img>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li> */}
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="7">
                          <Row>
                            <Row>
                              {/* modified */}
                              <h5 className="mb-3">
                                Documents:
                                {Roles?.hospitalListEdit === true ||
                                Roles?.accessAll === true ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        getpopup1()
                                      }}
                                      className="m-1"
                                      color="info"
                                      style={{ float: "right" }}
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="bx bx-edit"
                                      ></i>
                                      Add / Update / Other Documents
                                    </Button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </h5>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 mb-3 ">
                                            State Register
                                          </h6>
                                          <p className="text-muted">
                                            <img
                                              src={
                                                URLS.Base +
                                                forms.stateRegisterImg
                                              }
                                              width="80%"
                                            ></img>
                                          </p>
                                          <span style={{ color: "black" }}>
                                            Valid up to:
                                            <span style={{ color: "red" }}>
                                              {forms.stateRegisterValidUpto}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 mb-3">
                                            NABH Certificate
                                          </h6>
                                          <p className="text-muted">
                                            <img
                                              src={
                                                URLS.Base + forms.nabhCertImg
                                              }
                                              width="80%"
                                            ></img>
                                          </p>
                                          <span style={{ color: "black" }}>
                                            Valid up to :
                                            <span style={{ color: "red" }}>
                                              {forms.nabhCertValidUpto}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                            </Row>

                            <Row className="mt-5">
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 mb-3">
                                            CGHS Certificate
                                          </h6>
                                          <p className="text-muted">
                                            <img
                                              src={
                                                URLS.Base + forms.cghsCertImg
                                              }
                                              width="80%"
                                            ></img>
                                          </p>
                                          <span style={{ color: "black" }}>
                                            Valid up to:
                                            <span style={{ color: "red" }}>
                                              {forms.cghsCertValidUpto}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14  mb-3">
                                            Biomedical Westage
                                          </h6>
                                          <p className="text-muted">
                                            <img
                                              src={
                                                URLS.Base +
                                                forms.bioWasteCertImg
                                              }
                                              width="80%"
                                            ></img>
                                          </p>
                                          <span style={{ color: "black" }}>
                                            Valid up to :
                                            <span style={{ color: "red" }}>
                                              {forms.bioWasteCertValidUpto}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                            </Row>

                            <Row className="mt-5">
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 mb-3">
                                            Aarogyasri Certificate
                                          </h6>
                                          <p className="text-muted">
                                            <img
                                              src={
                                                URLS.Base + forms.aarogyaCertImg
                                              }
                                              width="80%"
                                            ></img>
                                          </p>
                                          <span style={{ color: "black" }}>
                                            Valid up to :
                                            <span style={{ color: "red" }}>
                                              {forms.aarogyaCertValidUpto}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                              {form.document1 == "" || form.validUpTo1 == "" ? (
                                <></>
                              ) : (
                                <>
                                  <Col md={6}>
                                    <ul className="verti-timeline list-unstyled">
                                      <li className="event-list">
                                        <div className="event-timeline-dot">
                                          <i className="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <div>
                                              <h6 className="font-size-14 mb-3">
                                                {form.title1}
                                              </h6>
                                              <p className="text-muted">
                                                {" "}
                                                <img
                                                  src={
                                                    URLS.Base + form.document1
                                                  }
                                                  width="80%"
                                                ></img>
                                              </p>
                                              <span style={{ color: "black" }}>
                                                Valid up to :
                                                <span style={{ color: "red" }}>
                                                  {form.validUpTo1}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Col>{" "}
                                </>
                              )}
                              {form.document2 == "" || form.validUpTo2 == "" ? (
                                <></>
                              ) : (
                                <>
                                  <Col md={6} className="mt-5">
                                    <ul className="verti-timeline list-unstyled">
                                      <li className="event-list">
                                        <div className="event-timeline-dot">
                                          <i className="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <div>
                                              <h6 className="font-size-14 mb-3">
                                                {form.title2}
                                              </h6>
                                              <p className="text-muted">
                                                {form.document2 == "" ? (
                                                  <></>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <img
                                                      src={
                                                        URLS.Base +
                                                        form.document2
                                                      }
                                                      width="80%"
                                                    ></img>
                                                  </>
                                                )}
                                              </p>
                                              <span style={{ color: "black" }}>
                                                Valid up to :
                                                <span style={{ color: "red" }}>
                                                  {form.validUpTo2}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Col>
                                </>
                              )}
                              {form.document3 == "" || form.validUpTo3 == "" ? (
                                <></>
                              ) : (
                                <>
                                  <Col md={6} className="mt-5">
                                    <ul className="verti-timeline list-unstyled">
                                      <li className="event-list">
                                        <div className="event-timeline-dot">
                                          <i className="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <div>
                                              <h6 className="font-size-14 mb-3">
                                                {form.title3}
                                              </h6>
                                              <p className="text-muted">
                                                {form.document3 == "" ? (
                                                  <></>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <img
                                                      src={
                                                        URLS.Base +
                                                        form.document3
                                                      }
                                                      width="80%"
                                                    ></img>
                                                  </>
                                                )}
                                              </p>
                                              <span style={{ color: "black" }}>
                                                Valid up to :
                                                <span style={{ color: "red" }}>
                                                  {form.validUpTo3}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Col>
                                </>
                              )}
                              {form.document4 == "" || form.validUpTo4 == "" ? (
                                <></>
                              ) : (
                                <>
                                  <Col md={6} className="mt-5">
                                    <ul className="verti-timeline list-unstyled">
                                      <li className="event-list">
                                        <div className="event-timeline-dot">
                                          <i className="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <div>
                                              <h6 className="font-size-14 mb-3">
                                                {form.title4}
                                              </h6>
                                              <p className="text-muted">
                                                {form.document4 == "" ? (
                                                  <></>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <img
                                                      src={
                                                        URLS.Base +
                                                        form.document4
                                                      }
                                                      width="80%"
                                                    ></img>
                                                  </>
                                                )}
                                              </p>
                                              <span style={{ color: "black" }}>
                                                Valid up to :
                                                <span style={{ color: "red" }}>
                                                  {form.validUpTo4}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Col>
                                </>
                              )}
                              {form.document5 == "" || form.validUpTo5 == "" ? (
                                <></>
                              ) : (
                                <>
                                  <Col md={6} className="mt-5">
                                    <ul className="verti-timeline list-unstyled">
                                      <li className="event-list">
                                        <div className="event-timeline-dot">
                                          <i className="bx bx-right-arrow-circle"></i>
                                        </div>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <div>
                                              <h6 className="font-size-14 mb-3">
                                                {form.title5}
                                              </h6>
                                              <p className="text-muted">
                                                {form.document5 == "" ? (
                                                  <></>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <img
                                                      src={
                                                        URLS.Base +
                                                        form.document5
                                                      }
                                                      width="80%"
                                                    ></img>
                                                  </>
                                                )}
                                              </p>
                                              <span style={{ color: "black" }}>
                                                Valid up to :
                                                <span style={{ color: "red" }}>
                                                  {form.validUpTo5}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </Row>
                        </TabPane>

                        <TabPane tabId="8">
                          <Row>
                            <Col md={12}>
                              {/* <Button
                                onClick={() => {
                                  getpopup3()
                                }}
                                className="m-1"
                                color="info"
                                style={{
                                  float: "right",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                                Add Promotion
                              </Button> */}

                              <h5 className="mt-5">Promotion Description: </h5>
                              <p className="mt-2 mb-3">{form.description}</p>
                            </Col>
                            <h5 className="mb-3 mt-5">Promotions Images: </h5>
                            <Row className="mb-5 mt-2">
                              <Col md={4} className="text-center">
                                {form.promotImageOne == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.promotImageOne}
                                      width="80%"
                                    ></img>
                                  </>
                                )}
                              </Col>
                              <Col md={4} className="text-center">
                                {form.promotImageTwo == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.promotImageTwo}
                                      width="80%"
                                    ></img>
                                  </>
                                )}
                              </Col>
                              <Col md={4} className="text-center">
                                {form.promotImageThree == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.promotImageThree}
                                      width="80%"
                                    ></img>
                                  </>
                                )}
                              </Col>
                            </Row>
                            <Row className="mb-5 mt-2">
                              <Col md={4} className="text-center">
                                {form.promotImageFour == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.promotImageFour}
                                      width="80%"
                                    ></img>
                                  </>
                                )}
                              </Col>
                              <Col md={4} className="text-center">
                                {form.promotImageFive == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.promotImageFive}
                                      width="80%"
                                    ></img>
                                  </>
                                )}
                              </Col>
                              <Col md={4} className="text-center">
                                {form.promotImageSix == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      src={URLS.Base + form.promotImageSix}
                                      width="80%"
                                    ></img>
                                  </>
                                )}
                              </Col>
                            </Row>

                            <h5 className="mb-3">Promotion Videos </h5>
                            <Row>
                              <Col md={6} className="text-center">
                                <iframe
                                  width="80%"
                                  src={form.promotVideoOne}
                                  title="YouTube video player"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                ></iframe>
                              </Col>

                              <Col md={6} className="text-center">
                                <iframe
                                  width="80%"
                                  src={form.promotVideoTwo}
                                  title="YouTube video player"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                ></iframe>
                              </Col>
                            </Row>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>

        <Modal
          size="lg"
          isOpen={modal_small1}
          toggle={() => {
            tog_small1()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add / Update Other Documents
            </h5>
            <button
              onClick={() => {
                setmodal_small1(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit(e)
              }}
            >
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title1"
                      value={form.title1}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo1"
                      value={form.validUpTo1}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title2"
                      value={form.title2}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler1}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo2"
                      value={form.validUpTo2}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title3"
                      value={form.title3}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler2}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo3"
                      value={form.validUpTo3}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title4"
                      value={form.title4}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler3}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo4"
                      value={form.validUpTo4}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title5"
                      value={form.title5}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler4}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo5"
                      value={form.validUpTo5}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>

                {/* <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title6"
                      value={form.title6}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler6}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo6"
                      value={form.validUpTo6}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title7"
                      value={form.title7}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler7}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo7"
                      value={form.validUpTo7}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title8"
                      value={form.title8}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler8}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo8"
                      value={form.validUpTo8}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title9"
                      value={form.title9}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler9}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo9"
                      value={form.validUpTo9}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Title</Label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="title5"
                      value={form.title10}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Documents</Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter image"
                      name="image"
                      onChange={changeHandler10}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Valid up to</Label>
                    <input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Title"
                      name="validUpTo10"
                      value={form.validUpTo10}
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col> */}
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small1(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="info" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="lg"
          isOpen={modal_small3}
          toggle={() => {
            tog_small3()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Add Promotions
            </h5>
            <button
              onClick={() => {
                setmodal_small3(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Image :
                      <span className="text-danger"> (Size : 400*500) *</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Add Promotion Images"
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Image :
                      <span className="text-danger"> (Size : 400*500) *</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Add Promotion Images"
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Image :
                      <span className="text-danger"> (Size : 400*500) *</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Add Promotion Images"
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Image :
                      <span className="text-danger"> (Size : 400*500) *</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Add Promotion Images"
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Image :
                      <span className="text-danger"> (Size : 400*500) *</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Add Promotion Images"
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Image :
                      <span className="text-danger"> (Size : 400*500) *</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Add Promotion Images"
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Video:
                      <span className="text-danger">(Youtube Embed Url) *</span>
                    </Label>
                    <input
                      type="url"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Promotion Videos "
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Promotion Video:
                      <span className="text-danger">(Youtube Embed Url) *</span>
                    </Label>
                    <input
                      type="url"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Promotion Videos "
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Description :
                    </Label>
                    <textarea
                      type="url"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description "
                      required
                      name="title"
                      value=""
                      onChange={e => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small3(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="info" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
