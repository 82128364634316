import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Label,
  Modal,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link, useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { ToastContainer, toast } from "react-toastify"

function PaymentReport() {
  const [Actin, setActin] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  useEffect(() => {
    ActinReport()
  }, [])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("Paymentids")

  const ActinReport = () => {
    var token = datas
    const data = { _id: Actinid }

    axios
      .post(URLS.GetOneAtpPayrole, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.payments)
      })
  }
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "ATP Code",
        "ATP Name",
        "ATP Number",
        "ATP Email",
        "ATP Type",
        "Zone",
        "Booking Count",
        "Amount",
        "Status",
      ],
    ]

    const data = Actin.map((elt, i) => [
      i + 1,
      elt.atpCode,
      elt.atpName,
      elt.atpPhone,
      elt.atpEmail,
      elt.atpType,
      elt.atpZoneName,
      elt.bookingCount,
      elt.bookingTotalAmount,
      elt.status,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Atp_Pay_Role.pdf")
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const Actinid1 = data => {
    sessionStorage.setItem("AtpidD", data.atpId)
    history.push("/AtpView")
  }

  const [form1, setform1] = useState([])

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas
    var formid = form1._id
    const dataArray = {
      status: form1.status,
    }

    axios
      .put(URLS.UpdateAtpPayrole + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            ActinReport()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="ACTIN" breadcrumbItem="View ATP Pay Role" />

            <Row>
              <Col>
                <Button
                  onClick={() => history.goBack()}
                  className="mb-3  m-1 "
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i> Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <div>
                  <div className="table-responsive" style={{ height: "480px" }}>
                    <div className="col-sm-12">
                      <div>
                        <Row>
                          <Col>
                            <div>
                              <Button
                                type="button"
                                className="btn btn-success"
                                onClick={exportPDF}
                              >
                                <i className="fas fa-file-pdf"></i> Pdf
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="mt-3">
                      <Table
                        id="empTable"
                        className="table table-bordered mb-5"
                      >
                        <thead className="text-center">
                          <tr>
                            <th>SlNo</th>
                            <th>ATP Code</th>
                            <th>ATP Image</th>
                            <th>ATP Name</th>
                            <th>ATP Number</th>
                            <th>ATP Type</th>
                            <th>ATP Zone</th>
                            <th>Booking Count</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <td>{(pageNumber - 1) * 20 + key + 21}</td>
                              <td>
                                <Link
                                  onClick={() => {
                                    Actinid1(data)
                                  }}
                                >
                                  {data.atpCode}
                                </Link>
                              </td>
                              <td>
                                <img
                                  src={URLS.Base + data.atpImage}
                                  alt=""
                                  className=" rounded-circle"
                                  style={{ height: "50px", width: "50px" }}
                                />
                              </td>
                              <td>{data.atpName}</td>
                              <td>{data.atpPhone}</td>
                              <td>{data.atpType}</td>
                              <td>{data.atpZoneName}</td>
                              <td>{data.bookingCount}</td>
                              <td>{data.bookingTotalAmount}</td>
                              <td>
                                {data.status == "pending" ? (
                                  <>
                                    <span className="badge bg-danger">
                                      {data.status}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="badge bg-success ">
                                      {data.status}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td>
                                {/* modified */}
                                {Roles?.AtpRoleEdit === true ||
                                Roles?.accessAll === true ? (
                                  <>
                                    {data.status == "pending" ? (
                                      <>
                                        {" "}
                                        <Button
                                          onClick={() => {
                                            getpopup(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="success"
                                          outline
                                        >
                                          <i className="bx bx-edit "></i>
                                        </Button>
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="mt-3" style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
      <Modal
        size="md"
        isOpen={modal_small}
        toggle={() => {
          tog_small()
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Edit Status
          </h5>
          <button
            onClick={() => {
              setmodal_small(false)
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form
            onSubmit={e => {
              handleSubmit1(e)
            }}
          >
            <div className="mb-3">
              <Label>Status</Label> <span className="text-danger">*</span>
              <select
                value={form1.status}
                name="status"
                onChange={e => {
                  handleChange1(e)
                }}
                className="form-select"
              >
                <option value="">Select</option>
                <option value="paid">Paid</option>
                {/* <option value="rejected">Rejected</option> */}
              </select>
            </div>

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small(false)
                }}
                color="danger"
                type="button"
              >
                Cancel <i className="fas fa-times-circle"></i>
              </Button>
              <Button className="m-1" color="primary" type="submit">
                Submit <i className="fas fa-check-circle"></i>
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default PaymentReport
