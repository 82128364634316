import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Table,
  Form,
  Label,
  Button,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Support() {
  const [Actin, setActin] = useState([])
  const [modal_small, setmodal_small] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas

    const data = {
      status: "",
      fromDate: "",
      toDate: "",
    }

    axios
      .post(URLS.GetHospitalTechinicalSupport, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.data)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [form1, setform1] = useState([])

  const [form2, setform2] = useState([])

  const getpopup = data => {
    setform2(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas
    const datos = form2._id
    const dataArray = {
      status: form1.status,
      description: form1.description,
    }

    axios
      .put(URLS.UpdateHospitalTechinicalSupport + "/" + datos, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetHospitals()
            toast(res.data.message)
            setmodal_small(false)
            setform1({
              status: "",
              description: "",
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetHospitalTechinicalSupportSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.data)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="HCU Technical Support" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>{" "}
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Ticket Id</th>
                                <th>Date / Time</th>
                                <th>HCU ID</th>
                                <th>HCU Name</th>
                                <th>Title</th>
                                <th>Screen Shot</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Reason</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.ticketid}</td>
                                  <td>
                                    {data.date}/{data.time}
                                  </td>
                                  <td>{data.hcuId}</td>
                                  <td>{data.hospitalName}</td>
                                  <td>{data.title}</td>
                                  <td>
                                    <img
                                      style={{ width: "100px" }}
                                      src={URLS.Base + data.images}
                                    />
                                  </td>
                                  <td>{data.description}</td>
                                  <td>
                                    {data.status == "canceled" ? (
                                      <>
                                        <span className="badge bg-danger">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {data.status == "resolved"  ? (
                                          <>
                                            <span className="badge bg-success">
                                              {data.status}
                                            </span>
                                          </>
                                        ) : (
                                          <span className="badge bg-warning">
                                            {data.status}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td>{data.rejectReason}</td>
                                  <td>
                                    {Roles?.hcuTcSupportEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        {data.status == "resolved" || data.status == "canceled" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <Button
                                              onClick={() => {
                                                getpopup(data)
                                              }}
                                              className="mr-2"
                                              style={{
                                                padding: "6px",
                                                margin: "3px",
                                              }}
                                              color="success"
                                              outline
                                            >
                                              <i className="bx bx-edit "></i>
                                            </Button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Support Status
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Status <span className="text-danger">*</span>
                  </Label>

                  <select
                    className="form-select"
                    required
                    value={form1.status}
                    name="status"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  >
                    <option value=""> Select </option>
                    <option value="resolved">Resolved</option>
                    <option value="canceled"> Canceled</option>
                  </select>
                </div>
              </Col>
              {form1.status == "canceled" ? (
                <>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Reason <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Reason"
                        required
                        value={form1.description}
                        name="description"
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
