import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Table,
  Label,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import gig from "../../assets/images/loders.gif"
import { URLS } from "../../Url"
import axios from "axios"

function DigitalBrochure() {
  const [isLoading, setIsLoading] = useState(true)

  const [form, setform] = useState([])

  const [forms, setforms] = useState([])

  const handlechange = e => {
    const myform = { ...forms }
    myform[e.target.name] = e.target.value
    setforms(myform)
  }

  useEffect(() => {
    GetAllBroucher()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetAllBroucher = () => {
    var token = datas

    axios
      .post(
        URLS.Getcharges,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.charges)
        setforms(res.data.charges)
        setIsLoading(false)
      })
  }
  const [modal_small, setmodal_small] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup1 = () => {
    tog_small()
  }

  const submibooking = e => {
    e.preventDefault()

    changstatus()
  }

  const changstatus = () => {
    var token = datas
    const Data = {
      regularAtpCharge: forms.regularAtpCharge,
      emergencyAtpCharge: forms.emergencyAtpCharge,
      buddyAtpCharge: forms.buddyAtpCharge,
      sampleCharges: forms.sampleCharges,
      sanitationCharges: forms.sanitationCharges,
      nightSampleCharges: forms.nightSampleCharges,
      instantsampleCharges: forms.instantsampleCharges,
      instantsanitationCharges: forms.instantsanitationCharges,
      nightSanitationCharges: forms.nightSanitationCharges,
      chargesApplyLimit: forms.chargesApplyLimit,
      cancelationCharge: forms.cancelationCharge,
      appointmentCharges: forms.appointmentCharges,
      transactionCharges: forms.transactionCharges,
      gst: forms.gst,
      tds: forms.tds,
    }

    axios
      .put(URLS.Updatecharges, Data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetAllBroucher()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
            setIsLoading(false)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="Charges " />

          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white"></CardHeader>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div style={{ float: "right" }}>
                        {Roles?.ChargesEdit === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <Button
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title="Edit Booking"
                              onClick={() => {
                                getpopup1(form)
                              }}
                              className="mr-5"
                              color="success"
                              outline
                            >
                              <i className="bx bx-edit text-dark "></i>
                              <span>Edit</span>
                            </Button>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <Row>
                        <div className="m-5">
                          <div
                            className="table-rep-plugin mt-4 table-responsive"
                            style={{ height: "480px" }}
                          >
                            <Table hover className="table table-bordered mb-4">
                              <thead>
                                <tr className="text-center">
                                  <th>Atp Regular Bookings Charges</th>
                                  <td>{form.regularAtpCharge}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Atp Act-INstant Bookings Charges</th>
                                  <td>{form.buddyAtpCharge}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Atp Emergency Bookings Charges</th>
                                  <td>{form.emergencyAtpCharge}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>
                                    Sample Collections Charges (Regular Booking
                                    Charges)
                                  </th>
                                  <td>{form.sampleCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>
                                    Sanitation Disposal Charges (Regular Booking
                                    Charges)
                                  </th>
                                  <td>{form.sanitationCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>
                                    Sample Collections Charges (Emergency
                                    Booking Charges)
                                  </th>
                                  <td>{form.nightSampleCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>
                                    Sanitation Disposal Charges (Emergency
                                    Booking Charges)
                                  </th>
                                  <td>{form.nightSanitationCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>
                                    Sample Collections Charges (ACT-INstant
                                    Charges)
                                  </th>
                                  <td>{form.instantsampleCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>
                                    Sanitation Disposal Charges (ACT-INstant
                                    Booking Charges)
                                  </th>
                                  <td>{form.instantsanitationCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th> Charges Apply</th>
                                  <td>{form.chargesApplyLimit}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Cancellation Charges</th>
                                  <td>{form.cancelationCharge}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Appointment Charges</th>
                                  <td>{form.appointmentCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Transaction Charges</th>
                                  <td>{form.transactionCharges}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Gst Charges</th>
                                  <td>{form.gst}</td>
                                </tr>

                                <tr className="text-center">
                                  <th>Tds </th>
                                  <td>{form.tds}</td>
                                </tr>
                              </thead>
                            </Table>
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>

          <Modal
            size="xl"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Charges
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  submibooking(e)
                }}
              >
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Atp Regular Charges
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Atp Regular Charges"
                        required
                        value={forms.regularAtpCharge}
                        name="regularAtpCharge"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Atp Emergency Charges
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Atp Emergency Charges"
                        required
                        value={forms.emergencyAtpCharge}
                        name="emergencyAtpCharge"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Atp Buddy Charges <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Atp Buddy Charges"
                        required
                        value={forms.buddyAtpCharge}
                        name="buddyAtpCharge"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Charges Apply <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Charges Apply"
                        required
                        value={forms.chargesApplyLimit}
                        name="chargesApplyLimit"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Sample Collections Charges (Regular Booking Charges)
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Sample Collections Charges"
                        required
                        value={forms.sampleCharges}
                        name="sampleCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Sanitation Disposal Charges (Regular Booking Charges)
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Sanitation Disposal Charges"
                        required
                        value={forms.sanitationCharges}
                        name="sanitationCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Sample Collections Charges (Emergency Booking Charges)
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Sample Collections Charges"
                        required
                        value={forms.nightSampleCharges}
                        name="nightSampleCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Sanitation Disposal Charges (Emergency Booking Charges)
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Sanitation Disposal Charges"
                        required
                        value={forms.nightSanitationCharges}
                        name="nightSanitationCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Sample Collections Charges (ACT-INstant Charges)
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter  Sample Collections Charges (ACT-INstant Charges)"
                        required
                        value={forms.instantsampleCharges}
                        name="instantsampleCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Sanitation Disposal Charges (ACT-INstant Booking
                        Charges)
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Sanitation Disposal Charges (ACT-INstant Booking Charges)"
                        required
                        value={forms.instantsanitationCharges}
                        name="instantsanitationCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Cancellation Charges
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Cancellation Charges"
                        required
                        value={forms.cancelationCharge}
                        name="cancelationCharge"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Appointment Charge
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Appointment Charge"
                        required
                        value={forms.appointmentCharges}
                        name="appointmentCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Transaction Charges
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="EnterTransaction Charges"
                        required
                        value={forms.transactionCharges}
                        name="transactionCharges"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Gst Charges <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Gst Charges"
                        required
                        value={forms.gst}
                        name="gst"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        for="basicpill-firstname-input1"
                        style={{ fontSize: "10px" }}
                      >
                        Tds <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Tds"
                        required
                        value={forms.tds}
                        name="tds"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <hr></hr>
                <div style={{ float: "right" }} className="m-2">
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default DigitalBrochure
