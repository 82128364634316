import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import profileImg from "../../assets/images/logo.png"
import classnames from "classnames"
import ReactPaginate from "react-paginate"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function State() {
  const [isLoading, setIsLoading] = useState(true)

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const history = useHistory()

  const [forms, setforms] = useState([])

  const [book, setbook] = useState([])

  const [cmbook, setcmbook] = useState([])

  const [Leaves, setLeaves] = useState([])

  const [atpSmiles, setatpSmiles] = useState([])
  

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("AtpidD")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetIdAtp, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.atpResult)
        setatpSmiles(res.data.atpSmiles)
        setbook(res.data.bookings)
        setcmbook(res.data.completedBookingsData)
        setLeaves(res.data.leaves)
        setIsLoading(false)
      })
  }

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = book.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(book.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [listPerPage1] = useState(20)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = cmbook.slice(pagesVisited1, pagesVisited1 + listPerPage1)
  const pageCount1 = Math.ceil(cmbook.length / listPerPage1)
  const changePage1 = ({ selected1 }) => {
    setPageNumber1(selected1)
  }

  const Actinidd = data => {
    sessionStorage.setItem("bookingids", data.booking_id)
    history.push("/ViewLab")
  }

  const Actinid1 = data => {
    sessionStorage.setItem("Hospitalids", data.hospitalId)
    history.push("/HospitalView")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Atp Profile" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Profile
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "6",
                            })}
                            onClick={() => {
                              toggle1("6")
                            }}
                          >
                            Pending List : <b>{book.length}</b>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "7",
                            })}
                            onClick={() => {
                              toggle1("7")
                            }}
                          >
                            Collection List : <b>{cmbook.length}</b>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "8",
                            })}
                            onClick={() => {
                              toggle1("8")
                            }}
                          >
                            Leaves
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "9",
                            })}
                            onClick={() => {
                              toggle1("9")
                            }}
                          >
                            Bank Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "10",
                            })}
                            onClick={() => {
                              toggle1("10")
                            }}
                          >
                            ID Card
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={activeTab1}
                        className="p-4  text-muted"
                      >
                        <TabPane tabId="5">
                          <h5 className="mb-3">Profile Details : </h5>
                          <Row>
                            <Col lg={4}>
                              <ul className="list-unstyled vstack gap-3 mb-0">
                                <img
                                  src={URLS.Base + forms.image}
                                  height="150px"
                                  width="50%"
                                ></img>
                                <li>
                                  <div className="pt-3">
                                    <h6>
                                      <i
                                        style={{ fontSize: "20px" }}
                                        className="bx bx-smile  text-warning"
                                      ></i>{" "}
                                      {atpSmiles}
                                    </h6>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex ">
                                    <i className="bx bxs-buildings font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Atp Name:{" "}
                                      </h6>
                                      <span className="text-muted">
                                        {forms.firstName}
                                        {forms.lastName}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-money font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        ATP Id:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.atpCode}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-user font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Gender:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.gender}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-spreadsheet font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Date of Birth:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.dateOfBirth}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-bar-chart-square font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">Age:</h6>
                                      <span className="text-muted">
                                        {forms.age}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bx-phone font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Contact Number:
                                      </h6>
                                      {forms.phone}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex  mt-3">
                                    <i
                                      className="bx bx-message
                                 font-size-18 text-primary"
                                    ></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Email:
                                      </h6>
                                      {forms.email}
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bx-bookmark font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Status :
                                      </h6>
                                      <span className="text-muted">
                                        {forms.status}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                            <Col lg={8}>
                              <Row>
                                <Col>
                                  <ul className="verti-timeline list-unstyled">
                                    {/* <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Salary :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.salary}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li> */}
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Atp Type :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.atpType}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Type :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.idProofType}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Number :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.idProofNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Date Of Joning:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.logCreatedDate}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Qualification:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.qualification}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Year Of Experience:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.yearOfExperience}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Year Of Graduation:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.yearOfGraduation}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>{" "}
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Timming From Time to To Time:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.fromTime} / {forms.toTime}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              State
                                            </h6>
                                            <p className="text-muted">
                                              {forms.stateName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              District
                                            </h6>
                                            <p className="text-muted">
                                              {forms.districtName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              City{" "}
                                            </h6>
                                            <p className="text-muted">
                                              {forms.cityName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Zone Name{" "}
                                            </h6>
                                            <p className="text-muted">
                                              {forms.zoneName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Address
                                            </h6>
                                            <p className="text-muted">
                                              {forms.address}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Number:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.idProofNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Image:
                                            </h6>
                                            <p className="text-muted">
                                              <img
                                                src={
                                                  URLS.Base + forms.idProofImage
                                                }
                                                height="100px"
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Certificates:
                                            </h6>
                                            <p className="text-muted">
                                              <img
                                                src={
                                                  URLS.Base + forms.certificates
                                                }
                                                height="100px"
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="6">
                          <h5 className="mb-3">Pending List: </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>HCU ID</th>
                                  <th>HCU Name</th>
                                  <th>HCU Zone</th>
                                  <th>ACTIN Booking Id</th>
                                  <th>ACTIN User Id</th>
                                  <th>User Name</th>
                                  <th>Contact No</th>
                                  <th>Test Details</th>
                                  <th>Date</th>
                                  <th>Type of booking</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              <tbody>
                                {lists.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{key + 1}</td>
                                    <td>
                                      <Link
                                        onClick={() => {
                                          Actinid1(data)
                                        }}
                                      >
                                        {data.hocId}
                                      </Link>
                                    </td>
                                    <td>{data.hospitalName}</td>
                                    <td>{data.zoneName}</td>
                                    <td>{data.bookingCode}</td>
                                    <td>{data.userCode}</td>
                                    <td>{data.customerName}</td>
                                    <td>{data.customerPhone}</td>
                                    <td className="text-start">
                                      {data.items.map((data, i) => (
                                        <>
                                          <p>
                                            {i + 1}). {data.itemName}
                                          </p>
                                        </>
                                      ))}
                                    </td>
                                    <td>
                                      {data.bookingDate}/{data.bookingTime}
                                    </td>
                                    <td>{data.testType}</td>
                                    <td>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Actinidd(data)
                                        }}
                                        color="info"
                                        outline
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists.length}
                              />
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="7">
                          <h5 className="mb-3">Collection List: </h5>
                          <div className="table-responsive" style={{height:"480px"}}>
                          <Table hover className="table table-bordered mb-4 ">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>HCU ID</th>
                                <th>HCU Name</th>
                                <th>HCU Zone</th>
                                <th>ACTIN User Id</th>
                                <th>ACTIN Booking Id</th>
                                <th>User Name</th>
                                <th>Contact No</th>
                                <th>Test Details</th>
                                <th>Date</th>
                                <th>Type of booking</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {lists1.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{key + 1}</td>
                                  <td>
                                    <Link
                                      onClick={() => {
                                        Actinid1(data)
                                      }}
                                    >
                                      {data.hocId}
                                    </Link>
                                  </td>
                                  <td>{data.hospitalName}</td>
                                  <td>{data.zoneName}</td>
                                  <td>{data.bookingCode}</td>
                                  <td>{data.userCode}</td>
                                  <td>{data.customerName}</td>
                                  <td>{data.customerPhone}</td>
                                  <td className="text-start">
                                    {data.items.map((data, i) => (
                                      <>
                                        <p>
                                          {i + 1}). {data.itemName}
                                        </p>
                                      </>
                                    ))}
                                  </td>
                                  <td>
                                    {data.bookingDate}/{data.bookingTime}
                                  </td>
                                  <td>{data.testType}</td>
                                  <td>
                                    <span className="badge bg-success ">
                                      {data.status}
                                    </span>
                                  </td>
                                  <td>
                                    <Button
                                      className="mr-2"
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      onClick={() => {
                                        Actinidd(data)
                                      }}
                                      color="info"
                                      outline
                                    >
                                      <i className="fas fa-eye"></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table></div>
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount1}
                              onPageChange={changePage1}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists1.length}
                            />
                          </div>
                        </TabPane>
                        <TabPane tabId="8">
                          <h5 className="mb-3">Leaves: </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>SlNo</th>
                                  <th>From Date</th>
                                  <th>To Date</th>
                                  <th>Reason</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Leaves.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{key + 1}</td>
                                    <td>{data.fromDate}</td>
                                    <td>{data.toDate}</td>
                                    <td>{data.leaveReason}</td>
                                    <td>{data.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </TabPane>
                        <TabPane tabId="9">
                          <Row>
                            <Col md={6}>
                              <ul className="verti-timeline list-unstyled">
                                <li className="event-list  mt-2">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Bank Name
                                        </h6>
                                        <p className="text-muted">
                                          {forms.bankName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Account Holder Name
                                        </h6>
                                        <p className="text-muted">
                                          {forms.acccountHolderName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Account Number
                                        </h6>
                                        <p className="text-muted">
                                          {forms.accountNumber}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Branch
                                        </h6>
                                        <p className="text-muted">
                                          {forms.branch}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          IFSC code
                                        </h6>
                                        <p className="text-muted">
                                          {forms.ifscCode}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          HCU Pan Number
                                        </h6>
                                        <p className="text-muted">
                                          {forms.panCardNumber}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                            <Col md={6}>
                              <ul className="verti-timeline list-unstyled">
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 mb-3">
                                          Cancelled Cheque
                                        </h6>
                                        <p className="text-muted">
                                          <a
                                            href={
                                              URLS.Base + forms.cancelledCheck
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={
                                                URLS.Base + forms.cancelledCheck
                                              }
                                              height="150px"
                                              width="90%"
                                            ></img>
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>

                                <li className="event-list mt-5">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 mb-3">
                                          Pan Card
                                        </h6>
                                        <p className="text-muted">
                                          <a
                                            href={
                                              URLS.Base + forms.panCardImage
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={
                                                URLS.Base + forms.panCardImage
                                              }
                                              height="150px"
                                              width="90%"
                                            ></img>{" "}
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="10">
                          <Row>
                            <h5
                              className="text-center mb-4 mt-3"
                              style={{ color: "black" }}
                            >
                              ID Card
                            </h5>
                            <Col md={4}> </Col>
                            <Col md={4}>
                              <Card className="overflow-hidden">
                                <div className="bg-danger bg-soft">
                                  <Row>
                                    <Col xs="7">
                                      <img
                                        src={profileImg}
                                        alt=""
                                        className="img-fluid mt-5 mb-4"
                                      />
                                    </Col>
                                    <Col md={5}>
                                      <p
                                        style={{
                                          float: "right",
                                          paddingTop: "55px",
                                          color: "black",
                                          fontWeight: "bold",
                                          paddingRight: "12px",
                                        }}
                                      >
                                        www.ACTIN.com
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <CardBody
                                  className="pt-0"
                                  style={{ background: "rgb(250,250,250)" }}
                                >
                                  <Row>
                                    <Col md={9}>
                                      <p className="pt-5">
                                        <b
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          Name :
                                        </b>
                                        <span style={{ color: "black" }}>
                                          {forms.firstName}
                                          {forms.lastName}
                                        </span>
                                      </p>
                                      <p className="pt-1">
                                        <b
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          Phone :
                                        </b>
                                        <span style={{ color: "black" }}>
                                          {forms.phone}
                                        </span>
                                      </p>
                                      <p className="pt-1">
                                        <b
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          Email :
                                        </b>
                                        <span style={{ color: "black" }}>
                                          {forms.email}
                                        </span>
                                      </p>
                                      <p className="pt-1 ">
                                        <b
                                          style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            paddingRight: "10px",
                                          }}
                                        >
                                          Address :
                                        </b>
                                        <span style={{ color: "black" }}>
                                          {forms.address}
                                        </span>
                                      </p>
                                    </Col>
                                    <Col md={3}>
                                      <div
                                        className="avatar-lg profile-user-wid mb-5"
                                        style={{ float: "right" }}
                                      >
                                        <img
                                          src={URLS.Base + forms.image}
                                          alt=""
                                          className="img-thumbnail rounded-circle"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr></hr>
                                  <p
                                    style={{
                                      textAlign: "center ",
                                      color: "black",
                                    }}
                                  >
                                    Housing Board, Hyderabad - 500085 .
                                  </p>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md={4}></Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
