import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState([])

  const [selectedFiles, setselectedFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  var datasid = data.user._id

  console.log(datasid)

  const handleSubmit = e => {
    e.preventDefault()
    AddStaff()
  }

  const history = useHistory()
  const AddStaff = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("topicName", form.topicName)
    dataArray.append("title", form.title)
    dataArray.append("description", form.description)
    dataArray.append("userId", form.userId)
    dataArray.append("staffId", datasid)
    dataArray.append("status", form.status)
    dataArray.append("assignedToId", form.assignedToId)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("image", selectedFiles[i])
    }

    axios
      .put(URLS.EditEnquiry + form._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Prescriptions")
            sessionStorage.setItem("tost", "Staff has been Added Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
          
  const [dep, setdep] = useState([])

  useEffect(() => {
    Getalldep()
    GetOneActins()
  }, [])
        
  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetUser,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setdep(res.data.userResult)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [staff, setstaff] = useState([])

  useEffect(() => {
    GetZones()
  }, [])

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaff,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setstaff(res.data.staff)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Actinid = sessionStorage.getItem("prescriptionid")

  const GetOneActins = () => {
    const dataS = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetOneEnquiry, dataS, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.enquiryResult)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ENQUIRY List" breadcrumbItem="Edit ENQUIRY" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      General Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        User Name <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.userId}
                        name="userId"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value="">Select </option>
                        {dep.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Topic Name <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.topicName}
                        name="topicName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value="">Select </option>
                        <option value="Tests at Home">Tests at Home </option>
                        <option value="Investigation at Hoc">
                          Investigation at Hoc{" "}
                        </option>
                        <option value="Doctor Consultation">
                          Doctor Consultation{" "}
                        </option>
                        <option value="Home Service">Home Service </option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Title <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter title"
                        required
                        value={form.title}
                        name="title"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Drescription <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="2"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Drescription"
                        required
                        value={form.description}
                        name="description"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}> Image</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload Image</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Status<span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.status}
                        name="status"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value="">Select </option>
                        <option value="Solved">Solved</option>
                        <option value="Pending">Pending </option>
                      </select>
                    </div>{" "}
                  </Col>
                  {form.status == "Pending" ? (
                    <>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Assign to<span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            required
                            value={form.assignedToId}
                            name="assignedToId"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value="">Select </option>
                            {staff.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.firstName} {data.lastName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              </CardBody>
            </Card>

            <Row className="mt-2">
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors
