import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { Link } from "react-router-dom"
import avatar1 from "../../assets/images/HS1.png"

function State() {
  const [modal_small, setmodal_small] = useState(false)

  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  // useEffect(() => {
  //   Getalldep()
  // }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const AddDep = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("title", form.title)

    axios
      .post(URLS.AddState, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            clearForm()
            Getalldep()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditDep = () => {
    var token = datas
    var formid = form1._id

    const dataArray = new FormData()
    dataArray.append("title", form1.title)

    axios
      .put(URLS.UpdateState + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            Getalldep()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Deletedep = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveState + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            Getalldep()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Deletedep(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddDep()
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    EditDep()
  }

  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setbanner(res.data.states)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform({
      title: "",
      stateId: "",
    })
  }
  const getpopup = () => {
    // setform1(data)
    tog_small()
  }

  const getpopup1 = () => {
    tog_small()
  }

  const [forms, setforms] = useState([])

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetStateSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.states)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const downloadImage = () => {
    saveAs(avatar1)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Prescription deciphering" />

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div style={{ float: "right" }}>
                        <Input
                          type="search"
                          name="search"
                          value={forms.search}
                          onChange={handlechange}
                          className="form-control"
                          placeholder="Search.."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive" style={{height:"480px"}}>
                    <Table className="table table-bordered mb-2 mt-3">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Date/Time</th>
                          <th>UserId/UserName</th>
                          <th>Phone </th>
                          <th>Email</th>
                          <th>DoctorId/DoctorName</th>
                          <th>HCUId/HCUName </th>
                          <th>Prescription Image</th>
                          <th>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>10-10-2023/04:49pm</td>
                          <td>
                            <Link to="/ViewUser">User1122 </Link>/Rahul
                          </td>
                          <td>8887744554</td>
                          <td>Ruhul@gmail.com</td>
                          <td>Drc06/Ramesh</td>
                          <td><Link to="/HospitalView">Hec1122 </Link>/Apollo</td>
                          <td>
                            <Button
                              onClick={() => {
                                downloadImage()
                              }}
                              size="sm"
                              className="m-1"
                              outline
                              color="danger"
                            >
                              <i
                                style={{ fontSize: " 14px" }}
                                className="bx bxs-receipt"
                              ></i>
                            </Button>
                          </td>
                          <td>
                            <Button
                              className="mr-2"
                              style={{
                                padding: "6px",
                                margin: "3px",
                              }}
                              onClick={() => {
                                getpopup()
                              }}
                              color="success"
                              outline
                            >
                              <i className="bx bx-edit "></i>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Prescription 
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                {" "}
                <Col>
                  {/* <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      File <span className="text-danger">*</span>
                    </Label>
                    <input
                      type="file"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      required
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div> */}
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Description</Label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input1"
                      placeholder="Enter Description"
                      required
                      rows="5"
                      name="title"
                      value={form1.title}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
