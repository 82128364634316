import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    AddStaff()
  }

  const [files, setFiles] = useState([])
  const [files1, setFiles1] = useState([])
  const [files2, setFiles2] = useState([])
  const [files3, setFiles3] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler2 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const history = useHistory()
  const AddStaff = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("firstName", form.firstName)
    dataArray.append("lastName", form.lastName)
    dataArray.append("phone", form.phone)
    dataArray.append("selectGender", form.selectGender)
    dataArray.append("age", form.age)
    dataArray.append("dateOfBirth", form.dateOfBirth)
    dataArray.append("idProofType", form.idProofType)
    dataArray.append("idProofNumber", form.idProofNumber)
    dataArray.append("qualification", form.qualification)
    dataArray.append("yearOfGraduation", form.yearOfGraduation)
    dataArray.append("yearOfExperience", form.yearOfExperience)
    dataArray.append("cityId", form.cityId)
    dataArray.append("districtId", form.districtId)
    dataArray.append("stateId", form.stateId)
    dataArray.append("address", form.address)
    dataArray.append("bankName", form.bankName)
    dataArray.append("accountNumber", form.accountNumber)
    dataArray.append("acccountHolderName", form.acccountHolderName)
    dataArray.append("branch", form.branch)
    dataArray.append("ifscCode", form.ifscCode)
    dataArray.append("panCardNumber", form.panCardNumber)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("confirmPassword", form.confirmPassword)
    dataArray.append("departmentId", form.departmentId)
    dataArray.append("roleId", form.roleId)
    dataArray.append("salary", form.salary)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("image", selectedFiles[i])
    }

    for (let i = 0; i < files.length; i++) {
      dataArray.append("idProofImage", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("certificates", files1[i])
    }

    for (let i = 0; i < files2.length; i++) {
      dataArray.append("panCardImage", files2[i])
    }

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("cancelledCheck", files3[i])
    }

    axios
      .put(URLS.EditStaff + "/" + form._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Staff")
            sessionStorage.setItem(
              "tost",
              "Staff has been Updated Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [dep, setdep] = useState([])

  useEffect(() => {
    Getalldep()
    getstates()
    GetOneActins()
    GetRoles()
  }, [])

  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetDepartment,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setdep(res.data.departments)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [role, setrole] = useState([])

  const Optionchangess = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    // var token = datas
    // const data = {
    //   departmentId: e.target.value,
    // }

    // axios
    //   .post(URLS.GetIdRole, data, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   })
    //   .then(res => {
    //     setrole(res.data.departmentResult)
    //   })
  }

  const GetRoles = () => {
    var token = datas
    axios
      .post(
        URLS.GetRole,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setrole(res.data.Roles)
      })
  }

  const [states, setstates] = useState([])
  const [District, setDistrict] = useState([])
  const [City, setCity] = useState([])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.distResult)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  const Optionchange2 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const Actinid = sessionStorage.getItem("staffid")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetIdStaff, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.staffResult)

        var token = datas

        const ds = res.data.staffResult.stateId

        const dataArray = new FormData()
        dataArray.append("stateId", ds)
        axios
          .post(URLS.GetIdDistrict, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setDistrict(res.data.distResult)
          })

        const dss = res.data.staffResult.districtId

        const dataArrays = new FormData()
        dataArrays.append("districtId", dss)
        axios
          .post(URLS.GetIdCity, dataArrays, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(res => {
            setCity(res.data.cityResult)
          })

        // const datasd = {
        //   departmentId: res.data.staffResult.departmentId,
        // }

        // axios
        //   .post(URLS.GetIdRole, datasd, {
        //     headers: { Authorization: `Bearer ${token}` },
        //   })
        //   .then(res => {
        //     setrole(res.data.departmentResult)
        //   })
      })
  }

  const [form1, setform1] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup1 = data => {
    tog_small()
    setform1(data)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACT List" breadcrumbItem="Edit Staff" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      General Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter first  Name"
                        required
                        value={form.firstName}
                        name="firstName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter last Name"
                        required
                        value={form.lastName}
                        name="lastName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone"
                        required
                        value={form.phone}
                        name="phone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label> Department</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.departmentId}
                        name="departmentId"
                        required
                        onChange={e => {
                          Optionchangess(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {dep.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.departmentName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Profile</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}

                          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="40"
                                    className="avatar-sm rounded bg-light"
                                    alt=""
                                    onClick={() => {
                                      getpopup1(URLS.Base + form.image)
                                    }}
                                    src={URLS.Base + form.image}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> Roles </Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        name="roleId"
                        onChange={e => {
                          handlechange(e)
                        }}
                        value={form.roleId}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {role.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.roleName}
                            </option>
                          )
                        })}
                      </select>{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Salary <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Salary"
                        required
                        value={form.salary}
                        name="salary"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select Gender <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.selectGender}
                        name="selectGender"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Male"> Male </option>
                        <option value="Female"> Female </option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date Of Birth<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Qualification"
                        required
                        value={form.dateOfBirth}
                        name="dateOfBirth"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Age<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Age"
                        required
                        value={form.age}
                        name="age"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Id Proof Type <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.idProofType}
                        name="idProofType"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Aadhaar"> Aadhaar </option>
                        <option value="Drivinglicence">
                          {" "}
                          Driving licence{" "}
                        </option>

                        <option value="Passport"> Passport </option>
                        <option value="VoterId">Voter Id</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      {form.idProofType == "Aadhaar" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Aadhaar Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Aadhaar Number"
                            required
                            minLength="12"
                            maxLength="12"
                            pattern="[0-9]+"
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {form.idProofType == "Drivinglicence" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Driving licence Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Driving licence Number"
                            required
                            minLength="12"
                            maxLength="16"
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {form.idProofType == "Passport" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Passport Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Passport Number"
                            required
                            minLength="8"
                            maxLength="12"
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {form.idProofType == "VoterId" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Voter Id
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Voter Id"
                            required
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Id Proof Image{" "}
                        <span className="text-danger">
                          *
                          <Button
                            onClick={() => {
                              getpopup1(URLS.Base + form.idProofImage)
                            }}
                            className="mr-2"
                            style={{
                              padding: "1px",
                            }}
                            color="info"
                            outline
                            type="button"
                          >
                            <i
                              style={{ fontSize: " 14px" }}
                              className="fas fa-eye"
                            ></i>
                          </Button>
                        </span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Qualification<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Qualification"
                        required
                        value={form.qualification}
                        name="qualification"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Certificates{" "}
                        <span className="text-danger">
                          *{" "}
                          <Button
                            onClick={() => {
                              getpopup1(URLS.Base + form.certificates)
                            }}
                            className="mr-2"
                            style={{
                              padding: "1px",
                            }}
                            color="info"
                            outline
                            type="button"
                          >
                            <i
                              style={{ fontSize: " 14px" }}
                              className="fas fa-eye"
                            ></i>
                          </Button>
                        </span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler1}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Year Of Experience<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Year Of Experience"
                        required
                        value={form.yearOfExperience}
                        name="yearOfExperience"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Year Of Graduation<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Year Of Graduation"
                        required
                        value={form.yearOfGraduation}
                        name="yearOfGraduation"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> State</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.stateId}
                        name="stateId"
                        required
                        onChange={e => {
                          Optionchange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.stateName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> District</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.districtId}
                        name="districtId"
                        required
                        onChange={e => {
                          Optionchange1(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.districtName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> City </Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.cityId}
                        name="cityId"
                        required
                        onChange={e => {
                          Optionchange2(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {City.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.cityName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>{" "}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#467BE5", fontWeight: "bold" }}
                    >
                      Bank Details
                    </h5>
                    <Row className="mt-3">
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Bank Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Bank Name"
                            required
                            value={form.bankName}
                            name="bankName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Holder Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Holder Name"
                            required
                            value={form.acccountHolderName}
                            name="acccountHolderName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Number"
                            required
                            value={form.accountNumber}
                            name="accountNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Branch<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Branch"
                            required
                            value={form.branch}
                            name="branch"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            IFSC code
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter IFSC code"
                            required
                            value={form.ifscCode}
                            name="ifscCode"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Pan Card Number
                            <span className="text-danger">* </span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Pan Card Number"
                            required
                            value={form.panCardNumber}
                            name="panCardNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Pan Card Image
                            <span className="text-danger">
                              *{" "}
                              <Button
                                className="mr-2"
                                style={{
                                  padding: "1px",
                                }}
                                color="info"
                                outline
                                type="button"
                                onClick={() => {
                                  getpopup1(URLS.Base + form.panCardImage)
                                }}
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="fas fa-eye"
                                ></i>
                              </Button>
                            </span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler2}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Cancelled Cheque
                            <span className="text-danger">
                              *{" "}
                              <Button
                                onClick={() => {
                                  getpopup1(URLS.Base + form.cancelledCheck)
                                }}
                                className="mr-2"
                                style={{
                                  padding: "1px",
                                }}
                                color="info"
                                outline
                                type="button"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="fas fa-eye"
                                ></i>
                              </Button>
                            </span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler3}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Email"
                            required
                            value={form.email}
                            name="email"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>{" "}
            </Row>
            <Row>
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-check-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Image
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img alt="" src={form1} width="100%" />
            </div>
          </Modal>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
