import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Form,
  Label,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { toast, ToastContainer } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const Actinid = data => {
    sessionStorage.setItem("bookingids", data.id)
    history.push("/ViewLab")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetHospitals()
    GetZones()
    GetAtps()
    GetHospital()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllHomeTestsBookings,
        {
          date: "",
          zoneId: "",
          atpId: "",
          hospitalId: "",
          type: "Emergency Test",
          status: "",
          paymentStatus: "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.booking)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    date: "",
    zoneId: "",
    atpId: "",
    hospitalId: "",
    type: "Emergency Test",
    status: "",
    paymentStatus: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const data = {
      date: filters.date,
      zoneId: filters.zoneId,
      atpId: filters.atpId,
      hospitalId: filters.hospitalId,
      type: "Emergency Test",
      status: filters.status,
      paymentStatus: filters.paymentStatus,
    }
    axios
      .post(URLS.GetAllHomeTestsBookings, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.booking)
        hidefilter()
        setfilters({
          date: "",
          zoneId: "",
          atpId: "",
          hospitalId: "",
          type: "Emergency Test",
          status: "",
          paymentStatus: "",
        })
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const [zones, setZones] = useState([])

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setZones(res.data.zones)
      })
  }

  const [Atp, setAtp] = useState([])

  const GetAtps = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtp,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setAtp(res.data.atp)
      })
  }

  const [Hospital, setHospital] = useState([])

  const GetHospital = () => {
    var token = datas
    const dataarray = {
      zoneId: "",
      serviceList: "",
      dateOfJoining: "",
    }
    axios
      .post(URLS.GetHospital, dataarray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setHospital(res.data.hospital)
      })
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllHomeTestsBookingsSearch + `${e.target.value}`,
        {
          date: "",
          zoneId: "",
          atpId: "",
          hospitalId: "",
          type: "Emergency Test",
          status: "",
          paymentStatus: "",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.booking)
      })
  }

  const [Actins, setActins] = useState([])

  useEffect(() => {
    GetAtpss()
  }, [])

  const GetAtpss = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtp,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActins(res.data.atp)
      })
  }

  const [modal_small1, setmodal_small1] = useState(false)

  const [form2, setform2] = useState([])

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    Editstate1()
  }

  const Editstate1 = () => {
    var token = datas
    var formid = form2.id

    const dataArray = new FormData()
    dataArray.append("reassignAtpId", form2.reassignAtpId)

    axios
      .put(URLS.ResignAtpbookings + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetHospital()
            toast(res.data.message)
            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [modal_small2, setmodal_small2] = useState(false)

  const [form3, setform3] = useState([])
  const [form4, setform4] = useState([])

  const handleChange3 = e => {
    let myUser = { ...form3 }
    myUser[e.target.name] = e.target.value
    setform3(myUser)
  }

  function tog_small3() {
    setmodal_small2(!modal_small2)
  }

  const getpopup3 = data => {
    setform4(data)
    tog_small3()
  }

  const handleSubmit3 = e => {
    e.preventDefault()
    Editstate3()
  }

  const Editstate3 = () => {
    var token = datas

    const dataArray = {
      bookingId: form4.id,
      amount: form3.amount,
    }

    axios
      .put(URLS.RefundAmountt, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetHospitals()
            toast(res.data.message)
            setmodal_small2(false)
            setform3({
              amount: "",
            })
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Emergency Booking list" />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-declaration-input10">
                            Day Wise
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-Declaration-input10"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="date"
                            value={filters.date}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Zone Wise
                          </Label>
                          <select
                            className="form-select"
                            value={filters.zoneId}
                            name="zoneId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            {" "}
                            <option value=""> Select </option>
                            {zones.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Atp Wise
                          </Label>

                          <select
                            className="form-select"
                            value={filters.atpId}
                            name="atpId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            {" "}
                            <option value=""> Select </option>
                            {Atp.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.firstName}
                                  {data.lastName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Hospital Wise
                          </Label>
                          <select
                            className="form-select"
                            value={filters.hospitalId}
                            name="hospitalId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            {" "}
                            <option value=""> Select </option>
                            {Hospital.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.hospitalName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <select
                            value={filters.status}
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="status"
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="pending">Pending</option>
                            <option value="booking confirmed">
                              Booking Confirmed
                            </option>
                            <option value="atp assigned">Atp Assigned</option>
                            <option value="atp started">Atp Started</option>
                            <option value="otp verfied">Otp Verfied</option>
                            <option value="sample collected">
                              Sample Collected
                            </option>
                            <option value="sample reached">
                              Sample Reached
                            </option>
                            <option value="sample denied">Sample Denied</option>
                            <option value="rescheduled">Rescheduled</option>
                            <option value="completed">Completed</option>
                            <option value="canceled">Canceled</option>
                            <option value="failed">Failed</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label>Payment Status</Label>
                          <select
                            value={filters.paymentStatus}
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="paymentStatus"
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="cod">Cod</option>
                            <option value="online">Online</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col>
                          {" "}
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                        </Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>HCU ID</th>
                              <th>HCU Name</th>
                              <th>Zone Name</th>
                              <th>ACTIN User Id</th>
                              <th>ACTIN Booking Id</th>
                              <th>User Name</th>
                              <th>Contact No</th>
                              <th>Atp Id / Atp Name</th>
                              <th>Test Details</th>
                              <th>Date</th>
                              <th>Timer</th>
                              <th>Total Amount</th>
                              <th>Refund Amount</th>
                              <th>Payment Type</th>
                              <th>Payment Status</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.hospitalCode}</td>
                                <td>{data.hocName}</td>
                                <td>{data.zoneName}</td>
                                <td>{data.userCode}</td>
                                <td>{data.bookingCode}</td>
                                <td>{data.customerName}</td>
                                <td>{data.customerPhone}</td>
                                <td>
                                  {data.atpId == "" || data.atpname == "" ? (
                                    <>-</>
                                  ) : (
                                    <>
                                      {data.atpId}/{data.atpname}
                                    </>
                                  )}
                                </td>
                                <td className="text-start">
                                  {data.items.map((data, i) => (
                                    <>
                                      <p key={i}>
                                        {i + 1}){data.itemName}
                                      </p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.bookingDate}/{data.bookingTime}
                                </td>
                                <td>
                                  <p style={{ color: "red" }}>
                                    {data.bookingTestHrs}
                                  </p>
                                </td>
                                <td>{data.totalAmount}</td>
                                <td>{data.refundAmount}</td>
                                <td>{data.paymentType}</td>
                                <td>{data.paymentStatus}</td>
                                <td>
                                  {data.status == "canceled" ? (
                                    <>
                                      <span className="badge bg-danger">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {data.status == "completed" ? (
                                        <>
                                          <span className="badge bg-success">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    onClick={() => {
                                      Actinid(data)
                                    }}
                                    color="info"
                                    outline
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                  {Roles?.emergencyBookingListEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "atp assigned" ||
                                      data.status == "booking confirmed" ? (
                                        <>
                                          <Button
                                            onClick={() => {
                                              getpopup1(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="success"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="bx bx-repeat"
                                            ></i>
                                          </Button>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {/* <Button
                                    onClick={() => {
                                      getpopup3(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="warning"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-wallet-alt"
                                    ></i>
                                  </Button> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Re-Assign To Atp
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit2(e)
                }}
              >
                <div className="mb-3">
                  <Label> Status</Label> <span className="text-danger">*</span>
                  <select
                    value={form2.reassignAtpId}
                    name="reassignAtpId"
                    onChange={e => {
                      handleChange2(e)
                    }}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {Actins.map((data, key) => {
                      return (
                        <option key={key} value={data._id}>
                          {data.firstName}
                          {data.lastName}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            size="md"
            isOpen={modal_small2}
            toggle={() => {
              tog_small3()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Refund Amount
              </h5>
              <button
                onClick={() => {
                  setmodal_small2(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit3(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Refund Amount<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter  Refund Amount"
                    required
                    value={form3.amount}
                    name="amount"
                    onChange={e => {
                      handleChange3(e)
                    }}
                  />
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small2(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
