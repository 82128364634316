import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import { useHistory } from "react-router-dom"

function Support() {
  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [banner, setbanner] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  function tog_small() {
    setmodal_small(!modal_small)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetPAYMENTGENERATE,
        { monthYear: "" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.payments)
        setIsLoading(false)
      })
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetPAYMENTGENERATESearch + `${e.target.value}`,
        { monthYear: "" },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.payments)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const ZoneIdszs = data => {
    sessionStorage.setItem("Paymentid", data._id)
    history.push("/ViewPayment")
  }

  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  const getpopup = data => {
    setform2(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    Editstatus()
  }

  const Editstatus = () => {
    var token = datas

    const dataArray = {
      status: form1.status,
      transactionId: form1.transactionId,
    }

    axios
      .put(URLS.UPDATEPAYMENTGENERATE + "/" + form2._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetAllBanners()
            toast(res.data.message)
            setmodal_small(false)
            setform1({})
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="Payment Generate" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white">
                      <CardTitle>
                        {/* <Button
                          onClick={() => {
                            getpopup()
                          }}
                          className="mr-2"
                          style={{
                            padding: "6px",
                            margin: "3px",
                            background: "#b30706",
                          }}
                        >
                          Payment Generate
                        </Button> */}
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead className="text-center">
                              <tr>
                                <th>S.No</th>
                                <th>HCU Code</th>
                                <th>HCU Name</th>
                                <th>Year / Month </th>
                                <th>Generate - Date / Time</th>
                                <th>Amount</th>
                                <th>Actin Amount</th>
                                <th>Tds</th>
                                <th>Description</th>
                                <th>Hcu Bill Number </th>
                                <th>Hcu Reciept Number </th>
                                <th>Transaction Id</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody className="text-center">
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.hocCode}</td>
                                  <td>{data.hospitalName}</td>
                                  <td>{data.monthYear}</td>
                                  <td>
                                    {data.logCreatedDate.slice(0, 10)} /
                                    {new Date(
                                      data.logCreatedDate
                                    ).toLocaleTimeString("en-US", {
                                      hour12: false,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      timeZone: "Asia/Kolkata",
                                    })}
                                  </td>
                                  <td>{data.hospitalAmount}</td>
                                  <td>{data.actinAmount}</td>
                                  <td>{data.tds}</td>
                                  <td>{data.description}</td>
                                  <td>{data.hcuBillNumber}</td>
                                  <td>{data.hcuRecieptNumber}</td>
                                  <td>{data.transactionId}</td>
                                  <td>
                                    {data.status == "Pending" ? (
                                      <>
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <span className="badge bg-success">
                                        {data.status}
                                      </span>
                                    )}
                                  </td>
                                  <td>

                                    {Roles?.paymentsEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            ZoneIdszs(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="info"
                                          outline
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-eye"
                                          ></i>
                                        </Button>

                                        <Button
                                          onClick={() => {
                                            getpopup(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="warning"
                                          outline
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="bx bx-repost"
                                          ></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Payment Generate
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input3">
                  Select Status <span className="text-danger">*</span>
                </Label>

                <select
                  className="form-select"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  required
                  name="status"
                  value={form1.status}
                >
                  <option value=""> Select</option>
                  <option value="approved"> Approved</option>
                  <option value="rejected"> Reject</option>
                </select>
              </div>

              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Transaction Id <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Transaction Id"
                  required
                  value={form1.transactionId}
                  name="transactionId"
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
