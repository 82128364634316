import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Table,
  Form,
  Label,
  Button,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function State() {
  const [Actin, setActin] = useState([])
  const [modal_small, setmodal_small] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllTestRequest,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.testrequest)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [form1, setform1] = useState([])

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas
    const dataArray = {
      _id: form1._id,
      status: form1.status,
      testid: form1.testid,
      nabhPrice: form1.nabhPrice,
      nonNabhPrice: form1.nonNabhPrice,
    }

    axios
      .put(URLS.UpdateTestRequest, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetHospitals()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  //view instructions ,information
  const [info, setinfo] = useState(false)
  const [ins, setins] = useState(false)
  const [Instructions, setInstructions] = useState([])
  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  function inst() {
    setins(!ins)
  }

  const getinfo = data => {
    setInformation(data.testInformation)
    infos()
  }

  const getinc = data => {
    setInstructions(data.testInstruction)
    inst()
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllTestRequestSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.testrequest)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="New Test Request" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr className="text-center">
                                <th>SlNo</th>
                                <th>HCU ID</th>
                                <th>HCU Name</th>
                                <th>HCU Zone</th>
                                <th>HCU Test Image</th>
                                <th>HCU TestID</th>
                                <th>HCU TestName</th>
                                <th>Public Price</th>
                                <th>HCU Price</th>
                                <th>Type of Sample</th>
                                <th>Type of Carrier </th>
                                <th>Sample Charge Limit </th>
                                <th>Transport Temperature </th>
                                <th>Test Information </th>
                                <th>Test Instruction</th>
                                <th>Status</th>
                                <th style={{ width: "100px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.hcuId}</td>
                                  <td>{data.hcuName}</td>
                                  <td>{data.zone_name}</td>
                                  <td>
                                    <img
                                      src={URLS.Base + data.image}
                                      height="60px"
                                      width="80px"
                                    ></img>
                                  </td>
                                  <td>{data.hcuTestId}</td>
                                  <td>{data.hcuTestName}</td>
                                  <td>{data.hcuTestPrice}</td>
                                  <td>{data.hcuCommission}</td>
                                  <td>{data.typeOfSample}</td>
                                  <td>{data.typeOfCarrier}</td>
                                  <td>{data.sampleChargeLimit}</td>
                                  <td>{data.transportTemperature}</td>

                                  <td className="text-center">
                                    <Button
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="warning"
                                      onClick={() => {
                                        getinfo(data)
                                      }}
                                    >
                                      <i className="bx bxs-file"></i> View
                                    </Button>
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="warning"
                                      onClick={() => {
                                        getinc(data)
                                      }}
                                    >
                                      <i className="bx bxs-file"></i> View
                                    </Button>
                                  </td>

                                  <td>
                                    {data.status == "Solved" ? (
                                      <>
                                        <span className="badge bg-success">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <span className="badge bg-warning">
                                        {data.status}
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    {Roles?.newTestsRequestEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        {data.status == "Solved" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <Button
                                              onClick={() => {
                                                getpopup(data)
                                              }}
                                              className="mr-2"
                                              style={{
                                                padding: "6px",
                                                margin: "3px",
                                              }}
                                              color="success"
                                              outline
                                            >
                                              <i className="bx bx-edit "></i>
                                            </Button>
                                          </>
                                        )}{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Status
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Actin Test Id <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Actin Test Id"
                  required
                  name="testid"
                  value={form1.testid}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Actin NABH</Label>
                <input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Actin NABH"
                  name="nabhPrice"
                  value={form1.nabhPrice}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Actin Non-NABH</Label>
                <input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Actin Non-NABH"
                  name="nonNabhPrice"
                  value={form1.nonNabhPrice}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label> Status</Label> <span className="text-danger">*</span>
                <select
                  value={form1.status}
                  name="status"
                  required
                  onChange={e => {
                    handleChange1(e)
                  }}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="Solved">Approved</option>
                </select>
              </div>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="lg"
          isOpen={info}
          toggle={() => {
            infos()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Information :-
            </h5>
            <button
              onClick={() => {
                setinfo(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="pt-2">
              <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
              {Information}
            </p>
          </div>
        </Modal>

        <Modal
          size="lg"
          isOpen={ins}
          toggle={() => {
            inst()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Instruction :-
            </h5>
            <button
              onClick={() => {
                setins(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {Instructions.map((data, i) => (
              <>
                <p key={i} className="pt-2">
                  <i className="bx bxs-send" style={{ color: "#B30706" }}></i>{" "}
                  {data.instructions}
                </p>
                <hr></hr>
              </>
            ))}
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
