import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    if (selectedFiles.length > 0) {
      AddAtps()
    } else {
      toast("Please upload  image")
    }
  }

  const [files, setFiles] = useState([])
  const [files1, setFiles1] = useState([])
  const [files2, setFiles2] = useState([])
  const [files3, setFiles3] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler2 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const history = useHistory()
  const AddAtps = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("firstName", form.firstName)
    dataArray.append("lastName", form.lastName)
    dataArray.append("phone", form.phone)
    dataArray.append("selectGender", form.selectGender)
    dataArray.append("age", form.age)
    dataArray.append("dateOfBirth", form.dateOfBirth)
    dataArray.append("idProofType", form.idProofType)
    dataArray.append("idProofNumber", form.idProofNumber)
    dataArray.append("qualification", form.qualification)
    dataArray.append("yearOfGraduation", form.yearOfGraduation)
    dataArray.append("yearOfExperience", form.yearOfExperience)
    dataArray.append("cityId", form.cityId)
    dataArray.append("districtId", form.districtId)
    dataArray.append("stateId", form.stateId)
    dataArray.append("address", form.address)
    dataArray.append("bankName", form.bankName)
    dataArray.append("accountNumber", form.accountNumber)
    dataArray.append("acccountHolderName", form.acccountHolderName)
    dataArray.append("branch", form.branch)
    dataArray.append("ifscCode", form.ifscCode)
    dataArray.append("panCardNumber", form.panCardNumber)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("confirmPassword", form.confirmPassword)
    dataArray.append("fromTime", form.fromTime)
    dataArray.append("toTime", form.toTime)
    dataArray.append("atpType", form.atpType)
    dataArray.append("zoneId", form.zoneId)

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("image", selectedFiles[i])
    }

    for (let i = 0; i < files.length; i++) {
      dataArray.append("idProofImage", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("certificates", files1[i])
    }

    for (let i = 0; i < files2.length; i++) {
      dataArray.append("panCardImage", files2[i])
    }

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("cancelledCheck", files3[i])
    }

    axios
      .post(URLS.AddAtp, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Act")
            sessionStorage.setItem("tost", "Atp has been Added Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const [states, setstates] = useState([])
  const [District, setDistrict] = useState([])
  const [City, setCity] = useState([])
  const [zone, setzone] = useState([])

  useEffect(() => {
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.distResult)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  const Optionchange2 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getZone(e.target.value)
  }

  const getZone = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("cityIdId", data)
    axios
      .post(URLS.GetcityIdZone, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setzone(res.data.cityResult)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACT List" breadcrumbItem="Add ATP" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      General Information
                    </h5>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        First Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter first  Name"
                        required
                        value={form.firstName}
                        name="firstName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Last Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter last Name"
                        required
                        value={form.lastName}
                        name="lastName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Phone <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Phone"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        value={form.phone}
                        name="phone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <Label> Atp Type </Label>
                      <span className="text-danger">*</span>
                      <select
                        name="atpType"
                        onChange={e => {
                          handlechange(e)
                        }}
                        value={form.atpType}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="Regular">Regular </option>
                        <option value="Buddy">Buddy </option>
                        <option value="Emergency">Emergency </option>
                      </select>
                    </div>

                    <Row>
                      <Col md={6}>
                        <Label for="basicpill-firstname-input1">
                          From Time<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="time"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Qualification"
                          required
                          value={form.fromTime}
                          name="fromTime"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="basicpill-firstname-input1">
                          To Time<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="time"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Qualification"
                          required
                          value={form.toTime}
                          name="toTime"
                          onChange={e => {
                            handlechange(e)
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Profile</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                 
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select Gender <span className="text-danger">*</span>
                      </Label>
                      <select
                        className="form-select"
                        required
                        value={form.selectGender}
                        name="selectGender"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Male"> Male </option>
                        <option value="Female"> Female </option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date Of Birth<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Qualification"
                        required
                        value={form.dateOfBirth}
                        name="dateOfBirth"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Age<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="test"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Age"
                        required
                        pattern="^[0-9]+$"
                        maxLength="3"
                        value={form.age}
                        name="age"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Id Proof Type <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.idProofType}
                        name="idProofType"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Aadhaar"> Aadhaar </option>
                        <option value="Drivinglicence">Driving licence</option>
                        <option value="Passport"> Passport </option>
                        <option value="VoterId">Voter Id</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      {form.idProofType == "Aadhaar" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Aadhaar Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Aadhaar Number"
                            required
                            minLength="12"
                            maxLength="12"
                            pattern="[0-9]+"
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {form.idProofType == "Drivinglicence" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Driving licence Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Driving licence Number"
                            required
                            minLength="12"
                            maxLength="16"
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {form.idProofType == "Passport" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Passport Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Passport Number"
                            required
                            minLength="8"
                            maxLength="12"
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {form.idProofType == "VoterId" ? (
                        <>
                          <Label for="basicpill-firstname-input1">
                            Voter Id
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Voter Id"
                            required
                            value={form.idProofNumber}
                            name="idProofNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Id Proof Image <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Qualification<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Qualification"
                        required
                        value={form.qualification}
                        name="qualification"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Certificates <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler1}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Year Of Experience<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Year Of Experience"
                        required
                        value={form.yearOfExperience}
                        name="yearOfExperience"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Year Of Graduation<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Year Of Graduation"
                        required
                        value={form.yearOfGraduation}
                        name="yearOfGraduation"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> State</Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.stateId}
                        name="stateId"
                        required
                        onChange={e => {
                          Optionchange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.stateName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> District</Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.districtId}
                        name="districtId"
                        required
                        onChange={e => {
                          Optionchange1(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.districtName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> City </Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.cityId}
                        name="cityId"
                        required
                        onChange={e => {
                          Optionchange2(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {City.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.cityName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label> Zone </Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.zoneId}
                        name="zoneId"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {zone.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#467BE5", fontWeight: "bold" }}
                    >
                      Bank Details
                    </h5>
                    <Row className="mt-3">
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Bank Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Bank Name"
                            required
                            value={form.bankName}
                            name="bankName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Holder Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Holder Name"
                            required
                            value={form.acccountHolderName}
                            name="acccountHolderName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Number"
                            required
                            type="text"
                            pattern="[0-9]+"
                            value={form.accountNumber}
                            name="accountNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Branch<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Branch"
                            required
                            value={form.branch}
                            name="branch"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            IFSC code
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter IFSC code"
                            required
                            value={form.ifscCode}
                            pattern="^[A-Z]{4}0\d{6}$"
                            name="ifscCode"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Pan Card Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter  Pan  Card Number"
                            required
                            value={form.panCardNumber}
                            pattern="^[A-Z]{5}[0-9]{4}[A-Z]$"
                            name="panCardNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Pan Card Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler2}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Cancelled Cheque
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler3}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h5
                      className=""
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      Account Information
                    </h5>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Email"
                            required
                            value={form.email}
                            name="email"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Password<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Password"
                            required
                            value={form.password}
                            name="password"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-4">
                          <Label for="basicpill-firstname-input1">
                            Confirm Password
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Confirm Password"
                            required
                            value={form.confirmPassword}
                            name="confirmPassword"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col md={12}>
                    <div className=" mb-2" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-info m-1"
                      >
                        Submit <i className="fas fa-check-circle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors
