import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"
import Moment from "react-moment"

function Ventures() {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    getActin()
  }, [])

  const getActin = () => {
    var token = datas
    axios
      .post(
        URLS.GetRequestprofile,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.requestforProfileList)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const Actinid = data => {
    sessionStorage.setItem("Hospitalid", data.hospitalObjId)
    history.push("/EditHospital")
  }

  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  const getpopup = data => {
    setform2(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstatus()
  }

  const Editstatus = () => {
    var token = datas

    const dataArray = {
      status: form1.status,
      description: form1.description,
      id: form2._id,
    }

    axios
      .post(URLS.EditProfileRequestprofile, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            getActin()
            toast(res.data.message)
            setmodal_small(false)
            setform1({})
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas

    axios
      .post(
        URLS.GetRequestprofileSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.requestforProfileList)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Hospital Edit Request " />
          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Ticket ID</th>
                              <th>HCU ID</th>
                              <th>HCU Name</th>
                              <th>HCU Email</th>
                              <th>Zone</th>
                              <th>Date</th>
                              <th>Subject</th>
                              <th>Upload</th>
                              <th>Description</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                <td>{data.ticketId}</td>
                                <td>{data.hospitalCode}</td>
                                <td>{data.hospitalName}</td>
                                <td>{data.email}</td>
                                <td>{data.hospitalzonename}</td>

                                <td>
                                  <Moment format="DD-MM-YYYY">
                                    {data.logCreatedDate}
                                  </Moment>
                                  /
                                  <Moment format="hh:mm A">
                                    {data.logCreatedDate}
                                  </Moment>
                                </td>

                                <td>{data.subject}</td>
                                <td>
                                  <a
                                    href={URLS.Base + data.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      style={{ width: "100px" }}
                                      src={URLS.Base + data.file}
                                    />
                                  </a>
                                </td>
                                <td>{data.description}</td>
                                <td>
                                  {data.status == "Rejected" ? (
                                    <>
                                      <span className="badge bg-danger">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {data.status == "Solved" ? (
                                        <>
                                          <span className="badge bg-success">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {Roles?.hospitalProfileRequestEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "Pending" ? (
                                        <>
                                          <Button
                                            onClick={() => {
                                              Actinid(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="success"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="bx bx-edit"
                                            ></i>
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              getpopup(data)
                                            }}
                                            className="mr-2"
                                            style={{
                                              padding: "6px",
                                              margin: "3px",
                                            }}
                                            color="warning"
                                            outline
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="bx bx-repost"
                                            ></i>
                                          </Button>
                                        </>
                                      ) : (
                                        <>-</>
                                      )}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Select Status <span className="text-danger">*</span>
                  </Label>

                  <select
                    className="form-select"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    required
                    name="status"
                    value={form1.status}
                  >
                    <option value=""> Select</option>
                    <option value="Solved"> Approved</option>
                    <option value="Rejected"> Reject</option>
                  </select>
                </div>
                {form1.status == "Rejected" ? (
                  <div className="mt-3">
                    <Label>Reason</Label> <span className="text-danger">*</span>
                    <textarea
                      className="form-control"
                      value={form1.description}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      name="description"
                      required
                      type="text"
                      placeholder="Enter Reason"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
