import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import axios from "axios"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [files, setFiles] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const [inputList, setInputList] = useState([])

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("testid", form.testid)
    dataArray.append("title", form.title)
    dataArray.append(
      "nabhPrice",
      form.nabhPrice == "undefined" ? "" : form.nabhPrice
    )
    dataArray.append(
      "nonNabhPrice",
      form.nonNabhPrice == "undefined" ? "" : form.nonNabhPrice
    )
    dataArray.append("sampleType", form.sampleType)
    dataArray.append("inversion", form.inversion)
    dataArray.append("vacutainerColor", form.vacutainerColor)
    dataArray.append("transportTemp", form.transportTemp)
    dataArray.append("information", form.information)
    dataArray.append("instructions", JSON.stringify(inputList))
    dataArray.append("sampleChargeLimit", form.sampleChargeLimit)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i])
    }

    axios
      .put(URLS.EditTest + "/" + form._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Tests")
            sessionStorage.setItem("tost", "Test has been Update Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { instructions: "" }])
  }

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("Testid")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetOneTest, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.labtest)
        setInputList(res.data.labtest.instructions)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Test" breadcrumbItem="Edit Test" />
          <Row>
            <Col xl="12">
              <div style={{ float: "right" }}>
                <Button
                  onClick={history.goBack}
                  className="mb-3  m-1 "
                  color="primary"
                  style={{ float: "right" }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        ACTIN Test Code <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Test Code"
                        required
                        value={form.testid}
                        name="testid"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        ACTIN Test Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Test Name"
                        required
                        value={form.title}
                        name="title"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">NABH Rate</Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter NABH Rate"
                        value={form.nabhPrice}
                        name="nabhPrice"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        NON-NABH Rate
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Test Price"
                        value={form.nonNabhPrice}
                        name="nonNabhPrice"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Type of Sample <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Type of Sample"
                        required
                        value={form.sampleType}
                        name="sampleType"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Inversion <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter inversion"
                        required
                        value={form.inversion}
                        name="inversion"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <label htmlFor="browsers">Vacutainer Colour :</label>
                      <input
                        required
                        type="text"
                        placeholder="Vacutainer Colour"
                        list="browsers"
                        id="browserChoice"
                        className="form-control"
                        name="vacutainerColor"
                        value={form.vacutainerColor}
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                      <datalist id="browsers">
                        <option value="Royal Blue (NAYYP / NAVYE)">
                          Royal Blue (NAYYP / NAVYE)
                        </option>
                        <option value="Light Blue (Sodium Citrate)">
                          Light Blue (Sodium Citrate)
                        </option>
                        <option value="Red (Clot Activator)">
                          Red (Clot Activator)
                        </option>
                        <option value="Gold (SST)">Gold (SST)</option>
                        <option value="Light Green (PST)">
                          Light Green (PST)
                        </option>
                        <option value="Dark Green (GLI / GNA)">
                          Dark Green (GLI / GNA)
                        </option>
                        <option value="Pink (Dry K2 EDTA)">
                          Pink (Dry K2 EDTA)
                        </option>
                        <option value="Tan (EDTA)">Tan (EDTA)</option>
                        <option value="Lavender (Dry K2 EDTA)">
                          Lavender (Dry K2 EDTA)
                        </option>
                        <option value="Gray (Potassium Oxalate / Sodium fluoride)">
                          Gray (Potassium Oxalate / Sodium fluoride)
                        </option>
                        <option value="Yellow (SST)">Yellow (SST)</option>
                        <option value="Adult Aerobic culture-(green cap)">
                          Adult Aerobic culture-(green cap)
                        </option>
                        <option value="Adult Anaerobic Culture-(orange cap)">
                          Adult Anaerobic Culture-(orange cap)
                        </option>
                        <option value="Mycobacterial Culture bottle(Red cap)">
                          Mycobacterial Culture bottle (Red cap)
                        </option>
                        <option value="Paediatric culture bottle (yellow cap)">
                          Paediatric culture bottle-(yellow cap)
                        </option>
                        <option value="Sterile containers">
                          Sterile containers
                        </option>
                        <option value="Swabs for culture">
                          Swabs for culture
                        </option>
                        <option value="Red/Yellow">Red/Yellow</option>
                        <option value="Yellow/Red">Yellow/Red</option>
                        <option value="Lavender/Red">Lavender/Red</option>
                        <option value="Yellow/Lavender">Yellow/Lavender</option>
                        <option value="Red, Yellow and Sterile Container">
                          Red, Yellow and Sterile Container
                        </option>
                        <option value="Lavender, Red and Yellow">
                          Lavender, Red and Yellow
                        </option>
                        <option value="Red/Yellow/Grey">Red/Yellow/Grey</option>
                        <option value="Sterile Container With 6N Hcl">
                          Sterile Container With 6N Hcl
                        </option>
                        <option value="Sterile container washed with glacial Acetic Acid">
                          Sterile container washed with glacial Acetic Acid
                        </option>
                        <option value="15 grams boric acid in Sterile container">
                          15 grams boric acid in Sterile container
                        </option>
                        <option value="Red/Yellow and Lavender">
                          Red/Yellow and Lavender
                        </option>
                      </datalist>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basixcpill-firstname-input3">
                        Sample Charge Limit{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <select
                        className="form-select"
                        required
                        value={form.sampleChargeLimit}
                        name="sampleChargeLimit"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Transport Temperature{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Transport Temperature"
                        required
                        value={form.transportTemp}
                        name="transportTemp"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Test Image
                        <span className="text-danger">
                          318 * 159 Image Size
                        </span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        name="image"
                        onChange={changeHandler}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Test Information <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Test Information"
                        required
                        rows={3}
                        value={form.information}
                        name="information"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg="6">
                    <div>
                      <Label>Test Instruction</Label>
                      <Row>
                        {inputList.map((x, i) => {
                          return (
                            <>
                              <Row>
                                <div key={i} className="box row">
                                  <Col md="9" sm="12" className="mb-3">
                                    <Input
                                      type="text"
                                      required
                                      name="instructions"
                                      placeholder="Enter Test Instruction"
                                      value={x.instructions}
                                      onChange={e => handleInputChange(e, i)}
                                    />
                                  </Col>

                                  <Col sm="2" md="3">
                                    <div className="btn-box">
                                      {inputList.length !== 1 && (
                                        <button
                                          className="mr10 btn btn-outline-danger btn-sm m-1"
                                          type="button"
                                          onClick={() => handleRemoveClick(i)}
                                        >
                                          Remove
                                          <i className="bx bx-x-circle"></i>
                                        </button>
                                      )}
                                      {inputList.length - 1 === i && (
                                        <button
                                          className="btn btn-sm btn-outline-info m-1"
                                          onClick={handleAddClick}
                                        >
                                          Add
                                          <i className="bx bx-plus-circle"></i>
                                        </button>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Row>
                            </>
                          )
                        })}
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors
