import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  CardText,
  Container,
  Row,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"

function Monitering() {
  const history = useHistory()

  const Time = sessionStorage.getItem("AtpMoniteringdate")

  const AtpID = sessionStorage.getItem("AtpMoniteringid")

  const [Test, setTest] = useState([])

  const Testid = data => {
    sessionStorage.setItem("bookingids", data._id)
    history.push("/ViewLab")
  }

  const Actinid1 = data => {
    sessionStorage.setItem("Hospitalids", data.hospitalId)
    history.push("/HospitalView")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtponeMonitring,
        { date: Time, atpId: AtpID },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setTest(res.data.booking)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Test.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Test.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Atp Monitoring" />

          <Row>
            {" "}
            <Col md={12}>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>{" "}
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h5
                    className="mb-3 text-primary "
                    style={{ textDecoration: "underline" }}
                  >
                    Tests at Home
                  </h5>{" "}
                  <div>
                    <Row>
                      {lists.map((data, key) => (
                        <Col sm="12" key={key}>
                          <CardText className="mb-0">
                            <li className="border p-3 d-flex justify-content-between accident-info mb-3 flex-wrap">
                              <div className="tickit-info">
                                <span className="text-muted">
                                  <i className="bx bxs-bookmark-star me-2 fs-5"></i>
                                  Actin Booking Id
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  <Link
                                    onClick={() => {
                                      Testid(data)
                                    }}
                                  >
                                    {data.bookingCode}{" "}
                                  </Link>
                                </h6>
                              </div>
                              <div className="time-info">
                                <span className="text-muted">
                                  <i className="bx bxs-book me-2 fs-5"></i>
                                  Type of Service
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  {data.itemType}
                                </h6>
                              </div>
                              <div className="time-info">
                                <span className="text-muted">
                                  <i className="bx bx-time-five me-2 fs-5"></i>
                                  Slot / Timming
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  {data.slotTime}-{data.slotDate}
                                </h6>
                              </div>
                              <div className="time-info">
                                <span className="text-muted">
                                  <i className="bx bx-time-five me-2 fs-5"></i>
                                  Collection / Time
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  {data.sampleCollectTime}-
                                  {data.sampleCollectDate}
                                </h6>
                              </div>
                              <div className="time-info">
                                <span className="text-muted">
                                  <i className="bx bx-time-five me-2 fs-5"></i>
                                  Reported at HCU / Time
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  {data.sampleReachedDate}-
                                  {data.sampleReachedTime}
                                </h6>
                              </div>
                              <div className="location-info">
                                <span className="text-muted">
                                  <i className="bx bx-map-pin me-2 fs-5"></i>
                                  HCU Id / Name / Location
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  <Link
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                  >
                                    {data.hcuId}
                                  </Link>
                                  / {data.hospitalName}/{data.location}
                                </h6>
                              </div>
                              <div className="time-info">
                                <span className="text-muted">
                                  <i className="bx bxs-bookmarks me-2 fs-5"></i>
                                  Status
                                </span>
                                <h6 className="fw-bold small mt-1 text-center">
                                  {data.status}
                                  {/* <span className="badge bg-success text-center">
                                    -
                                  </span> */}
                                </h6>
                              </div>
                            </li>
                          </CardText>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <Col md={12}>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Monitering
