import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import profileImg from "../../assets/images/logo.png"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import check from "../../assets/images/check.jpg"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function State() {
  const [isLoading, setIsLoading] = useState(true)

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const [forms, setforms] = useState([])

  console.log(forms)

  useEffect(() => {
    GetOneActins()
  }, [])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("staffids")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetIdStaff, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.staffResult)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="View Staff" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row className="mb-5">
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "5",
                            })}
                            onClick={() => {
                              toggle1("5")
                            }}
                          >
                            Profile
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "9",
                            })}
                            onClick={() => {
                              toggle1("9")
                            }}
                          >
                            Bank Details
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab1 === "10",
                            })}
                            onClick={() => {
                              toggle1("10")
                            }}
                          >
                            ID Card
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={activeTab1}
                        className="p-4  text-muted"
                      >
                        <TabPane tabId="5">
                          <h5 className="mb-3">Profile Details : </h5>
                          <Row>
                            <Col lg={4}>
                              <ul className="list-unstyled vstack gap-3 mb-0">
                                <img
                                  src={URLS.Base + forms.image}
                                  height="150px"
                                  width="50%"
                                ></img>

                                <li>
                                  <div className="d-flex mt-5">
                                    <i className="bx bxs-buildings font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Staff Name:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.firstName}
                                        {forms.lastName}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                {/* <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-money font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Identity Number:
                                      </h6>
                                      <span className="text-muted">
                                        Staff1122
                                      </span>
                                    </div>
                                  </div>
                                </li> */}
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-user font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Gender:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.selectGender}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-spreadsheet font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Date of Birth:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.dateOfBirth}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-bar-chart-square font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">Age:</h6>
                                      <span className="text-muted">
                                        {forms.age}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bx-phone font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Contact Number:
                                      </h6>
                                      {forms.phone}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex  mt-3">
                                    <i
                                      className="bx bx-message
                                 font-size-18 text-primary"
                                    ></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Email:
                                      </h6>
                                      {forms.email}
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bx-bookmark font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Status :
                                      </h6>
                                      <span className="text-muted">
                                        {forms.status}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                            <Col lg={8}>
                              <Row>
                                <Col>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Salary :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.salary}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Department :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.departmentName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Type :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.idProofType}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Number :
                                            </h6>
                                            <p className="text-muted">
                                              {forms.idProofNumber}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    {/* <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Date Of Joning:
                                            </h6>
                                            <p className="text-muted">
                                              20-20-2022
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li> */}

                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Qualification:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.qualification}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Year Of Experience:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.yearOfExperience}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Year Of Graduation:
                                            </h6>
                                            <p className="text-muted">
                                              {forms.yearOfGraduation}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                                <Col>
                                  <ul className="verti-timeline list-unstyled">
                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              State
                                            </h6>
                                            <p className="text-muted">
                                              {forms.stateName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              District
                                            </h6>
                                            <p className="text-muted">
                                              {forms.districtName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              City
                                            </h6>
                                            <p className="text-muted">
                                              {forms.cityName}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    <li className="event-list">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 mb-1">
                                              Address
                                            </h6>
                                            <p className="text-muted">
                                              {forms.address}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>

                                    {/* <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 mb-1">
                                          PinCode
                                        </h6>
                                        <p className="text-muted">5080001</p>
                                      </div>
                                    </div>
                                  </div>
                                </li> */}
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Id Proof Image:
                                            </h6>
                                            <p className="text-muted">
                                              <img
                                                src={
                                                  URLS.Base + forms.idProofImage
                                                }
                                                height="100px"
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="event-list mt-4">
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle"></i>
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <div>
                                            <h6 className="font-size-14 ">
                                              Certificates:
                                            </h6>
                                            <p className="text-muted">
                                              <img
                                                src={
                                                  URLS.Base + forms.certificates
                                                }
                                                height="100px"
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TabPane>

                        <TabPane tabId="9">
                          <Col sm="12">
                            <Row>
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list  mt-2">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 ">
                                            Bank Name
                                          </h6>
                                          <p className="text-muted">
                                            {forms.bankName}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 ">
                                            Account Holder Name
                                          </h6>
                                          <p className="text-muted">
                                            {forms.acccountHolderName}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 ">
                                            Account Number
                                          </h6>
                                          <p className="text-muted">
                                            {forms.accountNumber}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 ">
                                            Branch
                                          </h6>
                                          <p className="text-muted">
                                            {forms.branch}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 ">
                                            IFSC code
                                          </h6>
                                          <p className="text-muted">
                                            {forms.ifscCode}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 ">
                                            HCU Pan Number
                                          </h6>
                                          <p className="text-muted">
                                            {forms.panCardNumber}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Col>
                              <Col md={6}>
                                <ul className="verti-timeline list-unstyled">
                                  <li className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 mb-3">
                                            Cancelled Cheque
                                          </h6>
                                          <p className="text-muted">
                                            <a
                                              href={check}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                src={
                                                  URLS.Base +
                                                  forms.cancelledCheck
                                                }
                                                height="150px"
                                                width="90%"
                                              ></img>
                                            </a>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  <li className="event-list mt-5">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>
                                          <h6 className="font-size-14 mb-3">
                                            Pan Card
                                          </h6>
                                          <p className="text-muted">
                                            <img
                                              src={
                                                URLS.Base + forms.panCardImage
                                              }
                                              height="150px"
                                              width="90%"
                                            ></img>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </li>

                                  {/* <li className="event-list mt-5">
                                <div className="event-timeline-dot">
                                  <i className="bx bx-right-arrow-circle"></i>
                                </div>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <div>
                                      <h6 className="font-size-14 mb-3">
                                        Name Of Authorised Signatory to Bank
                                        Account
                                      </h6>
                                      <p className="text-muted">
                                        <img src={sig} height="100px"></img>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li> */}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </TabPane>
                        <TabPane tabId="10">
                          <Row>
                            <h5
                              className="text-center mb-4 mt-3"
                              style={{ color: "black" }}
                            >
                              ID Card
                            </h5>
                            <Col md={4}> </Col>
                            <Col md={5}>
                              <Card className="overflow-hidden">
                                <div className="bg-danger bg-soft">
                                  <Row>
                                    <Col xs="7">
                                      <img
                                        src={profileImg}
                                        alt=""
                                        className="img-fluid mt-5 mb-4"
                                      />
                                    </Col>
                                    <Col md={5}>
                                      <p
                                        style={{
                                          float: "right",
                                          paddingTop: "55px",
                                          color: "black",
                                          fontWeight: "bold",
                                          paddingRight: "12px",
                                        }}
                                      >
                                        www.ACTIN.com
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <CardBody
                                  className="pt-0"
                                  style={{ background: "rgb(250,250,250)" }}
                                >
                                  <Row>
                                    <Col md={8}>
                                      <Row>
                                        <Col md={5}>
                                          <p className="pt-4">
                                            <b
                                              style={{
                                                color: "black",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Name :
                                            </b>
                                          </p>
                                        </Col>
                                        <Col md={7} className="pt-4">
                                          <p style={{ color: "black" }}>
                                            {forms.firstName}
                                            {forms.lastName}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={5}>
                                          <p className="pt-1">
                                            <b
                                              style={{
                                                color: "black",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Phone :
                                            </b>
                                          </p>
                                        </Col>

                                        <Col md={7}>
                                          <p
                                            style={{ color: "black" }}
                                            className="pt-1"
                                          >
                                            {forms.phone}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col md={5}>
                                          <p className="pt-1">
                                            <b
                                              style={{
                                                color: "black",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Email :
                                            </b>{" "}
                                          </p>
                                        </Col>

                                        <Col md={7}>
                                          <p style={{ color: "black" }}>
                                            {forms.email}
                                          </p>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col md={4}>
                                      <div
                                        className="avatar-lg profile-user-wid mb-5"
                                        style={{ float: "right" }}
                                      >
                                        <img
                                          src={URLS.Base + forms.image}
                                          alt=""
                                          className="img-thumbnail rounded-circle"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <hr></hr>
                                  <p
                                    style={{
                                      textAlign: "center ",
                                      color: "black",
                                    }}
                                  >
                                    {forms.address} .
                                  </p>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md={3}></Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
