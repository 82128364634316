import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"
import gig from "../../assets/images/loders.gif"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import { PDFDocument } from "pdf-lib"
import fileSaver from "file-saver"

function TestsView() {
  const [Booking, setBooking] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("bookingId")

  const GetOneActins = () => {
    const data = {
      bookingId: Actinid,
    }

    var token = datas
    axios
      .post(URLS.ViewPendingRefund, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setBooking(res.data.bookingstest)
        setIsLoading(false)
      })
  }

  const [activeTab, setActiveTab] = useState(0)

  const toggleTab = tabIndex => {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex)
    }
  }

  const modifyPdf = async datas => {
    const data = datas
    const existingPdfBytes = await fetch(data).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    const watermarkImageBytes = await fetch(
      "https://api.actinplus.com/uploads/logoSmallTilt.png"
    ).then(res => res.arrayBuffer())
    const watermarkImage = await pdfDoc.embedPng(watermarkImageBytes)

    const pages = pdfDoc.getPages()

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i]
      const { width, height } = page.getSize()

      const x = (width - 300) / 2
      const y = (height - 100) / 2

      page.drawImage(watermarkImage, {
        x,
        y,
        width: 300,
        height: 100,
        opacity: 0.1,
      })
    }

    const modifiedPdfBytes = await pdfDoc.save()
    fileSaver.saveAs(
      new Blob([modifiedPdfBytes], { type: "application/pdf" }),
      "pdf-lib_modification_example.pdf"
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Home Test View" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>

          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Row>
                  <Nav pills className="navtab-bg nav-justified">
                    {Booking.map((data, index) => (
                      <>
                        <Row>
                          <Col md={12}>
                            <NavItem key={index}>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab === index,
                                })}
                                onClick={() => toggleTab(index)}
                              >
                                <img
                                  src={URLS.Base + data.image}
                                  alt=""
                                  className="avatar-md1 rounded-circle img-thumbnail"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                  }}
                                />
                                <b className="m-3">{data.name}</b>
                              </NavLink>
                            </NavItem>{" "}
                          </Col>
                        </Row>
                      </>
                    ))}
                  </Nav>
                </Row>

                <TabContent activeTab={activeTab} className="pt-3 text-muted">
                  {Booking.map((data, index) => {
                    return (
                      <TabPane key={index} tabId={index}>
                        <Row>
                          <Col md="12">
                            <Card>
                              <CardBody>
                                <h5 className="text-primary">Timing :-</h5>

                                <div className="table-rep-plugin table-responsive">
                                  <Table hover className="table table-bordered">
                                    <thead>
                                      <tr className="text-center">
                                        <th>HCU Code </th>
                                        <th>HCU Name </th>
                                        <th>HCU Zone </th>
                                        <th>Slot Date/Time </th>
                                        <th>Timer </th>
                                        <th>Sample Collect Date/Time </th>
                                        <th>Sample Reached Date/Time </th>
                                        <th>Report Bill Generation Time </th>
                                        <th>Refund Amount</th>
                                        <th>Payment Type</th>
                                        <th>Payment Status</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="text-center">
                                        <td>{data.hospitalCode}</td>
                                        <td>{data.hospitalName}</td>{" "}
                                        <td>{data.hospitalZone}</td>
                                        <td>
                                          {data.slotDate}/ {data.slotTime}
                                        </td>
                                        <td>
                                          <p style={{ color: "red" }}>
                                            {data.bookingTestHrs}
                                          </p>
                                        </td>
                                        <td>
                                          {
                                            data.otherTimings
                                              .sampleCollectedDate
                                          }{" "}
                                          -{" "}
                                          {
                                            data.otherTimings
                                              .sampleCollectedTime
                                          }
                                        </td>
                                        <td>
                                          {data.otherTimings.sampleReachedDate}{" "}
                                          -{" "}
                                          {data.otherTimings.sampleReachedTime}
                                        </td>
                                        <td>
                                          {data.otherTimings.completedDate} -{" "}
                                          {data.otherTimings.completedTime}
                                        </td>
                                        <td>
                                          {data.refundAmount == "" ? (
                                            <></>
                                          ) : (
                                            <>{data.refundAmount} </>
                                          )}
                                        </td>
                                        <td>{data.paymentType}</td>
                                        <td>{data.paymentStatus}</td>
                                        <td>
                                          {" "}
                                          {data.status == "canceled" ||
                                          data.status == "failed" ? (
                                            <>
                                              <span className="badge bg-danger">
                                                {data.status}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              {data.status == "completed" ? (
                                                <>
                                                  <span className="badge bg-success">
                                                    {data.status}
                                                  </span>
                                                </>
                                              ) : (
                                                <span className="badge bg-warning">
                                                  {data.status}
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Card>
                              <CardBody>
                                <h5 className="text-primary">
                                  User Details :-
                                </h5>
                                <Col md={12}>
                                  <div className="text-center">
                                    <div className="avatar-md1 profile-user-wid mb-4 mt-4">
                                      <span className="ml-5">
                                        <img
                                          src={URLS.Base + data.image}
                                          alt=""
                                          className="avatar-md1 rounded-circle img-thumbnail"
                                          style={{
                                            height: "115px",
                                            width: "115px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                    <h5 className="mt-1 mb-1">{data.name}</h5>
                                  </div>
                                </Col>
                                <ul className="list-unstyled mt-2">
                                  <li>
                                    <div className="d-flex">
                                      <i className="bx bx-phone text-primary fs-4"></i>
                                      <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Phone</h6>
                                        <p className="text-muted fs-14 mb-0">
                                          {data.phone}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="mt-3">
                                    <div className="d-flex">
                                      <i className="bx bx-mail-send text-primary fs-4"></i>
                                      <div className="ms-3">
                                        <h6 className="fs-14 mb-2">Email</h6>
                                        <p className="text-muted fs-14 mb-0">
                                          {data.email}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="mt-3">
                                    <div className="d-flex">
                                      <i className="bx bx-error-circle text-primary fs-4"></i>
                                      <div className="ms-3">
                                        <h6 className="fs-14 mb-2">
                                          Gender / Age{" "}
                                        </h6>
                                        <p className="text-muted fs-14 text-break mb-0">
                                          {data.gender} / {data.age}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="mt-3">
                                    <div className="d-flex">
                                      <i className="bx bx-map text-primary fs-4"></i>
                                      <div className="ms-3">
                                        <h6 className="fs-14 mb-2">
                                          Location{" "}
                                        </h6>
                                        <p className="text-muted fs-14 mb-0">
                                          {data.address}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md={8}>
                            <Card>
                              <CardBody>
                                <Row className="mb-4">
                                  <Col md={6}>
                                    <h5 className="text-primary">
                                      Atp Sample Collection Details :-
                                    </h5>
                                  </Col>

                                  <Col md={6}>
                                    {data.status == "completed" ? (
                                      <>
                                        {/* <a
                                          href={URLS.Base + data.file}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <Button
                                            className="mb-3 m-1"
                                            style={{ float: "right" }}
                                            color="warning"
                                          >
                                            View Report
                                          </Button>
                                        </a> */}
                                        <Button
                                          onClick={() => {
                                            modifyPdf(URLS.Base + data.file)
                                          }}
                                          className="mb-3 m-1"
                                          style={{ float: "right" }}
                                          color="warning"
                                        >
                                          Download Report
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <div className="mt-2"></div>
                                      </>
                                    )}
                                  </Col>
                                </Row>

                                <div className="table-rep-plugin table-responsive">
                                  <Table hover className="table table-bordered">
                                    <thead>
                                      <tr className="text-center">
                                        <th>Blood Pressure</th>
                                        <td>{data.memberBloodPressure}</td>
                                      </tr>

                                      <tr className="text-center">
                                        <th>Temperature</th>
                                        <td>{data.memberTemperature}</td>
                                      </tr>

                                      <tr className="text-center">
                                        <th>PR Two</th>
                                        <td>{data.memberprTwo}</td>
                                      </tr>
                                    </thead>
                                  </Table>
                                </div>

                                <h5 className="text-primary mt-5"> ID's :</h5>
                                <Table
                                  hover
                                  className="table table-bordered mt-3"
                                >
                                  <thead>
                                    <tr className="text-center">
                                      <th>ID</th>
                                      <th>Actin</th>
                                      <th>HCU</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="text-center">
                                      <th>User Id</th>
                                      <td>{data.userCode}</td>
                                      <td>{data.hcuUserId}</td>
                                    </tr>
                                    <tr className="text-center">
                                      <th>Booking Id</th>
                                      <td>{data.bookingId}</td>
                                      <td>{data.hcuBookingId}</td>
                                    </tr>
                                    <tr className="text-center">
                                      <th>Collected By</th>
                                      <td>
                                        {data.atpdata.atpCode}-
                                        {data.atpdata.name}
                                      </td>
                                      <td>
                                        {data.employeeName}-{data.employeeId}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card>
                              <CardBody>
                                <h5 className="text-primary">
                                  Home Test Details :-
                                </h5>
                                <div className="table-rep-plugin table-responsive">
                                  <Table
                                    hover
                                    className="table table-bordered mt-4 "
                                  >
                                    <thead>
                                      <tr className="text-center">
                                        <th>ACTIN TEST ID</th>
                                        <th>HCU TEST ID</th>
                                        <th>Test Name</th>
                                        <th>Vactuine color</th>
                                        <th>Inversion</th>
                                        <th>Temperature</th>
                                        <th>Type Of Sample</th>
                                        <th>Health Scheme Name</th>
                                        <th>Health Scheme Image</th>
                                        <th>Health Scheme Validity</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data.testsdata.map((datas, key) => (
                                        <tr key={key} className="text-center">
                                          <td>{datas.actintestId}</td>
                                          <td>{datas.hocTestid}</td>
                                          <td>{datas.itemName}</td>
                                          <td>{datas.vacutainercolor}</td>
                                          <td>{datas.inversion}</td>
                                          <td>{datas.transportTemp}</td>
                                          <td>{datas.sampleType}</td>
                                          <td>{datas.cardName}</td>
                                          <td>
                                            <a
                                              href={
                                                URLS.Base +
                                                datas.coverUnderImage
                                              }
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                src={
                                                  URLS.Base +
                                                  datas.coverUnderImage
                                                }
                                                alt=""
                                                style={{
                                                  height: "50px",
                                                }}
                                              />
                                            </a>
                                          </td>
                                          <td>{datas.cardValidity}</td>
                                          <td>{datas.price}</td>
                                          <td>
                                            <span className="badge bg-success ">
                                              {datas.status}
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                      <tr className="text-center">
                                        <td colSpan={10} className="text-end">
                                          <h5
                                            style={{
                                              fontSize: "14px",
                                              color: "black",
                                            }}
                                          >
                                            Total Amount
                                          </h5>
                                        </td>
                                        <td colSpan={2} className="text-start">
                                          <h5
                                            style={{
                                              fontSize: "14px",
                                              color: "black",
                                            }}
                                          >
                                         {data.totalPrice} /-
                                          </h5>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Card className="mb-5">
                          <CardBody>
                            <div>
                              <div className="row">
                                {" "}
                                <div className="col-md-6">
                                  <h5 className="mb-4 text-primary">
                                    HCU Review :
                                  </h5>
                                  <blockquote className="p-4 border-light border rounded mb-4">
                                    <div className="d-flex mt-4">
                                      <img
                                        src={URLS.Base + data.image}
                                        className="avatar-xs me-3 rounded-circle"
                                        alt="img"
                                      />
                                      <div className="flex-grow-1">
                                        <h5 className="mt-0 mb-1 font-size-15">
                                          {data.name}
                                        </h5>
                                        <p className="text-muted">
                                          {data.hospitalReview}
                                        </p>

                                        <div className="text-muted font-size-12">
                                          <i className="far fa-calendar-alt text-primary me-1" />{" "}
                                          {data.slotDate}
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </blockquote>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="mb-4 text-primary">
                                    ATP Review :
                                  </h5>
                                  <blockquote className="p-4 border-light border rounded mb-4">
                                    <div className="d-flex mt-4">
                                      <img
                                        src={URLS.Base + data.image}
                                        className="avatar-xs me-3 rounded-circle"
                                        alt="img"
                                      />

                                      <div className="flex-grow-1">
                                        <h5 className="mt-0 mb-1 font-size-15">
                                          {data.name}
                                        </h5>
                                        <p className="text-muted">
                                          {data.atpreview}
                                        </p>

                                        <div className="text-muted font-size-12">
                                          <i className="far fa-calendar-alt text-primary me-1" />{" "}
                                          {data.slotDate}
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </blockquote>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </TabPane>
                    )
                  })}
                </TabContent>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default TestsView
