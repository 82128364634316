import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import gig from "../../assets/images/loders.gif"
import { useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import axios from "axios"

function ViewHomeSlotService() {
  const history = useHistory()
  const [Timming, setTimming] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [Items, setItems] = useState([])
  const [Member, setMember] = useState([])
  const [Slot, setSlot] = useState([])
  const [invoice, setInvoice] = useState(null)

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("bookingidsser")

  const GetOneActins = () => {
    const data = {
      bookingId: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetOneHomeServicesBookings, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setItems(res.data.data[0].items[0])
        setMember(res.data.data[0].members)
        setSlot(res.data.data[0])
        setTimming(res.data.data[0].items[0].otherTimings)
        setIsLoading(false)
        setInvoice(res.data.invoice)                  // Invoice
      })
  }

  const handleDownload = () => {
    if (invoice) {
      window.open(URLS.Base + invoice, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Invoice URL is not available');
    }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="View Home Service" />
          {/* <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row> */}
           <Row className="d-flex justify-content-end">
            <Col xl="auto">
              <Button className="btn btn-success mb-2" onClick={handleDownload}>
                Invoice
              </Button>
            </Col>
            <Col xl="auto">
              <Button onClick={history.goBack} className="mb-3" color="primary">
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>

          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col md={4}>
                  <Card>
                    <CardBody>
                      <h5 className="text-primary"> User Details :</h5>
                      <Col md={12}>
                        <div className="text-center">
                          <div className="avatar-md1 profile-user-wid mb-4 mt-1">
                            <span className="ml-5">
                              <img
                                src={URLS.Base + Member.profileImage}
                                alt=""
                                className="avatar-md1 rounded-circle img-thumbnail"
                                style={{ height: "110px", width: "110px" }}
                              />
                            </span>
                          </div>
                          <h5 className="mt-1 mb-1">{Member.name}</h5>
                        </div>
                      </Col>

                      <ul className="list-unstyled mt-2">
                        <li>
                          <div className="d-flex">
                            <i className="bx bx-phone text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Phone</h6>
                              <p className="text-muted fs-14 mb-0">
                                {Member.phone}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-mail-send text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Email</h6>
                              <p className="text-muted fs-14 mb-0">
                                {Member.email}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-error-circle text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Gender / Age </h6>
                              <p className="text-muted fs-14 text-break mb-0">
                                {Member.gender} / {Member.age}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li className="mt-3">
                          <div className="d-flex">
                            <i className="bx bx-map text-primary fs-4"></i>
                            <div className="ms-3">
                              <h6 className="fs-14 mb-2">Location </h6>
                              <p className="text-muted fs-14 mb-0">
                                {Slot.address}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={8}>
                  <Card>
                    <CardBody>
                      <h5 className="text-primary"> Slot Details :</h5>

                      <Row className="task-dates">
                        <Col sm="4" xs="6">
                          <div className="mt-4 ">
                            <h5 className="font-size-14">Slot Date/Time :</h5>
                            <p className="text-muted mb-0">{Slot.dateTime}</p>
                          </div>
                        </Col>
                        <Col sm="4" xs="6">
                          <div className="mt-4 ">
                            <h5 className="font-size-14">
                              Hcu Accept Date/Time :
                            </h5>
                            <p className="text-muted mb-0">
                              {" "}
                              {Timming.confirmDate} - {Timming.confirmTime}
                            </p>
                          </div>
                        </Col>
                        <Col sm="4" xs="4">
                          <div className="mt-4">
                            <h5 className="font-size-14">
                              Completed Date / Time :
                            </h5>
                            <p className="text-muted mb-0">
                              {Timming.completedDate} - {Timming.completedTime}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <h5 className="text-primary mt-5 pt-4"> ID's :</h5>
                      <Table hover className="table table-bordered mt-5 mb-5">
                        <thead>
                          <tr className="text-center">
                            <th>ID</th>
                            <th>Actin</th>
                            <th>HCU</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <th>User Id</th>
                            <td>{Slot.userCode}</td>
                            <td>{Slot.hcuUserId}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Booking Id</th>
                            <td>{Slot.bookingCode}</td>
                            <td>{Slot.HcuBookingId}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Collected By</th>
                            <td>-</td>
                            <td>
                              {Slot.employeeId}-{Slot.employeeName}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Card>
                    {" "}
                    <CardBody>
                      <h5 className="text-primary">Home Test Details :</h5>
                      <Table hover className="table table-bordered mt-4">
                        <thead>
                          <tr className="text-center">
                            <th>HCU Code </th>
                            <th>HCU Name </th>
                            <th>HCU Zone </th>
                            <th>Service Provider Id</th>
                            <th>Service Provider Name</th>
                            <th>Actin Service Id</th>
                            <th>HCU Service Id</th>
                            <th>HCU Service Name</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <td>{Items.hocCode}</td>
                            <td>{Items.hocName}</td>{" "}
                            <td>{Items.hocZoneName}</td>
                            <td>{Slot.doctorEmployId}</td>
                            <td>{Slot.doctorname}</td>
                            <td>{Items.actinId}</td>
                            <td>{Items.hocTestid}</td>
                            <td>{Items.itemName}</td>
                            <td>{Items.itemPrice}</td>
                            <td>
                              {Slot.description == "" ? (
                                <>-</>
                              ) : (
                                <>{Slot.description}</>
                              )}
                            </td>
                            <td>
                              {Slot.status == "canceled" ||
                              Slot.status == "failed" ? (
                                <>
                                  <span className="badge bg-danger">
                                    {Slot.status}
                                  </span>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {Slot.status == "completed" ? (
                                    <>
                                      <span className="badge bg-success">
                                        {Slot.status}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="badge bg-warning">
                                      {Slot.status}
                                    </span>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td colSpan={8} className="text-end">
                              <h6 style={{ fontSize: "12px" }}>Gst Charges</h6>
                              <h6 style={{ fontSize: "12px" }}>
                                Coupon Discount
                              </h6>
                              <h6 style={{ fontSize: "12px" }}>
                                Transaction Charges
                              </h6>
                              <h6 style={{ fontSize: "12px" }}>
                                Appointment Charges
                              </h6>
                            </td>

                            <td colSpan={2} className="text-start">
                              <h6 style={{ fontSize: "12px" }}>
                                + {Items.gstCharge}
                              </h6>
                              <h6 style={{ fontSize: "12px" }}>
                                + {Items.couponDiscount}
                              </h6>
                              <h6 style={{ fontSize: "12px" }}>
                                + {Items.appointmentPrice}
                              </h6>
                              <h6 style={{ fontSize: "12px" }}>
                                + {Items.transactionCharges}
                              </h6>
                            </td>
                          </tr>
                          <tr className="text-center">
                            <td colSpan={8} className="text-end">
                              <h5
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                }}
                              >
                                Total Amount
                              </h5>
                            </td>
                            <td colSpan={2} className="text-start">
                              <h5
                                style={{
                                  fontSize: "14px",
                                  color: "black",
                                }}
                              >
                                = {Slot.totalPrice} /-
                              </h5>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <Card className="mb-5">
                <CardBody>
                  <div>
                    <div className="row">
                      {" "}
                      <div className="col-md-12">
                        <h5 className="mb-4 text-primary">Service Provider Review :</h5>
                        <blockquote className="p-4 border-light border rounded mb-4">
                          <div className="d-flex mt-4">
                            <img
                              src={URLS.Base + Member.profileImage}
                              className="avatar-xs me-3 rounded-circle"
                              alt="img"
                            />

                            <div className="flex-grow-1">
                              <h5 className="mt-0 mb-1 font-size-15">
                                {Member.name}
                              </h5>
                              <p className="text-muted">
                                {Items.hospitalReview}
                              </p>

                              <div className="text-muted font-size-12">
                                <i className="far fa-calendar-alt text-primary me-1" />{" "}
                                {Slot.slotDate}
                              </div>
                            </div>
                          </div>{" "}
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card> */}

              <Card className="mb-5">
                <CardBody>
                  <div>
                    <div className="row">
                      {" "}
                      <div className="col-md-6">
                        <h5 className="mb-4 text-primary">
                          {" "}
                          Service Provider Review :
                        </h5>
                        <blockquote className="p-4 border-light border rounded mb-4">
                          <div className="d-flex mt-4">
                            <img
                              src={URLS.Base + Member.profileImage}
                              className="avatar-xs me-3 rounded-circle"
                              alt="img"
                            />

                            <div className="flex-grow-1">
                              <h5 className="mt-0 mb-1 font-size-15">
                                {Member.name}
                              </h5>
                              <p className="text-muted">{Items.doctorReview}</p>

                              <div className="text-muted font-size-12">
                                <i className="far fa-calendar-alt text-primary me-1" />{" "}
                                {Slot.slotDate}
                              </div>
                            </div>
                          </div>{" "}
                        </blockquote>
                      </div>
                      <div className="col-md-6">
                        <h5 className="mb-4 text-primary">Hcu Review :</h5>
                        <blockquote className="p-4 border-light border rounded mb-4">
                          <div className="d-flex mt-4">
                            <img
                              src={URLS.Base + Member.profileImage}
                              className="avatar-xs me-3 rounded-circle"
                              alt="img"
                            />

                            <div className="flex-grow-1">
                              <h5 className="mt-0 mb-1 font-size-15">
                                {Member.name}
                              </h5>
                              <p className="text-muted">
                                {Items.hospitalReview}
                              </p>

                              <div className="text-muted font-size-12">
                                <i className="far fa-calendar-alt text-primary me-1" />{" "}
                                {Slot.slotDate}
                              </div>
                            </div>
                          </div>{" "}
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewHomeSlotService
