import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import ju from "../../assets/images/ju.png"
import adobephotoshop from "../../assets/images/users/avatar-7.jpg"

function TestsView() {
  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Home Test View" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  <h5 className="text-primary"> User Details :</h5>
                  <Col md={12}>
                    <div className="text-center">
                      <div className="avatar-md1 profile-user-wid mb-4 mt-1">
                        <span className="ml-5">
                          <img
                            src={adobephotoshop}
                            alt=""
                            className="avatar-md1 rounded-circle img-thumbnail"
                            height="150"
                            width="100"
                          />
                        </span>
                      </div>
                      <h5 className="mt-1 mb-1">Sameer</h5>
                    </div>
                  </Col>

                  <ul className="list-unstyled mt-2">
                    <li>
                      <div className="d-flex">
                        <i className="bx bx-phone text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Phone</h6>
                          <p className="text-muted fs-14 mb-0">9988776655</p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-mail-send text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Email</h6>
                          <p className="text-muted fs-14 mb-0">
                            Sameer@gmail.com
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-error-circle text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Gender / Age </h6>
                          <p className="text-muted fs-14 text-break mb-0">
                            Male / 21
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-3">
                      <div className="d-flex">
                        <i className="bx bx-map text-primary fs-4"></i>
                        <div className="ms-3">
                          <h6 className="fs-14 mb-2">Location / Pincode</h6>
                          <p className="text-muted fs-14 mb-0">Hyderabad / 508002.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <h5 className="text-primary"> Slot Details :</h5>

                  <Row className="task-dates">
                    <Col sm="4" xs="6">
                      <div className="mt-4 ">
                        <h5 className="font-size-14">Date/Time :</h5>
                        <p className="text-muted mb-0">22-09-2023 / 12:00 Am</p>
                      </div>
                    </Col>

                    <Col sm="3" xs="6">
                      <div className="mt-4">
                        <h5 className="font-size-14">Slot :</h5>
                        <p className="text-muted mb-0">4</p>
                      </div>
                    </Col>
                    <Col sm="5" xs="6">
                      <div className="mt-4">
                        <h5 className="font-size-14">
                          Report Bill Generation Time :
                        </h5>
                        <p className="text-muted mb-0">12:00 Hrs</p>
                      </div>
                    </Col>
                  </Row>
                  <h5 className="text-primary mt-5"> ID's :</h5>
                  <Table hover className="table table-bordered mt-4 mb-5">
                    <thead>
                      <tr className="text-center">
                        <th>ID</th>
                        <th>ACTIN</th>
                        <th>HCU</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <th>User Id</th>
                        <td>ACTINUser1122</td>
                        <td>Apollo1123</td>
                      </tr>
                      <tr className="text-center">
                        <th>Booking Id</th>
                        <td>ACTIN1122</td>
                        <td>Apollo1123</td>
                      </tr>
                      <tr className="text-center">
                        <th>Collected By</th>
                        <td>Vijay / ACTINAtp22</td>
                        <td>Kavya / Apollo1123</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="12">
              <Card>
                {" "}
                <CardBody>
                  <h5 className="text-primary">Home Test Details :</h5>
                  <Table hover className="table table-bordered mt-4">
                    <thead>
                      <tr className="text-center">
                        <th>ACTIN Test Id</th>
                        <th>HCU Test Id</th>
                        <th>Test Name</th>
                        <th>Vactuine color:</th>
                        <th>Inversion:</th>
                        <th>Temperature:</th>
                        <th>Type Of Sample:</th>
                        <th>Price</th>
                        <th>Health Scheme</th>
                        <th>Upload Bills</th>
                        <th>Upload Report</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>ACTINTest02</td>
                        <td>ApolloTest02</td>
                        <td>Blood Test</td>
                        <td>
                          <span className="text-warning">
                            <i
                              className="bx bx-test-tube"
                              style={{ fontSize: "30px", fontWeight: "bold" }}
                            ></i>
                          </span>
                        </td>

                        <td>4</td>
                        <td>2-8 C</td>
                        <td>Blood</td>
                        <td>200</td>
                        <td>-</td>
                        <td>
                          <a href={ju} target="_blank" rel="noreferrer">
                            <img src={ju} height="100px"></img>
                          </a>
                        </td>
                        <td>
                          {" "}
                          <a href={ju} target="_blank" rel="noreferrer">
                            <img src={ju} height="100px"></img>
                          </a>
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Amount </td>
                        <td>200</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TestsView
