import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Form,
  Label,
  Modal,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory, Link } from "react-router-dom"
import classnames from "classnames"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import jsPDF from "jspdf"
import "jspdf-autotable"

function HospitalPrice() {
  const history = useHistory()

  const [change, setchange] = useState([])

  const [change2, setchange2] = useState([])

  const [activeTab1, setactiveTab1] = useState("5")

  const [dataCommissionType, setdataCommissionType] = useState([])

  const [dataCommissionType1, setdataCommissionType1] = useState([])

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const hcuid = sessionStorage.getItem("Hospitalidss")

  useEffect(() => {
    GetAllHomeTests()
    GetAllInvestigations()
    GetAlldoctors()
    GetAllService()
    GetOneHospital()
  }, [])

  const [test, settest] = useState([])

  const GetAllHomeTests = () => {
    var token = datas
    axios
      .post(
        URLS.getaddAllhometest,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        settest(res.data.tests)
        setdataCommissionType(res.data.commissionType)
      })
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.getaddAllhometestSearch + `${e.target.value}`,
        {hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        settest(res.data.tests)
        setdataCommissionType(res.data.commissionType)
      })
  }

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = test.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(test.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Investigations, setInvestigations] = useState([])

  const GetAllInvestigations = () => {
    var token = datas
    axios
      .post(
        URLS.getaddAllInvestigations,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setInvestigations(res.data.investigations)
        setdataCommissionType1(res.data.commissionType)
      })
  }

  const [Searchs1, setSearchs1] = useState([])

  const Search1 = e => {
    let myUser = { ...Searchs1 }
    myUser[e.target.name] = e.target.value
    setSearchs1(myUser)
    var token = datas
    axios
      .post(
        URLS.getaddAllInvestigationsSearch + `${e.target.value}`,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setInvestigations(res.data.investigations)
        setdataCommissionType1(res.data.commissionType)
      })
  }

  const [listPerPage1] = useState(20)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = Investigations.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  )
  const pageCount1 = Math.ceil(Investigations.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const [doctor, setdoctor] = useState([])

  const GetAlldoctors = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllDoctor,
        { hospitalId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.dctr)
      })
  }

  const [Searchs2, setSearchs2] = useState([])

  const Search2 = e => {
    let myUser = { ...Searchs2 }
    myUser[e.target.name] = e.target.value
    setSearchs2(myUser)
    var token = datas
    axios
      .post(
        URLS.GetAllDoctorSearch + `${e.target.value}`,
        { hospitalId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setdoctor(res.data.dctr)
      })
  }

  const [listPerPage2] = useState(20)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = doctor.slice(pagesVisited2, pagesVisited2 + listPerPage2)
  const pageCount2 = Math.ceil(doctor.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [Service, setService] = useState([])

  const GetAllService = () => {
    var token = datas
    axios
      .post(
        URLS.GetallHomeServiceslist,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setService(res.data.homeservice)
      })
  }

  const [Searchs3, setSearchs3] = useState([])

  const Search3 = e => {
    let myUser = { ...Searchs3 }
    myUser[e.target.name] = e.target.value
    setSearchs3(myUser)
    var token = datas
    axios
      .post(
        URLS.GetallHomeServiceslistSearch + `${e.target.value}`,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setService(res.data.homeservice)
      })
  }

  const [listPerPage3] = useState(20)
  const [pageNumber3, setPageNumber3] = useState(0)

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = Service.slice(pagesVisited3, pagesVisited3 + listPerPage3)
  const pageCount3 = Math.ceil(Service.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  const [forms, setforms] = useState([])

  const GetOneHospital = () => {
    const data = {
      _id: hcuid,
    }

    var token = datas
    axios
      .post(URLS.GetIdHospital, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.hospital)
      })
  }

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = data => {
    tog_small()
    setchange(data)
  }

  const handleChange1 = e => {
    let myUser = { ...change }
    myUser[e.target.name] = e.target.value
    setchange(myUser)
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas

    if (dataCommissionType == "Price") {
      var dataArray = {
        hocTestid: change.hocTestid,
        hocTestPrice: change.hocTestPrice,
        hcuCommission: change.hcuCommission,
      }

      axios
        .put(URLS.EditAllhometest + change._id, dataArray, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(
          res => {
            if (res.status === 200) {
              GetAllHomeTests()
              toast(res.data.message)
              setmodal_small(false)
            }
          },
          error => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message)
            }
          }
        )
    } else {
      var dataArray = {
        hocTestid: change.hocTestid,
        hocTestPrice: change.hocTestPrice,
      }

      axios
        .put(URLS.EditAllhometest + change._id, dataArray, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(
          res => {
            if (res.status === 200) {
              GetAllHomeTests()
              toast(res.data.message)
              setmodal_small(false)
            }
          },
          error => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message)
            }
          }
        )
    }
  }

  const manageDeletes = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DelectDistrict(data)
    }
  }
  const DelectDistrict = data => {
    var token = datas

    var remid = data._id

    axios
      .put(
        URLS.Deletehometest + "/" + remid,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllHomeTests()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [modal_small2, setmodal_small2] = useState(false)

  function tog_small2() {
    setmodal_small2(!modal_small2)
  }

  const getpopup2 = data => {
    tog_small2()
    setchange2(data)
  }

  const handleChange2 = e => {
    let myUser = { ...change2 }
    myUser[e.target.name] = e.target.value
    setchange2(myUser)
  }
  const handleSubmit2 = e => {
    e.preventDefault()
    Editstate2()
  }

  const Editstate2 = () => {
    var token = datas
    const dataArray = {
      hocTestid: change2.hocTestid,
      hocTestPrice: change2.price,
      hcuCommission: change2.hcuCommission,
    }

    axios
      .put(URLS.EditAllInvestigations + change2._id, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetAllInvestigations()
            toast(res.data.message)
            setmodal_small2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDeletes2 = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Delect2(data)
    }
  }
  const Delect2 = data => {
    var token = datas

    var remid = data._id

    axios
      .put(
        URLS.DeletesInvestigations + "/" + remid,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllInvestigations()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [info, setinfo] = useState(false)
  const [ins, setins] = useState(false)
  const [Instructions, setInstructions] = useState([])
  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  function inst() {
    setins(!ins)
  }

  const getinfo = data => {
    setInformation(data.information)
    infos()
  }

  const getinc = data => {
    setInstructions(data.instructions)
    inst()
  }

  const [info1, setinfo1] = useState(false)
  const [ins1, setins1] = useState(false)
  const [Instructions1, setInstructions1] = useState([])
  const [Information1, setInformation1] = useState([])

  function infos1() {
    setinfo1(!info1)
  }

  function inst1() {
    setins1(!ins1)
  }

  const getinfo1 = data => {
    setInformation1(data.information)
    infos1()
  }

  const getinc1 = data => {
    setInstructions1(data.instructions)
    inst1()
  }

  const [info2, setinfo2] = useState(false)
  const [ins2, setins2] = useState(false)
  const [Instructions2, setInstructions2] = useState([])
  const [Information2, setInformation2] = useState([])

  function infos2() {
    setinfo2(!info2)
  }

  function inst2() {
    setins2(!ins2)
  }

  const getinfo2 = data => {
    setInformation2(data.information)
    infos2()
  }

  const getinc2 = data => {
    setInstructions2(data.instructions)
    inst2()
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "ACTIN TEST ID",
        "HCU TEST ID",
        "Test Name",
        "NABH Rate",
        "Non-NABH",
        "Public Price",
        "HCU Price",
        "Vacutainer Color",
        "status",
      ],
    ]

    const data = test.map((elt, i) => [
      i + 1,
      elt.testid,
      elt.hocTestid,
      elt.title,
      elt.nabhPrice,
      elt.nonNabhPrice,
      elt.hocTestPrice,
      elt.hcuCommission,
      elt.vacutainerColor,
      elt.status,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Home_Tests.pdf")
  }

  const exportPDF1 = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "ACTIN Investigation Id",
        "HCU Investigation Id",
        "Investigation Name",
        "Public Price",
        "HCU Price",
        "Location in HCU",
        "status",
      ],
    ]
    const data = Investigations.map((elt, i) => [
      i + 1,
      elt.testid,
      elt.hocTestid,
      elt.title,
      elt.price,
      elt.hcuCommission,
      elt.roomLocationHoc,
      elt.status,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Investigation.pdf")
  }

  const exportPDF2 = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "Doctor Id",
        "Name",
        "Phone",
        "Email",
        "Location in HCU",
        "Tariff / Cost",
        "Speciality",
      ],
    ]
    const data = doctor.map((elt, i) => [
      i + 1,
      elt.doctorEmployId,
      elt.firstName + elt.lastName,
      elt.phone,
      elt.email,
      elt.locationInHoc,
      elt.tarrifPrice,
      elt.speciality,
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Doctor_details.pdf")
  }

  const exportPDF3 = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "ACTIN Service Id",
        "HCU Service Id",
        "ACTIN Service Name",
        "HCU Service Price",
        "status",
      ],
    ]
    const data = Service.map((elt, i) => [
      i + 1,
      elt.testid,
      elt.hocTestid,
      elt.title,
      elt.hocTestPrice,
      elt.status,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Home_Service.pdf")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="HCU Price List" />
          <Row>
            <Col md={12}>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "5",
                          })}
                          onClick={() => {
                            toggle1("5")
                          }}
                        >
                          Tests at Home
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "6",
                          })}
                          onClick={() => {
                            toggle1("6")
                          }}
                        >
                          Investigations at HCU
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "7",
                          })}
                          onClick={() => {
                            toggle1("7")
                          }}
                        >
                          Consultation
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "8",
                          })}
                          onClick={() => {
                            toggle1("8")
                          }}
                        >
                          Home Service
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <Row className="mb-5">
                    <Col md={4}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list mt-4">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">HCU Id:</h6>
                                <p className="text-muted">
                                  {forms.hospitalCode}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list mt-4">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">HCU Name:</h6>
                                <p className="text-muted">
                                  {forms.hospitalName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={4}>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list mt-4">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  HCU Zone Name:
                                </h6>
                                <p className="text-muted">{forms.zoneName}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <TabContent activeTab={activeTab1} className=" text-muted">
                    <TabPane tabId="5">
                      <Row>
                        <Col md={6}>
                          <div>
                            <h5
                              className="mb-3 text-primary"
                              style={{ textDecoration: "underline" }}
                            >
                              Tests at Home
                            </h5>
                          </div>
                          <div className="mt-3">
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div style={{ float: "right" }}>
                            <Link to="/AddTest">
                              <Button color="primary">
                                New Test <i className="bx bx-plus-circle"></i>
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              className="btn btn-info m-1"
                              onClick={exportPDF}
                            >
                              <i className="fas fa-file-pdf"></i> Pdf
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div>
                          <div
                            className="table-responsive"
                            style={{ height: "480px" }}
                          >
                            <Table className="table table-bordered mb-2 mt-3">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  <th>Actin Test Image</th>
                                  <th>Actin Test Id</th>
                                  <th>HCU Test Id</th>
                                  <th>Test Name</th>
                                  <th>NABH Rate</th>
                                  <th>Non-NABH</th>
                                  <th>Public Price</th>
                                  {dataCommissionType == "Price" ? (
                                    <>
                                      <th>HCU Price</th>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <th>Vacutainer Color</th>
                                  <th>Sample Charge Limit </th>
                                  <th>Instruction</th>
                                  <th>Information</th>
                                  <th>status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lists.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                    <td>
                                      <img
                                        src={URLS.Base + data.testImage}
                                        width="80px"
                                      ></img>
                                    </td>
                                    <td>{data.testid}</td>
                                    <td>{data.hocTestid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.nabhPrice}</td>
                                    <td>{data.nonNabhPrice}</td>
                                    <td>{data.hocTestPrice}</td>
                                    {dataCommissionType == "Price" ? (
                                      <>
                                        <td> {data.hcuCommission}</td>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    <td>{data.vacutainerColor}</td>
                                    <td>{data.sampleChargeLimit}</td>
                                    <td className="text-center">
                                      <Button
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="warning"
                                        onClick={() => {
                                          getinfo(data)
                                        }}
                                      >
                                        <i className="bx bxs-file"></i> View
                                      </Button>
                                    </td>
                                    <td className="text-center">
                                      <Button
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="warning"
                                        onClick={() => {
                                          getinc(data)
                                        }}
                                      >
                                        <i className="bx bxs-file"></i> View
                                      </Button>
                                    </td>
                                    <td className="text-start">
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        color="success"
                                        outline
                                        onClick={() => {
                                          getpopup(data)
                                        }}
                                      >
                                        <i className="bx bx-edit "></i>
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          manageDeletes(data)
                                        }}
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        color="danger"
                                        outline
                                      >
                                        <i className="bx bx-trash"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Col md={12}>
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount}
                                  onPageChange={changePage}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists.length}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </TabPane>

                    <TabPane tabId="6">
                      <Row>
                        <Col md={6}>
                          <div>
                            <h5
                              className="mb-3 text-primary "
                              style={{ textDecoration: "underline" }}
                            >
                              Investigations at HCU
                            </h5>
                          </div>
                          <div className="mt-3">
                            <Input
                              type="search"
                              name="search"
                              value={Searchs1.search}
                              onChange={Search1}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div style={{ float: "right" }}>
                            <Link to="/AddInvestigationlist">
                              <Button color="primary">
                                New Investigations
                                <i className="bx bx-plus-circle"></i>
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              className="btn btn-info m-1 "
                              onClick={exportPDF1}
                            >
                              <i className="fas fa-file-pdf"></i> Pdf
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div
                        className="table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table className="table table-bordered mb-2 mt-3">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Image</th>
                              <th>Actin Investigation Id</th>
                              <th>HCU Investigation Id</th>
                              <th>Investigation Name</th>
                              <th>Public Price</th>
                              {dataCommissionType1 == "Price" ? (
                                <>
                                  <th>HCU Price</th>
                                </>
                              ) : (
                                <></>
                              )}
                              <th>Location in HCU</th>
                              <th>Instruction</th>
                              <th>Information</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists1.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber1 - 1) * 20 + key + 21}</td>
                                <td>
                                  <img
                                    src={URLS.Base + data.testImage}
                                    width="80px"
                                  ></img>
                                </td>
                                <td>{data.testid}</td>
                                <td>{data.hocTestid}</td>
                                <td>{data.title}</td>
                                <td>{data.price}</td>
                                {dataCommissionType1 == "Price" ? (
                                  <>
                                    <td> {data.hcuCommission}</td>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <td>{data.roomLocationHoc}</td>
                                <td className="text-center">
                                  <Button
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="warning"
                                    onClick={() => {
                                      getinfo1(data)
                                    }}
                                  >
                                    <i className="bx bxs-file"></i> View
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="warning"
                                    onClick={() => {
                                      getinc1(data)
                                    }}
                                  >
                                    <i className="bx bxs-file"></i> View
                                  </Button>
                                </td>
                                <td>
                                  <span className="badge bg-success ">
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="success"
                                    outline
                                    onClick={() => {
                                      getpopup2(data)
                                    }}
                                  >
                                    <i className="bx bx-edit "></i>
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      manageDeletes2(data)
                                    }}
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="danger"
                                    outline
                                  >
                                    <i className="bx bx-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col md={12}>
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount1}
                              onPageChange={changePage1}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists1.length}
                            />
                          </div>
                        </Col>
                      </div>
                    </TabPane>

                    <TabPane tabId="7">
                      <Row>
                        <Col md={6}>
                          <div>
                            <h5
                              className="mb-3 text-primary"
                              style={{ textDecoration: "underline" }}
                            >
                              Consultation
                            </h5>
                          </div>
                          <div className="mt-3">
                            <Input
                              type="search"
                              name="search"
                              value={Searchs2.search}
                              onChange={Search2}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div style={{ float: "right" }}>
                            <Button
                              type="button"
                              className="btn btn-info m-1"
                              onClick={exportPDF2}
                            >
                              <i className="fas fa-file-pdf"></i> Pdf
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div
                        className="table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table className="table table-bordered mb-2 mt-3">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Doctor Id</th>
                              <th>Profile</th>
                              <th>Name </th>
                              <th>Phone </th>
                              <th>Email</th>
                              <th>Location in HCU </th>
                              <th>Tariff / Cost </th>
                              <th>Speciality </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists2.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber2 - 1) * 20 + key + 21}</td>
                                <td>{data.doctorEmployId}</td>
                                <td>
                                  <img
                                    src={URLS.Base + data.employImagelogo}
                                    width="80px"
                                  ></img>
                                </td>
                                <td>
                                  {data.firstName}/{data.lastName}
                                </td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.locationInHoc}</td>
                                <td>{data.tarrifPrice}</td>
                                <td>{data.speciality}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Col md={12}>
                          <div
                            className="d-flex mt-3 mb-1"
                            style={{ float: "right" }}
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount2}
                              onPageChange={changePage2}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists2.length}
                            />
                          </div>
                        </Col>
                      </div>
                    </TabPane>

                    <TabPane tabId="8">
                      <Row>
                        <Col md={6}>
                          <div>
                            <h5
                              className="mb-3 text-primary "
                              style={{ textDecoration: "underline" }}
                            >
                              Home Service
                            </h5>
                          </div>
                          <div className="mt-3">
                            <Input
                              type="search"
                              name="search"
                              value={Searchs3.search}
                              onChange={Search3}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div style={{ float: "right" }}>
                            <Link to="/AddOneHomeservice">
                              <Button color="primary">
                                Add Home Service
                                <i className="bx bx-plus-circle"></i>
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              className="btn btn-info m-1"
                              onClick={exportPDF3}
                            >
                              <i className="fas fa-file-pdf"></i> Pdf
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div>
                          <div
                            className="table-responsive"
                            style={{ height: "480px" }}
                          >
                            <Table className="table table-bordered mb-2 mt-3">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>Actin Service Image</th>
                                  <th>Actin Service Id</th>
                                  <th>HCU Service Id</th>
                                  <th>Actin Service Name</th>
                                  <th>HCU Service Price</th>
                                  <th>Information</th>
                                  <th>Instruction</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lists3.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{(pageNumber3 - 1) * 20 + key + 21}</td>
                                    <td>
                                      <img
                                        src={URLS.Base + data.testImage}
                                        width="80px"
                                      ></img>
                                    </td>
                                    <td>{data.testid}</td>
                                    <td>{data.hocTestid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.hocTestPrice}</td>
                                    <td className="text-center">
                                      <Button
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="warning"
                                        onClick={() => {
                                          getinfo2(data)
                                        }}
                                      >
                                        <i className="bx bxs-file"></i> View
                                      </Button>
                                    </td>
                                    <td className="text-center">
                                      <Button
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="warning"
                                        onClick={() => {
                                          getinc2(data)
                                        }}
                                      >
                                        <i className="bx bxs-file"></i> View
                                      </Button>
                                    </td>
                                    <td>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Col md={12}>
                              <div
                                className="d-flex mt-3 mb-1"
                                style={{ float: "right" }}
                              >
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount3}
                                  onPageChange={changePage3}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists3.length}
                                />
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    HCU Test Id
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter HCU Test Id"
                    required
                    name="hocTestid"
                    value={change.hocTestid}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Public Price <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Enter Public Price"
                    required
                    name="hocTestPrice"
                    value={change.hocTestPrice}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                {dataCommissionType == "Price" ? (
                  <>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        HCU Price
                        <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter HCU Price"
                        required
                        name="hcuCommission"
                        value={change.hcuCommission}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            size="md"
            isOpen={modal_small2}
            toggle={() => {
              tog_small2()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small2(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit2(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    HCU Test Id
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter HCU Test Id"
                    required
                    name="hocTestid"
                    value={change2.hocTestid}
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Public Price <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Enter Public Price"
                    required
                    name="price"
                    value={change2.price}
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    HCU Price
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter HCU Price"
                    required
                    name="hcuCommission"
                    value={change2.hcuCommission}
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small2(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={info}
            toggle={() => {
              infos()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Information :-
              </h5>
              <button
                onClick={() => {
                  setinfo(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="pt-2">
                <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
                {Information}
              </p>
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={ins}
            toggle={() => {
              inst()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Instruction :-
              </h5>
              <button
                onClick={() => {
                  setins(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {Instructions.map((data, i) => (
                <>
                  <p key={i} className="pt-2">
                    <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
                    {data.instructions}
                  </p>
                  <hr></hr>
                </>
              ))}
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={info1}
            toggle={() => {
              infos1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Information :-
              </h5>
              <button
                onClick={() => {
                  setinfo1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="pt-2">
                <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
                {Information1}
              </p>
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={ins1}
            toggle={() => {
              inst()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Instruction :-
              </h5>
              <button
                onClick={() => {
                  setins1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {Instructions1.map((data, i) => (
                <>
                  <p key={i} className="pt-2">
                    <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
                    {data.instructions}
                  </p>
                  <hr></hr>
                </>
              ))}
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={info2}
            toggle={() => {
              infos2()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Information :-
              </h5>
              <button
                onClick={() => {
                  setinfo2(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="pt-2">
                <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
                {Information2}
              </p>
            </div>
          </Modal>

          <Modal
            size="lg"
            isOpen={ins2}
            toggle={() => {
              inst2()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Instruction :-
              </h5>
              <button
                onClick={() => {
                  setins2(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {Instructions2.map((data, i) => (
                <>
                  <p key={i} className="pt-2">
                    <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
                    {data.instructions}
                  </p>
                  <hr></hr>
                </>
              ))}
            </div>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default HospitalPrice
