import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Label,
  Modal,
} from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [Actin, setActin] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetAtps()
    datass()
  }, [])

  const GetAtps = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtp,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.atp)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        URLS.GetAtpSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setActin(res.data.atp)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
    status: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetAtpsFiliter()
  }

  const GetAtpsFiliter = () => {
    var token = datas
    const data = {
      atpType: filters.atpType,
      zoneId: filters.zoneId,
    }
    axios
      .post(URLS.GetAtpSearch, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.atp)
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const Actinid = data => {
    sessionStorage.setItem("Atpids", data._id)
    history.push("/EditAct")
  }

  const Actinid1 = data => {
    sessionStorage.setItem("AtpidD", data._id)
    history.push("/AtpView")
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    const gs = {
      status: "inactive",
    }
    axios
      .put(URLS.InActiveAtp + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAtps()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    const gs = {
      status: "active",
    }

    axios
      .put(URLS.InActiveAtp + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAtps()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    GetCities()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [city, setcity] = useState([])

  const GetCities = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcity(res.data.zones)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="ATP list" />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Zone <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            required
                            value={filters.zoneId}
                            name="zoneId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            {city.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Atp Type <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            required
                            value={filters.atpType}
                            name="atpType"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="Regular"> Regular </option>
                            <option value="Buddy"> Buddy </option>
                            <option value="Emergency"> Emergency </option>
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col>
                          {Roles?.atpListAdd === true ||
                          Roles?.accessAll === true ? (
                            <>
                              <Link to="/AddAct">
                                <Button color="primary">
                                  New ATP <i className="bx bx-plus-circle"></i>
                                </Button>
                              </Link>{" "}
                            </>
                          ) : (
                            ""
                          )}
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>{" "}
                        </Col>

                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={Search}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>ATP Id</th>
                              <th>ATP Image</th>
                              <th>ATP Name</th>
                              <th>ATP Number</th>
                              <th>ATP Email</th>
                              <th>ATP Type</th>
                              <th>Zone</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>
                                  <Link
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                  >
                                    {data.atpCode}
                                  </Link>
                                </td>
                                <td>
                                  <img
                                    src={URLS.Base + data.image}
                                    alt=""
                                    className=" rounded-circle"
                                    style={{ height: "50px", width: "50px" }}
                                  />
                                </td>
                                <td>
                                  {data.firstName}
                                  {data.lastName}{" "}
                                </td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.atpType}</td>
                                <td>{data.zone_name}</td>
                                <td>
                                  {" "}
                                  {data.status == "active" ? (
                                    <>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger ">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  
                                  {Roles?.atpListEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          Actinid(data)
                                        }}
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="success"
                                      >
                                        <i
                                          style={{ fontSize: " 14px" }}
                                          className="bx bx-edit"
                                        ></i>
                                      </Button>{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <Button
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="info"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>

                                  {Roles?.atpListBlock === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "active" ? (
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="danger"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-lock"
                                          ></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            manageDelete1(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="success"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-check"
                                          ></i>
                                        </Button>
                                      )}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Reset Password
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <Row>
                  <Col>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        New Password <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter New Password "
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Confirm Password <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Confirm Password"
                        required
                        name="price"
                      />
                    </div>
                  </Col>
                </Row>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
