import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Button,
  Modal,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"

function RecruitView() {
  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  const [forms, setforms] = useState([])

  const [change, setchange] = useState([])

  const [Test, settest] = useState([])

  const [Topic, seTopic] = useState([])

  const [inc, setinc] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("TicketIdhs")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetOneHomeServiceTickets, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.hospitalData)
        settest(res.data.testData)
        setinc(res.data.testData.instructions)
        seTopic(res.data.TicketRaisingData)
        setchange(res.data.testData)
      })
  }
  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = () => {
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...change }
    myUser[e.target.name] = e.target.value
    setchange(myUser)
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const Editstate = () => {
    var token = datas
    const dataArray = {
      hocTestid: change.hocTestid,
      price: change.hocTestPrice,
      _id: Actinid,
    }

    axios
      .post(URLS.UpdateHomeServiceTickets, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetOneActins()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  const manageDeletes = () => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DelectDistrict()
    }
  }
  const DelectDistrict = () => {
    var token = datas

    var remid = Actinid
    axios
      .delete(URLS.DeleteHomeServiceTickets + "/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.goBack()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="TICKET RASING View" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md={6}>
                  {" "}
                  <ul className="list-unstyled vstack gap-3 mb-0">
                    <div className="KKL">
                      <img
                        src={URLS.Base + forms.hospitalLogo}
                        height="220px"
                        width="50%"
                      ></img>
                    </div>
                    <li>
                      <div className="d-flex mt-5">
                        <i className="bx bxs-buildings font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">HCU Name:</h6>
                          <span className="text-muted">{forms.hcuname} </span>
                        </div>
                      </div>
                    </li>
                    {/* <li>
                      <div className="d-flex mt-3">
                        <i className="bx bx-money font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Identity Number:</h6>
                          <span className="text-muted">
                            {forms.identityNumber}
                          </span>
                        </div>
                      </div>
                    </li> */}
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bxs-book font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            HCU Billing Contact Number:
                          </h6>
                          {forms.hcuBillingContactNumber}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bxs-book font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            Home Tests Contact Number:
                          </h6>
                          {forms.homeTestContactNumber}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bxs-building-house font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            HCU Investigation Contact Number:
                          </h6>
                          {forms.hcuInvestigationContactNumber}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex mt-3">
                        <i className="fas fa-hospital-user font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            Consultation Contact Number:
                          </h6>
                          {forms.ConsultationContactNumber}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bxs-home font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">
                            Home Service Contact Number:
                          </h6>
                          {forms.homeServiceContactNumber}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex mt-3">
                        <i className="bx bxs-message-alt font-size-18 text-primary"></i>
                        <div className="ms-3">
                          <h6 className="mb-1 fw-semibold">Email</h6>
                          <span className="text-muted">{forms.email}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col md={6}>
                  <div>
                    <h5 className="text-center mt-4">TICKET RASING Details</h5>
                    <div
                      className="table-responsive"
                      style={{ height: "480px" }}
                    >
                      <Table className="table table-bordered mb-2 mt-3">
                        <thead>
                          <tr className="text-center">
                            <th>Action</th>
                            <td>
                              {Roles?.ticketRaisingListEdit === true ||
                              Roles?.accessAll === true ? (
                                <>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="success"
                                    outline
                                    onClick={() => {
                                      getpopup()
                                    }}
                                  >
                                    Edit <i className="bx bx-edit "></i>
                                  </Button>
                                </>
                              ) : (
                                ""
                              )}
                              {Roles?.ticketRaisingListDelete === true ||
                              Roles?.accessAll === true ? (
                                <>
                                  <Button
                                    onClick={() => {
                                      manageDeletes()
                                    }}
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="danger"
                                    outline
                                  >
                                    Delete<i className="bx bx-trash"></i>
                                  </Button>{" "}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                          <tr className="text-center">
                            <th>Title</th>
                            <td>{Topic.title}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Description</th>
                            <td>{Topic.description}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Topic</th>
                            <td>{Topic.topic}</td>
                          </tr>
                          <tr className="text-center">
                            <th>ACTIN Test Image</th>
                            <td>
                              <img
                                src={URLS.Base + Test.testImage}
                                width="80px"
                              ></img>
                            </td>
                          </tr>
                          <tr className="text-center">
                            <th>ACTIN Test Id</th>
                            <td>{Test.testid}</td>
                          </tr>
                          <tr className="text-center">
                            <th>HCU Test Id</th>
                            <td>{Test.hocTestid}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Test Name</th>
                            <td>{Test.title}</td>
                          </tr>
                          {/* <tr className="text-center">
                            <th>NABH Rate</th>
                            <td>{Test.nabhrate}</td>
                          </tr>
                          <tr className="text-center">
                            <th>Non-NABH Rate</th>
                            <td>{Test.nonnabhrate}</td>
                          </tr> */}
                          <tr className="text-center">
                            <th>Price</th>
                            <td>{Test.hocTestPrice}</td>
                          </tr>
                          {/* <tr className="text-center">
                            <th>Vacutainer Color</th>
                            <td>{Test.vacutainercolor}</td>
                          </tr> */}
                          <tr className="text-center">
                            <th>Instruction</th>
                            <td className="text-center">
                              {inc.map((data, i) => (
                                <>
                                  <li key={i}>{data.instructions}</li>
                                </>
                              ))}
                            </td>
                          </tr>
                          <tr className="text-center">
                            <th>Information</th>
                            <td>{Test.information}</td>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col md={6}>
                      <h5 className="mb-3">HCU Details:</h5>
                      <ul className="verti-timeline list-unstyled">
                        <li className="event-list mt-4">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Date Of Joining:
                                </h6>
                                <p className="text-muted">
                                  {forms.dateOfJoining}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list ">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">Valid Upto :</h6>
                                <p className="text-muted">{forms.validupto}</p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Health Scheme :
                                </h6>
                                <p className="text-muted">
                                  {forms.healthScheme}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Health Standard :
                                </h6>
                                <p className="text-muted">
                                  {forms.healthStandard}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  HCU Speciality Type:
                                </h6>
                                <p className="text-muted">
                                  {forms.hcuSpecialityType}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Mode of Treatment :
                                </h6>
                                <p className="text-muted">
                                  {" "}
                                  {forms.modeOfTreatement}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Legal Entity Name:
                                </h6>
                                <p className="text-muted">
                                  {forms.legalEntityName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Legal Entity Registration Number:
                                </h6>
                                <p className="text-muted">
                                  {forms.legalEntityRegistrationNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Legal Entity Registration Date:
                                </h6>
                                <p className="text-muted">
                                  {" "}
                                  {forms.legalEntityDate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Organisation Head Name:
                                </h6>
                                <p className="text-muted">
                                  {forms.organizationHeadName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Organisation Head Contact Number:
                                </h6>
                                <p className="text-muted">
                                  {forms.organizationHeadContactNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col md={6}>
                      <ul className="verti-timeline list-unstyled mt-5">
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Timing Of HCU:
                                </h6>
                                <p className="text-muted">
                                  {forms.timingOfHco}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">A+:</h6>
                                <p className="text-muted">{forms.aplus}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  HCU Help Desk Number:
                                </h6>
                                <p className="text-muted">
                                  {forms.hcuHelpDeskNumber}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  Establishment Year:
                                </h6>
                                <p className="text-muted">
                                  {" "}
                                  {forms.establishementYear}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">State Name:</h6>
                                <p className="text-muted"> {forms.stateName}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">
                                  District Name:
                                </h6>
                                <p className="text-muted">
                                  {forms.districtName}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 ">City Name:</h6>
                                <p className="text-muted">{forms.cityName}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">Address</h6>
                                <p className="text-muted">{forms.address}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">Zone</h6>
                                <p className="text-muted">{forms.zone}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">
                                  Lattitude / Longitude
                                </h6>
                                <p className="text-muted">
                                  {forms.latitude} / {forms.longitude}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h6 className="font-size-14 mb-1">Pin Code</h6>
                                <p className="text-muted">{forms.pincode}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    HCU Test Id
                    <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter HCU Test Id"
                    required
                    name="hocTestid"
                    value={change.hocTestid}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    HCU Test Price <span className="text-danger">*</span>
                  </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Enter HCU Test Price"
                    required
                    name="hocTestPrice"
                    value={change.hocTestPrice}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
