import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  CardTitle,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import jsPDF from "jspdf"

import "jspdf-autotable"

const Banner = () => {
  const [modal_small, setmodal_small] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [banner, setbanner] = useState([])
  const [form, setform] = useState([])
  const [form1, setform1] = useState([])
  const [Files, setFiles] = useState("")
  const [Files1, setFiles1] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const AddBanner = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("speciality", form.speciality)
    dataArray.append("filedOfMedicine", form.filedOfMedicine)
    dataArray.append("tarrifPrice", form.tarrifPrice)
    for (let i = 0; i < Files.length; i++) {
      dataArray.append("specialityImage", Files[i])
    }
    axios
      .post(URLS.Adddoctorspeciality, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            clearForm()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const EditBanner = () => {
    var token = datas
    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("speciality", form1.speciality)
    dataArray.append("filedOfMedicine", form1.filedOfMedicine)
    dataArray.append("tarrifPrice", form1.tarrifPrice)
    for (let i = 0; i < Files1.length; i++) {
      dataArray.append("specialityImage", Files1[i])
    }
    axios
      .put(URLS.Editdoctorspeciality + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
            setmodal_small(false)
            clearForm1()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const DeleteBanner = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.Deletedoctorspeciality + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetAllBanners()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteBanner(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddBanner()
  }
  const handleSubmit1 = e => {
    e.preventDefault()
    EditBanner()
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.Getdoctorspeciality,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.faqs)
        setIsLoading(false)
      })
  }
  const clearForm1 = () => {
    setFiles1({
      Image: "",
    })
  }
  const clearForm = () => {
    setform({
      speciality: "",
      filedOfMedicine: "",
      tarrifPrice: "",
    })
    setFiles({
      Image: "",
    })
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetdoctorspecialitySearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.faqs)
        setIsLoading(false)
      })
  }
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const exportPDF = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [["SlNo", "Field of Medicine", "Speciality Name", "Price"]]

    const data = banner.map((elt, i) => [
      i + 1,
      elt.filedOfMedicine,
      elt.speciality,
      elt.tarrifPrice,
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Doctor_Speciality_list.pdf")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Doctor Speciality" />
          <Row>
            {Roles?.consultationPriceRequestAdd === true ||
            Roles?.accessAll === true ? (
              <>
                <Col md={4}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Add Doctor Speciality</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          handleSubmit(e)
                        }}
                      >
                        <div className="mb-3">
                          <Label> Field of Medicine</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.filedOfMedicine}
                            name="filedOfMedicine"
                            onChange={e => {
                              handleChange(e)
                            }}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            <option value="Allopathic Medicine">
                              Allopathic Medicine
                            </option>
                            <option value="Ayurvedic Medicine">
                              Ayurvedic Medicine
                            </option>
                            <option value="Homeopathic Medicine">
                              Homeopathic Medicine
                            </option>
                            <option value="Unani">UNANI</option>
                            <option value="Others"> Others</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Speciality Name{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Speciality Name"
                            required
                            name="speciality"
                            value={form.speciality}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Image{" "}
                            <span className="text-danger">
                              318 * 159 Image Size
                            </span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            required
                            name="Image"
                            value={Files.Image}
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Starting At Price{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Starting At Price"
                            required
                            name="tarrifPrice"
                            value={form.tarrifPrice}
                            onChange={e => {
                              handleChange(e)
                            }}
                          />
                        </div>

                        <div style={{ float: "right" }}>
                          <Button color="primary" type="submit">
                            Submit <i className="fas fa-check-circle"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col
              md={
                Roles?.consultationPriceRequestAdd === true ||
                Roles?.accessAll === true
                  ? 8
                  : 12
              }
            >
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white">
                      <CardTitle>
                        Doctor Speciality List{" "}
                        <Button
                          type="button"
                          className="btn btn-info m-2"
                          onClick={exportPDF}
                        >
                          <i className="fas fa-file-pdf"></i> Pdf
                        </Button>
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              className="form-control"
                              placeholder="Search.."
                              value={search.search}
                              onChange={searchAll}
                              name="search"
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Field of Medicine</th>
                                <th>Speciality Name</th>
                                <th>Image</th>
                                <th>Price</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.filedOfMedicine}</td>
                                  <td>{data.speciality}</td>
                                  <td>
                                    <img
                                      style={{ width: "100px" }}
                                      src={URLS.Base + data.specialityImage}
                                    />
                                  </td>
                                  <td>{data.tarrifPrice}</td>
                                  <td>
                                    {/* modified */}
                                    {Roles?.consultationPriceRequestEdit ===
                                      true || Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            getpopup(data)       
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="success"
                                          outline
                                        >
                                          <i className="bx bx-edit "></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {Roles?.consultationPriceRequestDelete ===
                                      true || Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="danger"
                                          outline
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Doctor Speciality
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label> Field of Medicine</Label>{" "}
                <span className="text-danger">*</span>
                <select
                  value={form1.filedOfMedicine}
                  name="filedOfMedicine"
                  onChange={e => {
                    handleChange1(e)
                  }}
                  required
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="Allopathic Medicine">
                    Allopathic Medicine
                  </option>
                  <option value="Ayurvedic Medicine">Ayurvedic Medicine</option>
                  <option value="Homeopathic Medicine">
                    Homeopathic Medicine
                  </option>
                  <option value="Unani">UNANI</option>
                  <option value="Others"> Others</option>
                </select>
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Speciality Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Speciality Name"
                  required
                  name="speciality"
                  value={form1.speciality}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Starting At Price <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter Starting At Price "
                  required
                  name="tarrifPrice"
                  value={form1.tarrifPrice}
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Image</Label>
                <Input
                  type="file"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  placeholder="Enter image"
                  name="Image"
                  value={Files1.Image}
                  onChange={changeHandler1}
                />
              </div>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Banner
