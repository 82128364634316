import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"

function Monitering() {
  const [activeTab1, setactiveTab1] = useState("5")

  const Actinid = sessionStorage.getItem("Hospitalidss")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const history = useHistory()

  const [Test, setTest] = useState([])

  const Testid = data => {
    sessionStorage.setItem("bookingids", data.booking_id)
    history.push("/ViewLab")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllHomeTestsBooking,
        { hocId: Actinid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setTest(res.data.booking)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Test.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Test.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Investigations, setInvestigations] = useState([])

  const Investigationsid = data => {
    sessionStorage.setItem("bookingidsinv", data.booking_id)
    history.push("/ViewAcceptingInvestigation")
  }

  const [listPerPage1] = useState(20)
  const [pageNumber1, setPageNumber1] = useState(0)

  useEffect(() => {
    GetInvestigations()
  }, [])

  const GetInvestigations = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllInvestigationsBooking,
        { hocId: Actinid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setInvestigations(res.data.data)
      })
  }

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = Investigations.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  )
  const pageCount1 = Math.ceil(Investigations.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const [Consultation, setConsultation] = useState([])

  const Consultationid = data => {
    sessionStorage.setItem("bookingidscon", data.id)
    history.push("/ViewDoctorConsultation")
  }

  const [listPerPage2] = useState(20)
  const [pageNumber2, setPageNumber2] = useState(0)

  useEffect(() => {
    GetConsultation()
  }, [])

  const GetConsultation = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllDoctorsConsltationBooking,
        { hospitalId: Actinid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setConsultation(res.data.booking)
      })
  }

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = Consultation.slice(pagesVisited2, pagesVisited2 + listPerPage2)
  const pageCount2 = Math.ceil(Consultation.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [HomeService, setHomeService] = useState([])

  const HomeServiceid = data => {
    sessionStorage.setItem("bookingidsser", data.id)
    history.push("/ViewHomeService")
  }

  const [listPerPage3] = useState(20)
  const [pageNumber3, setPageNumber3] = useState(0)

  useEffect(() => {
    GetHomeService()
  }, [])

  const GetHomeService = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllHomeServicesBooking,
        { hospitalId: Actinid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setHomeService(res.data.booking)
      })
  }

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = HomeService.slice(pagesVisited3, pagesVisited3 + listPerPage3)
  const pageCount3 = Math.ceil(HomeService.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  const [Package, setPackage] = useState([])

  const Packageid = data => {
    sessionStorage.setItem("bookingids", data.booking_id)
    history.push("/ViewPackagesBookings")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage4] = useState(20)
  const [pageNumber4, setPageNumber4] = useState(0)

  useEffect(() => {
    GetPackages()
  }, [])

  const GetPackages = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllpackagess,
        { hocId: Actinid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setPackage(res.data.booking)
      })
  }

  const pagesVisited4 = pageNumber4 * listPerPage4
  const lists4 = Package.slice(pagesVisited4, pagesVisited4 + listPerPage4)
  const pageCount4 = Math.ceil(Package.length / listPerPage4)
  const changePage4 = ({ selected }) => {
    setPageNumber4(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Hospital Monitoring" />

          <Row>
            {" "}
            <Col md={12}>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>{" "}
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "5",
                          })}
                          onClick={() => {
                            toggle1("5")
                          }}
                        >
                          Tests at Home <b>({Test.length})</b>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "9",
                          })}
                          onClick={() => {
                            toggle1("9")
                          }}
                        >
                          Packages <b>({Package.length})</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "6",
                          })}
                          onClick={() => {
                            toggle1("6")
                          }}
                        >
                          Investigations at HCU <b>({Investigations.length})</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "7",
                          })}
                          onClick={() => {
                            toggle1("7")
                          }}
                        >
                          Consultation <b>({Consultation.length})</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab1 === "8",
                          })}
                          onClick={() => {
                            toggle1("8")
                          }}
                        >
                          Home Service <b>({HomeService.length})</b>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <TabContent activeTab={activeTab1} className=" text-muted">
                    <TabPane tabId="5">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Tests at Home
                      </h5>
                      <div>
                        <Row>
                          {lists.map((data, key) => (
                            <Col sm="12" key={key}>
                              <CardText className="mb-0">
                                <li className="border p-3 d-flex justify-content-between accident-info mb-3 flex-wrap">
                                  <div className="tickit-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmark-star me-2 fs-5"></i>
                                      Actin Booking Id
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      <Link
                                        onClick={() => {
                                          Testid(data)
                                        }}
                                      >
                                        {data.bookingCode}{" "}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-book me-2 fs-5"></i>
                                      Type of Service
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-start">
                                      {data.items.map((data, i) => (
                                        <>
                                          <li key={i}>{data.itemName}</li>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Slot Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.slotDate}-{data.slotTime}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Sample Reached Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {
                                              datas.otherTimings
                                                .sampleReachedDate
                                            }{" "}
                                            -{" "}
                                            {
                                              datas.otherTimings
                                                .sampleReachedTime
                                            }
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Report / Bill Generation Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.completedDate}-
                                            {datas.otherTimings.completedTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmarks me-2 fs-5"></i>
                                      Status
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.status == "canceled" ||
                                      data.status == "failed" ? (
                                        <>
                                          <span className="badge bg-danger">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {data.status == "completed" ? (
                                            <>
                                              <span className="badge bg-success">
                                                {data.status}
                                              </span>
                                            </>
                                          ) : (
                                            <span className="badge bg-warning">
                                              {data.status}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </h6>
                                  </div>
                                </li>
                              </CardText>
                            </Col>
                          ))}
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists.length}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>

                    <TabPane tabId="9">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Packages
                      </h5>
                      <div>
                        <Row>
                          {Package.map((data, key) => (
                            <Col sm="12" key={key}>
                              <CardText className="mb-0">
                                <li className="border p-3 d-flex justify-content-between accident-info mb-3 flex-wrap">
                                  <div className="tickit-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmark-star me-2 fs-5"></i>
                                      Actin Booking Id
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      <Link
                                        onClick={() => {
                                          Packageid(data)
                                        }}
                                      >
                                        {data.bookingCode}{" "}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-book me-2 fs-5"></i>
                                      Type of Service
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-start">
                                      {data.items.map((data, i) => (
                                        <>
                                          <li key={i}>{data.itemName}</li>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Slot Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.slotDate}-{data.slotTime}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Sample Reached Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {
                                              datas.otherTimings
                                                .sampleReachedDate
                                            }{" "}
                                            -{" "}
                                            {
                                              datas.otherTimings
                                                .sampleReachedTime
                                            }
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Report / Bill Generation Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.completedDate}-
                                            {datas.otherTimings.completedTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmarks me-2 fs-5"></i>
                                      Status
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.status == "canceled" ||
                                      data.status == "failed" ? (
                                        <>
                                          <span className="badge bg-danger">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {data.status == "completed" ? (
                                            <>
                                              <span className="badge bg-success">
                                                {data.status}
                                              </span>
                                            </>
                                          ) : (
                                            <span className="badge bg-warning">
                                              {data.status}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </h6>
                                  </div>
                                </li>
                              </CardText>
                            </Col>
                          ))}
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount4}
                                onPageChange={changePage4}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists4.length}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="6">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Investigations at HCU
                      </h5>

                      <div>
                        <Row>
                          {lists1.map((data, key) => (
                            <Col key={key} sm="12">
                              <CardText className="mb-0">
                                <li className="border p-3 d-flex justify-content-between accident-info mb-3 flex-wrap">
                                  <div className="tickit-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmark-star me-2 fs-5"></i>
                                      Actin Booking Id
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      <Link
                                        onClick={() => {
                                          Investigationsid(data)
                                        }}
                                      >
                                        {data.bookingCode}{" "}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-book me-2 fs-5"></i>
                                      Type of Service
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-start">
                                      {data.items.map((data, i) => (
                                        <>
                                          <li key={i}>{data.itemName}</li>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Slot Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.slotDate}-{data.slotTime}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Hcu Confirm Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.otpVerifyDate}-
                                            {datas.otherTimings.otpVerifyTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Report / Bill Generation Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.completedDate}-
                                            {datas.otherTimings.completedTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmarks me-2 fs-5"></i>
                                      Status
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.status == "canceled" ||
                                      data.status == "failed" ? (
                                        <>
                                          <span className="badge bg-danger">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {data.status == "completed" ? (
                                            <>
                                              <span className="badge bg-success">
                                                {data.status}
                                              </span>
                                            </>
                                          ) : (
                                            <span className="badge bg-warning">
                                              {data.status}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </h6>
                                  </div>
                                </li>
                              </CardText>
                            </Col>
                          ))}
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount1}
                                onPageChange={changePage1}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists1.length}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="7">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Consultation
                      </h5>{" "}
                      <div>
                        <Row>
                          {lists2.map((data, key) => (
                            <Col sm="12" key={key}>
                              <CardText className="mb-0">
                                <li className="border p-3 d-flex justify-content-between accident-info mb-3 flex-wrap">
                                  <div className="tickit-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmark-star me-2 fs-5"></i>
                                      Actin Booking Id
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      <Link
                                        onClick={() => {
                                          Consultationid(data)
                                        }}
                                      >
                                        {data.bookingCode}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-book me-2 fs-5"></i>
                                      Type of Service
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.doctorname}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Slot Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.slotDate}-{data.slotTime}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Consultation / Date / Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.otpVerifyDate}-
                                            {datas.otherTimings.otpVerifyTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Prescription / Bill Generation Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.completedDate}-
                                            {datas.otherTimings.completedTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmarks me-2 fs-5"></i>
                                      Status
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.status == "canceled" ||
                                      data.status == "failed" ? (
                                        <>
                                          <span className="badge bg-danger">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {data.status == "completed" ? (
                                            <>
                                              <span className="badge bg-success">
                                                {data.status}
                                              </span>
                                            </>
                                          ) : (
                                            <span className="badge bg-warning">
                                              {data.status}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </h6>
                                  </div>
                                </li>
                              </CardText>
                            </Col>
                          ))}
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount2}
                                onPageChange={changePage2}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists2.length}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="8">
                      <h5
                        className="mb-3 text-primary "
                        style={{ textDecoration: "underline" }}
                      >
                        Home Service
                      </h5>{" "}
                      <div>
                        <Row>
                          {lists3.map((data, key) => (
                            <Col sm="12" key={key}>
                              <CardText className="mb-0">
                                <li className="border p-3 d-flex justify-content-between accident-info mb-3 flex-wrap">
                                  <div className="tickit-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmark-star me-2 fs-5"></i>
                                      Actin Booking Id
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      <Link
                                        onClick={() => {
                                          HomeServiceid(data)
                                        }}
                                      >
                                        {data.bookingCode}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-book me-2 fs-5"></i>
                                      Type of Service
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((data, i) => (
                                        <>
                                          <p>
                                            {" "}
                                            {i + 1}).{data.itemName}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>
                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Slot Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.bookingDate}/{data.bookingTime}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bx-time-five me-2 fs-5"></i>
                                      Service Date/Time
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.items.map((datas, i) => (
                                        <>
                                          <p key={i}>
                                            {datas.otherTimings.completedDate}-
                                            {datas.otherTimings.completedTime}
                                          </p>
                                        </>
                                      ))}
                                    </h6>
                                  </div>

                                  <div className="time-info">
                                    <span className="text-muted">
                                      <i className="bx bxs-bookmarks me-2 fs-5"></i>
                                      Status
                                    </span>
                                    <h6 className="fw-bold small mt-1 text-center">
                                      {data.status == "canceled" ||
                                      data.status == "failed" ? (
                                        <>
                                          <span className="badge bg-danger">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {data.status == "completed" ? (
                                            <>
                                              <span className="badge bg-success">
                                                {data.status}
                                              </span>
                                            </>
                                          ) : (
                                            <span className="badge bg-warning">
                                              {data.status}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </h6>
                                  </div>
                                </li>
                              </CardText>
                            </Col>
                          ))}
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount3}
                                onPageChange={changePage3}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists3.length}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Monitering
