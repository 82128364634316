import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Modal,
  Label,
} from "reactstrap"
import { useHistory, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [Actin, setActin] = useState([])

  const [modal_small, setmodal_small] = useState(false)

  function tog_small() {
    setmodal_small(!modal_small)
  }

  const getpopup = () => {
    tog_small()
  }

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    getActin()
  }, [])

  const getActin = () => {
    var token = datas
    axios
      .post(
        URLS.Getrecruitmentlist,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.atp)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Search = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas
    console.log(token)

    axios
      .post(
        URLS.GetrecruitmentlistSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setActin(res.data.atp)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }


  const Actinid1 = data => {
    sessionStorage.setItem("RecruitId", data._id)
    history.push("/RecruitView")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Recruit List" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {isLoading == true ? (
                    <>
                      <div
                        style={{ zIndex: "9999999999999", height: "420px" }}
                        className="text-center mt-5 pt-5"
                      >
                        <img src={gig} height="140px"></img>
                        <div>Loading......</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={form1.search}
                              onChange={Search}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Name</th>
                              <th>Number</th>
                              <th>Email</th>
                              <th>State</th>
                              <th>District</th>
                              <th>City</th>
                              <th>Address</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>
                                  {data.firstName}
                                  {data.lastName}
                                </td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.stateName}</td>
                                <td>{data.cityName}</td>
                                <td>{data.address}</td>
                                <td>{data.status}</td>
                                <td>
                                  {data.status == "Rejected" ? (
                                    <>
                                      <span className="badge bg-danger">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      {data.status == "Accepted" ? (
                                        <>
                                          <span className="badge bg-success">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <span className="badge bg-warning">
                                          {data.status}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {Roles?.recruitWaitingListEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          getpopup(data)
                                        }}
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="success"
                                      >
                                        <i
                                          style={{ fontSize: " 14px" }}
                                          className="bx bx-edit"
                                        ></i>
                                      </Button>{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Button
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="info"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal_small}
            toggle={() => {
              tog_small()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Edit Status
              </h5>
              <button
                onClick={() => {
                  setmodal_small(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Select Status <span className="text-danger">*</span>
                  </Label>

                  <select className="form-select" required name="cityId">
                    <option value=""> Select</option>
                    <option value=""> Accepted</option>
                    <option value=""> Rejected</option>
                  </select>
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
