import React from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"

function Addzones() {
  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="View Coupon" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={12}>
              <Card>
                <CardBody>
                 
                  <Row className="mb-5">
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Coupon Type:</h5>
                      <span>Telangana</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Title:</h5>
                      <span>Telangana</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Coupon Code:</h5>
                      <span>Telangana</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Limit For Same User :</h5>
                      <span>Kphb</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Coupon Code Type :</h5>
                      <span>Kphb</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Price  :</h5>
                      <span>Kphb</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Minimum Purchase :</h5>
                      <span>Kphb</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Maximum Discount  :</h5>
                      <span>Kphb</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>From Date  :</h5>
                      <span>Kphb</span>
                    </Col>
                    <Col md={3} className="mt-5 mb-5">
                      <h5>Expire Date  :</h5>
                      <span>Kphb</span>
                    </Col>
                    
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Addzones
