import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Button,
  Table,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"
import nodata from "../../assets/images/nodata.gif"
import { ToastContainer, toast } from "react-toastify"

function AddVendors() {
  const [hometest, sethometest] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const hcuid = sessionStorage.getItem("Hospitalidss")

  const [dataCommissionType, setdataCommissionType] = useState([])

  // const handleInputChange = (key, index, value) => {
  //   const newDataArray = [...hometest]
  //   newDataArray[index][key] = value
  //   sethometest(newDataArray)
  // }

  const handleInputChange = (_id, key, value) => {
    const newDataArray = [...hometest]
    const index = newDataArray.findIndex(item => item._id === _id)
    if (index !== -1) {
      newDataArray[index][key] = value
      sethometest(newDataArray)
    } else {
      console.error(`Item with _id ${_id} not found in hometest array.`)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    var validationErrors = []

    if (dataCommissionType == "Price") {
      hometest.forEach((data, index) => {
        if (data.hocTestid && !data.hocTestPrice && !data.hcuCommission) {
          validationErrors.push(
            `HCU Price is required when HCU TEST ID is entered for row ${
              index + 1
            }`
          )
        }

        if (data.hocTestid && !data.hocTestPrice && !data.hcuCommission) {
          validationErrors.push(
            `HCU TEST ID is required when HCU Price is entered for row ${
              index + 1
            }`
          )
        }

        if (
          (data.hocTestid || data.hocTestPrice || data.hcuCommission) &&
          data.checkstatus !== true
        ) {
          validationErrors.push(
            `Status must be "true" or "false" for row ${index + 1}`
          )
        }
      })

      if (validationErrors.length > 0) {
        validationErrors.forEach(error => {
          toast.error(error)
        })
      } else {
        var token = datas
        const dataArray = {
          data: hometest,
          hocId: hcuid,
        }

        axios
          .post(URLS.addAllhometest, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            res => {
              if (res.status === 200) {
                toast(res.data.message)
                history.push("/HospitalPrice")
                sessionStorage.setItem(
                  "tost",
                  "Home Test has been Added Successfully"
                )
              }
            },
            error => {
              if (error.response && error.response.status === 400) {
                toast(error.response.data.message)
              }
            }
          )
      }
    } else {
      hometest.forEach((data, index) => {
        if (data.hocTestid && !data.hocTestPrice) {
          validationErrors.push(
            `HCU Price is required when HCU TEST ID is entered for row ${
              index + 1
            }`
          )
        }

        if (data.hocTestid && !data.hocTestPrice) {
          validationErrors.push(
            `HCU TEST ID is required when HCU Price is entered for row ${
              index + 1
            }`
          )
        }

        if (
          (data.hocTestid || data.hocTestPrice) &&
          data.checkstatus !== true
        ) {
          validationErrors.push(
            `Status must be "true" or "false" for row ${index + 1}`
          )
        }
      })

      if (validationErrors.length > 0) {
        validationErrors.forEach(error => {
          toast.error(error)
        })
      } else {
        var token = datas
        const dataArray = {
          data: hometest,
          hocId: hcuid,
        }

        axios
          .post(URLS.addAllhometest, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            res => {
              if (res.status === 200) {
                toast(res.data.message)
                history.push("/HospitalPrice")
                sessionStorage.setItem(
                  "tost",
                  "Hospital test has been Added Successfully"
                )
              }
            },
            error => {
              if (error.response && error.response.status === 400) {
                toast(error.response.data.message)
              }
            }
          )
      }
    }
  }

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    const data = {
      hocId: hcuid,
    }
    axios
      .post(URLS.getAllhometest, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        sethometest(res.data.tests)
        setdataCommissionType(res.data.commissionType)
        setIsLoading(false)
      })
  }

  const [Searchs, setSearchs] = useState("")

  const Search = e => {
    // let myUser = { ...Searchs }
    // myUser[e.target.name] = e.target.value
    // setSearchs(myUser)
    // var token = datas
    // axios
    //   .post(
    //     URLS.getAllhometestSearch + `${e.target.value}`,
    //     { hocId: hcuid },
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //   )
    //   .then(res => {
    //     sethometest(res.data.tests)
    //     setdataCommissionType(res.data.commissionType)
    //     setIsLoading(false)
    //   })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="List Of Actin Tests" />
          <Row>
            <Col xl="12">
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => history.goBack()}
                  className="mb-3  m-1 "
                  color="primary"
                  style={{ float: "right" }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card className="mb-5 ">
              {isLoading == true ? (
                <>
                  <div
                    style={{ zIndex: "9999999999999", height: "420px" }}
                    className="text-center mt-5 pt-5"
                  >
                    <img src={gig} height="140px"></img>
                    <div>Loading......</div>
                  </div>
                </>
              ) : (
                <>
                  <CardBody>
                    <Row>
                      <Col></Col>
                      <Col>
                        <div style={{ float: "right" }}>
                          <Input
                            type="text"
                            // name="search"
                            // value={Searchs.search}
                            // onChange={Search}
                            onChange={e => {
                              setSearchs(e.target.value)
                            }}
                            className="form-control"
                            placeholder="Search.."
                          />
                        </div>
                      </Col>
                    </Row>
                    {hometest.length > 0 ? (
                      <>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-5 mt-3">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>ACTIN TEST ID</th>
                                <th>Test Name</th>
                                <th>NABH Rate</th>
                                <th>Non-NABH</th>
                                <th>HCU TEST ID</th>
                                <th>Public Price</th>
                                {dataCommissionType == "Price" ? (
                                  <>
                                    <th>HCU Price</th>{" "}
                                  </>
                                ) : (
                                  <></>
                                )}
                                <th>Add</th>
                              </tr>
                            </thead>

                            <tbody>
                              {hometest
                                .filter(value => {
                                  if (Searchs == !null) {
                                    return value
                                  } else if (
                                    value?.title
                                      .toLowerCase()
                                      .includes(Searchs.toLowerCase())
                                  ) {
                                    return value
                                  }
                                })
                                .map((data, index) => (
                                  <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{data.testid}</td>
                                    <td>{data.title}</td>
                                    <td>{data.nabhPrice}</td>
                                    <td>{data.nonNabhPrice}</td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter HCU TEST ID"
                                        value={data.hocTestid}
                                        onChange={e =>
                                          handleInputChange(
                                            data._id,
                                            "hocTestid",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter HCU Price"
                                        value={data.hocTestPrice}
                                        onChange={e =>
                                          handleInputChange(
                                            data._id,
                                            "hocTestPrice",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    {dataCommissionType == "Price" ? (
                                      <>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter HCU Commission"
                                            value={data.hcuCommission}
                                            onChange={e =>
                                              handleInputChange(
                                                data._id,
                                                "hcuCommission",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    <td>
                                      <div className="form-check form-switch form-switch-md mb-3">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id={`customSwitchsizemd${data._id}`}
                                          name="checkstatus"
                                          defaultChecked={
                                            data.checkstatus == true
                                          }
                                          value={data.checkstatus}
                                          onChange={e =>
                                            handleInputChange(
                                              data._id,
                                              "checkstatus",
                                              e.target.checked
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          <div style={{ float: "right" }}>
                            <Button
                              className="m-1"
                              color="primary"
                              type="submit"
                            >
                              Submit <i className="fas fa-check-circle"></i>
                            </Button>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      <>
                        <div
                          style={{ zIndex: "9999999999999", height: "440px" }}
                          className="text-center mt-5 pt-5"
                        >
                          <img src={nodata} height="300px"></img>
                        </div>
                      </>
                    )}
                  </CardBody>
                </>
              )}
            </Card>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
