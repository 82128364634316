import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Button,
  Table,
  Input,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import gig from "../../assets/images/loders.gif"
import nodata from "../../assets/images/nodata.gif"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"

function AddVendors() {
  const [hometest, sethometest] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const [dataCommissionType, setdataCommissionType] = useState([])

  const hcuid = sessionStorage.getItem("Hospitalidss")

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  // const handleInputChange = (key, index, value) => {
  //   const newDataArray = [...hometest]
  //   newDataArray[index][key] = value
  //   sethometest(newDataArray)
  // }

  const handleInputChange = (_id, key, value) => {
    const newDataArray = [...hometest]
    const index = newDataArray.findIndex(item => item._id === _id)
    if (index !== -1) {
      newDataArray[index][key] = value
      sethometest(newDataArray)
    } else {
      console.error(`Item with _id ${_id} not found in hometest array.`)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const validationErrors = []
    if (dataCommissionType == "Price") {
      hometest.forEach((data, index) => {
        if (
          data.price &&
          !data.hocInvestigateid &&
          !data.roomLocationHoc &&
          !data.hcuCommission
        ) {
          validationErrors.push(
            `Investigate Price is required when Investigate Test Id is entered for row ${
              index + 1
            }`
          )
        }

        if (
          data.hocInvestigateid &&
          !data.price &&
          !data.roomLocationHoc &&
          !data.hcuCommission
        ) {
          validationErrors.push(
            `Investigate Test Id is required when Investigate Price is entered for row ${
              index + 1
            }`
          )
        }

        if (
          (data.price ||
            data.hocInvestigateid ||
            (data.roomLocationHoc && !data.hcuCommission)) &&
          data.checkstatus !== true
        ) {
          validationErrors.push(
            `Status must be "true" or "false" for row ${index + 1}`
          )
        }
      })

      if (validationErrors.length > 0) {
        validationErrors.forEach(error => {
          toast.error(error)
        })
      } else {
        var token = datas
        const dataArray = {
          hocId: hcuid,
          data: hometest,
        }

        axios
          .post(URLS.addAllInvestigations, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            res => {
              if (res.status === 200) {
                toast(res.data.message)
                history.push("/HospitalPrice")
                sessionStorage.setItem(
                  "tost",
                  "Investigation has been Added Successfully"
                )
              }
            },
            error => {
              if (error.response && error.response.status === 400) {
                toast(error.response.data.message)
              }
            }
          )
      }
    } else {
      hometest.forEach((data, index) => {
        if (data.price && !data.hocInvestigateid && !data.roomLocationHoc) {
          validationErrors.push(
            `Investigate Price is required when Investigate Test Id is entered for row ${
              index + 1
            }`
          )
        }

        if (data.hocInvestigateid && !data.price && !data.roomLocationHoc) {
          validationErrors.push(
            `Investigate Test Id is required when Investigate Price is entered for row ${
              index + 1
            }`
          )
        }

        if (
          (data.price || data.hocInvestigateid || data.roomLocationHoc) &&
          data.checkstatus !== true
        ) {
          validationErrors.push(
            `Status must be "true" or "false" for row ${index + 1}`
          )
        }
      })

      if (validationErrors.length > 0) {
        validationErrors.forEach(error => {
          toast.error(error)
        })
      } else {
        var token = datas
        const dataArray = {
          hocId: hcuid,
          data: hometest,
        }

        axios
          .post(URLS.addAllInvestigations, dataArray, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(
            res => {
              if (res.status === 200) {
                toast(res.data.message)
                history.push("/HospitalPrice")
                sessionStorage.setItem(
                  "tost",
                  "Hospital has been Added Successfully"
                )
              }
            },
            error => {
              if (error.response && error.response.status === 400) {
                toast(error.response.data.message)
              }
            }
          )
      }
    }
  }

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas

    axios
      .post(
        URLS.getAllInvestigations,
        { hocId: hcuid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        sethometest(res.data.investigations)
        setdataCommissionType(res.data.commissionType)
        setIsLoading(false)
      })
  }

  const [Searchs, setSearchs] = useState("")

  const Search = e => {
    // let myUser = { ...Searchs }
    // myUser[e.target.name] = e.target.value
    // setSearchs(myUser)
    // var token = datas
    // axios
    //   .post(
    //     URLS.getAllInvestigationsSearch + `${e.target.value}`,
    //     { hocId: hcuid },
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //   )
    //   .then(res => {
    //     sethometest(res.data.investigations)
    //     setdataCommissionType(res.data.commissionType)
    //     setIsLoading(false)
    //   })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Test" breadcrumbItem="Add Investigation" />
          <Row>
            <Col xl="12">
              <div style={{ float: "right" }}>
                <Button
                  onClick={history.goBack}
                  className="mb-3  m-1 "
                  color="primary"
                  style={{ float: "right" }}
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </div>
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card className="mb-5 ">
              {isLoading == true ? (
                <>
                  <div
                    style={{ zIndex: "9999999999999", height: "420px" }}
                    className="text-center mt-5 pt-5"
                  >
                    <img src={gig} height="140px"></img>
                    <div>Loading......</div>
                  </div>
                </>
              ) : (
                <>
                  <CardBody>
                    <Row>
                      <Col></Col>
                      <Col>
                        <div style={{ float: "right" }}>
                          <Input
                            type="text"
                            // name="search"
                            // value={Searchs.search}
                            // onChange={Search}
                            onChange={e => {
                              setSearchs(e.target.value)
                            }}
                            className="form-control"
                            placeholder="Search.."
                          />
                        </div>
                      </Col>
                    </Row>
                    {hometest.length > 0 ? (
                      <>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-5 mt-3">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Actin Investigation Id</th>
                                <th>Investigation Name</th>
                                <th>HCU Investigation Id</th>
                                <th>HCU Price</th>
                                {dataCommissionType == "Price" ? (
                                  <>
                                    <th>HCU Commission</th>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <th>Location in HCU</th>
                                <th style={{ width: "100px" }}>Active</th>
                              </tr>
                            </thead>
                            <tbody>
                              {hometest.filter(value => {
                                  if (Searchs == !null) {
                                    return value
                                  } else if (
                                    value?.actinInvestigationName
                                      .toLowerCase()
                                      .includes(Searchs.toLowerCase())
                                  ) {
                                    return value
                                  }
                                }).map((data, index) => (
                                <tr key={index} className="text-center">
                                  <td>{index + 1}</td>
                                  <td>{data.actinInvestigationCode}</td>
                                  <td>{data.actinInvestigationName}</td>

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter HCU TEST ID"
                                      value={data.hocInvestigateid}
                                      onChange={e =>
                                        handleInputChange(
                                          data._id,
                                          "hocInvestigateid",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter HCU Price"
                                      value={data.price}
                                      onChange={e =>
                                        handleInputChange(
                                          data._id,
                                          "price",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>

                                  {dataCommissionType == "Price" ? (
                                    <>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter HCU Commission"
                                          value={data.hcuCommission}
                                          onChange={e =>
                                            handleInputChange(
                                              data._id,
                                              "hcuCommission",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </td>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Room Location Hoc"
                                      value={data.roomLocationHoc}
                                      onChange={e =>
                                        handleInputChange(
                                          data._id,
                                          "roomLocationHoc",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <div className="form-check form-switch form-switch-md mb-3">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`customSwitchsizemd${data._id}`}
                                        name="status"
                                        defaultChecked={
                                          data.checkstatus == true
                                        }
                                        value={data.checkstatus}
                                        onChange={e =>
                                          handleInputChange(
                                            data._id,
                                            "status",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div style={{ float: "right" }}>
                            <Button
                              className="m-1"
                              color="primary"
                              type="submit"
                            >
                              Submit <i className="fas fa-check-circle"></i>
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{ zIndex: "9999999999999", height: "440px" }}
                          className="text-center mt-5 pt-5"
                        >
                          <img src={nodata} height="300px"></img>
                        </div>
                      </>
                    )}
                  </CardBody>
                </>
              )}
            </Card>
          </Form>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
