import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactEcharts from "echarts-for-react"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import { Link } from "react-router-dom"

const Dashboard = () => {
  const [form, setform] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [Calendar, setCalendar] = useState([])

  const [TestAthome, setTestAthome] = useState([])

  const [Investigations, setInvestigations] = useState([])

  const [Constultation, setConstultation] = useState([])

  const [Homeservice, setHomeservice] = useState([])

  const [Customer, setCustomer] = useState([])

  const [Health, setHealth] = useState([])

  const [Atp, setAtp] = useState([])

  useEffect(() => {
    GetSettings()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const GetSettings = () => {
    var token = datas

    axios
      .post(
        URLS.GetAllDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.data)
        setCalendar(res.data.data.claendardata)
        setTestAthome(res.data.data.homegraphsdata)
        setConstultation(res.data.data.doctorgraphdata)
        setInvestigations(res.data.data.investigatgraphata)
        setHomeservice(res.data.data.homeservicesgraphsdata)
        setHealth(res.data.data.healeth)
        setAtp(res.data.data.atpdata)
        setCustomer(res.data.data.customers)
        setIsLoading(false)
      })
  }

  const reports1s = [
    { title: "CGHS", iconClass: "bx-copy-alt", description: Health.CGHSvalue },
    {
      title: "EHS",
      iconClass: "bx-purchase-tag-alt",
      description: Health.EHSvalue,
    },
    {
      title: "AAROGYASRI",
      iconClass: "bx-archive-in",
      description: Health.AAROGYASRIvalue,
    },
    {
      title: "PMJAY",
      iconClass: "bx-archive-in",
      description: Health.PMJAYvalue,
    },
    {
      title: "ECHS",
      iconClass: "bx-archive-in",
      description: Health.EHSvalue,
    },
    { title: "ESI", iconClass: "bx-archive-in", description: Health.ESIvalue },
    {
      title: "Pvt Insurance",
      iconClass: "bx-archive-in",
      description: Health.PvtInsurancevalue,
    },
    {
      title: "General Public",
      iconClass: "bx-archive-in",
      description: Health.GeneralPublicvalue,
    },
  ]

  const reports1 = [
    {
      title: "Tests at Home ",
      iconClass: "bx-copy-alt",
      description: form.testatHome,
    },
    {
      title: "Investigations at HCU",
      iconClass: "bx-purchase-tag-alt",
      description: form.investigationCount,
    },
    {
      title: "Consultation",
      iconClass: "bx-archive-in",
      description: form.consultationCount,
    },
    {
      title: "Home Services ",
      iconClass: "bx-archive-in",
      description: form.homeservicesCount,
    },
  ]

  const reports2s = [
    {
      title: "Total Bookings ",
      iconClass: "bx-copy-alt",
      description: form.totalCount,
    },

    {
      title: "Slot Bookings",
      iconClass: "bx-purchase-tag-alt",
      description: form.testatHome,
    },
    {
      title: "ACT-INstant Bookings",
      iconClass: "bx-archive-in",
      description: form.instantCount,
    },
    {
      title: "Emergency Bookings",
      iconClass: "bx-copy-alt",
      description: form.emergencyCount,
    },
  ]

  const reports2 = [
    {
      title: "Total ATP'S",
      iconClass: "bx-archive-in",
      description: Atp.atpcount,
    },
    {
      title: "Total Regular ATP'S",
      iconClass: "bx-archive-in",
      description: Atp.regulartatpcount,
    },
    {
      title: "Total Buddy's",
      iconClass: "bx-purchase-tag-alt",
      description: Atp.buddyatpcount,
    },

    {
      title: "Emerg calls ATP'S",
      iconClass: "bx-archive-in",
      description: Atp.emergencyatpcount,
    },
    {
      title: "Total ATP'S Pres/Abs",
      iconClass: "bx-archive-in",
      description: "-",
    },
    {
      title: "ATP'S Pres/Abs",
      iconClass: "bx-archive-in",
      description: "-",
    },
    {
      title: "Buddy's Pres/Abs",
      iconClass: "bx-archive-in",
      description: "-",
    },
    {
      title: "Emerg ATP'S Pres/Abs",
      iconClass: "bx-archive-in",
      description: "-",
    },
  ]

  document.title = "Dashboard"

  const options1 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["Cardiology", "Dermatologist", "Orthopedic"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: TestAthome,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const options2 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["CT", "MRI", "Ultra Sound"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: Investigations,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const options3 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["ECG", "CGM", "Holter"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: Constultation,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const options4 = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: ["ECG", "CGM", "Holter"],
      textStyle: {
        color: ["#74788d"],
      },
    },
    color: ["#02a499", "#f8b425", "#ec4561"],
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: Homeservice,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  }

  const eventRender = info => {
    const now = new Date()
    if (info.event.start < now) {
      info.el.classList.add("past-event")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]
  

  return (
    <React.Fragment>
      {Roles?.Dashview === true || Roles?.accessAll === true ? (
        <>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title={"ACTIN"} breadcrumbItem={"Dashboard"} />
              {isLoading == true ? (
                <>
                  <Card>
                    <CardBody>
                      <div
                        style={{ zIndex: "9999999999999", height: "450px" }}
                        className="text-center mt-5 pt-5"
                      >
                        {" "}
                        <img src={gig} height="140px"></img>
                        <div>Loading......</div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <>
                  <Row>
                    <Col lg="12">
                      <Card>
                        <CardBody>
                          <Row>
                            <Col lg="12">
                              <div className="d-flex">
                                <div className="flex-grow-1 align-self-center">
                                  <div className="text-muted">
                                    <h5 className="mb-4">
                                      <b>Dashboard</b>
                                    </h5>

                                    <Row>
                                      <Col xs="2">
                                        <div>
                                          <h1 className="mb-0">
                                            <b>{form.hospitalcount}</b>
                                          </h1>
                                          <p className="text-muted text-truncate mb-2">
                                            Total Hospitals
                                          </p>
                                        </div>
                                      </Col>
                                      <Col xs="3">
                                        <div>
                                          <h1 className="mb-0">
                                            <b>
                                              {form.hospitaltestContactcount}
                                            </b>
                                          </h1>
                                          <p className="text-muted text-truncate mb-2">
                                            Total Exclusive Tests
                                          </p>
                                        </div>
                                      </Col>
                                      <Col xs="2">
                                        <div>
                                          <h1 className="mb-0">
                                            <b>
                                              {form.InvestigationsContactcount}
                                            </b>
                                          </h1>
                                          <p className="text-muted text-truncate mb-2">
                                            Investigations
                                          </p>
                                        </div>
                                      </Col>
                                      <Col xs="2">
                                        <div>
                                          <h1 className="mb-0">
                                            <b>{form.consultationcount}</b>
                                          </h1>
                                          <p className="text-muted text-truncate mb-2">
                                            Exclusive Consultations
                                          </p>
                                        </div>
                                      </Col>
                                      <Col xs="2">
                                        <div>
                                          <h1 className="mb-0">
                                            <b>{form.homeservicescount}</b>
                                          </h1>
                                          <p className="text-muted text-truncate mb-2">
                                            Exclusive Home Services
                                          </p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <h5>Total Bookings </h5>
                    <Col xl="12">
                      <Row>
                        {reports1.map((report, key) => (
                          <Col md="3" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-24"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        {reports2s.map((report, key) => (
                          <Col md="3" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-24"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                      <Col xl="12">
                        <h5>Health Schemes Count</h5>
                        <Row>
                          {/* Reports Render */}
                          {reports1s.map((report, key) => (
                            <Col md="3" key={"_col_" + key}>
                              <Card className="mini-stats-wid">
                                <CardBody>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <p className="text-muted fw-medium">
                                        {report.title}
                                      </p>
                                      <h4 className="mb-0">
                                        {report.description}
                                      </h4>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                      <span className="avatar-title rounded-circle bg-primary">
                                        <i
                                          className={
                                            "bx " +
                                            report.iconClass +
                                            " font-size-24"
                                          }
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Row>
                        <h5>Total ATP'S </h5>
                        {reports2.map((report, key) => (
                          <Col md="3" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                              <CardBody>
                                <div className="d-flex">
                                  <div className="flex-grow-1">
                                    <p className="text-muted fw-medium">
                                      {report.title}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.description}
                                    </h4>
                                  </div>
                                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                      <i
                                        className={
                                          "bx " +
                                          report.iconClass +
                                          " font-size-24"
                                        }
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <h5>Graphs </h5>
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <h5>Tests (NUMBER)</h5>
                          <ReactEcharts
                            style={{ height: "350px" }}
                            option={options1}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <h5>Investigations at HCU </h5>
                          <ReactEcharts
                            style={{ height: "350px" }}
                            option={options2}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <h5>Consultation (NUMBER)</h5>
                          <ReactEcharts
                            style={{ height: "350px" }}
                            option={options3}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <h5>Home Services (NUMBER)</h5>
                          <ReactEcharts
                            style={{ height: "350px" }}
                            option={options4}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <h5>Scheduled </h5>
                    <Col xl={12}>
                      <Card>
                        <CardBody>
                          <div>
                            <FullCalendar
                              plugins={[dayGridPlugin]}
                              initialView="dayGridMonth"
                              events={Calendar}
                              eventRender={eventRender}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <h5>Latest Customers</h5>
                    <Col lg="12">
                      <Card>
                        <CardBody>
                          <h5 className="mt-3 mb-3">
                            Latest Customers
                            <span style={{ float: "right" }}>
                              Customers Count :
                              <span className="text-primary">200</span>
                            </span>
                          </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>SlNo</th>
                                  <th>User Id</th>
                                  <th>Name</th>
                                  <th>Phone</th>
                                  <th>Email</th>
                                  <th>Age</th>
                                  <th>Address</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Customer.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{key + 1}</td>
                                    <td>
                                      <Link>{data.userCode}</Link>
                                    </td>
                                    <td>{data.name}</td>
                                    <td>{data.phone}</td>
                                    <td>{data.email}</td>
                                    <td>{data.age}</td>
                                    <td>{data.address}</td>
                                    <td>
                                      {data.status == "active" ? (
                                        <>
                                          <span className="badge bg-success ">
                                            {data.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="badge bg-danger ">
                                            {data.status}
                                          </span>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </div>{" "}
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

export default Dashboard
