import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show")

        const parent3 = parent2.parentElement

        if (parent3) {
          parent3.classList.add("mm-active")
          parent3.childNodes[0].classList.add("mm-active")
          const parent4 = parent3.parentElement
          if (parent4) {
            parent4.classList.add("mm-show")
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show")
              parent5.childNodes[0].classList.add("mm-active")
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {Roles?.Dashview === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/dashboard">
                    <i className="bx bx-home-circle"></i>
                    <span>{props.t("Dashboards")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.zonesView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/Zones">
                    <i className="bx bx-world"></i>
                    <span>{props.t("Zones")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.EmployeeManagmentView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-wallet-alt"></i>
                    <span>{props.t("Employee Managment")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.departementView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Departments">
                            {props.t("Departments")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.rolesAndPermissionView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/RolesPremissions">
                            {props.t("Roles & Premissions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.staffView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Staff">{props.t("Staff")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.AllHospitalView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-business"></i>
                    <span>{props.t("All Hospitals")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.allHospitalDashboardView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AllVendorsdashboard">
                            {props.t("All Hositals Dashboard")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.hospitalListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Hospital">
                            {props.t("Hospitals List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.hospitalMonitoringView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/HospitalMonitering">
                            {props.t("Hospital Monitoring")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.hospitalProfileRequestEdit === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/HospitalEditRequest">
                            {props.t("Hospital Profile Request")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.ATPView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-group"></i>
                    <span>{props.t("ATP ")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.ATPView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Act">{props.t("ATP List")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.atpSlotMakingView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AtpTimming">
                            {props.t("ATP Slot Making")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.atpMonitoringView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Atpmoniter">
                            {props.t("ATP Monitoring")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.atpSlotWiseMonitoringView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AtpSlotWiseMonitering">
                            {props.t("ATP Slot Wise Monitoring")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.recruitWaitingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Recruit">
                            {props.t("Recruit waiting List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.leaveRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/GetBuddsRequest">
                            {props.t("Buddy Request")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.leaveRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AtpLeaves">
                            {props.t("ATP Pending Leaves")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.leaveRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/ApprovedLeaves">
                            {props.t("ATP Approved Leaves")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.UserListView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-id-card"></i>
                    <span>{props.t("User")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/Users">{props.t("User list")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.TestsAtHomeView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-book"></i>
                    <span>{props.t("Tests at Home")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.homeTestListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Tests">{props.t("Home Tests List")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.newTestsRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/NewTestsRequest">
                            {props.t("New Test Request")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.packagesRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Packagesrequest">
                            {props.t("Packages request")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.InvestigationsAtHCUView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-building-house"></i>
                    <span>{props.t("Investigations at HCU")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.investigationsRequestsView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/InvestigationsAtHospitalList">
                            {props.t("Investigation List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.newInvestigationRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/InvestigationsAtHospitalListRequset">
                            {props.t("New Investigation Request")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.DoctorConsultationView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-hospital-user"></i>
                    <span>{props.t("Doctor Consultation")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.consultationPriceRequestView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Speciality">
                            {props.t("Doctor Speciality")}
                          </Link>
                        </li>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.pescreptiondecipheringView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Ps">
                            {props.t("Prescription Deciphering")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.HomeServiceView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-home"></i>
                    <span>{props.t("Home Service")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.homeServiceListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/HomeServiceList">
                            {props.t("Home Service List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.homeServiceListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/HomeServiceRequest">
                            {props.t("New Home Service Request")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.ticketRaisingListView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-quote-right"></i>
                    <span>{props.t("Ticket Rasing List")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/PriceRequest">
                        {props.t("Home tests Ticket Rasing")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/PackagesTickets">
                        {props.t("Packages Tickets")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/InvestigationsTickets">
                        {props.t("Investigations Ticket Rasing")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/ConsultationTickets">
                        {props.t("Consultation Ticket Rasing")}
                      </Link>
                    </li>

                    <li>
                      <Link to="/HomeServicesTickets">
                        {props.t("Home Services Ticket Rasing")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.BookingManagmentView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-bookmarks"></i>
                    <span>{props.t("Booking Managment")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.homeBookingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Labs">
                            {props.t("Home Booking Tests")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.emergencyBookingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Emergency">
                            {props.t("Emergency Booking List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.actInstantBookingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Actinstant">
                            {props.t("ACT-INstant Booking List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.PackageBookingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/PackagesBookings">
                            {props.t("Packages Booking List")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.investigationAtHcuView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AcceptingInvestigation">
                            {props.t("Investigations at HCU")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.consultationView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Consultation">
                            {props.t("Consultation")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.homeServiceView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/HomeService">
                            {props.t("Home Service")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.CancellationListView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-bookmarks"></i>
                    <span>{props.t("Cancellation Bookings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.CancellationBookingListView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/PendingCancellation">
                            {props.t("Pending Cancellation")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/CompleatedCancellation">
                            {props.t("Completed Cancellation")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/PendingRefunds">
                            {props.t("Pending Refunds")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/CompletedRefunds">
                            {props.t("Completed Refunds")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.AccountsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-book-reader"></i>
                    <span>{props.t("Accounts")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.ExpensesCategoryView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Income">
                            {props.t("Expenses Category")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.ExpenseslistView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Expenses">{props.t("Expenses List")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.IncomeStatmentView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Revenue">
                            {props.t("Income Statement")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.StaffPayRoleView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Payrole">{props.t("Staff Pay Role")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.AtpRoleView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AtpPayrole">
                            {props.t("Atp Pay Role")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.paymentsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-receipt"></i>
                    <span>{props.t("Payments Managment")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/Payments">{props.t("Payments")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.PaymentsReportsView === true ||
            Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-file-doc"></i>
                    <span>{props.t("Payment Reports")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/BookingsTestsReport">
                            {props.t("Home Tests Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/ActinstantReport">
                            {props.t("ACT-INstant Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/EmergencyReport">
                            {props.t("Emergency Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.homeTestReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/PackageReport">
                            {props.t("Package Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.investigationAtHcuReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/AcceptingInvestigationsReport">
                            {props.t("Investigations Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.consultationReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/DoctorConsultationReport">
                            {props.t("Consultation Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.homeServiceReportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/HomeServiceReport">
                            {props.t("Home Service Report")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.userTcSupportView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-support"></i>
                    <span>{props.t("Technical Support")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {" "}
                    {Roles?.hcuTcSupportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Support">{props.t("HCU TC Support")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.atpTcSupportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/DoctorTcSupport">
                            {props.t("Doctor Contact Support")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.atpTcSupportView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/DoctorFeedback">
                            {props.t("Doctor FeedBack")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.CRMView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bxs-layer"></i>
                    <span>{props.t("CRM")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.enquiryView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Prescriptions">{props.t("Enquiry")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.chatView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/UserSupport">
                            {props.t("User Support")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.bannersView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-images"></i>
                    <span>{props.t("Banners")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.bannersView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Banners">{props.t("Banners")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    {Roles?.suBannersView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/SubBanners">{props.t("Sub Banners")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.bannersView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/DoctorBanners">
                            {props.t("Doctor Banners")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.couponsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/Coupones">
                    <i className="fas fa-ticket-alt"></i>
                    <span>{props.t("Coupons")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.notificationsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/Notifications">
                    <i className="fa fa-bell"></i>
                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
              </>
            ) : (
              ""
            )}

            {Roles?.SettingsView === true || Roles?.accessAll === true ? (
              <>
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-cogs"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {Roles?.BlogView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Blog">{props.t("Blog")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                    
                    {Roles?.stateView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/State">{props.t("State")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}{" "}

                    {Roles?.distrctView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Distirct">{props.t("District")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}{" "}

                    {Roles?.cityView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/City">{props.t("City")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.manualView === true || Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Manual">{props.t("Manual")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.cgsCornerView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/CghsCorner">{props.t("CGHS Corner")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.ChargesView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Charges">{props.t("Charges")}</Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {Roles?.settingsView === true ||
                    Roles?.accessAll === true ? (
                      <>
                        <li>
                          <Link to="/Enquiry">{props.t("Enquiry")}</Link>
                        </li>
                        <li>
                          <Link to="/ContactUs">{props.t("Contact Us")}</Link>
                        </li>
                        <li>
                          <Link to="/Faqs">{props.t("FAQ'S")}</Link>
                        </li>

                        <li>
                          <Link to="/About">{props.t("About")}</Link>
                        </li>
                        <li>
                          <Link to="/PrivicyPolicy">
                            {props.t("Privacy Policy")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/RefundPolicy">
                            {props.t("Refund Policy")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/Termsconditions">
                            {props.t("Terms & Conditions")}
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
