import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

function Ventures() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
    datass()
    GetCities()
  }, [])

  const GetHospitals = () => {
    var token = datas

    const dataarray = {
      zoneId: "",
      serviceList: "",
      dateOfJoining: "",
    }
    axios
      .post(URLS.GetHospital, dataarray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.hospital)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("Hospitalid", data._id)
    history.push("/EditHospital")
  }

  const Actinid1 = data => {
    sessionStorage.setItem("Hospitalids", data._id)
    history.push("/HospitalView")
  }

  const Actinid2 = data => {
    sessionStorage.setItem("Hospitalidss", data._id)
    history.push("/HospitalPrice")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    zoneId: "",
    serviceList: "",
    dateOfJoining: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetHospitalsFiliter()
  }

  const GetHospitalsFiliter = () => {
    var token = datas
    let data = {
      zoneId: filters.zoneId,
      serviceList: filters.serviceList,
      dateOfJoining: filters.dateOfJoining,
    }
    axios
      .post(URLS.GetHospital, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.hospital)
        setfilter(false)
        setfilters({
          zoneId: "",
          serviceList: "",
          dateOfJoining: "",
        })
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    const gs = {
      status: "inactive",
    }
    axios
      .put(URLS.InActiveHospital + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHospitals()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    const gs = {
      status: "active",
    }

    axios
      .put(URLS.InActiveHospital + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHospitals()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [city, setcity] = useState([])

  const GetCities = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcity(res.data.zones)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas

    const dataarray = {
      zoneId: "",
      serviceList: "",
      dateOfJoining: "",
    }

    axios
      .post(
        URLS.GetHospitalSearch + `${e.target.value}`,
        dataarray,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.hospital)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Hospital list" />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-declaration-input10">
                            Date Of Joining{" "}
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-Declaration-input10"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="dateOfJoining"
                            value={filters.dateOfJoining}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">Zone</Label>

                          <select
                            className="form-select"
                            value={filters.zoneId}
                            name="zoneId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            {city.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Service Wise
                          </Label>

                          <select
                            className="form-select"
                            value={filters.serviceList}
                            name="serviceList"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            {" "}
                            <option value=""> Select</option>
                            <option value="Test At Home">
                              {" "}
                              Tests at Home{" "}
                            </option>
                            <option value="Investigations at HCU">
                              {" "}
                              Investigations at HCU{" "}
                            </option>
                            <option value="Consultations">
                              {" "}
                              Consultations
                            </option>
                            <option value="Home Services">
                              {" "}
                              Home Services{" "}
                            </option>
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          {Roles?.hospitalListAdd === true ||
                          Roles?.accessAll === true ? (
                            <>
                              <Link to="/AddHospital">
                                <Button color="primary">
                                  New Hospital{" "}
                                  <i className="bx bx-plus-circle"></i>
                                </Button>
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>{" "}
                        </Col>

                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "460px" }}
                      >
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Hospital Id</th>
                              <th>Hospital Name</th>
                              <th>Mobile</th>
                              <th>Email</th>
                              <th>Zone</th>
                              <th>Location</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>
                                  <Link
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                  >
                                    {data.hospitalCode}
                                  </Link>
                                </td>
                                <td>{data.hospitalName}</td>
                                <td>{data.hospitalMobile}</td>
                                <td>{data.email}</td>
                                <td>{data.zoneName}</td>
                                <td>{data.address}</td>
                                <td>
                                  {data.status == "active" ? (
                                    <>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger ">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {Roles?.hospitalListEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          Actinid(data)
                                        }}
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="success"
                                      >
                                        <i
                                          style={{ fontSize: " 14px" }}
                                          className="bx bx-edit"
                                        ></i>
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {Roles?.hospitalListEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          Actinid2(data)
                                        }}
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="success"
                                      >
                                        <i
                                          style={{ fontSize: " 14px" }}
                                          className="bx bxs-data"
                                        ></i>
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <Button
                                    onClick={() => {
                                      Actinid1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="info"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>

                                  {Roles?.hospitalListBlock === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "active" ? (
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="danger"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-lock"
                                          ></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            manageDelete1(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="success"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-check"
                                          ></i>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
