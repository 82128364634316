import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { CSVLink } from "react-csv"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function PaymentReport() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])
  useEffect(() => {
    ActinReport()
  }, [])

  const [userInCsv, setuserInCsv] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const ActinReport = () => {
    var token = datas
    const data = {
      hospitalId: "",
      fromDate: "",
      toDate: "",
      zoneId: "",
      status: "All",
    }

    axios
      .post(URLS.GetAllBookingHomeServiceReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setActin(res.data.data)
        setuserInCsv(res.data.ExcelData)
      })
  }
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
    zoneId: "",
    hospitalId: "",
    status: "All",
  })

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      zoneId: filters.zoneId,
      hospitalId: filters.hospitalId,
      status: filters.status,
    }
    axios
      .post(URLS.GetAllBookingHomeServiceReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setActin(res.data.data)
        setuserInCsv(res.data.ExcelData)
        hidefilter()
        setfilters("")
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const csvReport = {
    filename: "Actin Report",
    data: userInCsv,
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A1"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "S.No",
        "ACTIN User Id",
        "ACTIN Booking Id",
        "HCU User Id",
        "HCU Booking Id",
        "Booking Date",
        "Booking Status",
        "Final Amount ",
        "Appointment Charges ",
        "Coupon Discount",
        "Transaction Charges",
        "Final Amount Paid By User",
        "Gst Charges",
        "HCU Share ",
        "TDS Amount",
        "ACTIN Share ",
        "HCU Bill No ",
        "HCU Receipt No",
        "Payment Date",
        "Payment Id ",
        "Status",
      ],
    ]

    const data = Actin.map((elt, i) => [
      i + 1,
      elt.actinUserCode,
      elt.actinBookingCode,
      elt.hcuUserCode,
      elt.hcuBookingCode,
      elt.bookingDate,
      elt.bookingStatus,
      elt.subTotal,
      elt.appointmentCharges,
      elt.couponDiscount,
      elt.transactionCharges,
      elt.totalPrice,
      elt.gst,
      elt.hcuShare,
      elt.tdsamount,
      elt.actinShare,
      elt.hcuBillNumber,
      elt.hcuRecieptNumber,
      elt.paymentDate,
      elt.transactionId,
      elt.paymentStatus,
    ])
    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Home_Service_Report.pdf")
  }
  useEffect(() => {
    GetCities()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [city, setcity] = useState([])

  const GetCities = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcity(res.data.zones)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [hospital, sethospital] = useState([])

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    const dataarray = {
      zoneId: "",
      serviceList: "",
      dateOfJoining: "",
    }
    axios
      .post(URLS.GetHospital, dataarray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        sethospital(res.data.hospital)
      })
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="ACTIN" breadcrumbItem="Home Service Report" />

            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              required
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Zone Wise <span className="text-danger">*</span>
                            </Label>

                            <select
                              className="form-select"
                              value={filters.zoneId}
                              name="zoneId"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                            >
                              <option value=""> Select </option>
                              {city.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input3">
                              Hospital Wise{" "}
                              <span className="text-danger">*</span>
                            </Label>

                            <select
                              className="form-select"
                              value={filters.hospitalId}
                              name="hospitalId"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                            >
                              <option value=""> Select </option>
                              {hospital.map((data, key) => {
                                return (
                                  <option key={key} value={data._id}>
                                    {data.hospitalName}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <span className="text-danger">*</span>
                            <select
                              value={filters.status}
                              required
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="status"
                              className="form-select"
                            >
                              <option value="All">Select All</option>
                              <option value="pending">Pending</option>
                              <option value="booking confirmed">
                                Booking Confirmed
                              </option>
                              <option value="otp verfied">Otp Verfied</option>
                              <option value="rescheduled">Rescheduled</option>
                              <option value="completed">Completed</option>
                              <option value="canceled">Canceled</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mt-4">
                            <Button type="submit" className="m-1" color="info">
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}

            <Card>
              {isLoading == true ? (
                <>
                  <div
                    style={{ zIndex: "9999999999999", height: "420px" }}
                    className="text-center mt-5 pt-5"
                  >
                    <img src={gig} height="140px"></img>
                    <div>Loading......</div>
                  </div>
                </>
              ) : (
                <>
                  <CardBody>
                    <div>
                      <div
                        className="table-responsive"
                        style={{ height: "480px" }}
                      >
                        <div className="col-sm-12">
                          <div style={{ float: "right" }}>
                            <Row>
                              <Col>
                                <div style={{ float: "right" }}>
                                  <CSVLink {...csvReport}>
                                    <button
                                      className="btn btn-success me-2"
                                      type="submit"
                                    >
                                      <i className="fas fa-file-excel"></i>{" "}
                                      Excel
                                    </button>
                                  </CSVLink>
                                  <Button
                                    type="button"
                                    className="btn btn-danger "
                                    onClick={exportPDF}
                                  >
                                    <i className="fas fa-file-pdf"></i> Pdf
                                  </Button>

                                  <Button
                                    className="m-1"
                                    onClick={() => {
                                      setfilter(!filter)
                                    }}
                                    color="info"
                                  >
                                    <i className="fas fa-filter"></i> Filter
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div className="mt-3">
                          <Table
                            id="empTable"
                            className="table table-bordered mb-3 mt-5"
                          >
                            <thead className="text-center">
                              <tr>
                                <th>S.No</th>
                                <th>ACTIN User Id</th>
                                <th>ACTIN Booking Id</th>
                                <th>HCU User Id</th>
                                <th>HCU Booking Id</th>
                                <th>Booking Date</th>
                                <th>Booking Status</th>
                                <th>Final Amount </th>
                                <th>Appointment Charges </th>
                                <th>Coupon Discount</th>
                                <th>Transaction Charges</th>
                                <th>Final Amount Paid By User</th>
                                <th>Gst Charges</th>
                                <th>HCU Share </th>
                                <th>TDS Amount</th>
                                <th>ACTIN Share </th>
                                <th>HCU Bill No </th>
                                <th>HCU Receipt No</th>
                                <th>Payment Date</th>
                                <th>Payment Id </th>
                                <th>Status</th>
                              </tr>
                            </thead>

                            <tbody className="text-center">
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.actinUserCode}</td>
                                  <td>{data.actinBookingCode}</td>
                                  <td>{data.hcuUserCode}</td>
                                  <td>{data.hcuBookingCode}</td>
                                  <td>{data.bookingDate}</td>
                                  <td>
                                    {data.bookingStatus == "canceled" ||
                                    data.bookingStatus == "failed" ? (
                                      <>
                                        <span className="badge bg-danger">
                                          {data.bookingStatus}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {data.bookingStatus == "completed" ? (
                                          <>
                                            <span className="badge bg-success">
                                              {data.bookingStatus}
                                            </span>
                                          </>
                                        ) : (
                                          <span className="badge bg-warning">
                                            {data.bookingStatus}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td>{data.subTotal}</td>
                                  <td>{data.appointmentCharges}</td>
                                  <td>{data.couponDiscount}</td>
                                  <td>{data.transactionCharges}</td>
                                  <td>{data.totalPrice}</td>
                                  <td>{data.gst}</td>
                                  <td>{data.hcuShare}</td>
                                  <td>{data.tdsamount.toFixed(2)}</td>
                                  <td>{data.actinShare}</td>
                                  <td>{data.hcuBillNumber}</td>
                                  <td>{data.hcuRecieptNumber}</td>
                                  <td>{data.paymentDate}</td>
                                  <td>{data.transactionId}</td>
                                  <td>{data.paymentStatus}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </>
              )}
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
