import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Map, GoogleApiWrapper, Polygon } from "google-maps-react"
import { URLS } from "../../Url"
import axios from "axios"

function Addzones(props) {
  const [newZones, setNewZones] = useState([])
  // const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });

  const history = useHistory()

  useEffect(() => {
    GetZones()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Center = { lat: 17.38714, lng: 78.491684 }

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNewZones(res.data.zones)

        //   // Calculate the center of all zones
        //   if (res.data.zones.length > 0) {
        //     const center = res.data.zones.reduce(
        //       (acc, zone) => {
        //         if (zone.coordinates && zone.coordinates.length > 0) {
        //           return {
        //             lat: acc.lat + zone.coordinates[0].lat,
        //             lng: acc.lng + zone.coordinates[0].lng
        //           };
        //         }
        //         return acc;
        //       },
        //       { lat: 0, lng: 0 }
        //     );

        //     center.lat /= res.data.zones.length;
        //     center.lng /= res.data.zones.length;

        //     console.log("Calculated Center:", center); // Debug

        //     setMapCenter(center);
        //   } else {
        //     // Handle the case where there are no zones
        //     console.error("No zones data found");
        //   }
        // })
        // .catch((error) => {
        //   // Handle API request errors here
        //   console.error("Error fetching zone data:", error);
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="All Zone View" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3 m-1"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody style={{ height: "500px" }}>
                  <Map
                    style={{ padding: "20px", height: "450px", width: "96%" }}
                    google={props.google}
                    zoom={10}
                    // center={Center}
                    initialCenter={Center}
                  >
                    {newZones.map((zone, index) => (
                      <Polygon
                        key={index}
                        paths={zone.coordinates}
                        strokeColor={zone.strokeColor || "#ff0000"}
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor={zone.fillColor || "#ff0000"}
                        fillOpacity={0.35}
                      />
                    ))}
                  </Map>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBAgjZGzhUBDznc-wI5eGRHyjVTfENnLSs",
})(Addzones)
