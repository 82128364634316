import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"

function RecruitView() {
  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  const [forms, setforms] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("RecruitId")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.Getonerecruitmentlist, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.atp)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="RECRUIT Profile" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody>
                  <h5 className="mb-3">Recruit User Details : </h5>
                  <Row>
                    <Col lg={4}>
                      <ul className="list-unstyled vstack gap-3 mb-0">
                        {forms.profileImage == "" ? (
                          ""
                        ) : (
                          <img
                            src={URLS.Base + forms.profileImage}
                            height="150px"
                            width="50%"
                          ></img>
                        )}
                        <li>
                          <div className="d-flex mt-5">
                            <i className="bx bxs-buildings font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold"> Name: </h6>
                              <span className="text-muted">
                                {forms.firstName}
                                {forms.lastName}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex  mt-3">
                            <i className="bx bxs-home-circle font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">
                                Contact Number:
                              </h6>
                              {forms.phone}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex  mt-3">
                            <i className="bx bxs-user-pin font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">Email:</h6>
                              {forms.email}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex  mt-3">
                            <i className="bx bx-bookmark font-size-18 text-primary"></i>
                            <div className="ms-3">
                              <h6 className="mb-1 fw-semibold">Status :</h6>
                              <span className="text-muted">{forms.status}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={8}>
                      <Row>
                        <Col>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list mt-4">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Qualification:
                                    </h6>
                                    <p className="text-muted">
                                      {forms.qualification}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list mt-4">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Year Of Experience:
                                    </h6>
                                    <p className="text-muted">
                                      {forms.yearsOfExperience}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list mt-4">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Year Of Graduation:
                                    </h6>
                                    <p className="text-muted">
                                      {forms.yearOfGraduation}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list mt-4">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">
                                      Resume :
                                    </h6>
                                    <p className="text-muted">
                                      <embed
                                        src={URLS.Base + forms.identifyImage}
                                        type="application/pdf"
                                        width="100%"
                                        height="300px"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                        <Col>
                          <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">State</h6>
                                    <p className="text-muted">
                                      {forms.stateName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">District</h6>
                                    <p className="text-muted">
                                      {forms.districtName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 ">City </h6>
                                    <p className="text-muted">
                                      {forms.cityName}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Address
                                    </h6>
                                    <p className="text-muted">
                                      {forms.address}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="event-list">
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle"></i>
                              </div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <div>
                                    <h6 className="font-size-14 mb-1">
                                      Postal Address
                                    </h6>
                                    <p className="text-muted">
                                      {forms.pincode}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RecruitView
