import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import { ToastContainer, toast } from "react-toastify"

function AddVendors() {
  const handleSubmit = e => {
    e.preventDefault()
    Adddealer()
  }

  const [inputList, setInputList] = useState([])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { fromtime: "", totime: "" }])
  }
  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtptimeslot,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setInputList(res.data.slotatp.timings)
      })
  }

  const Adddealer = () => {
    var token = datas

    const data = {
      timmings: inputList,
    }

    axios
      .put(URLS.UpdateAtptimeslot, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/AtpTimming")
            sessionStorage.setItem(
              "tost",
              "Atp slot has been updated Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ATP'S"
            breadcrumbItem="Atp's Holiday's Slot Making"
          />

          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>{" "}
            </Col>
          </Row>
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Card>
              <CardBody>
                <Row className="mt-2">
                  <Row>
                    <Col md={10}>
                      <div>
                        <Row>
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <Row>
                                  <div key={i} className="box row">
                                    <Col md="4" sm="12" className="mb-1">
                                      <Label>From Time</Label>
                                      <Input
                                        type="time"
                                        name="fromtime"
                                        placeholder="Enter From Time"
                                        value={x.fromtime}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col md="4" sm="12" className="mb-1">
                                      <Label>To Time</Label>
                                      <Input
                                        type="time"
                                        name="totime"
                                        placeholder="Enter To Time"
                                        value={x.totime}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <Label className="mt-1"></Label>
                                      <div className="btn-box">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger btn-sm m-1 mt-3"
                                            type="button"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove{" "}
                                            <i className="bx bx-x-circle"></i>
                                          </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                          <button
                                            className="btn btn-sm btn-outline-info m-1 mt-3"
                                            onClick={handleAddClick}
                                          >
                                            Add{" "}
                                            <i className="bx bx-plus-circle"></i>
                                          </button>
                                        )}
                                      </div>
                                    </Col>

                                    <Col sm="2" className="mt-5">
                                      <p style={{ fontWeight: "bold" }}>
                                        Slot : {i + 1}
                                      </p>
                                    </Col>
                                  </div>
                                </Row>
                              </>
                            )
                          })}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Row>
                <div style={{ float: "right" }}>
                  <Button className="m-1 mt-5" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddVendors
