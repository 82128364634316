import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Button,
  Input,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import Moment from "react-moment"
import gig from "../../assets/images/loders.gif"

function HospitalPrice() {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const Actinid1 = data => {
    sessionStorage.setItem("TicketId", data._id)
    history.push("/PriceRequestView")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    date: "",
    zoneId: "",
    hospitalId: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetAtpsFiliter()
  }

  const GetAtpsFiliter = () => {
    var token = datas
    const data = {
      zoneId: filters.zoneId,
      date: filters.date,
      hospitalId: filters.hospitalId,
    }
    axios
      .post(URLS.GetaALLTicketRasingList, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.testTicketRaising)
        setfilter(false)
        setfilters({
          date: "",
          zoneId: "",
          hospitalId: "",
        })
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  const [Actin, setActin] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetaALLTicketRasingList,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.testTicketRaising)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    GetCities()
  }, [])

  const [city, setcity] = useState([])

  const GetCities = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcity(res.data.zones)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [hospital, sethospital] = useState([])

  useEffect(() => {
    GetHospitalss()
  }, [])

  const GetHospitalss = () => {
    var token = datas
    const dataarray = {
      zoneId: "",
      serviceList: "",
      dateOfJoining: "",
    }
    axios
      .post(URLS.GetHospital, dataarray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        sethospital(res.data.hospital)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="Test at Home Ticket Rasing List"
          />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-declaration-input10">
                            Date Wise
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-Declaration-input10"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                            name="date"
                            value={filters.date}
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Zone <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            value={filters.zoneId}
                            name="zoneId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            <option value=""> Select </option>
                            {city.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Hospital Wise<span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            value={filters.hospitalId}
                            name="hospitalId"
                            onChange={e => {
                              handleChangeflt(e)
                            }}
                          >
                            {" "}
                            <option value=""> Select </option>
                            {hospital.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.hospitalName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row>
                            <Col>
                              <h5
                                className=" text-primary"
                                style={{ textDecoration: "underline" }}
                              >
                                Test at Home Ticket Rasing List
                              </h5>
                            </Col>{" "}
                            <Col>
                              <Button
                                className="m-1 mb-3"
                                onClick={() => {
                                  setfilter(!filter)
                                }}
                                color="info"
                                style={{ float: "right" }}
                              >
                                <i className="fas fa-filter"></i> Filter
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12}>
                          <div>
                            <div
                              className="table-responsive"
                              style={{ height: "480px" }}
                            >
                              <Table className="table table-bordered mb-4 mt-2">
                                <thead>
                                  <tr className="text-center">
                                    <th>S.No</th>
                                    <th>Ticket Id</th>
                                    <th>Date / Time</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Topic</th>
                                    <th>Status</th>
                                    <th>Resolution Date & Time</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lists.map((data, key) => (
                                    <tr key={key} className="text-center">
                                      <td>
                                        {(pageNumber - 1) * 20 + key + 21}
                                      </td>
                                      <td>{data.ticketId}</td>
                                      <td>
                                        <Moment format="DD-MM-YYYY">
                                          {data.logCreatedDate}
                                        </Moment>
                                        /
                                        <Moment format="hh:mm A">
                                          {data.logCreatedDate}
                                        </Moment>
                                      </td>
                                      <td>{data.title}</td>
                                      <td>{data.description}</td>
                                      <td>{data.topic}</td>
                                      <td>
                                        {data.status == "Pending" ? (
                                          <>
                                            <span className="badge bg-warning">
                                              {data.status}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <span className="badge bg-success">
                                              {data.status}
                                            </span>
                                          </>
                                        )}
                                      </td>
                                      <td>
                                        {data.resolvedDateTime == "" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <Moment format="DD-MM-YYYY">
                                              {data.resolvedDateTime}
                                            </Moment>
                                            /
                                            <Moment format="hh:mm A">
                                              {data.resolvedDateTime}
                                            </Moment>
                                          </>
                                        )}
                                      </td>
                                      <td>
                                        <Button
                                          onClick={() => {
                                            Actinid1(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="info"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-eye"
                                          ></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <div className="mt-3" style={{ float: "right" }}>
                                <ReactPaginate
                                  previousLabel={"Previous"}
                                  nextLabel={"Next"}
                                  pageCount={pageCount}
                                  onPageChange={changePage}
                                  containerClassName={"pagination"}
                                  previousLinkClassName={"previousBttn"}
                                  nextLinkClassName={"nextBttn"}
                                  disabledClassName={"disabled"}
                                  activeClassName={"active"}
                                  total={lists.length}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HospitalPrice
