import React, { useState, useEffect } from "react"
import { CardBody, Container, Row, Col, Card, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Map, GoogleApiWrapper, Polygon } from "google-maps-react"
import { URLS } from "../../Url"
import axios from "axios"

function Addzones(props) {
  const [zones, setZones] = useState([])
  const [newZone, setNewZone] = useState([])
  const [newZone1, setNewZone1] = useState([])
  const [form, setform] = useState([])

  const history = useHistory()

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("ZoneIdss")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetIdZone, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.zoneResult)
        setNewZone(res.data.zoneResult.coordinates)
        setNewZone1(res.data.zoneResult.coordinates[0])
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Zone View" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={12}>
              <Card>
                <CardBody>
                  <h5 className="mb-5">Zone View : </h5>
                  <Row className="mt-5 mb-5">
                    <Col md={3}>
                      <h5>State Name:</h5>
                      <span>{form.stateName}</span>
                    </Col>
                    <Col md={3}>
                      <h5>Distict Name:</h5>
                      <span>{form.districtName}</span>
                    </Col>
                    <Col md={3}>
                      <h5>City Name:</h5>
                      <span>{form.cityName}</span>
                    </Col>
                    <Col md={3}>
                      <h5>Zone Name:</h5>
                      <span>{form.name}</span>
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    {zones.map((data, i) => (
                      <>
                        <Col key={i} className="mt-5 mb-5">
                          <h5>Latitude:</h5>
                          <span>{data.lat}</span>
                          <h5 className="mt-5 ">Longitude:</h5>
                          <span>{data.lng}</span>
                        </Col>
                      </>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <Card>
                <CardBody style={{ height: "500px" }}>
                  <Map
                    style={{ padding: "20px", height: "450px", width: "96%" }}
                    google={props.google}
                    zoom={12}
                    center={newZone1}
                    initialCenter={newZone1}
                  >
                    {zones.map((zone, index) => (
                      <Polygon
                        key={index}
                        paths={zone}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        fillColor="#0000FF"
                        fillOpacity={0.35}
                      />
                    ))}
                    <Polygon
                      paths={newZone}
                      strokeColor="#FF0000"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#FF0000"
                      fillOpacity={0.35}
                    />
                  </Map>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBAgjZGzhUBDznc-wI5eGRHyjVTfENnLSs",
})(Addzones)
