import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import ReactPaginate from "react-paginate"

function State() {
  const [forms, setforms] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [activeTab1, setactiveTab1] = useState("5")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  const history = useHistory()

  const [Test, setTest] = useState([])

  const Testid = data => {
    sessionStorage.setItem("bookingids", data.bookingId)
    history.push("/ViewLab")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Test.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Test.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [Investigations, setInvestigations] = useState([])

  const Investigationsid = data => {
    sessionStorage.setItem("bookingidsinv", data.bookingId)
    history.push("/ViewAcceptingInvestigation")
  }

  const [listPerPage1] = useState(20)
  const [pageNumber1, setPageNumber1] = useState(0)

  const pagesVisited1 = pageNumber1 * listPerPage1
  const lists1 = Investigations.slice(
    pagesVisited1,
    pagesVisited1 + listPerPage1
  )
  const pageCount1 = Math.ceil(Investigations.length / listPerPage1)
  const changePage1 = ({ selected }) => {
    setPageNumber1(selected)
  }

  const [Consultation, setConsultation] = useState([])

  const Consultationid = data => {
    sessionStorage.setItem("bookingidscon", data.bookingId)
    history.push("/ViewDoctorConsultation")
  }

  const [listPerPage2] = useState(20)
  const [pageNumber2, setPageNumber2] = useState(0)

  const pagesVisited2 = pageNumber2 * listPerPage2
  const lists2 = Consultation.slice(pagesVisited2, pagesVisited2 + listPerPage2)
  const pageCount2 = Math.ceil(Consultation.length / listPerPage2)
  const changePage2 = ({ selected }) => {
    setPageNumber2(selected)
  }

  const [HomeService, setHomeService] = useState([])

  const HomeServiceid = data => {
    sessionStorage.setItem("bookingidsser", data.bookingId)
    history.push("/ViewHomeService")
  }

  const [listPerPage3] = useState(20)
  const [pageNumber3, setPageNumber3] = useState(0)

  const pagesVisited3 = pageNumber3 * listPerPage3
  const lists3 = HomeService.slice(pagesVisited3, pagesVisited3 + listPerPage3)
  const pageCount3 = Math.ceil(HomeService.length / listPerPage3)
  const changePage3 = ({ selected }) => {
    setPageNumber3(selected)
  }

  useEffect(() => {
    GetOneActins()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Actinid = sessionStorage.getItem("UserId")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetOneUser, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setforms(res.data.userResult)
        setTest(res.data.testdata)
        setInvestigations(res.data.investigationdata)
        setConsultation(res.data.consultationdata)
        setHomeService(res.data.homeServicedata)
        setIsLoading(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="User Profile" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row className="mb-5">
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Nav pills className="navtab-bg nav-justified">
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "5",
                                })}
                                onClick={() => {
                                  toggle1("5")
                                }}
                              >
                                Profile
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "6",
                                })}
                                onClick={() => {
                                  toggle1("6")
                                }}
                              >
                                Test At Home
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "7",
                                })}
                                onClick={() => {
                                  toggle1("7")
                                }}
                              >
                                Investigation At HCE
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "8",
                                })}
                                onClick={() => {
                                  toggle1("8")
                                }}
                              >
                                Consultation
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                  active: activeTab1 === "9",
                                })}
                                onClick={() => {
                                  toggle1("9")
                                }}
                              >
                                Home Service
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>
                      <TabContent
                        activeTab={activeTab1}
                        className="p-4  text-muted"
                      >
                        <TabPane tabId="5">
                          <h5 className="mb-3">Profile Details : </h5>
                          <Row>
                            <Col lg={4}>
                              <ul className="list-unstyled vstack gap-3 mb-0">
                                <img
                                  src={URLS.Base + forms.profileImage}
                                  height="150px"
                                  width="50%"
                                ></img>

                                <li>
                                  <div className="d-flex mt-5">
                                    <i className="bx bxs-buildings font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Name:{" "}
                                      </h6>
                                      <span className="text-muted">
                                        {forms.name}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex mt-3">
                                    <i className="bx bx-money font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Identity Number:
                                      </h6>
                                      <span className="text-muted">
                                        {forms.userCode}
                                      </span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bxs-home-circle font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Contact Number:
                                      </h6>
                                      {forms.phone}
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bxs-user-pin font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Alternate Number:
                                      </h6>
                                      {forms.phone}
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="d-flex  mt-3">
                                    <i className="bx bx-bookmark font-size-18 text-primary"></i>
                                    <div className="ms-3">
                                      <h6 className="mb-1 fw-semibold">
                                        Status :
                                      </h6>
                                      <span className="text-muted">
                                        {forms.status}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                            <Col lg={8}>
                              <ul className="verti-timeline list-unstyled">
                                <li className="event-list mt-4">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Date Of Joning:
                                        </h6>
                                        <p className="text-muted">
                                          {forms.logCreatedDate.slice(0,10)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>{" "}
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Gender
                                        </h6>
                                        <p className="text-muted">
                                          {forms.gender}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">Age</h6>
                                        <p className="text-muted">
                                          {forms.age}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Card Name
                                        </h6>
                                        <p className="text-muted">
                                          {forms.cardName}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Card Validity
                                        </h6>
                                        <p className="text-muted">
                                          {forms.cardValidity}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li className="event-list">
                                  <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                  </div>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <div>
                                        <h6 className="font-size-14 ">
                                          Covered Under
                                        </h6>
                                        <p className="text-muted">
                                          <img
                                            src={
                                              URLS.Base + forms.coverUnderImage
                                            }
                                            height="150px"
                                            width="50%"
                                            alt="empty"
                                          ></img>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="6">
                          <h5 className="mb-3"> Test At Home: </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>ACTIN Booking Id</th>
                                  <th>ACTIN User Id</th>
                                  <th>User Name</th>
                                  <th>Contact No</th>
                                  <th>Test Details</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lists.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                    <td>{data.bookingCode}</td>
                                    <td>{data.userCode}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>
                                    <td className="text-start">
                                      {data.items.map((data, i) => (
                                        <>
                                          <p key={i}>
                                            {" "}
                                            {i + 1}) {data.label}
                                          </p>
                                        </>
                                      ))}
                                    </td>
                                    <td>
                                      {data.bookingDate}/{data.bookingTime}
                                    </td>

                                    <td>
                                      <span className="badge bg-success ">
                                        {data.bookingStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Testid(data)
                                        }}
                                        color="info"
                                        outline
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists.length}
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="7">
                          <h5 className="mb-3">Investigation At HCE : </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>ACTIN Booking Id</th>
                                  <th>ACTIN User Id</th>
                                  <th>User Name</th>
                                  <th>User Number</th>
                                  <th>Investigation Name</th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {" "}
                                {lists1.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{(pageNumber1 - 1) * 5 + key + 6}</td>
                                    <td>{data.bookingCode}</td>
                                    <td>{data.userCode}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>
                                    <td className="text-start">
                                      {data.items.map((data, i) => (
                                        <>
                                          <p key={i}>
                                            {" "}
                                            {i + 1}) {data.label}
                                          </p>
                                        </>
                                      ))}
                                    </td>
                                    <td>
                                      {data.bookingDate}/{data.bookingTime}
                                    </td>

                                    <td>
                                      <span className="badge bg-success ">
                                        {data.bookingStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Investigationsid(data)
                                        }}
                                        color="info"
                                        outline
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount1}
                                onPageChange={changePage1}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists1.length}
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="8">
                          <h5 className="mb-3">Consultation List: </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>S.No</th>
                                  <th>HCU Doctor / Id</th>
                                  <th>ACTIN Booking Id</th>
                                  <th>ACTIN User Id</th>
                                  <th>User Name</th>
                                  <th>Phone</th>
                                  <th>Date</th>
                                  <th>Status </th>
                                  <th>Action </th>
                                </tr>
                              </thead>
                              <tbody>
                                {lists2.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{(pageNumber2 - 1) * 5 + key + 6}</td>
                                    <td>
                                      {data.doctorEmployCode}/ {data.doctorName}
                                    </td>
                                    <td>{data.bookingCode}</td>
                                    <td>{data.userCode}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>

                                    <td>
                                      {data.bookingDate}/{data.bookingTime}
                                    </td>

                                    <td>
                                      <span className="badge bg-success ">
                                        {data.bookingStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          Consultationid(data)
                                        }}
                                        color="info"
                                        outline
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount2}
                                onPageChange={changePage2}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists2.length}
                              />
                            </div>
                          </Col>
                        </TabPane>

                        <TabPane tabId="9">
                          <h5 className="mb-3">Home Service List: </h5>
                          <div className="table-rep-plugin mt-4 table-responsive" style={{height:"480px"}}>
                            <Table hover className="table table-bordered mb-4 ">
                              <thead>
                                <tr className="text-center">
                                  <th>SlNo</th>
                                  <th>Home Service Name</th>
                                  <th>Home Service Details</th>
                                  <th>User Name</th>
                                  <th>User Phone</th>
                                  <th>User Address</th>
                                  <th>Date / Time</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {" "}
                                {lists3.map((data, key) => (
                                  <tr key={key} className="text-center">
                                    <td>{(pageNumber3 - 1) * 5 + key + 6}</td>
                                    <td>{data.bookingCode}</td>
                                    <td>{data.userCode}</td>
                                    <td>{data.userName}</td>
                                    <td>{data.userPhone}</td>
                                    <td>{data.userAddress}</td>
                                    {data.bookingDate}/{data.bookingTime}
                                    <td>
                                      <span className="badge bg-success ">
                                        {data.bookingStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        onClick={() => {
                                          HomeServiceid(data)
                                        }}
                                        color="info"
                                        outline
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <Col md={12}>
                            <div
                              className="d-flex mt-3 mb-1"
                              style={{ float: "right" }}
                            >
                              <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount3}
                                onPageChange={changePage3}
                                containerClassName={"pagination"}
                                previousLinkClassName={"previousBttn"}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                total={lists3.length}
                              />
                            </div>
                          </Col>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
