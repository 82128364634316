import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

const Notifications = () => {
  const [form, setform] = useState([])

  const [Noti, setNoti] = useState([])

  const [customer, setcustomer] = useState([])

  const [Atp, setAtp] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const getNotifications = () => {
    var token = datas
    axios
      .post(
        URLS.GetNotifications,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notification)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getNotifications()
    getactivecustomers()
    getactiveAtp()
    GetCities()
  }, [])

  const getactivecustomers = () => {
    var token = datas

    axios
      .post(
        URLS.GetUser,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcustomer(res.data.userResult)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }
  const getactiveAtp = () => {
    var token = datas

    axios
      .post(
        URLS.GetAtp,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setAtp(res.data.atp)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [selectedMulti, setselectedMulti] = useState([])

  const [selectedMulti1, setselectedMulti1] = useState([])

  const [error, setError] = useState("")

  function handleMulti(data) {
    setselectedMulti(data)
  }

  function handleMulti1(data) {
    setselectedMulti1(data)
  }

  const options = customer.map(data => ({
    value: data._id,
    label: data.name,
  }))

  const options1 = Atp.map(data => ({
    value: data._id,
    label: data.firstName + data.lastName,
  }))

  const addnotifi = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("title", form.title)

    if (form.department == "ATP") {
      for (let i = 0; i < selectedMulti1.length; i++) {
        dataArray.append("userList", selectedMulti1[i].value)
      }
    }

    if (form.department == "USER") {
      for (let i = 0; i < selectedMulti.length; i++) {
        dataArray.append("userList", selectedMulti[i].value)
      }
    }

    if (form.department == "All") {
      dataArray.append("userList", "All")
    }

    dataArray.append("notifType", form.notifType)
    dataArray.append("description", form.description)
    dataArray.append("department", form.department)
    dataArray.append("zoneId", form.zoneId)

    axios
      .post(URLS.AddNotifications, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setIsLoading(true)
            getNotifications()
            clearForm()
            setselectedMulti("")
            setselectedMulti1("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const deletenoti = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteNotifications + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getNotifications()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletenoti(data)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    addnotifi()
    // if (form.userList == "All") {
    //   addnotifi()
    // }

    // if (selectedMulti.length == 0) {
    //   setError("Please select at least one option.")
    // } else {
    //   addnotifi()
    // }
  }

  const clearForm = () => {
    setform({
      department: "",
      zoneId: "",
      notifType: "",
      title: "",
      description: "",
      userList: "",
    })
  }

  const [forms, setforms] = useState([])

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetNotificationsSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notification)
      })
  }

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Noti.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Noti.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [city, setcity] = useState([])

  const GetCities = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcity(res.data.zones)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Notifications" />

          <Row>
            {Roles?.notificationsAdd === true || Roles?.accessAll === true ? (
              <>
                <Col md={4}>
                  <Card className="p-4">
                    <h5>Add Notification</h5>

                    <Form
                      onSubmit={e => {
                        handleSubmit(e)
                      }}
                    >
                      <div>
                        <div className="mt-3">
                          <Label>Title</Label>{" "}
                          <span className="text-danger">*</span>
                          <Input
                            value={form.title}
                            onChange={e => {
                              handleChange(e)
                            }}
                            name="title"
                            required
                            type="text"
                            placeholder="Enter Title"
                          />
                        </div>
                        <div className="mt-3">
                          <Label for="basicpill-firstname-input1">
                            Notification Type
                            <span className="text-danger">*</span>
                          </Label>

                          <select
                            value={form.notifType}
                            name="notifType"
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                            required
                          >
                            <option value="">Select</option>
                            <option value="pushfcm">Push Notification</option>
                            {/* <option value="whatsapp">What's App</option> */}
                          </select>
                        </div>

                        <div className="mt-3">
                          <Label for="basicpill-firstname-input1">
                            Zone
                            <span className="text-danger">*</span>
                          </Label>

                          <select
                            value={form.zoneId}
                            name="zoneId"
                            onChange={e => {
                              handleChange(e)
                            }}
                            required
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {/* <option value="All">All </option> */}
                            {city.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <div className="mt-3">
                          <Label for="basicpill-firstname-input1">
                            Department
                            <span className="text-danger">*</span>
                          </Label>

                          <select
                            value={form.department}
                            name="department"
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                            required
                          >
                            <option value="">Select</option>
                            <option value="All">All </option>
                            <option value="All">All Atp's</option>
                            <option value="ATP">Single Atp</option>
                            <option value="All">All Customer's</option>
                            <option value="USER">Single Customer</option>
                            {/* <option value="All">All doctor's</option>
                        <option value="singled">Single doctor</option>
                        <option value="All">All personal care provider's</option>
                        <option value="singles">Single personal care provider</option> */}
                          </select>
                        </div>

                        {form.department == "USER" ? (
                          <div className="mt-3">
                            <Label>Customers</Label>
                            <span className="text-danger">*</span>
                            <Select
                              name="userList"
                              value={selectedMulti}
                              onChange={handleMulti}
                              options={options}
                              required
                              isMulti
                            />
                            {error && <div className="error">{error}</div>}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* {form.userList == "singles" ? (
                      <div className="mt-3">
                        <Label>personal care provider</Label>
                        <span className="text-danger">*</span>
                        <Select
                          name="userList"
                          value={selectedMulti}
                          onChange={handleMulti}
                          options={options}
                          required
                          isMulti
                        />
                        {error && <div className="error">{error}</div>}
                      </div>
                    ) : (
                      ""
                    )}
                    {form.userList == "singled" ? (
                      <div className="mt-3">
                        <Label>Single doctor </Label>
                        <span className="text-danger">*</span>
                        <Select
                          name="userList"
                          value={selectedMulti}
                          onChange={handleMulti}
                          options={options}
                          required
                          isMulti
                        />
                        {error && <div className="error">{error}</div>}
                      </div>
                    ) : (
                      ""
                    )} */}
                        {form.department == "ATP" ? (
                          <div className="mt-3">
                            <Label>Atp</Label>
                            <span className="text-danger">*</span>
                            <Select
                              name="userList"
                              value={selectedMulti1}
                              onChange={handleMulti1}
                              options={options1}
                              required
                              isMulti
                            />
                            {error && <div className="error">{error}</div>}
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="mt-3">
                          <Label>Description</Label>{" "}
                          <span className="text-danger">*</span>
                          <textarea
                            className="form-control"
                            value={form.description}
                            onChange={e => {
                              handleChange(e)
                            }}
                            name="description"
                            required
                            type="text"
                            placeholder="Description"
                          />
                        </div>
                      </div>

                      <div className="text-end mt-3">
                        <Button type="submit" color="success m-1" outline>
                          Submit <i className="bx bx-check-circle"></i>
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}

            <Col
              md={
                Roles?.notificationsAdd === true || Roles?.accessAll === true
                  ? 8
                  : 12
              }
            >
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col></Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={forms.search}
                              onChange={handlechange}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4">
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Date/Time</th>
                              <th>Zone </th>
                              <th>Notification Type </th>
                              <th>Department</th>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <th scope="row">
                                  {(pageNumber - 1) * 20 + key + 21}
                                </th>

                                <td>
                                  {data.notifDate} / {data.notifTime}{" "}
                                </td>

                                <td>
                                  {" "}
                                  {data.zoneInfo.map((datas, keys) => (
                                    <>
                                      <p key={keys}>{datas.zoneName}</p>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {data.notifType == "pushfcm" ? (
                                    <>Push Notification</>
                                  ) : (
                                    <></>
                                  )}{" "}
                                </td>
                                <td>{data.department}</td>
                                <td>{data.title}</td>
                                <td>{data.description}</td>
                                <td>
                                  {Roles?.notificationsDelete === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          manageDelete(data)
                                        }}
                                        size="sm"
                                        className="m-1"
                                        outline
                                        color="danger"
                                      >
                                        <i
                                          style={{ fontSize: " 14px" }}
                                          className="bx bx-trash"
                                        ></i>
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Notifications
