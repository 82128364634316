import React from "react"
import { Redirect } from "react-router-dom"
import AllVendorsdashboard from "pages/Dashboard/AllVendorsdashboard"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import Resetpsw from "pages/Authentication/Resetpsw"
import Compareotp from "pages/Authentication/Compareotp"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Hr
import Act from "pages/Act/Act"
import AddAct from "pages/Act/AddAct"
import EditAct from "pages/Act/EditAct"
import AtpStockRequest from "pages/Act/AtpStockRequest"
import Atpmoniter from "pages/Act/Atpmoniter"
import AtpLeaves from "pages/Act/AtpLeaves"
import AtpStock from "pages/Act/AtpStock"

//Staff
import Departments from "pages/Staff/Departments"
import RolesPremissions from "pages/Staff/RolesPremissions"
import Staff from "pages/Staff/Staff"
import Prescriptions from "pages/Staff/Prescriptions"

//SETTINGS
import State from "pages/Settings/State"
import Distirct from "pages/Settings/Distirct"
import City from "pages/Settings/City"
import Terms from "pages/Settings/Terms"
import Faqs from "pages/Settings/Faqs"
import PrivicyPolicy from "pages/Settings/PrivicyPolicy"
import About from "pages/Settings/About"

// import HcuFaqs from "pages/Settings/HcuFaqs"
// import HcuPrivicyPolicy from "pages/Settings/HcuPrivicyPolicy"
// import HcuRefundPolicy from "pages/Settings/HcuRefundPolicy"

// import AtpFaqs from "pages/Settings/AtpFaqs"
// import AtpPrivicyPolicy from "pages/Settings/AtpPrivicyPolicy"
// import AtpTerms from "pages/Settings/AtpTerms"

//Support
import Support from "pages/Support/Support"
import Test from "pages/Test"
import Chat from "pages/Chat/Chat"

import HomeServiceReport from "pages/Reports/HomeServiceReport"

//ExectutiveManager
import AddLab from "pages/Labs/AddLab"
import Labs from "pages/Labs/Labs"
import EditLab from "pages/Labs/EditLab"
import Adduser from "pages/Users/Adduser"
import Users from "pages/Users/Users"
import Edituser from "pages/Users/Edituser"
import AddHospital from "pages/Hospital/AddHospital"
import EditHospital from "pages/Hospital/EditHospital"
import Hospital from "pages/Hospital/Hospital"

import Banners from "pages/Banners/Banners"

import AddTests from "pages/Tests/AddTests"
import EditTests from "pages/Tests/EditTests"
import Tests from "pages/Tests/Tests"
import TestView from "pages/Tests/TestView"

import HospitalView from "pages/Hospital/HospitalView"

import AtpView from "pages/Act/AtpView"

import ViewUser from "pages/Users/ViewUser"

import Notifications from "pages/Notifications/Notifications"

import Coupones from "pages/Promo/Coupones"

import ViewLab from "pages/Labs/ViewLab"

import AddPrescription from "pages/Staff/AddPrescription"

import Recruit from "pages/Act/Recruit"

import Zones from "pages/Settings/Zones"

import HospitalEditRequest from "pages/Hospital/HospitalEditRequest"

import HomeService from "pages/Labs/Physiotherapy"

import Consultation from "pages/Labs/Consultation"

import ViewUserTest from "pages/Users/ViewUserTest"

import Zone from "pages/Zone.js/Zone"

import BudsList from "pages/Act/BudsList"

import NewTestsRequest from "pages/Tests/NewTestsRequest"

import HospitalEditRequestView from "../pages/Hospital/HospitalEditRequestView"

import AcceptingInvestigation from "pages/Labs/AcceptingInvestigation"

import ViewAcceptingInvestigation from "pages/Labs/ViewAcceptingInvestigation"

import ViewHomeService from "pages/Labs/ViewHomeService"

import ViewDoctorConsultation from "pages/Labs/ViewDoctorConsultation"

import RecruitView from "pages/Act/RecruitView"

import HospitalPrice from "pages/Hospital/HospitalPrice"

import HospitalMonitering from "pages/Hospital/HospitalMonitering"

import PriceRequest from "pages/Hospital/PriceRequest"

import AtpSupport from "pages/Support/AtpSupport"

import UserSupport from "pages/Support/UserSupport"

import AddStaff from "pages/Staff/AddStaff"

import EditStaff from "pages/Staff/EditStaff"

import ViewStaff from "pages/Staff/ViewStaff"

import PriceRequestView from "pages/Hospital/PriceRequestView"

import DoctorConsultationReport from "pages/Reports/DoctorConsultationReport"

import AcceptingInvestigationsReport from "pages/Reports/AcceptingInvestigationsReport"

import BookingsTestsReport from "pages/Reports/BookingsTestsReport"

//InvestigationsAtHospitalList
import InvestigationsAtHospitalList from "pages/InvestigationsAtHospital/InvestigationsAtHospitalList"
import AddInvestigationsAtHospitalList from "pages/InvestigationsAtHospital/AddInvestigationsAtHospitalList"
import EditInvestigationsAtHospitalList from "pages/InvestigationsAtHospital/EditInvestigationsAtHospitalList"
import InvestigationsAtHospitalListRequset from "pages/InvestigationsAtHospital/InvestigationsAtHospitalListRequset"

//InvestigationsAtHospitalList
import AddHomeServiceList from "pages/HomeServices.js/AddHomeServiceList"
import EditHomeServiceList from "pages/HomeServices.js/EditHomeServiceList"
import HomeServiceList from "pages/HomeServices.js/HomeServiceList"
import HomeServiceRequest from "pages/HomeServices.js/HomeServiceRequest"

//
import CghsCorner from "pages/Settings/CghsCorner"
import Manual from "pages/Settings/Manual"
import AdminMonitoring from "pages/Settings/AdminMonitoring"
import HospitalMonitringView from "pages/Hospital/HospitalMonitringView"

import ViewAtpMonitering from "pages/Act/ViewAtpMonitering"

import Payments from "pages/Payments/Payments"

import AtpTimming from "pages/Act/AtpTimming"

import AddAtpTimming from "pages/Act/AddAtpTimming"

import EditAtpTimming from "pages/Act/EditAtpTimming"

import ConsultationPriceRequest from "pages/Consultation/ConsultationPriceRequest"

import Emergency from "pages/Labs/Emergency"

import EditZones from "pages/Settings/EditZones"

import AtpSlotWiseMonitering from "pages/Act/AtpSlotWiseMonitering"

import ZonesView from "pages/Settings/ZonesView"

import AddRole from "pages/Staff/AddRole"

import EditRole from "pages/Staff/EditRole"

import Actinstant from "pages/Labs/Actinstant"

import SubBanners from "pages/Banners/SubBanners"

import ViewCoupon from "pages/Promo/ViewCoupon"

import Packagesrequest from "pages/Labs/Packagesrequest"

import DoctorPrescription from "pages/Consultation/DoctorPrescription"

import ViewDoctorPrescription from "pages/Consultation/ViewDoctorPrescription"

import RefundPolicy from "pages/Settings/RefundPolicy"

import EditAtpUpdateslot from "pages/Act/EditAtpUpdateslot"

import ContactUs from "pages/Settings/ContactUs"

import EditPrescription from "pages/Staff/EditPrescription"

import Ps from "pages/Settings/Ps"

import AllZonesView from "pages/Zone.js/AllZonesView"

// Tickets
import InvestigationsTickets from "pages/Hospital/InvestigationsTickets"
import ViewInvestigationsTickets from "pages/Hospital/ViewInvestigationsTickets"
import ConsultationTickets from "pages/Hospital/ConsultationTickets"
import ViewConsultationTickets from "pages/Hospital/ViewConsultationTickets"
import HomeServicesTickets from "pages/Hospital/HomeServicesTickets"
import ViewHomeServicesTickets from "pages/Hospital/ViewHomeServicesTickets"

import ApprovedLeaves from "pages/Act/ApprovedLeaves"

import ViewHcu from "pages/ViewHcu"

import GetBuddsRequest from "pages/Act/GetBuddsRequest"

import PackagesBookings from "pages/Labs/PackagesBookings"

import ViewPackagesBookings from "pages/Labs/ViewPackagesBookings"

import PackageReport from "pages/Reports/PackageReport"

import AtpPayrole from "pages/Act/AtpPayrole"
import AtpViewPayrole from "pages/Act/AtpViewPayrole"

//Accounts
import Income from "pages/Accounts/Income"
import Expenses from "pages/Accounts/Expenses"
import Inventory from "pages/Accounts/Inventory"
import Revenue from "pages/Accounts/Revenue"

//PayRole
import AddPayRole from "pages/Accounts/AddPayRole"
import EditPayRole from "pages/Accounts/EditPayRole"
import Payrole from "pages/Accounts/Payrole"
import ViewPayrole from "pages/Accounts/ViewPayrole"

import Charges from "pages/Settings/Charges"

import PackagesTickets from "pages/Hospital/PackagesTickets"
import ViewPackagesTickets from "pages/Hospital/ViewPackagesTickets"
import ViewPayment from "pages/Payments/ViewPayment"

import ActinstantReport from "pages/Reports/ActinstantReport"
import EmergencyReport from "pages/Reports/EmergencyReport"

import DoctorFeedback from "pages/Support/DoctorFeedback"
import DoctorTcSupport from "pages/Support/DoctorTcSupport"

import DoctorBanners from "pages/Banners/DoctorBanners"

import Speciality from "pages/Consultation/Speciality"
import AddTest from "../pages/Labs/AddTest"

import AddInvestigationlist from "pages/Labs/AddInvestigationlist"

import PendingCancellation from "pages/Cancellation/PendingCancellation"
import CompleatedCancellation from "pages/Cancellation/CompleatedCancellation"

import Blog from "pages/Settings/Blog"
import Enquiry from "pages/Settings/Enquiry"

import AddOneHomeservice from "pages/Labs/AddOneHomeservice"

import PendingRefunds from "pages/Cancellation/PendingRefunds"
import CompletedRefunds from "pages/Cancellation/CompletedRefunds"

import ViewRefund from "pages/Cancellation/ViewRefund"

const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },
  { path: "/AllZonesView", component: AllZonesView },
  { path: "/ViewHcu", component: ViewHcu },
  { path: "/AddTest", component: AddTest },

  { path: "/ViewRefund", component: ViewRefund },

  { path: "/PendingRefunds", component: PendingRefunds },
  { path: "/CompletedRefunds", component: CompletedRefunds },

  { path: "/AddOneHomeservice", component: AddOneHomeservice },

  { path: "/Enquiry", component: Enquiry },

  { path: "/Blog", component: Blog },

  { path: "/PendingCancellation", component: PendingCancellation },
  { path: "/CompleatedCancellation", component: CompleatedCancellation },

  { path: "/AddInvestigationlist", component: AddInvestigationlist },

  { path: "/Speciality", component: Speciality },

  { path: "/DoctorBanners", component: DoctorBanners },

  { path: "/PackageReport", component: PackageReport },
  { path: "/ApprovedLeaves", component: ApprovedLeaves },
  { path: "/GetBuddsRequest", component: GetBuddsRequest },

  { path: "/AtpPayrole", component: AtpPayrole },
  { path: "/AtpViewPayrole", component: AtpViewPayrole },

  //PackagesTickets
  { path: "/PackagesTickets", component: PackagesTickets },
  { path: "/ViewPackagesTickets", component: ViewPackagesTickets },

  //DoctorTcSupport
  { path: "/DoctorFeedback", component: DoctorFeedback },
  { path: "/DoctorTcSupport", component: DoctorTcSupport },

  //ViewPayment
  { path: "/ViewPayment", component: ViewPayment },

  //Accounts
  { path: "/Income", component: Income },
  { path: "/Expenses", component: Expenses },
  { path: "/Inventory", component: Inventory },
  { path: "/Revenue", component: Revenue },
  { path: "/Charges", component: Charges },

  //Accounts
  { path: "/Payrole", component: Payrole },
  { path: "/AddPayRole", component: AddPayRole },
  { path: "/EditPayRole", component: EditPayRole },
  { path: "/ViewPayrole", component: ViewPayrole },

  //Packages Bookings
  { path: "/PackagesBookings", component: PackagesBookings },
  { path: "/ViewPackagesBookings", component: ViewPackagesBookings },

  { path: "/ActinstantReport", component: ActinstantReport },
  { path: "/EmergencyReport", component: EmergencyReport },

  // Tickets
  { path: "/InvestigationsTickets", component: InvestigationsTickets },
  { path: "/ViewInvestigationsTickets", component: ViewInvestigationsTickets },
  { path: "/ConsultationTickets", component: ConsultationTickets },
  { path: "/ViewConsultationTickets", component: ViewConsultationTickets },
  { path: "/HomeServicesTickets", component: HomeServicesTickets },
  { path: "/ViewHomeServicesTickets", component: ViewHomeServicesTickets },

  { path: "/Ps", component: Ps },

  { path: "/ContactUs", component: ContactUs },

  { path: "/EditPrescription", component: EditPrescription },

  { path: "/RefundPolicy", component: RefundPolicy },

  { path: "/DoctorPrescription", component: DoctorPrescription },

  { path: "/ViewDoctorPrescription", component: ViewDoctorPrescription },

  { path: "/Packagesrequest", component: Packagesrequest },

  { path: "/EditAtpUpdateslot", component: EditAtpUpdateslot },

  { path: "/ViewCoupon", component: ViewCoupon },

  { path: "/SubBanners", component: SubBanners },

  { path: "/Actinstant", component: Actinstant },

  { path: "/EditZones", component: EditZones },

  { path: "/AddRole", component: AddRole },

  { path: "/EditRole", component: EditRole },

  { path: "/ZonesView", component: ZonesView },

  { path: "/AtpSlotWiseMonitering", component: AtpSlotWiseMonitering },

  { path: "/AllVendorsdashboard", component: AllVendorsdashboard },

  { path: "/Emergency", component: Emergency },

  { path: "/ConsultationPriceRequest", component: ConsultationPriceRequest },

  { path: "/AtpTimming", component: AtpTimming },

  { path: "/EditAtpTimming", component: EditAtpTimming },

  { path: "/AddAtpTimming", component: AddAtpTimming },

  { path: "/Payments", component: Payments },

  { path: "/ViewAtpMonitering", component: ViewAtpMonitering },

  { path: "/HospitalMonitringView", component: HospitalMonitringView },

  { path: "/PriceRequestView", component: PriceRequestView },

  { path: "/PriceRequestView", component: PriceRequestView },

  { path: "/AddStaff", component: AddStaff },

  { path: "/EditStaff", component: EditStaff },

  { path: "/ViewStaff", component: ViewStaff },

  { path: "/CghsCorner", component: CghsCorner },
  { path: "/Manual", component: Manual },
  { path: "/Admincommission", component: AdminMonitoring },

  // InvestigationsAtHospitalList
  {
    path: "/InvestigationsAtHospitalList",
    component: InvestigationsAtHospitalList,
  },
  {
    path: "/AddInvestigationsAtHospitalList",
    component: AddInvestigationsAtHospitalList,
  },
  {
    path: "/EditInvestigationsAtHospitalList",
    component: EditInvestigationsAtHospitalList,
  },
  {
    path: "/InvestigationsAtHospitalListRequset",
    component: InvestigationsAtHospitalListRequset,
  },
  // InvestigationsAtHospitalList

  { path: "/AddHomeServiceList", component: AddHomeServiceList },
  { path: "/EditHomeServiceList", component: EditHomeServiceList },
  { path: "/HomeServiceList", component: HomeServiceList },
  { path: "/HomeServiceRequest", component: HomeServiceRequest },

  { path: "/UserSupport", component: UserSupport },

  { path: "/AtpSupport", component: AtpSupport },

  { path: "/HospitalMonitering", component: HospitalMonitering },

  { path: "/PriceRequest", component: PriceRequest },

  { path: "/HospitalPrice", component: HospitalPrice },

  { path: "/RecruitView", component: RecruitView },

  { path: "/ViewDoctorConsultation", component: ViewDoctorConsultation },

  { path: "/AcceptingInvestigation", component: AcceptingInvestigation },

  { path: "/ViewHomeService", component: ViewHomeService },

  {
    path: "/ViewAcceptingInvestigation",
    component: ViewAcceptingInvestigation,
  },

  { path: "/HospitalEditRequestView", component: HospitalEditRequestView },

  { path: "/NewTestsRequest", component: NewTestsRequest },

  { path: "/BudsList", component: BudsList },

  { path: "/Consultation", component: Consultation },

  { path: "/HomeService", component: HomeService },

  { path: "/ViewUserTest", component: ViewUserTest },

  { path: "/ViewLab", component: ViewLab },

  { path: "/AddPrescription", component: AddPrescription },

  { path: "/Zone", component: Zone },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/Coupones", component: Coupones },

  { path: "/Notifications", component: Notifications },

  //profile
  { path: "/AddTests", component: AddTests },
  { path: "/EditTests", component: EditTests },
  { path: "/Tests", component: Tests },
  { path: "/TestView", component: TestView },

  // Act
  { path: "/Act", component: Act },
  { path: "/AddAct", component: AddAct },
  { path: "/EditAct", component: EditAct },
  { path: "/Atpmoniter", component: Atpmoniter },
  { path: "/AtpLeaves", component: AtpLeaves },
  { path: "/AtpStockRequest", component: AtpStockRequest },
  { path: "/AtpStock", component: AtpStock },

  { path: "/AtpView", component: AtpView },
  { path: "/Recruit", component: Recruit },

  // Banners
  { path: "/Banners", component: Banners },

  // Hospitals
  { path: "/AddHospital", component: AddHospital },
  { path: "/EditHospital", component: EditHospital },
  { path: "/Hospital", component: Hospital },
  { path: "/HospitalView", component: HospitalView },

  // Labs
  { path: "/AddLab", component: AddLab },
  { path: "/Labs", component: Labs },
  { path: "/EditLab", component: EditLab },
  { path: "/HospitalEditRequest", component: HospitalEditRequest },

  //USERS
  { path: "/Adduser", component: Adduser },
  { path: "/Users", component: Users },
  { path: "/Edituser", component: Edituser },
  { path: "/ViewUser", component: ViewUser },

  //Staff
  { path: "/Departments", component: Departments },
  { path: "/RolesPremissions", component: RolesPremissions },
  { path: "/Staff", component: Staff },
  { path: "/Prescriptions", component: Prescriptions },

  //SETTINGS
  { path: "/State", component: State },
  { path: "/Distirct", component: Distirct },
  { path: "/City", component: City },
  { path: "/Zones", component: Zones },
  { path: "/Faqs", component: Faqs },
  { path: "/Termsconditions", component: Terms },
  { path: "/PrivicyPolicy", component: PrivicyPolicy },
  { path: "/About", component: About },

  //Support
  { path: "/Chat", component: Chat },
  { path: "/Test", component: Test },
  { path: "/Support", component: Support },

  { path: "/HomeServiceReport", component: HomeServiceReport },
  { path: "/DoctorConsultationReport", component: DoctorConsultationReport },
  {
    path: "/AcceptingInvestigationsReport",
    component: AcceptingInvestigationsReport,
  },
  { path: "/BookingsTestsReport", component: BookingsTestsReport },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/ForgetPassword", component: ForgetPassword },
  { path: "/Resetpsw", component: Resetpsw },
  { path: "/Compareotp", component: Compareotp },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
