import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Button, Table } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const history = useHistory()
  const [zones, setZones] = useState([])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    datass()
    GetZones()
  }, [])

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaff,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setZones(res.data.staff)
          setIsLoading(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = zones.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(zones.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [forms, setforms] = useState([])

  const handlechanges = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetStaffSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setZones(res.data.staff)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const ZoneIdz = data => {
    sessionStorage.setItem("staffid", data._id)
    history.push("/EditStaff")
  }

  const ZoneIdszs = data => {
    sessionStorage.setItem("staffids", data._id)
    history.push("/ViewStaff")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    const gs = {
      status: "inactive",
    }
    axios
      .put(URLS.DeleteStaff + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetZones()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    const gs = {
      status: "active",
    }

    axios
      .put(URLS.DeleteStaff + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetZones()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Staff List" />
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          {Roles?.staffAdd === true ||
                          Roles?.accessAll === true ? (
                            <>
                              <Link to="/AddStaff">
                                <Button color="primary">
                                  New Staff{" "}
                                  <i className="bx bx-plus-circle"></i>
                                </Button>
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>

                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={forms.search}
                              onChange={handlechanges}
                              className="form-control"
                              placeholder="Search.."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive "
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Staff Name</th>
                              <th>Staff Number</th>
                              <th>Staff Email</th>
                              <th>Department</th>
                              <th>Role</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>
                                  {data.firstName}
                                  {data.lastName}
                                </td>
                                <td>{data.phone}</td>
                                <td>{data.email}</td>
                                <td>{data.departmentName}</td>
                                <td>{data.roleName}</td>
                                <td>
                                  {data.status == "active" ? (
                                    <>
                                      <span className="badge bg-success ">
                                        {data.status}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className="badge bg-danger ">
                                        {data.status}
                                      </span>
                                    </>
                                  )}
                                </td>
                                <td>
                                  {Roles?.staffEdit === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          ZoneIdz(data)
                                        }}
                                        className="mr-2"
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        color="success"
                                        outline
                                      >
                                        <i className="bx bx-edit "></i>
                                      </Button>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <Button
                                    onClick={() => {
                                      ZoneIdszs(data)
                                    }}
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    color="info"
                                    outline
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="fas fa-eye"
                                    ></i>
                                  </Button>

                                  {Roles?.staffBlock === true ||
                                  Roles?.accessAll === true ? (
                                    <>
                                      {data.status == "active" ? (
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="danger"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-lock"
                                          ></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            manageDelete1(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="success"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="fas fa-user-check"
                                          ></i>
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
