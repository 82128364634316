import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function State() {
  const [modal_small, setmodal_small] = useState(false)
  const [banner, setbanner] = useState([])
  const [form1, setform1] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  useEffect(() => {
    GetState()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Editstate = () => {
    var token = datas

    const dataArray = {
      description: form1.description,
      id: form1._id,
    }

    axios
      .post(URLS.UpdatePrescription, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetState()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const GetState = () => {
    var token = datas
    axios
      .post(
        URLS.GetPrescription,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setbanner(res.data.prescription)
          setIsLoading(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const [forms, setforms] = useState([])

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetPrescriptionSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.prescription)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Prescreption List" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white">
                      <CardTitle>Prescreption List</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={forms.search}
                              onChange={handlechange}
                              className="form-control"
                              placeholder="Search.."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr className="text-center">
                                <th>S No</th>
                                <th>User Name</th>
                                <th>User Phone</th>
                                <th>User Age</th>
                                <th>User Gender</th>
                                <th>Image</th>
                                <th>Time Of Upload</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.customerName}</td>
                                  <td>{data.customerPhone}</td>
                                  <td>{data.customerAge}</td>
                                  <td>{data.customerGender}</td>
                                  <td>
                                    <a
                                      href={URLS.Base + data.prescribeImg}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={URLS.Base + data.prescribeImg}
                                        width="100px"
                                      ></img>{" "}
                                    </a>
                                  </td>
                                  <td>{data.timeOfUpload}</td>
                                  <td>{data.description}</td>
                                  <td>
                                    {data.success == true ? (
                                      <>
                                        {" "}
                                        <span className="badge bg-success">
                                          Solved{" "}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="badge bg-warning">
                                          Pending
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {Roles?.pescreptiondecipheringEdit ===
                                      true || Roles?.accessAll === true ? (
                                      <>
                                        {data.success == true ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <Button
                                              onClick={() => {
                                                getpopup(data)
                                              }}
                                              className="mr-2"
                                              style={{
                                                padding: "6px",
                                                margin: "3px",
                                              }}
                                              color="success"
                                              outline
                                            >
                                              <i className="bx bx-edit "></i>
                                            </Button>
                                          </>
                                        )}{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>{" "}
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Prescreption
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Description <span className="text-danger">*</span>
                </Label>
                <textarea
                  type="text"
                  rows="5"
                  className="form-control "
                  id="basicpill-firstname-input1"
                  placeholder="Enter description"
                  required
                  value={form1.description}
                  name="description"
                  onChange={e => {
                    handleChange1(e)
                  }}
                />
              </div>{" "}
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default State
