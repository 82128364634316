import React, { useState, useEffect } from "react"
import { Container, Col, Card, CardBody, Row, Button, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { URLS } from "../../Url"
import { Link, useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"

function DoctorSchedule() {
  const [from, setfrom] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetHospitals()
    datass()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtptimeslot,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setfrom(res.data.slotatp.timings)
        setIsLoading(false)
      })
  }

  const Actinid = () => {
    history.push("/EditAtpTimming")
  }

  const ActinidS = data => {
    sessionStorage.setItem("slotid", data._id)
    history.push("/EditAtpUpdateslot")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [from2, setfrom2] = useState([])

  useEffect(() => {
    GetHolidaylist()
  }, [])

  const GetHolidaylist = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtpHolidaylist,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setfrom2(res.data.slotholidaylist)
        setIsLoading(false)
      })
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteState(data)
    }
  }
  const DeleteState = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveAtpHolidaylist + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHolidaylist()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Atp's Time Slot Making" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead className="text-center">
                              <tr>
                                <th>S.No</th>
                                <th>From Time</th>
                                <th>To Time</th>
                                <th>Slots</th>
                                <th style={{ width: "100px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="text-center">
                                <td>1</td>
                                <td>
                                  {from.map((data, key) => (
                                    <>
                                      <li key={key} className="mt-2">
                                        {data.fromtime}
                                      </li>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {from.map((data, key) => (
                                    <>
                                      <li key={key} className="mt-2">
                                        {data.totime}
                                      </li>
                                    </>
                                  ))}
                                </td>
                                <td>
                                  {from.map((data, key) => (
                                    <>
                                      <li key={key} className="mt-2">
                                        Slot : {key + 1}
                                      </li>
                                    </>
                                  ))}
                                </td>
                                {Roles?.atpSlotMakingEdit === true ||
                                Roles?.accessAll === true ? (
                                  <>
                                    <td>
                                      <Button
                                        className="mr-2"
                                        onClick={() => {
                                          Actinid()
                                        }}
                                        style={{
                                          padding: "6px",
                                          margin: "3px",
                                        }}
                                        color="danger"
                                        // outline
                                      >
                                        <i className="bx bx-edit ">Edit </i>
                                      </Button>
                                    </td>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </tbody>

                            
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <h5>Holidays's List</h5>
                      <Col>
                        {Roles?.atpSlotMakingAdd === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <Link to="/AddAtpTimming">
                              <Button color="primary" className="m-2">
                                Atp's Holiday's Slot Making
                                <i className="bx bx-plus-circle"></i>
                              </Button>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Date</th>
                                <th>From Time</th>
                                <th>To Time</th>
                                <th>Slots</th>
                                <th style={{ width: "100px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {from2.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{key + 1}</td>
                                  <td>{data.date}</td>
                                  <td>
                                    {data.timings.map((datas, i) => (
                                      <>
                                        {datas.fromtime == "" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <li key={i} className="mt-2">
                                              {datas.fromtime}
                                            </li>{" "}
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </td>
                                  <td>
                                    {data.timings.map((datas, i) => (
                                      <>
                                        {datas.totime == "" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            {" "}
                                            <li key={i} className="mt-2">
                                              {datas.totime}
                                            </li>{" "}
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </td>
                                  <td>
                                    {data.timings.map((datas, key) => (
                                      <>
                                        {datas.totime == "" ||
                                        datas.fromtime == "" ? (
                                          <>-</>
                                        ) : (
                                          <>
                                            <li key={key} className="mt-2">
                                              Slot : {key + 1}
                                            </li>
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </td>
                                  <td>
                                    {Roles?.atpSlotMakingEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            ActinidS(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="success"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="bx bx-edit"
                                          ></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {Roles?.atpSlotMakingDelete === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="danger"
                                          outline
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default DoctorSchedule
