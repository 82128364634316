import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import Select from "react-select"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState({
    hospitalName: "",
    billingPhone: "",
    homeTestPhone: "",
    investigationPhone: "",
    consultationPhone: "",
    servicePhone: "",
    helpDeskPhone: "",
    aboutUs: "",
    stateId: "",
    districtId: "",
    cityId: "",
    zoneId: "",
    address: "",
    pinCode: "",
    email: "",
    password: "",
    confirmPassword: "",
    stateRegisterValidUpto: "",
    nabhCertValidUpto: "",
    cghsCertValidUpto: "",
    bioWasteCertValidUpto: "",
    aarogyaCertValidUpto: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    branchName: "",
    ifscCode: "",
    gstNumber: "",
    tdsExamination: "",
    hcoPanNumber: "",
    authorizedName: "",
    legalEntityNumber: "",
    legalEntityName: "",
    identityNumber: "",
    legalEntityDate: "",
    dateOfJoining: "",
    dateOfValidity: "",
    aPlus: "",
    timingOfHco: "",
    fromTime: "",
    toTime: "",
    testsAtHomeCommission: "",
    investigationAtHcuCommission: "",
    doctorConsultationCommission: "",
    homeServicesCommission: "",
    packageCommission: "",
    accountType: "",
    hospitalSpecialityType: "",
    treatmentMode: "",
  })

  const [selectedFiles, setselectedFiles] = useState([])

  const [files, setFiles] = useState([])
  const [files1, setFiles1] = useState([])
  const [files2, setFiles2] = useState([])
  const [files3, setFiles3] = useState([])
  const [files4, setFiles4] = useState([])
  const [files5, setFiles5] = useState([])
  const [files6, setFiles6] = useState([])
  // const [files7, setFiles7] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler1 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles1(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler2 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles2(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler3 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles3(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler4 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles4(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler5 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles5(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }
  const changeHandler6 = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles6(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  // const changeHandler7 = e => {
  //   const file = e.target.files
  //   var ext = file[0].name.split(".").pop()
  //   var type = ext
  //   if (type == "jpg" || type == "jpeg" || type == "png") {
  //     setFiles7(e.target.files)
  //   } else {
  //     e.target.value = null
  //     toast("file format not supported.Pls choose Image")
  //   }
  // }

  const [selectedOptions, setSelectedOptions] = useState([])

  const HealthScheme = selectedOptions => {
    setSelectedOptions(selectedOptions)
  }

  const optionss = [
    { value: "CGHS", label: "CGHS" },
    {
      value: "ECHS",
      label: "ECHS",
    },
    {
      value: "EHS",
      label: "EHS",
    },
    {
      value: "ESI",
      label: "ESI",
    },
    {
      value: "PMJAY",
      label: "PMJAY",
    },
    {
      value: "AAROGYASRI",
      label: "AAROGYASRI",
    },
    {
      value: "PvtHealthInsurance",
      label: "Pvt Health Insurance",
    },
  ]

  const [selectedOptions1, setSelectedOptions1] = useState([])

  const HealthStandard = selectedOptions1 => {
    setSelectedOptions1(selectedOptions1)
  }

  const optionss1 = [
    {
      value: "NABH/NABL",
      label: "NABH/NABL",
    },
    {
      value: "NON-NABH/NON-NABL",
      label: "NON-NABH/NON-NABL",
    },
    {
      value: "ICMR",
      label: "ICMR",
    },
    {
      value: "JCI",
      label: "JCI",
    },
    {
      value: "QCI",
      label: "QCI",
    },
    {
      value: "ISO9001",
      label: "ISO9001 ",
    },
    {
      value: "ISQua",
      label: "ISQua",
    },
  ]

  const [selectedOptions2, setSelectedOptions2] = useState([])

  const optionss2 = [
    { value: "TestsatHome", label: "Test At Home" },
    {
      value: "InvestigationsatHCU ",
      label: "Investigations at HCU",
    },
    {
      value: "Consultations",
      label: "Consultations",
    },
    {
      value: "HomeServices",
      label: "Home Services",
    },
  ]

  const handleChange2 = selectedOptions2 => {
    setSelectedOptions2(selectedOptions2)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()
    if (selectedFiles.length > 0) {
      Adddealer()
    } else {
      toast("Please upload  image")
    }
  }

  const [address, setaddress] = useState("")

  const [coordinateds, setcoordinateds] = useState({
    lat: "",
    lng: "",
    address: "",
  })

  const handleSelects = async value => {
    setaddress(value)
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(latLng => setcoordinateds(latLng))
  }

  const history = useHistory()

  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("hospitalName", form.hospitalName)
    dataArray.append("billingPhone", form.billingPhone)
    dataArray.append("homeTestPhone", form.homeTestPhone)
    dataArray.append("investigationPhone", form.investigationPhone)
    dataArray.append("consultationPhone", form.consultationPhone)
    dataArray.append("servicePhone", form.servicePhone)
    dataArray.append("helpDeskPhone", form.helpDeskPhone)
    dataArray.append("aboutUs", form.aboutUs)
    dataArray.append("stateId", form.stateId)
    dataArray.append("districtId", form.districtId)
    dataArray.append("cityId", form.cityId)
    dataArray.append("zoneId", form.zoneId)
    dataArray.append("location", address)
    dataArray.append("latitude", coordinateds.lat)
    dataArray.append("longitude", coordinateds.lng)
    dataArray.append("address", form.address)
    dataArray.append("pinCode", form.pinCode)
    dataArray.append("email", form.email)
    dataArray.append("password", form.password)
    dataArray.append("confirmPassword", form.confirmPassword)
    dataArray.append("stateRegisterValidUpto", form.stateRegisterValidUpto)
    dataArray.append("nabhCertValidUpto", form.nabhCertValidUpto)
    dataArray.append("cghsCertValidUpto", form.cghsCertValidUpto)
    dataArray.append("bioWasteCertValidUpto", form.bioWasteCertValidUpto)
    dataArray.append("aarogyaCertValidUpto", form.aarogyaCertValidUpto)
    dataArray.append("bankName", form.bankName)
    dataArray.append("accountHolderName", form.accountHolderName)
    dataArray.append("accountNumber", form.accountNumber)
    dataArray.append("branchName", form.branchName)
    dataArray.append("ifscCode", form.ifscCode)
    dataArray.append("gstNumber", form.gstNumber)
    dataArray.append("tdsExamination", form.tdsExamination)
    dataArray.append("hcoPanNumber", form.hcoPanNumber)
    dataArray.append("authorizedName", form.authorizedName)
    dataArray.append("identityNumber", "")
    // dataArray.append("identityNumber", form.identityNumber)
    dataArray.append("legalEntityName", form.legalEntityName)
    dataArray.append("legalEntityNumber", form.legalEntityNumber)
    dataArray.append("legalEntityDate", form.legalEntityDate)
    dataArray.append("dateOfJoining", form.dateOfJoining)
    dataArray.append("dateOfValidity", form.dateOfValidity)
    dataArray.append("aPlus", form.aPlus)
    dataArray.append("timingOfHco", form.timingOfHco)
    dataArray.append("fromTime", form.fromTime)
    dataArray.append("toTime", form.toTime)
    dataArray.append("commissionType", form.commissionType)
    dataArray.append("testsAtHomeCommission", form.testsAtHomeCommission)
    dataArray.append(
      "investigationAtHcuCommission",
      form.investigationAtHcuCommission
    )
    dataArray.append(
      "doctorConsultationCommission",
      form.doctorConsultationCommission
    )
    dataArray.append("homeServicesCommission", form.homeServicesCommission)
    dataArray.append("packageCommission", form.packageCommission)
    dataArray.append("accountType", form.accountType)
    dataArray.append("hospitalSpecialityType", form.hospitalSpecialityType)
    dataArray.append("treatmentMode", form.treatmentMode)

    dataArray.append("organizationHeadName", form.organizationHeadName)
    dataArray.append("organizationHeadPhone", form.organizationHeadPhone)

    dataArray.append("establishDate", form.establishDate)

    dataArray.append("healthStandardObj", JSON.stringify(selectedOptions1))

    dataArray.append("healthSchemeobj", JSON.stringify(selectedOptions))

    dataArray.append("serviceListObj", JSON.stringify(selectedOptions2))

    for (let i = 0; i < files.length; i++) {
      dataArray.append("canceledCheckImg", files[i])
    }

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("hcoPanImg", files1[i])
    }

    for (let i = 0; i < files2.length; i++) {
      dataArray.append("stateRegisterImg", files2[i])
    }

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("nabhCertImg", files3[i])
    }

    for (let i = 0; i < files4.length; i++) {
      dataArray.append("cghsCertImg", files4[i])
    }

    for (let i = 0; i < files5.length; i++) {
      dataArray.append("bioWasteCertImg", files5[i])
    }

    for (let i = 0; i < files6.length; i++) {
      dataArray.append("aarogyaCertImg", files6[i])
    }

    dataArray.append("authorizedSign", "")

    // for (let i = 0; i < files7.length; i++) {
    //   dataArray.append("authorizedSign", files7[i])
    // }

    for (let i = 0; i < selectedFiles.length; i++) {
      dataArray.append("hospitalLogo", selectedFiles[i])
    }

    axios
      .post(URLS.AddHospital, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/Hospital")
            sessionStorage.setItem(
              "tost",
              "Hospital has been Added Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [states, setstates] = useState([])
  const [District, setDistrict] = useState([])
  const [City, setCity] = useState([])
  const [zone, setzone] = useState([])

  useEffect(() => {
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.distResult)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  const Optionchange2 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getZone(e.target.value)
  }

  const getZone = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("cityIdId", data)
    axios
      .post(URLS.GetcityIdZone, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setzone(res.data.cityResult)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Hospital List" breadcrumbItem="Add Hospital" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2">
                  <h5
                    className="mb-4"
                    style={{ color: "#467BE5", fontWeight: "bold" }}
                  >
                    Hospital Profile Information
                  </h5>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Hospital Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Hospital Name"
                        required
                        
                        value={form.hospitalName}
                        name="hospitalName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>

                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Health Scheme
                      </Label>
                      <Select
                        options={optionss}
                        placeholder="Select Health Scheme"
                        value={selectedOptions}
                        onChange={HealthScheme}
                        isSearchable={true}
                        isMulti
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Health Standard
                      </Label>
                      <Select
                        options={optionss1}
                        placeholder="Select Health Standard"
                        value={selectedOptions1}
                        onChange={HealthStandard}
                        isSearchable={true}
                        isMulti
                      />
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Mode of Treatment <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.treatmentMode}
                        name="treatmentMode"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Allopathy"> Allopathy </option>
                        <option value="Homeopathy"> Homeopathy </option>
                        <option value="Ayurveda"> Ayurveda </option>
                        <option value="Unani"> Unani </option>
                        <option value="Others"> Others </option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Hospital Speciality Type
                      </Label>
                      <span className="text-danger">*</span>

                      <select
                        className="form-select"
                        value={form.hospitalSpecialityType}
                        name="hospitalSpecialityType"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        {form.treatmentMode == "Allopathy" ? (
                          <>
                            <option value="MultiSpeciality">
                              MultiSpeciality
                            </option>
                            <option value="ExclusiveCancerHospital">
                              Exclusive Cancer Hospital
                            </option>
                            <option value="ExclusiveEyeHospital">
                              Exclusive Eye Hospital
                            </option>
                            <option value=" ExclusiveWomenChildCareHospital">
                              Exclusive Women & Child Care Hospital
                            </option>
                            <option value="ExclusiveDentalClinic">
                              Exclusive Dental Clinic
                            </option>
                            <option value="ExclusiveDentalClinic">
                              Clinic
                            </option>
                          </>
                        ) : (
                          ""
                        )}
                        {form.treatmentMode == "Others" ? (
                          <>
                            <option value="PHYSIOTHERAPY">PHYSIOTHERAPY</option>
                            <option value="YOGACENTER">YOGA CENTER</option>
                            <option value="ACUPUNCTURE(PAINMANAGEMENT)">
                              ACUPUNCTURE (PAIN MANAGEMENT)
                            </option>
                            <option value=" REHABILITATION">
                              REHABILITATION
                            </option>
                          </>
                        ) : (
                          ""
                        )}
                      </select>
                    </div>
                  </Col>

                  <Col lg="6">
                    <div className="text-center m-1  mt-5">
                      <h5 style={{ fontWeight: "bold" }}>Hospital Logo</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </div>
                      <p className="text-danger">255 * 192 Image Size</p>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <h5
                    className="mb-4 mt-3"
                    style={{ color: "#467BE5", fontWeight: "bold" }}
                  >
                    Hospital Contact Details
                  </h5>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Hospital Billing Contact Number
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Hospital Billing Contact Number"
                        required
                        // minLength="10"
                        // maxLength="10"
                        // pattern="[0-9]+"
                        value={form.billingPhone}
                        name="billingPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Home Tests Contact Number
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Home Tests Contact Number"
                        // minLength="10"
                        // maxLength="10"
                        // pattern="[0-9]+"
                        value={form.homeTestPhone}
                        name="homeTestPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Hospital Investigation Contact Number
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Hospital Investigation Contact Number"
                        // minLength="10"
                        // maxLength="10"
                        // pattern="[0-9]+"
                        value={form.investigationPhone}
                        name="investigationPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Consultation Contact Number
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Consultation Contact Number"
                        // minLength="10"
                        // maxLength="10"
                        // pattern="[0-9]+"
                        value={form.consultationPhone}
                        name="consultationPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Home Service Contact Number
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Home Service Contact Number"
                        // minLength="10"
                        // maxLength="10"
                        // pattern="[0-9]+"
                        value={form.servicePhone}
                        name="servicePhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        HCU Help Desk Number
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter HCU Help Desk Number"
                        // minLength="10"
                        // maxLength="10"
                        // pattern="[0-9]+"
                        value={form.helpDeskPhone}
                        name="helpDeskPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        HCU About <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter HCU About"
                        required
                        rows={3}
                        value={form.aboutUs}
                        name="aboutUs"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <h5
                    className="mb-4 mt-3"
                    style={{ color: "#467BE5", fontWeight: "bold" }}
                  >
                    Hospital Other Details
                  </h5>
                  {/* <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Identity Number: <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter 
                        Identity Number"
                        required
                        value={form.identityNumber}
                        name="identityNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col> */}

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Legal Entity Name:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Legal Entity Name"
                        required
                        value={form.legalEntityName}
                        name="legalEntityName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Legal Entity Registration Number:
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Legal Entity Registration Number"
                        value={form.legalEntityNumber}
                        name="legalEntityNumber"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Legal Entity Registration Date:
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Legal Entity Registration Date"
                        value={form.legalEntityDate}
                        name="legalEntityDate"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Organisation Head Name:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Organisation Head Name"
                        required
                        value={form.organizationHeadName}
                        name="organizationHeadName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Organisation Head Contact Number:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Organisation Head Contact Number"
                        required
                        minLength="10"
                        maxLength="10"
                        pattern="[0-9]+"
                        value={form.organizationHeadPhone}
                        name="organizationHeadPhone"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Establishment Year:
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Establishment Year"
                        value={form.establishDate}
                        name="establishDate"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3 ">
                      <Label for="basicpill-firstname-input1">
                        Services provided with ACTIN plus
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={optionss2}
                        placeholder="Select Services provided with ACTIN plus"
                        value={selectedOptions2}
                        onChange={handleChange2}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Date Of Joining:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Date Of Joining"
                        required
                        value={form.dateOfJoining}
                        name="dateOfJoining"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Valid Upto:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Valid Upto"
                        required
                        value={form.dateOfValidity}
                        name="dateOfValidity"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basixcpill-firstname-input3">
                        A+ <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.aPlus}
                        name="aPlus"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Yes">Yes</option>
                        <option value="No"> No</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basixcpill-firstname-input3">
                        Timing Of HCU <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.timingOfHco}
                        name="timingOfHco"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="twentyfourSeven"> 24 x 7</option>
                        <option value="noTime"> No</option>
                      </select>
                    </div>
                  </Col>
                  {form.timingOfHco == "noTime" ? (
                    <>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            From Time:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Date Of Joining"
                            required
                            value={form.fromTime}
                            name="fromTime"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            To Time:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="time"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Date Of Joining"
                            required
                            value={form.toTime}
                            name="toTime"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basixcpill-firstname-input3">
                        Commission Type <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form.commissionType}
                        name="commissionType"
                        onChange={e => {
                          handlechange(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="Price">Price</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                  </Col>
                  <h5
                    className="mb-4 mt-3"
                    style={{ color: "#467BE5", fontWeight: "bold" }}
                  >
                    Hospital Commission
                  </h5>

                  {form.commissionType == "Percentage" ? (
                    <>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Tests at Home Commission:
                            <span className="text-danger">*(%)</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Tests at Home Commission"
                            required
                            value={form.testsAtHomeCommission}
                            name="testsAtHomeCommission"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Investigation at HCU Commission:
                            <span className="text-danger">*(%)</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Investigation at HCU Commission"
                            required
                            value={form.investigationAtHcuCommission}
                            name="investigationAtHcuCommission"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Packages Commission :
                            <span className="text-danger">*(%)</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Packages Commission"
                            required
                            value={form.packageCommission}
                            name="packageCommission"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Doctor Consultation Commission:
                        <span className="text-danger">*(%)</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Doctor Consultation Commission"
                        required
                        value={form.doctorConsultationCommission}
                        name="doctorConsultationCommission"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Home Services Commission:
                        <span className="text-danger">*(%)</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Home Services Commission"
                        required
                        value={form.homeServicesCommission}
                        name="homeServicesCommission"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <h5
                    className="mb-4 mt-3"
                    style={{ color: "#467BE5", fontWeight: "bold" }}
                  >
                    Hospital Address Details
                  </h5>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label> State</Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.stateId}
                        name="stateId"
                        required
                        onChange={e => {
                          Optionchange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.stateName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label> District</Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.districtId}
                        name="districtId"
                        required
                        onChange={e => {
                          Optionchange1(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.districtName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label> City </Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.cityId}
                        name="cityId"
                        required
                        onChange={e => {
                          Optionchange2(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {City.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.cityName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label> Zone </Label>
                      <span className="text-danger">*</span>
                      <select
                        value={form.zoneId}
                        name="zoneId"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {zone.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Location <span className="text-danger">*</span>
                      </Label>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setaddress}
                        onSelect={handleSelects}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div
                            key={suggestions.description}
                            className="form-group "
                          >
                            <input
                              {...getInputProps({
                                placeholder: "Search location ...",
                                className: "location-search-input form-control",
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item"
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    }

                                return (
                                  // <></>
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={suggestion.placeId}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <label>Latitude</label>
                      <input
                        id="name"
                        type="number"
                        className="form-control"
                        placeholder="Enter latitude"
                        name="latittude"
                        value={coordinateds.lat}
                        disabled="disabled"
                        onChange={e => {
                          handlechange(e)
                        }}
                        required
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <label>Longitude</label>
                      <input
                        id="name"
                        type="number"
                        className="form-control"
                        placeholder="Enter longitude"
                        name="longitude"
                        value={coordinateds.lng}
                        onChange={e => {
                          handlechange(e)
                        }}
                        disabled="disabled"
                        required
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Address <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="1"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Address"
                        required
                        value={form.address}
                        name="address"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Pin Code: <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Pin Code"
                        required
                        pattern="^\d{6}$"
                        value={form.pinCode}
                        name="pinCode"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-2"
                      style={{ color: "#467BE5", fontWeight: "bold" }}
                    >
                      Bank Details
                    </h5>
                    <Row className="mt-3">
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Bank Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Bank Name"
                            required
                            value={form.bankName}
                            name="bankName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Holder Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Holder Name"
                            required
                            value={form.accountHolderName}
                            name="accountHolderName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Account Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            pattern="[0-9]+"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Account Number"
                            required
                            value={form.accountNumber}
                            name="accountNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Branch<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Branch"
                            required
                            value={form.branchName}
                            name="branchName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            IFSC code
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter IFSC code"
                            required
                            pattern="^[A-Z]{4}0\d{6}$"
                            value={form.ifscCode}
                            name="ifscCode"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            TDS <span className="text-danger">*</span>
                          </Label>
                          <select
                            className="form-select"
                            required
                            value={form.tdsExamination}
                            name="tdsExamination"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="No"> No </option>
                          </select>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            GST Number       
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter 
                            GST Number"
                            // minLength="15"
                            // maxLength="15"
                            // pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$"
                            value={form.gstNumber}
                            name="gstNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Name Of Authorised Signatory
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter 
                            Name Of Authorised Signatory to Bank Account"
                            value={form.authorizedName}
                            name="authorizedName"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>

                      {/* <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Name Of Authorised Signatory Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler7}
                          />
                        </div>
                      </Col> */}

                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input3">
                            Bank Account Type
                            <span className="text-danger">*</span>
                          </Label>

                          <select
                            className="form-select"
                            required
                            value={form.accountType}
                            name="accountType"
                            onChange={e => {
                              handlechange(e)
                            }}
                          >
                            <option value=""> Select </option>
                            <option value="CurrentAccount">
                              Current Account
                            </option>
                            <option value="SavingAccount">
                              Saving Account
                            </option>
                          </select>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            HCU Pan Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter HCU Pan Number"
                            required
                            value={form.hcoPanNumber}
                            pattern="^[A-Z]{5}[0-9]{4}[A-Z]$"
                            name="hcoPanNumber"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            Cancelled Cheque
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-2">
                          <Label for="basicpill-firstname-input1">
                            HCU Pan Card Image
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler1}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h5
                      className="mb-4 mt-2"
                      style={{ color: "#467BE5", fontWeight: "bold" }}
                    >
                      Hospital Documents
                    </h5>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            State Register<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler2}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            State Register Valid Up to
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter State Register"
                            required
                            value={form.stateRegisterValidUpto}
                            name="stateRegisterValidUpto"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            NABH Certificate
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler3}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            NABH Certificate Valid Up to
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter NABH Certificate"
                            value={form.nabhCertValidUpto}
                            name="nabhCertValidUpto"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            CGHS Certificate
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler4}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            CGHS Certificate Valid Up to
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter CGHS Certificate"
                            value={form.cghsCertValidUpto}
                            name="cghsCertValidUpto"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Biomedical Westage
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            required
                            onChange={changeHandler5}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Biomedical Westage Valid Up to
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Biomedical Westage"
                            required
                            value={form.bioWasteCertValidUpto}
                            name="bioWasteCertValidUpto"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Aarogyasri Certificate
                          </Label>
                          <Input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler6}
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Aarogyasri Certificate Valid Up to
                          </Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter Aarogyasri Certificate"
                            value={form.aarogyaCertValidUpto}
                            name="aarogyaCertValidUpto"
                            onChange={e => {
                              handlechange(e)
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <h5
                  className="mb-2 "
                  style={{ color: "#467BE5", fontWeight: "bold" }}
                >
                  Account Information
                </h5>
                <Row className="mt-3">
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Email / UserName<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="email"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Email"
                        required
                        value={form.email}
                        name="email"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Password<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Password"
                        required
                        value={form.password}
                        name="password"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-2">
                      <Label for="basicpill-firstname-input1">
                        Confirm Password
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Confirm Password"
                        required
                        value={form.confirmPassword}
                        name="confirmPassword"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col md={12}>
                <div className=" mb-2" style={{ float: "right" }}>
                  <button
                    type="submit"
                    style={{ width: "120px" }}
                    className="btn btn-info m-1"
                  >
                    Submit <i className="fas fa-cHCUk-circle"></i>
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default AddVendors
