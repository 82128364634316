import React, { useEffect, useState } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import Select from "react-select"

const Coupons = () => {
  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [selectedOptions1, setSelectedOptions1] = useState([])

  function HealthStandard(data) {
    setSelectedOptions1(data)
  }

  const [selectedOptions2, setSelectedOptions2] = useState([])

  function HealthStandard2(data) {
    setSelectedOptions2(data)
  }

  function tog_small() {
    setmodal_small(!modal_small)
    removeBodyCss()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [coup, setcoup] = useState([])
  const [form, setform] = useState({
    coupType: "",
    title: "",
    couponCode: "",
    limitForSameUser: "",
    couponCodeType: "",
    price: "",
    minimumPurchase: "",
    maximumDiscount: "",
    fromDate: "",
    expireDate: "",
    percentage: "",
  })
  const [form1, setform1] = useState([])

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }
  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const getAllcoupons = () => {
    var token = datas
    axios
      .post(
        URLS.GetCoupon,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.coupons)
        setIsLoading(false)
      })
  }

  const addcoupons = () => {
    var token = datas

    const dataArray = {
      coupType: form.coupType,
      title: form.title,
      couponCode: form.couponCode,
      limitForSameUser: form.limitForSameUser,
      couponCodeType: form.couponCodeType,
      price: form.price,
      minimumPurchase: form.minimumPurchase,
      maximumDiscount: form.maximumDiscount,
      fromDate: form.fromDate,
      expireDate: form.expireDate,
      percentage: form.percentage,
      userList: selectedOptions1,
    }

    axios
      .post(URLS.AddCoupon, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllcoupons()
            clearForm()
            setIsLoading(true)
            setSelectedOptions1("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const getpopup = data => {
    setform1(data)
    setSelectedOptions2(data.users)
    tog_small()
  }

  const editcoupon = () => {
    var token = datas
    var formid = form1._id

    const dataArray = {
      coupType: form1.coupType,
      title: form1.title,
      couponCode: form1.couponCode,
      limitForSameUser: form1.limitForSameUser,
      couponCodeType: form1.couponCodeType,
      price: form1.price,
      minimumPurchase: form1.minimumPurchase,
      maximumDiscount: form1.maximumDiscount,
      fromDate: form1.fromDate,
      expireDate: form1.expireDate,
      percentage: form1.percentage,
      userList: selectedOptions2,
    }

    axios
      .put(URLS.UpdateCoupon + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllcoupons()
            setmodal_small(false)
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handleSubmit = e => {
    e.preventDefault()
    addcoupons()
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    editcoupon()
  }

  const deletebenners = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.InActiveCoupon + "/" + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getAllcoupons()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletebenners(data)
    }
  }

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = coup.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(coup.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }
  useEffect(() => {
    getAllcoupons()
  }, [])

  const clearForm = () => {
    setform({
      coupType: "",
      title: "",
      couponCode: "",
      limitForSameUser: "",
      couponCodeType: "",
      price: "",
      minimumPurchase: "",
      maximumDiscount: "",
      fromDate: "",
      expireDate: "",
      percentage: "",
    })
  }

  const [search, setsearch] = useState([])

  const searchAll = e => {
    let myUser = { ...search }
    myUser[e.target.name] = e.target.value
    setsearch(myUser)

    var token = datas
    axios
      .post(
        URLS.GetCouponSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setcoup(res.data.coupons)
      })
  }

  const [dds, setdds] = useState([])

  const handleChangedates = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds(tomorrow.toISOString().split("T")[0])
  }

  const [dds1, setdds1] = useState([])

  const handleChangedates1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const tomorrow = new Date(e.target.value)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setdds1(tomorrow.toISOString().split("T")[0])
  }

  const [Users, setUsers] = useState([])

  useEffect(() => {
    GetUsers()
  }, [])

  const GetUsers = () => {
    var token = datas
    axios
      .post(
        URLS.GetUsersDropdown,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setUsers(res.data.userDropdown)
      })
  }

  const [ins, setins] = useState(false)

  const [Instructions, setInstructions] = useState([])

  function inst() {
    setins(!ins)
  }

  const getinc = data => {
    setInstructions(data.users)
    inst()
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Coupons" />
          <Row>
            {Roles?.couponsAdd === true || Roles?.accessAll === true ? (
              <>
                <Col md={12}>
                  <Card>
                    <CardHeader className="bg-white">
                      <CardTitle>Add Coupons</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form
                        onSubmit={e => {
                          handleSubmit(e)
                        }}
                      >
                        <Row>
                          {" "}
                          <Col md={4}>
                            {" "}
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                Coupon Type
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                name="coupType"
                                value={form.coupType}
                                onChange={e => {
                                  handleChange(e)
                                }}
                                required
                                className="form-select"
                              >
                                {" "}
                                <option value="">Select</option>
                                <option value="Default">Default</option>
                                <option value="FirstOrder">First Order</option>
                                <option value="FreeDelivery">
                                  Free Delivery
                                </option>
                                <option value="CustomerWise">
                                  Customer Wise
                                </option>
                              </select>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Title <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Title"
                                required
                                name="title"
                                value={form.title}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            {" "}
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input3">
                                Coupon Code{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input3"
                                placeholder="Enter Coupon Code"
                                required
                                name="couponCode"
                                value={form.couponCode}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          {form.coupType == "FirstOrder" ? (
                            ""
                          ) : (
                            <Col md={4}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input2">
                                  Limit For Same User{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="basicpill-firstname-input2"
                                  placeholder="Enter Ex : 10"
                                  required
                                  name="limitForSameUser"
                                  value={form.limitForSameUser}
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                />
                              </div>
                            </Col>
                          )}
                          {form.coupType == "FreeDelivery" ? (
                            ""
                          ) : (
                            <>
                              <Col md={4}>
                                {" "}
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Coupon Code Type
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    name="couponCodeType"
                                    value={form.couponCodeType}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                    required
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="Price">Price</option>
                                    <option value="Percentage">
                                      Percentage
                                    </option>
                                  </select>
                                </div>
                              </Col>
                              <Col md={4}>
                                {" "}
                                {form.couponCodeType == "Percentage" ? (
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input2">
                                      Percentage %{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-firstname-input2"
                                      placeholder="Enter Percentage"
                                      required
                                      name="percentage"
                                      value={form.percentage}
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input2">
                                      Price{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="basicpill-firstname-input2"
                                      placeholder="Enter Price "
                                      required
                                      name="price"
                                      value={form.price}
                                      onChange={e => {
                                        handleChange(e)
                                      }}
                                    />
                                  </div>
                                )}
                              </Col>
                            </>
                          )}
                          <Col md={4}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Minimum Purchase{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter Minimum Purchase"
                                required
                                name="minimumPurchase"
                                value={form.minimumPurchase}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          {form.coupType == "FreeDelivery" ? (
                            ""
                          ) : (
                            <>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input2">
                                    Maximum Discount{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="basicpill-firstname-input2"
                                    placeholder="Enter Maximum Discount"
                                    required
                                    name="maximumDiscount"
                                    value={form.maximumDiscount}
                                    onChange={e => {
                                      handleChange(e)
                                    }}
                                  />
                                </div>
                              </Col>{" "}
                            </>
                          )}
                          <Col md={4}>
                            {" "}
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                From Date <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                required
                                name="fromDate"
                                min={new Date().toISOString().split("T")[0]}
                                value={form.fromDate}
                                onChange={e => {
                                  handleChangedates(e)
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input2">
                                Expire Date{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="basicpill-firstname-input2"
                                placeholder="Enter  Version"
                                required
                                name="expireDate"
                                min={dds}
                                value={form.expireDate}
                                onChange={e => {
                                  handleChange(e)
                                }}
                              />
                            </div>
                          </Col>
                          {form.coupType == "CustomerWise" ? (
                            <Col md={4}>
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Customer id / Name
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  options={Users}
                                  placeholder="Select Customer id / Name"
                                  value={selectedOptions1}
                                  onChange={HealthStandard}
                                  isSearchable={true}
                                  isMulti
                                />
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>

                        <div style={{ float: "right" }}>
                          <Button className="m-1" color="primary" type="submit">
                            Submit <i className="fas fa-check-circle"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white">
                  <CardTitle>Coupons List</CardTitle>
                </CardHeader>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              className="form-control"
                              placeholder="Search.."
                              value={search.search}
                              onChange={searchAll}
                              name="search"
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr>
                                <th>S.No</th>
                                <th>Coupon Type</th>
                                <th>Title </th>
                                <th>Coupon Code </th>
                                <th>Limit For Same User</th>
                                <th>Coupon Code Type</th>
                                <th>Amount </th>
                                <th>Minimum Purchase </th>
                                <th>Maximum Discount </th>
                                <th>Users </th>
                                <th>From Date </th>
                                <th>Expire Date </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.coupType}</td>
                                  <td>{data.title}</td>
                                  <td>{data.couponCode}</td>
                                  <td>{data.limitForSameUser}</td>
                                  <td>{data.couponCodeType}</td>
                                  <td>
                                    {data.price == "" ? (
                                      <>{data.percentage}%</>
                                    ) : (
                                      <>{data.price}</>
                                    )}
                                  </td>
                                  <td>{data.minimumPurchase}</td>
                                  <td>{data.maximumDiscount}</td>
                                  <td className="text-center">
                                    {data.couponsDelete == "CustomerWise" ? (
                                      <>
                                        <Button
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="warning"
                                          onClick={() => {
                                            getinc(data)
                                          }}
                                        >
                                          View
                                        </Button>
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </td>
                                  <td>{data.fromDate}</td>
                                  <td>{data.expireDate}</td>

                                  <td>
                                    {Roles?.couponsEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            getpopup(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="success"
                                          outline
                                        >
                                          <i className="bx bx-edit "></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}{" "}
                                    {Roles?.couponsEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="danger"
                                          outline
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />

        <Modal
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Edit Coupon Details
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Row>
                {" "}
                <Col md={6}>
                  {" "}
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      Coupon Type
                      <span className="text-danger">*</span>
                    </Label>
                    <select
                      name="coupType"
                      value={form1.coupType}
                      onChange={e => {
                        handleChange1(e)
                      }}
                      required
                      className="form-select"
                    >
                      {" "}
                      <option value="">Select</option>
                      <option value="Default">Default</option>
                      <option value="FirstOrder">First Order</option>
                      <option value="FreeDelivery">Free Delivery</option>
                      <option value="CustomerWise">Customer Wise</option>
                    </select>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter Title"
                      required
                      name="title"
                      value={form1.title}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  {" "}
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input3">
                      Coupon Code <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input3"
                      placeholder="Enter Coupon Code"
                      required
                      name="couponCode"
                      value={form1.couponCode}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                {form1.coupType == "FirstOrder" ? (
                  ""
                ) : (
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input2">
                        Limit For Same User{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input2"
                        placeholder="Enter Ex : 10"
                        required
                        name="limitForSameUser"
                        value={form1.limitForSameUser}
                        onChange={e => {
                          handleChange1(e)
                        }}
                      />
                    </div>
                  </Col>
                )}
                {form1.coupType == "FreeDelivery" ? (
                  ""
                ) : (
                  <>
                    <Col md={6}>
                      {" "}
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Coupon Code Type
                          <span className="text-danger">*</span>
                        </Label>
                        <select
                          name="couponCodeType"
                          value={form1.couponCodeType}
                          onChange={e => {
                            handleChange1(e)
                          }}
                          required
                          className="form-select"
                        >
                          <option value="">Select</option>
                          <option value="Price">Price</option>
                          <option value="Percentage">Percentage</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      {form1.couponCodeType == "Percentage" ? (
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input2">
                            Percentage % <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input2"
                            placeholder="Enter Percentage"
                            required
                            name="percentage"
                            value={form1.percentage}
                            onChange={e => {
                              handleChange1(e)
                            }}
                          />
                        </div>
                      ) : (
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input2">
                            Price <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="basicpill-firstname-input2"
                            placeholder="Enter Price "
                            required
                            name="price"
                            value={form1.price}
                            onChange={e => {
                              handleChange1(e)
                            }}
                          />
                        </div>
                      )}
                    </Col>
                  </>
                )}
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Minimum Purchase <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="number"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter Minimum Purchase"
                      required
                      name="minimumPurchase"
                      value={form1.minimumPurchase}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                {form1.coupType == "FreeDelivery" ? (
                  ""
                ) : (
                  <>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input2">
                          Maximum Discount{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="basicpill-firstname-input2"
                          placeholder="Enter Maximum Discount"
                          required
                          name="maximumDiscount"
                          value={form1.maximumDiscount}
                          onChange={e => {
                            handleChange1(e)
                          }}
                        />
                      </div>
                    </Col>{" "}
                  </>
                )}
                <Col md={6}>
                  {" "}
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      From Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      required
                      name="fromDate"
                      min={new Date().toISOString().split("T")[0]}
                      value={form1.fromDate}
                      onChange={e => {
                        handleChangedates1(e)
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input2">
                      Expire Date <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="date"
                      className="form-control"
                      id="basicpill-firstname-input2"
                      placeholder="Enter  Version"
                      required
                      name="expireDate"
                      min={dds1}
                      value={form1.expireDate}
                      onChange={e => {
                        handleChange1(e)
                      }}
                    />
                  </div>
                </Col>
                {form1.coupType == "CustomerWise" ? (
                  <Col md={6}>
                    {/* <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Customer id / Name
                        <span className="text-danger">*</span>
                      </Label>
                      <select
                        name="customerid"
                        value={form1.customerid}
                        onChange={e => {
                          handleChange(e)
                        }}
                        required
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="All">All</option>
                        {Users.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.userCode} / {data.name}
                            </option>
                          )
                        })}
                      </select>
                    </div> */}

                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Customer id / Name
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        options={Users}
                        placeholder="Select Customer id / Name"
                        value={selectedOptions2}
                        onChange={HealthStandard2}
                        isSearchable={true}
                        isMulti
                      />
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="lg"
          isOpen={ins}
          toggle={() => {
            inst()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Users :-
            </h5>
            <button
              onClick={() => {
                setins(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {Instructions.map((data, i) => (
              <>
                <p key={i} className="pt-2">
                  <i className="bx bxs-send" style={{ color: "#B30706" }}></i>{" "}
                  {data.label}
                </p>
                <hr></hr>
              </>
            ))}
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Coupons
