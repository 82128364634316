import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"
import { useHistory } from "react-router-dom"

function Support() {
  const [modal_small, setmodal_small] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const [banner, setbanner] = useState([])

  const [form, setform] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const handleSubmit = e => {
    e.preventDefault()
    AddRequest()
  }

  function tog_small() {
    setmodal_small(!modal_small)
  }

  useEffect(() => {
    GetAllBanners()
  }, [])

  const AddRequest = () => {
    var token = datas
    const dataArray = {
      monthYear: form.monthYear,
    }
    axios
      .post(URLS.AddAtpPayrole, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setmodal_small(false)
            GetAllBanners()
            clearForm()
            setIsLoading(true)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const clearForm = () => {
    setform({
      monthYear: "",
    })
  }

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = banner.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(banner.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const GetAllBanners = () => {
    var token = datas
    axios
      .post(
        URLS.GetAtpPayrole,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbanner(res.data.payments)
        setIsLoading(false)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const getpopup = () => {
    tog_small()
  }

  const PayByid = data => {
    sessionStorage.setItem("Paymentids", data._id)
    history.push("/AtpViewPayrole")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Actin" breadcrumbItem="ATP Pay Role" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardHeader className="bg-white">
                      <CardTitle>
                        <Button
                          onClick={() => {
                            getpopup()
                          }}
                          className="mr-2"
                          color="primary"
                          style={{
                            padding: "6px",
                            margin: "3px",
                           
                          }}
                        >
                          ATP Pay Role Generate
                        </Button>
                      </CardTitle>
                    </CardHeader>

                    <CardBody>
                      <div>
                        <div className="table-responsive" style={{height:"480px"}}>
                          <Table className="table table-bordered mb-4 ">
                            <thead className="text-center">
                              <tr>
                                <th>S.No</th>
                                <th>Year / Month </th>
                                <th>Generate - Date / Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody className="text-center">
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <th>{(pageNumber - 1) * 20 + key + 21}</th>
                                  <td>{data.monthYear}</td>
                                  <td>
                                    {data.logCreatedDate.slice(0, 10)} /
                                    {new Date(
                                      data.logCreatedDate
                                    ).toLocaleTimeString("en-US", {
                                      hour12: false,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      timeZone: "Asia/Kolkata",
                                    })}
                                  </td>

                                  <td>
                                    <Button
                                      onClick={() => {
                                        PayByid(data)
                                      }}
                                      className="mr-2"
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="info"
                                      outline
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
            ATP Pay Role Generate
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Month / Year <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="month"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Month Year"
                    required
                    value={form.monthYear}
                    name="monthYear"
                    onChange={e => {
                      handlechange(e)
                    }}
                  />
                </div>
              </Col>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
