import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function City() {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const history = useHistory()
  const [zones, setZones] = useState([])
  const [states, setstates] = useState([])
  const [District, setDistrict] = useState([])
  const [City, setCity] = useState([])
  const [form, setform] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    datass()
    GetZones()
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetState,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.states)
      })
  }

  const Optionchange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getDistricts(e.target.value)
  }

  const getDistricts = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("stateId", data)
    axios
      .post(URLS.GetIdDistrict, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.distResult)
      })
  }

  const Optionchange1 = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
    getCity(e.target.value)
  }

  const getCity = data => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("districtId", data)
    axios
      .post(URLS.GetIdCity, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setCity(res.data.cityResult)
      })
  }

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetZone,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setZones(res.data.zones)
          setIsLoading(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = zones.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(zones.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [forms, setforms] = useState([])

  const handlechanges = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetZoneSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setZones(res.data.zones)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [filter, setfilter] = useState(false)

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const data = {
      name: form.name,
      stateId: form.stateId,
      districtId: form.districtId,
      cityId: form.cityId,
    }
    axios
      .post(URLS.GetZoneSearch, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data.zones)
        GetZones()
        hidefilter()
      })
  }

  const hidefilter = () => setfilter(false)

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      Deletedep(data)
    }
  }

  const Deletedep = data => {
    var token = datas
    var remid = data._id
    axios
      .put(
        URLS.InActiveZone + "/" + remid,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetZones()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const ZoneId = data => {
    sessionStorage.setItem("ZoneIds", data._id)
    history.push("/EditZones")
  }

  const ZoneIds = data => {
    sessionStorage.setItem("ZoneIdss", data._id)
    history.push("/ZonesView")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Zones" />
          {filter ? (
            <>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      getfilter(e)
                    }}
                  >
                    <Row>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label> State</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.stateId}
                            name="stateId"
                            onChange={e => {
                              Optionchange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {states.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.stateName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label> District</Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.districtId}
                            name="districtId"
                            onChange={e => {
                              Optionchange1(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {District.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.districtName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mb-3">
                          <Label> City </Label>{" "}
                          <span className="text-danger">*</span>
                          <select
                            value={form.cityId}
                            name="cityId"
                            onChange={e => {
                              handleChange(e)
                            }}
                            className="form-select"
                          >
                            <option value="">Select</option>
                            {City.map((data, key) => {
                              return (
                                <option key={key} value={data._id}>
                                  {data.cityName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="mt-4">
                          <Button type="submit" className="m-1" color="info">
                            <i className="fas fa-check-circle"></i> search
                          </Button>
                          <Button
                            onClick={hidefilter}
                            className="m-1"
                            color="danger"
                          >
                            <i className="fas fa-times-circle"></i> Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Col>
                        {Roles?.zonesAdd === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <Link to="/Zone">
                              <Button color="primary">
                                New Zone <i className="bx bx-plus-circle"></i>
                              </Button>
                            </Link>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        <Link to="/AllZonesView">
                          <Button color="warning" className="m-2">
                            All Zones View
                          </Button>
                        </Link>

                        <Button
                          className="m-1"
                          onClick={() => {
                            setfilter(!filter)
                          }}
                          color="info"
                        >
                          <i className="fas fa-filter"></i> Filter
                        </Button>
                      </Col>

                      <Row>
                        <Col md={12}>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={forms.search}
                              onChange={handlechanges}
                              className="form-control"
                              placeholder="Search.."
                            />
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr>
                                <th>S No</th>
                                <th>Zone Name</th>
                                <th>City Name</th>
                                <th>District Name</th>
                                <th>State Name</th>
                                <th style={{ width: "100px" }}>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key}>
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.name}</td>
                                  <td>{data.cityName}</td>
                                  <td>{data.districtName}</td>
                                  <td>{data.stateName}</td>
                                  <td>
                                    {Roles?.zonesEdit === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            ZoneId(data)
                                          }}
                                          className="mr-2"
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="success"
                                          outline
                                        >
                                          <i className="bx bx-edit "></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <Button
                                      onClick={() => {
                                        ZoneIds(data)
                                      }}
                                      className="mr-2"
                                      style={{
                                        padding: "6px",
                                        margin: "3px",
                                      }}
                                      color="info"
                                      outline
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>
                                    {Roles?.zonesDelete === true ||
                                    Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            manageDelete(data)
                                          }}
                                          style={{
                                            padding: "6px",
                                            margin: "3px",
                                          }}
                                          color="danger"
                                          outline
                                        >
                                          <i className="bx bx-trash"></i>
                                        </Button>{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default City
