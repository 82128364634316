import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Table ,Button} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  const history = useHistory()

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllCompleatedRefundsBookings,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.payments)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("bookingId", data.bookingId)
    history.push("/ViewRefund")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="Completed Refunds Bookings"
          />

          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Booking Date</th>
                              <th>User Code</th>
                              <th>User Name</th>
                              <th>User Phone</th>
                              <th>Booking Type</th>
                              <th>Booking Code</th>
                              <th>Appointment Charge</th>
                              <th>Coupon Discount</th>
                              <th>Member Card</th>
                              <th>Sanitation Charge</th>
                              <th>Gst</th>
                              <th>Transaction Charge</th>
                              <th>Total Amount</th>
                              <th>User To be Refund</th>
                              <th>Transaction Id</th>
                              <th>Description</th>
                              <th>Attached File</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.bookingDate}</td>
                                <td>{data.actinUserCode}</td>
                                <td>{data.actinUserName}</td>
                                <td>{data.actinUserPhone}</td>
                                <td>{data.bookingType}</td>
                                <td>{data.actinBookingCode}</td>
                                <td>{data.appointmentCharge}</td>
                                <td>{data.couponDiscount}</td>
                                <td>{data.memberCards}</td>
                                <td>{data.sannitationCharge}</td>
                                <td>{data.gst}</td>
                                <td>{data.transactionCharge}</td>
                                <td>{data.totalAmount}</td>
                                <td>{data.userFinalAmount}</td>
                                <td>{data.cancelPayTransId}</td>
                                <td>{data.description}</td>
                                <td>
                                  <a
                                    href={URLS.Base + data.attachedFile}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src={URLS.Base + data.attachedFile}
                                      height="100px"
                                    ></img>
                                  </a>
                                </td>
                                <td>
                                  <Button
                                    className="mr-2"
                                    style={{
                                      padding: "6px",
                                      margin: "3px",
                                    }}
                                    onClick={() => {
                                      Actinid(data)
                                    }}
                                    color="info"
                                    outline
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
