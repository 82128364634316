const Url = "https://api.actinplus.com/"

export const URLS = {
  Base: Url,

  //Profile
  getProfile: Url + "v1/actinapi/admin/auth/getAdminProfile",
  updateprofile: Url + "v1/actinapi/admin/auth/updateAdminProfile",
  Changepass: Url + "v1/actinapi/admin/auth/changepass",
  UpdateImages: Url + "v1/actinapi/admin/auth/updateAdminprofileImg",
  forget: Url + "v1/actinapi/admin/auth/sendOtpemail",
  OTP: Url + "v1/actinapi/admin/auth/compareotp",
  Resetpass: Url + "v1/actinapi/admin/auth/resetpassword",

  //settings/state
  AddState: Url + "v1/actinapi/admin/state/addstate",
  GetState: Url + "v1/actinapi/admin/state/getAllStates",
  GetStateSearch: Url + "v1/actinapi/admin/state/getAllStates?searchQuery=",
  UpdateState: Url + "v1/actinapi/admin/state/editstate",
  InActiveState: Url + "v1/actinapi/admin/state/deletestate/",

  //settings/District
  AddDistrict: Url + "v1/actinapi/admin/district/adddistrict",
  GetDistrict: Url + "v1/actinapi/admin/district/getAlldistricts",
  GetDistrictSearch:
    Url + "v1/actinapi/admin/district/getAlldistricts?searchQuery=",
  UpdateDistrict: Url + "v1/actinapi/admin/district/editdistrict",
  InActiveDistrict: Url + "v1/actinapi/admin/district/deletedistrict",
  GetIdDistrict: Url + "v1/actinapi/admin/district/getdropdown",

  //settings/City
  AddCity: Url + "v1/actinapi/admin/city/addcity",
  GetCity: Url + "v1/actinapi/admin/city/getallcities",
  GetCitySearch: Url + "v1/actinapi/admin/city/getallcities?searchQuery=",
  UpdateCity: Url + "v1/actinapi/admin/city/editcity",
  InActiveCity: Url + "v1/actinapi/admin/city/deletecity",
  GetIdCity: Url + "v1/actinapi/admin/city/getalldropdown",

  //Zone
  AddZone: Url + "v1/actinapi/admin/zone/addzone",
  GetZone: Url + "v1/actinapi/admin/zone/getall",
  GetZoneSearch: Url + "v1/actinapi/admin/zone/getall?searchQuery=",
  UpdateZone: Url + "v1/actinapi/admin/zone/editzone/",
  InActiveZone: Url + "v1/actinapi/admin/zone/deletezone",
  GetIdZone: Url + "v1/actinapi/admin/zone/getZone",
  GetcityIdZone: Url + "v1/actinapi/admin/zone/getdropdown",

  //Hospital
  AddHospital: Url + "v1/actinapi/admin/hospital/add",
  GetHospital: Url + "v1/actinapi/admin/hospital/getall",
  GetHospitalSearch: Url + "v1/actinapi/admin/hospital/getall?searchQuery=",
  UpdateHospital: Url + "v1/actinapi/admin/hospital/edit",
  UpdatedocsHospital: Url + "v1/actinapi/admin/hospitalotherdoc/edit",
  InActiveHospital: Url + "v1/actinapi/admin/hospital/updatestatus/",
  GetIdHospital: Url + "v1/actinapi/admin/hospital/getdetails",

  //Users
  GetUser: Url + "v1/actinapi/admin/user/getall",
  GetUserID: Url + "v1/actinapi/admin/user/updatestatus/",
  GetUserSearch: Url + "v1/actinapi/admin/user/getall?searchQuery=",
  GetOneUser: Url + "v1/actinapi/admin/user/getdetails",

  //Atp
  AddAtp: Url + "v1/actinapi/admin/atp/addatp",
  GetAtp: Url + "v1/actinapi/admin/atp/getall",
  GetAtpSearch: Url + "v1/actinapi/admin/atp/getall?searchQuery=",
  UpdateAtp: Url + "v1/actinapi/admin/atp/editatp",
  InActiveAtp: Url + "v1/actinapi/admin/atp/updatestatus/",
  GetIdAtp: Url + "v1/actinapi/admin/atp/getatp",

  AddAtpPayrole: Url + "v1/actinapi/admin/atpmonthlypay/addpaydetails",
  GetAtpPayrole: Url + "v1/actinapi/admin/atpmonthlypay/getall",
  GetOneAtpPayrole: Url + "v1/actinapi/admin/atpmonthlypay/getallatps",
  UpdateAtpPayrole: Url + "v1/actinapi/admin/atpmonthlypay/updatepayment",

  //ATP'STIMESLOTMAKING
  GetAtptimeslot: Url + "v1/actinapi/admin/slot/getSlot",
  UpdateAtptimeslot: Url + "v1/actinapi/admin/slot/editSlot",

  //AtpHolidaylist
  AddAtpHolidaylist: Url + "v1/actinapi/admin/slotholiday/addslothldy",
  GetAtpHolidaylist: Url + "v1/actinapi/admin/slotholiday/getAll",
  GetoneAtpHolidaylist: Url + "v1/actinapi/admin/slotholiday/getslthldylist",
  UpdateAtpHolidaylist: Url + "v1/actinapi/admin/slotholiday/edit",
  InActiveAtpHolidaylist: Url + "v1/actinapi/admin/slotholiday/delete/",

  GetPendingLeaves: Url + "v1/ACTINapi/admin/atpleave/getallpending",
  GetPendingLeavesSearch:
    Url + "v1/ACTINapi/admin/atpleave/getallpending?searchQuery=",
  GetApprovedLeaves: Url + "v1/ACTINapi/admin/atpleave/getAllApprovedLeaves",
  GetApprovedLeavesSearch:
    Url + "v1/ACTINapi/admin/atpleave/getAllApprovedLeaves?searchQuery=",
  ApprovedLeaves: Url + "v1/ACTINapi/admin/atpleave/approveleave",

  //Coupons
  AddCoupon: Url + "v1/actinapi/admin/coupon/add",
  GetCoupon: Url + "v1/actinapi/admin/coupon/getall",
  GetCouponSearch: Url + "v1/actinapi/admin/coupon/getall?searchQuery=",
  UpdateCoupon: Url + "v1/actinapi/admin/coupon/edit",
  InActiveCoupon: Url + "v1/actinapi/admin/coupon/delete",
  GetIdCoupon: Url + "v1/actinapi/admin/coupon/getdetails",

  //Department
  AddDepartment: Url + "v1/actinapi/admin/department/adddepartment",
  GetDepartment: Url + "v1/actinapi/admin/department/getdepartments",
  GetDepartmentSearch:
    Url + "v1/actinapi/admin/department/getdepartments?searchQuery=",
  UpdateDepartment: Url + "v1/actinapi/admin/department/editdepartment",
  InActiveDepartment: Url + "v1/actinapi/admin/department/deletedepartment",

  //Banners
  AddBanners: Url + "v1/actinapi/admin/banner/addbanner",
  GetBanners: Url + "v1/actinapi/admin/banner/getall",
  GetBannersSearch: Url + "v1/actinapi/admin/banner/getall?searchQuery=",
  UpdateBanners: Url + "v1/actinapi/admin/banner/editbanner/",
  InActiveBanners: Url + "v1/actinapi/admin/banner/deletebanner/",

  //SubBanners
  AddSubBanners: Url + "v1/actinapi/admin/subbanner/addbanner",
  GetSubBanners: Url + "v1/actinapi/admin/subbanner/getall",
  GetSubBannersSearch: Url + "v1/actinapi/admin/subbanner/getall?searchQuery=",
  UpdateSubBanners: Url + "v1/actinapi/admin/subbanner/editbanner/",
  InActiveSubBanners: Url + "v1/actinapi/admin/subbanner/deletebanner/",

  //DoctorBanners
  AddDoctorBanner: Url + "v1/actinapi/admin/doctorbanner/addbanner",
  GetDoctorBanner: Url + "v1/actinapi/admin/doctorbanner/getall",
  GetDoctorBannerSearch:
    Url + "v1/actinapi/admin/doctorbanner/getall?searchQuery=",
  UpdateDoctorBanner: Url + "v1/actinapi/admin/doctorbanner/editbanner/",
  InActiveDoctorBanner: Url + "v1/actinapi/admin/doctorbanner/deletebanner/",

  //Manual
  AddManual: Url + "v1/actinapi/admin/manual/addmanual",
  GetManual: Url + "v1/actinapi/admin/manual/getAll",
  GetManualSearch: Url + "v1/actinapi/admin/manual/getAll?searchQuery=",
  UpdateManual: Url + "v1/actinapi/admin/manual/editmanual",
  InActiveManual: Url + "v1/actinapi/admin/manual/deletemanual/",

  //Faqs
  AddFaqs: Url + "v1/actinapi/admin/faq/addfaq",
  GetFaqs: Url + "v1/actinapi/admin/faq/getAllfaqs",
  GetFaqsSearch: Url + "v1/actinapi/admin/faq/getAllfaqs?searchQuery=",
  UpdateFaqs: Url + "v1/actinapi/admin/faq/editfaq",
  InActiveFaqs: Url + "v1/actinapi/admin/faq/deletefaq",

  //GetSettings
  GetSetting: Url + "v1/actinapi/admin/setting/getSetting",
  UpdateAbout: Url + "v1/actinapi/admin/setting/updateaboutUs",
  UpdateTerms: Url + "v1/actinapi/admin/setting/updatetermsAndCondition",
  UpdateRefundPolicy: Url + "v1/actinapi/admin/setting/updaterefundPolicy",
  UpdatePrivicyPolicy: Url + "v1/actinapi/admin/setting/updateprivacyPolicy",

  //Cghs Corner
  AddCghsCorner: Url + "v1/actinapi/admin/cgscorner/addcgscorener",
  GetCghsCorner: Url + "v1/actinapi/admin/cgscorner/getallcgscorner",
  GetCghsCornerSearch:
    Url + "v1/actinapi/admin/cgscorner/getallcgscorner?searchQuery=",
  UpdateCghsCorner: Url + "v1/actinapi/admin/cgscorner/editcgscorner/",
  InActiveCghsCorner: Url + "v1/actinapi/admin/cgscorner/deletecgscorner/",

  //Role
  AddRole: Url + "v1/actinapi/admin/role/addrole",
  GetRole: Url + "v1/actinapi/admin/role/getAll",
  GetOneRole: Url + "v1/actinapi/admin/role/getrole",
  EditRole: Url + "v1/actinapi/admin/role/editRole",
  DeleteRole: Url + "v1/actinapi/admin/role/deleterole/",

  //Staff
  AddStaff: Url + "v1/actinapi/admin/staff/addstaff",
  GetStaff: Url + "v1/actinapi/admin/staff/getAllstaff",
  GetStaffSearch: Url + "v1/actinapi/admin/staff/getAllstaff?searchQuery=",
  EditStaff: Url + "v1/actinapi/admin/staff/editstaff",
  DeleteStaff: Url + "v1/actinapi/admin/staff/updatestatus/",
  GetIdRole: Url + "v1/actinapi/admin/staff/getalldropdown",
  GetIdStaff: Url + "v1/actinapi/admin/staff/getstaff",

  //HomeTests
  AddTest: Url + "v1/actinapi/admin/hometest/addLabtest",
  GetTest: Url + "v1/actinapi/admin/hometest/getall",
  GetTestSearch: Url + "v1/actinapi/admin/hometest/getall?searchQuery=",
  EditTest: Url + "v1/actinapi/admin/hometest/edittest",
  GetOneTest: Url + "v1/actinapi/admin/hometest/gettest",
  DeleteOneTest: Url + "v1/actinapi/admin/hometest/updatestatus/",
  DeleteOneTests: Url + "v1/actinapi/admin/hometest/deletetest",

  //Investigation
  AddInvestigation: Url + "v1/actinapi/admin/investigation/addinvestigation",
  GetInvestigation: Url + "v1/actinapi/admin/investigation/getAll",
  GetInvestigationSearch:
    Url + "v1/actinapi/admin/investigation/getAll?searchQuery=",
  EditInvestigation: Url + "v1/actinapi/admin/investigation/edit",
  GetOneInvestigation: Url + "v1/actinapi/admin/investigation/get",
  DeleteOneInvestigation: Url + "v1/actinapi/admin/investigation/updatestatus/",

  //InvestigationRequest
  EditInvestigationRequest:
    Url + "v1/actinapi/admin/investigation/updateinvestigationrequest",
  GetInvestigationRequest:
    Url + "v1/actinapi/admin/investigation/getpendinginvestigationrequest",
  GetInvestigationRequestSearch:
    Url +
    "v1/actinapi/admin/investigation/getpendinginvestigationrequest?searchQuery=",

  //HomeServicetest
  AddHomeServicetest: Url + "v1/actinapi/admin/homeserviceAdmin/addhmservice",
  GetHomeServicetest: Url + "v1/actinapi/admin/homeserviceAdmin/getAll",
  GetHomeServicetestSearch:
    Url + "v1/actinapi/admin/homeserviceAdmin/getAll?searchQuery=",
  EditHomeServicetest: Url + "v1/actinapi/admin/homeserviceAdmin/edit",
  GetOneHomeServicetest: Url + "v1/actinapi/admin/homeserviceAdmin/getHmsrvc",
  DeleteOneHomeServicetest:
    Url + "v1/actinapi/admin/homeserviceAdmin/updatestatus/",

  //Notifications
  AddNotifications: Url + "v1/actinapi/admin/notification/addnotifcation",
  GetNotifications: Url + "v1/actinapi/admin/notification/getall",
  GetNotificationsSearch:
    Url + "v1/actinapi/admin/notification/getall?searchQuery=",
  DeleteNotifications: Url + "v1/actinapi/admin/notification/deletenotif/",

  //Enquiry
  AddEnquiry: Url + "v1/actinapi/admin/enquiry/addenquiry",
  GetEnquiry: Url + "v1/actinapi/admin/enquiry/getAll",
  GetEnquirySearch: Url + "v1/actinapi/admin/enquiry/getAll?searchQuery=",
  GetOneEnquiry: Url + "v1/actinapi/admin/enquiry/get",
  EditEnquiry: Url + "v1/actinapi/admin/enquiry/edit/",
  DeleteEnquiry: Url + "v1/actinapi/admin/enquiry/delteenquiry",

  //Prescription
  GetPrescription: Url + "v1/actinapi/admin/prescription/getAllPrescriptions",
  GetPrescriptionSearch:
    Url + "v1/actinapi/admin/prescription/getAllPrescriptions?searchQuery=",
  UpdatePrescription: Url + "v1/actinapi/admin/prescription/editPrescription",

  //Contact Us
  GetContactUs: Url + "v1/actinapi/admin/contactus/get",
  UpdateContactUs: Url + "v1/actinapi/admin/contactus/editcontactus",

  //Requestprofile
  GetRequestprofile: Url + "v1/actinapi/admin/requestforProfile/getall",
  GetRequestprofileSearch:
    Url + "v1/actinapi/admin/requestforProfile/getall?searchQuery=",
  EditProfileRequestprofile: Url + "v1/actinapi/admin/requestforProfile/update",

  //GetaALLTicketRasingList
  GetaALLTicketRasingList: Url + "v1/actinapi/admin/testticketraising/getAll",
  GetoneTicketRasingList: Url + "v1/actinapi/admin/testticketraising/getAll",
  Getonetestticketraising:
    Url + "v1/actinapi/admin/testticketraising/getdetails",
  Editonetestticketraising:
    Url + "v1/actinapi/admin/testticketraising/updatestatus",
  Deletetestticketraising:
    Url + "v1/actinapi/admin/testticketraising/deletetest",

  //GetaALLPackagesTicketRasingList
  GetALLPackagesTicketRasingList:
    Url + "v1/actinapi/admin/packageticketraising/getAll",
  GetonePackagesTicketticketraising:
    Url + "v1/actinapi/admin/packageticketraising/getdetails",
  EditonePackagesTicketticketraising:
    Url + "v1/actinapi/admin/packageticketraising/updatestatus",
  DeletePackagesTicketticketraising:
    Url + "v1/actinapi/admin/packageticketraising/deletetest",

  //Test Request
  GetAllTestRequest: Url + "v1/actinapi/admin/testrequest/getAll",
  GetAllTestRequestSearch:
    Url + "v1/actinapi/admin/testrequest/getAll?searchQuery=",
  UpdateTestRequest: Url + "v1/actinapi/admin/testrequest/updatestatus",

  //Package Request
  GetAllPackageRequest: Url + "v1/actinapi/admin/packagerequest/getAll",
  GetAllPackageRequestSearch:
    Url + "v1/actinapi/admin/packagerequest/getAll?searchQuery=",
  UpdatePackageRequest: Url + "v1/actinapi/admin/packagerequest/updatestatus",

  //Service Request
  GetAllServiceRequest: Url + "v1/ACTINapi/admin/homeservice/request/getall",
  GetAllServiceRequestSearch:
    Url + "v1/ACTINapi/admin/homeservice/request/getall?searchQuery=",
  UpdateServiceRequest:
    Url + "v1/ACTINapi/admin/homeservice/request/statusupdate",

  //Tickets
  GetAllInvestigationsTickets:
    Url + "v1/actinapi/admin/investigationticket/getallinvestigationtickets",
  GetOneInvestigationsTickets:
    Url + "v1/actinapi/admin/investigationticket/getinvestigationticketbyid",
  UpdateInvestigationsTickets:
    Url + "v1/actinapi/admin/investigationticket/updateinvestigationticket/",
  DeleteInvestigationsTickets:
    Url + "v1/actinapi/admin/investigationticket/deleteinvestigationprice",

  GetHomeServiceTickets: Url + "v1/ACTINapi/admin/serviceticket/getall",
  GetOneHomeServiceTickets: Url + "v1/ACTINapi/admin/serviceticket/getdetails",
  UpdateHomeServiceTickets:
    Url + "v1/ACTINapi/admin/serviceticket/updatestatus",
  DeleteHomeServiceTickets: Url + "v1/ACTINapi/admin/serviceticket/delete",

  GetConsultationTickets:
    Url + "v1/actinapi/admin/doctorticket/getalldoctortickets",
  GetOneConsultationTickets:
    Url + "v1/actinapi/admin/doctorticket/getdoctorticketbyid",
  UpdateConsultationTickets:
    Url + "v1/actinapi/admin/doctorticket/updatedoctorticket/",
  DeleteConsultationTickets:
    Url + "v1/actinapi/admin/doctorticket/deletedoctorprice",

  //All Pending Cancellation Bookings Details
  GetAllPendingCancellationBookings:
    Url + "v1/actinapi/admin/cancelbookingpay/getallunpaid",
  GetAllGetAllPendingCancellationBookingsSearch:
    Url + "v1/ACTINapi/admin/bookings/getall?searchQuery=",
  AddPendingCancellationBookings:
    Url + "v1/actinapi/admin/cancelbookingpay/addcanceledbookingdetails",

  //All Compleated Bookings Details
  GetAllCompleatedCancellationBookings:
    Url + "v1/actinapi/admin/cancelbookingpay/getallpaiddata",
  GetAllGetAllCompleatedCancellationBookingsSearch:
    Url + "v1/actinapi/admin/cancelbookingpay/getallpaiddata?searchQuery=",

  //All Pending Refunds Bookings Details
  GetAllPendingRefundsBookings:
    Url + "v1/actinapi/admin/cancelbookingpay/getUnpaidFailedBookings",
  GetAllGetAllPendingRefundsBookingsSearch:
    Url +
    "v1/ACTINapi/admin/cancelbookingpay/getUnpaidFailedBookings?searchQuery=",

  //All Compleated Refunds Bookings Details
  GetAllCompleatedRefundsBookings:
    Url + "v1/actinapi/admin/cancelbookingpay/getPaidfailedBookings",
  GetAllGetAllCompleatedRefundsBookingsSearch:
    Url +
    "v1/actinapi/admin/cancelbookingpay/getPaidfailedBookings?searchQuery=",

  //All Bookings Details
  GetAllHomeTestsBookings: Url + "v1/ACTINapi/admin/bookings/getall",
  GetAllHomeTestsBookingsSearch:
    Url + "v1/ACTINapi/admin/bookings/getall?searchQuery=",
  GetOneTestsBookings: Url + "v1/ACTINapi/admin/bookings/getBookingDetails",

  //All Packages Bookings Details
  GetAllPackagesBookings: Url + "v1/ACTINapi/admin/bookings/getall",
  GetAllPackagesBookingsSearch:
    Url + "v1/ACTINapi/admin/bookings/getall?searchQuery=",
  GetOnePackagesBookings:
    Url + "v1/actinapi/admin/bookings/getpackagebookingdetails",

  GetAllInvestigationsBookings:
    Url + "v1/ACTINapi/admin/bookings/investigation/getAll",
  GetAllInvestigationsBookingsSearch:
    Url + "v1/ACTINapi/admin/bookings/investigation/getAll?searchQuery=",
  GetOneInvestigationsBookings:
    Url + "v1/ACTINapi/admin/bookings/investigation/BookingDetails",

  GetAllDoctorsConsltationBookings:
    Url + "v1/ACTINapi/admin/bookings/consultancy/getall",
  GetAllDoctorsConsltationBookingsSearch:
    Url + "v1/ACTINapi/admin/bookings/consultancy/getall?searchQuery=",
  GetOneDoctorsConsltationBookings:
    Url + "v1/ACTINapi/admin/bookings/consultancy/BookingDetails",

  GetAllHomeServicesBookings:
    Url + "v1/ACTINapi/admin/bookings/homeservices/getall",
  GetAllHomeServicesBookingsSearch:
    Url + "v1/ACTINapi/admin/bookings/homeservices/getall?searchQuery=",
  GetOneHomeServicesBookings:
    Url + "v1/ACTINapi/admin/bookings/homeservices/BookingDetails",

  ///Home test Prices
  getaddAllhometest: Url + "v1/actinapi/vendor/hometestprice/gethospitaltests",
  getaddAllhometestSearch:
    Url + "v1/actinapi/vendor/hometestprice/gethospitaltests?searchQuery=",
  getaddAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/getallhocinvest",
  getaddAllInvestigationsSearch:
    Url + "v1/actinapi/vendor/investigation/getallhocinvest?searchQuery=",
  GetAllDoctor: Url + "v1/actinapi/vendor/doctor/getAll",
  GetAllDoctorSearch: Url + "v1/actinapi/vendor/doctor/getAll?searchQuery=",
  GetallHomeServiceslist: Url + "v1/actinapi/vendor/homeservice/getAll",

  GetallHomeServiceslistSearch: Url + "v1/actinapi/vendor/homeservice/getAll?searchQuery=",

  //Add Test
  getAllhometest: Url + "v1/actinapi/vendor/hometestprice/get",
  getAllhometestSearch:
    Url + "v1/actinapi/vendor/hometestprice/get?searchQuery=",

  //Add Test
  addAllhometest: Url + "v1/actinapi/vendor/hometestprice/addtestprice",
  EditAllhometest:
    Url + "v1/actinapi/vendor/hometestprice/updatehometestprice/",
  Deletehometest: Url + "v1/actinapi/vendor/hometestprice/deletehometestprice",

  //getAllInvestigations
  getAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/getallactininvest",
  getAllInvestigationsSearch:
    Url + "v1/actinapi/vendor/investigation/getallactininvest?searchQuery=",
  addAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/addinvestpriceinhoc",
  EditAllInvestigations:
    Url + "v1/actinapi/vendor/investigation/updateinvestprice/",
  DeletesInvestigations:
    Url + "v1/actinapi/vendor/investigation/deleteinvestprice",

  ResignAtpbookings: Url + "v1/actinapi/admin/bookings/reassignatp",

  ///Home Monitring
  GetAllHomeTestsBooking: Url + "v1/actinapi/admin/monitoring/tests/getall",
  GetAllpackagess: Url + "v1/actinapi/admin/monitoring/packages/getAll",
  GetAllInvestigationsBooking:
    Url + "v1/actinapi/admin/monitoring/investigations/getall",
  GetAllDoctorsConsltationBooking:
    Url + "v1/actinapi/admin/monitoring/consultancy/getall",
  GetAllHomeServicesBooking:
    Url + "v1/actinapi/admin/monitoring/homeservices/getall",

  GetAtpAllMonitring: Url + "v1/actinapi/admin/monitoring/atp/getall",

  //Atp Monitring
  GetAtpMonitring: Url + "v1/actinapi/admin/monitoring/atp/getall",
  GetAtpMonitringSearch:
    Url + "v1/actinapi/admin/monitoring/atp/getall?searchQuery=",

  //Atp Monitring
  GetAtponeMonitring:
    Url + "v1/actinapi/admin/monitoring/atp/getsingleatpbookings",

  //slot wise Atp Monitring
  GetAtpSlotWiseMonitring: Url + "v1/actinapi/admin/monitoring/atp/getslotwise",

  //GetDashboard
  GetDashboard: Url + "v1/actinapi/admin/dashboard/hospital",

  //GetAllDashboard
  GetAllDashboard: Url + "v1/actinapi/admin/dashboard/admin",

  //GetAllReports
  GetAllBookingTestReports: Url + "v1/actinapi/admin/reports/test",
  GetAllBookingInvestigationReports:
    Url + "v1/actinapi/admin/reports/investigation",
  GetAllBookingConstultationReports:
    Url + "v1/actinapi/admin/reports/consultation",
  GetAllBookingHomeServiceReports:
    Url + "v1/actinapi/admin/reports/homeservice",

  GetAllActinstantReports: Url + "v1/actinapi/admin/reports/actinstanttest",
  GetAllEmergencyReports: Url + "v1/actinapi/admin/reports/emergencytest",

  //Get Ticket Rasing List
  GetUserTicket: Url + "v1/actinapi/admin/userticket/getalltickets",
  GetUserTicketSearch:
    Url + "v1/actinapi/admin/userticket/getalltickets?searchQuery=",
  UpdateUserTicket: Url + "v1/actinapi/admin/userticket/updatestatus",

  GetAtpTicket: Url + "v1/actinapi/vendor/booking/getAll?searchQuery=",
  UpdateAtpTicket: Url + "v1/actinapi/vendor/booking/getAll?searchQuery=",

  GetHCUTicket: Url + "v1/actinapi/vendor/booking/getAll?searchQuery=",
  UpdateHCUTicket: Url + "v1/actinapi/vendor/booking/getAll?searchQuery=",

  //BuddsRequest
  GetBuddsRequest: Url + "v1/actinapi/admin/atp/getbuddyrequests",
  GetBuddsRequestSearch:
    Url + "v1/actinapi/admin/atp/getbuddyrequests?searchQuery=",
  UpdateBuddsRequest: Url + "v1/actinapi/admin/atp/updatebuddyrequest",

  LeaveRequest: Url + "v1/actinapi/admin/atp/reassignbuddy",

  //Get Hospital Techinical Support Tickets
  GetHospitalTechinicalSupport:
    Url + "v1/actinapi/admin/support/gethospitaltickets",
  GetHospitalTechinicalSupportSearch:
    Url + "v1/actinapi/admin/support/gethospitaltickets?searchQuery=",

  //Update Techinical Support Tickets
  UpdateHospitalTechinicalSupport:
    Url + "v1/actinapi/admin/support/updatehospitalticketstatus",

  //Get ATP Techinical Support Tickets
  GetAtpTechinicalSupport: Url + "v1/actinapi/admin/support/getatptickets",
  GetAtpTechinicalSupportSearch:
    Url + "v1/actinapi/admin/support/getatptickets?searchQuery=",
  //Update Techinical Support Tickets
  UpdateAtpTechinicalSupport:
    Url + "v1/actinapi/admin/support/updateatpticketstatus",

  //Get Package Report
  GetPackageReport: Url + "v1/actinapi/admin/reports/testpackage",

  //Get Package Report
  Updateatpbookingassign: Url + "v1/actinapi/admin/bookings/reassignatp",

  //Expense Category
  AddExpenseCategory: Url + "v1/actinapi/admin/expensecategory/addcategory",
  GetExpenseCategory: Url + "v1/actinapi/admin/expensecategory/getallcateg",
  GetExpenseCategorySearch:
    Url + "v1/actinapi/admin/expensecategory/getallcateg?searchQuery=",
  UpdateExpenseCategory: Url + "v1/actinapi/admin/expensecategory/editcateg",
  DeleteExpenseCategory: Url + "v1/actinapi/admin/expensecategory/deletecateg/",

  //Expense List
  AddExpense: Url + "v1/actinapi/admin/expense/addexpense",
  GetExpense: Url + "v1/actinapi/admin/expense/getallexpenses",
  GetExpenseSearch:
    Url + "v1/actinapi/admin/expense/getallexpenses?searchQuery=",
  UpdateExpense: Url + "v1/actinapi/admin/expense/editexpense",
  DeleteExpense: Url + "v1/actinapi/admin/expense/deleteexpense/",

  //Income Statement List
  GetIncomeStatement: Url + "v1/actinapi/admin/expense/getexpensereport",

  //PayRole
  AddPayRole: Url + "v1/actinapi/admin/expense/addexpense",
  GetPayRole: Url + "v1/actinapi/admin/expense/getallexpenses",
  GetPayRoleSearch:
    Url + "v1/actinapi/admin/expense/getallexpenses?searchQuery=",
  UpdatePayRole: Url + "v1/actinapi/admin/expense/editexpense",
  DeletePayRole: Url + "v1/actinapi/admin/expense/deleteexpense/",

  Getpayrolestaff: Url + "v1/actinapi/admin/payroll/getallstaff",
  Getdatestaff: Url + "v1/actinapi/admin/payroll/getdaysinmonth",
  Getearnings: Url + "v1/actinapi/admin/payroll/gettotalearning",

  // update actin charges
  Getcharges: Url + "v1/actinapi/admin/actincharges/getcharges",
  Updatecharges: Url + "v1/actinapi/admin/actincharges/updatecharges",

  GetUsersDropdown: Url + "v1/actinapi/admin/user/getuserdropdown",

  //PAYMENT GENERATE
  UPDATEPAYMENTGENERATE: Url + "v1/actinapi/admin/actinhcupay/updatestatus",
  GetPAYMENTGENERATE: Url + "v1/actinapi/admin/actinhcupay/getall",
  GetPAYMENTGENERATESearch:
    Url + "v1/actinapi/admin/actinhcupay/getall?searchQuery=",
  GETONEPAYMENTGENERATE: Url + "v1/actinapi/admin/actinhcupay/getdetails",

  //getallpayrolls
  AddPayRole: Url + "v1/actinapi/admin/payroll/addpayroll",
  GetallPayRole: Url + "v1/actinapi/admin/payroll/getallpayrolls",
  GetallPayRoleSearch:
    Url + "v1/actinapi/admin/payroll/getallpayrolls?searchQuery=",

  AddUserSupport: Url + "v1/actinapi/admin/userticket/callback/addtoenquiry",

  GetDoctorFeedback: Url + "v1/actinapi/admin/doctorfeedback/getall",
  UpdateDoctorFeedback: Url + "v1/actinapi/admin/doctorfeedback/editfdb",

  GetDoctorTcSupport: Url + "v1/actinapi/admin/doctorprofilerequest/getall",
  UpdateDoctorTcSupport:
    Url + "v1/actinapi/admin/doctorprofilerequest/editdocreq",

  //doctorspeciality
  Adddoctorspeciality: Url + "v1/actinapi/admin/doctorspeciality/addspec",
  Getdoctorspeciality: Url + "v1/actinapi/admin/doctorspeciality/getallspecs",
  GetdoctorspecialitySearch:
    Url + "v1/actinapi/admin/doctorspeciality/getallspecs?searchQuery=",
  Editdoctorspeciality: Url + "v1/actinapi/admin/doctorspeciality/editspec/",
  Deletedoctorspeciality:
    Url + "v1/actinapi/admin/doctorspeciality/deletespec/",

  AddBlog: Url + "v1/actinapi/admin/blog/addblog",
  GetBlog: Url + "v1/actinapi/admin/blog/getallblogs",
  GetBlogSearch: Url + "v1/actinapi/admin/blog/getallblogs?searchQuery=",
  EditBlog: Url + "v1/actinapi/admin/blog/editblog/",
  DeleteBlog: Url + "v1/actinapi/admin/blog/deleteblog/",

  // get all recruitment list
  Getrecruitmentlist: Url + "v1/actinapi/admin/atprecruite/getallpeniding",
  GetrecruitmentlistSearch:
    Url + "v1/actinapi/admin/atprecruite/getallpeniding?searchQuery=",
  Getonerecruitmentlist: Url + "v1/actinapi/admin/atprecruite/getdetails",

  //Enquiry
  GetEnquirys: Url + "v1/actinapi/admin/contactus/getallwebcontactus",
  GetEnquirysSearch:
    Url + "v1/actinapi/admin/contactus/getallwebcontactus?searchQuery=",
  DeleteEnquirys: Url + "v1/actinapi/admin/contactus/deletewebcontactus/",

  chatCount: Url + "v1/actinapi/admin/userticket/makemesgcountzero",

  //Enquiry
  AddHomeServiceslist: Url + "v1/actinapi/vendor/homeservice/addhmservice",
  GetadminHomeServiceslist:
    Url + "v1/actinapi/vendor/homeservice/getallhomeservices",

  RefundAmountt: Url + "v1/actinapi/admin/bookings/refundamount",

  UpdatePendingRefund:
    Url + "v1/actinapi/admin/cancelbookingpay/addfailedPayment",

  ViewPendingRefund:
    Url + "v1/actinapi/admin/cancelbookingpay/failedBookingDetails",
}
