import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import axios from "axios"
import { URLS } from "../../Url"

function AddVendors() {
  const [form, setform] = useState({
    workingDays: "",
    leaves: "",
    basicSalary: "",
    houseRentAllownce: "",
    conveyenceAllowance: "",
    specialAllowance: "",
    otherAllowance: "",
    totalEarning: "",
    providentFund: "",
    emplStateInsurance: "",
    taxDeductOnSalary: "",
    professionalTax: "",
    advanceAmount: "",
    leavesAmount: "",
    totalSalary: "",
  })

  const [form1, setform1] = useState([])

  const [dates, setdates] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleSubmit = e => {
    e.preventDefault()

    AddStaff()
  }

  const history = useHistory()
  const AddStaff = () => {
    var token = datas
    const dataArray = {
      month: form1.month,
      staffType: form1.staffType,
      staffId: form1.staffId,
      totalDays: dates,
      leaves: form.leaves,
      basicSalary: form.basicSalary,
      houseRentAllownce: form.houseRentAllownce,
      conveyenceAllowance: form.conveyenceAllowance,
      specialAllowance: form.specialAllowance,
      otherAllowance: form.otherAllowance,
      totalEarning: form.totalEarning,
      providentFund: form.providentFund,
      emplStateInsurance: form.emplStateInsurance,
      taxDeductOnSalary: form.taxDeductOnSalary,
      professionalTax: form.professionalTax,
      advanceAmount: form.advanceAmount,
      leavesAmount: form.leavesAmount,
      totalSalary: form.totalSalary,
      workingDays: form.workingDays,
    }

    axios
      .post(URLS.AddPayRole, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/PayRole")
            sessionStorage.setItem(
              "tost",
              "PayRole has been Generate  Successfully"
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)

    // Assuming 'dates' is a variable representing days, calculate 'js'
    const js = parseFloat(form.totalEarning) / parseFloat(dates)

    // Calculate total salary based on working days and 'js'
    myUser.totalSalary = (
      parseFloat(myUser.workingDays) * parseFloat(js)
    ).toFixed(2)

    // Calculate leaves based on 'dates' and working days
    myUser.leaves = parseFloat(dates) - parseFloat(myUser.workingDays)

    myUser.leavesAmount = (parseFloat(myUser.leaves) * parseFloat(js)).toFixed(
      2
    )

    // Calculate deductions individually
    const pf = parseFloat(myUser.providentFund)
    const fspecialAllowance = parseFloat(myUser.specialAllowance)
    const fotherAllowance = parseFloat(myUser.otherAllowance)
    const emplStateInsuranceDeduction = parseFloat(myUser.emplStateInsurance)
    const taxDeductOnSalaryDeduction = parseFloat(myUser.taxDeductOnSalary)
    const professionalTaxDeduction = parseFloat(myUser.professionalTax)
    const advanceAmountDeduction = parseFloat(myUser.advanceAmount)

    // Calculate total deductions
    const totalDeductions = (
      pf +
      fspecialAllowance +
      fotherAllowance +
      emplStateInsuranceDeduction +
      taxDeductOnSalaryDeduction +
      professionalTaxDeduction +
      advanceAmountDeduction
    ).toFixed(2)

    // Deduct the total deductions from the total salary to get net salary
    myUser.totalSalary = (
      parseFloat(myUser.totalSalary) - parseFloat(totalDeductions)
    ).toFixed(2)

    setform(myUser)
  }

  const [District, setDistrict] = useState([])

  const Optionchange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
    GetStaff(e.target.value)
  }

  const GetStaff = data => {
    var token = datas
    const dataArray = {
      staffType: data,
    }
    axios
      .post(URLS.Getpayrolestaff, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setDistrict(res.data.staff)
      })
  }

  const Optiondatechange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
    Getdate(e.target.value)
  }

  const Getdate = data => {
    var token = datas
    const dataArray = {
      yearMonth: data,
    }
    axios
      .post(URLS.Getdatestaff, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setdates(res.data.daysInMonth)
      })
  }

  const Optiondatechange5 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
    Getdates(e.target.value)
  }

  const Getdates = data => {
    var token = datas
    const dataArray = {
      employeeId: data,
    }
    axios
      .post(URLS.Getearnings, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Add Payrole" breadcrumbItem="Add Payrole" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row>
                  <h4>Payrole :- </h4>
                  <hr></hr>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Month<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="month"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter month"
                        required
                        value={form1.month}
                        name="month"
                        onChange={e => {
                          Optiondatechange1(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select Type <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form1.staffType}
                        name="staffType"
                        onChange={e => {
                          Optionchange1(e)
                        }}
                      >
                        <option value=""> Select </option>
                        <option value="STAFF"> Staff </option>
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input3">
                        Select Employee <span className="text-danger">*</span>
                      </Label>

                      <select
                        className="form-select"
                        required
                        value={form1.staffId}
                        name="staffId"
                        onChange={e => {
                          Optiondatechange5(e)
                        }}
                      >
                        <option value=""> Select </option>
                        {District.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.firstName}
                              {data.lastName}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Total Days<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Total Days"
                        required
                        value={dates}
                        name="totalDays"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Working Days<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Working Days"
                        required
                        value={form.workingDays}
                        name="workingDays"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Leaves<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Leaves"
                        required
                        disabled
                        value={form.leaves}
                        name="leaves"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <h4>Earning :-</h4>
                  <hr></hr>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Basic Salary<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Basic Salary"
                        required
                        disabled
                        value={form.basicSalary}
                        name="basicSalary"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Hra<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Hra"
                        required
                        disabled
                        value={form.houseRentAllownce}
                        name="houseRentAllownce"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Conveyence Allowance
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Conveyence Allowance"
                        required
                        disabled
                        value={form.conveyenceAllowance}
                        name="conveyenceAllowance"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Special Allowance<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Special Allowance"
                        required
                        value={form.specialAllowance}
                        name="specialAllowance"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Others <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Others "
                        required
                        value={form.otherAllowance}
                        name="otherAllowance"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Earning Total <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Earning Total "
                        required
                        disabled
                        value={form.totalEarning}
                        name="totalEarning"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <h4>Deductions :-</h4>
                  <hr></hr>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        PF<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter PF"
                        required
                        disabled
                        value={form.providentFund}
                        name="providentFund"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        ESI<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter ESI"
                        required
                        value={form.emplStateInsurance}
                        name="emplStateInsurance"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Loan
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Loan"
                        required
                        value={form.taxDeductOnSalary}
                        name="taxDeductOnSalary"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Tax<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Tax"
                        required
                        value={form.professionalTax}
                        name="professionalTax"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Advance Amount <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Advance Amount "
                        required
                        value={form.advanceAmount}
                        name="advanceAmount"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Leaves Amount <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Leaves Amount "
                        required
                        disabled
                        value={form.leavesAmount}
                        name="leavesAmount"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Net Salary<span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Net Salary"
                        required
                        disabled
                        value={form.totalSalary}
                        name="totalSalary"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <div style={{ float: "right" }}>
                  <Button className="m-1" color="info" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddVendors
