import React, { useState, useEffect } from "react"
import {
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import axios from "axios"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import { useHistory } from "react-router-dom"

const Roles = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [ras, setras] = useState({
    Dashview: false,

    zonesView: false,
    zonesAdd: false,
    zonesEdit: false,
    zonesDelete: false,

    EmployeeManagmentView: false,

    departementView: false,
    departementAdd: false,
    departementeEdit: false,
    departementBlock: false,

    rolesAndPermissionView: false,
    rolesAndPermissionAdd: false,
    rolesAndPermissionEdit: false,
    rolesAndPermissionDelete: false,

    staffView: false,
    staffAdd: false,
    staffEdit: false,
    staffBlock: false,

    AllHospitalView: false,

    allHospitalDashboardView: false,
    hospitalListView: false,
    hospitalListAdd: false,
    hospitalListEdit: false,
    hospitalListBlock: false,

    ticketRaisingListView: false,
    ticketRaisingListEdit: false,
    ticketRaisingListDelete: false,

    hospitalMonitoringView: false,

    hospitalProfileRequestView: false,
    hospitalProfileRequestEdit: false,

    ATPView: false,

    atpListView: false,
    atpListAdd: false,
    atpListEdit: false,
    atpListBlock: false,

    atpSlotMakingView: false,
    atpSlotMakingAdd: false,
    atpSlotMakingEdit: false,
    atpSlotMakingDelete: false,

    atpMonitoringView: false,

    atpSlotWiseMonitoringView: false,

    recruitWaitingListView: false,
    recruitWaitingListEdit: false,

    leaveRequestView: false,
    leaveRequestEdit: false,

    UserListView: false,

    userListView: false,
    userListAdd: false,
    userListEdit: false,
    userListBlock: false,

    TestsAtHomeView: false,

    homeTestListView: false,
    homeTestListAdd: false,
    homeTestListEdit: false,
    homeTestListDelete: false,

    newTestsRequestView: false,
    newTestsRequestEdit: false,

    packagesRequestView: false,
    packagesRequestEdit: false,

    InvestigationsAtHCUView: false,

    investigationsRequestsView: false,
    investigationsRequestsAdd: false,
    investigationsRequestsEdit: false,
    investigationsRequestsDelete: false,

    newInvestigationRequestView: false,
    newInvestigationRequestEdit: false,

    DoctorConsultationView: false,

    consultationPriceRequestAdd: false,
    consultationPriceRequestEdit: false,
    consultationPriceRequestView: false,
    consultationPriceRequestDelete: false,

    pescreptiondecipheringView: false,
    pescreptiondecipheringEdit: false,

    HomeServiceView: false,

    homeServiceListView: false,
    homeServiceListAdd: false,
    homeServiceListEdit: false,
    homeServiceListDelete: false,

    BookingManagmentView: false,

    newHomeServiceRequestView: false,
    newHomeServiceRequestEdit: false,

    homeBookingListView: false,
    homeBookingListEdit: false,

    emergencyBookingListView: false,
    emergencyBookingListEdit: false,

    actInstantBookingListView: false,
    actInstantBookingListEdit: false,

    PackageBookingListView: false,
    PackageBookingListEdit: false,

    CancellationListView: false,

    CancellationBookingListView: false,
    CancellationBookingListEdit: false,

    investigationAtHcuView: false,
    investigationAtHcuEdit: false,

    consultationView: false,
    consultationEdit: false,

    homeServiceView: false,
    homeServiceEdit: false,

    paymentsView: false,
    paymentsEdit: false,

    PaymentsReportsView: false,

    homeTestReportView: false,
    investigationAtHcuReportView: false,
    consultationReportView: false,
    homeServiceReportView: false,

    TechinicalSupportView: false,

    CRMView: false,

    AccountsView: false,

    ExpensesCategoryView: false,
    ExpensesCategoryAdd: false,
    ExpensesCategoryEdit: false,
    ExpensesCategoryDelete: false,

    ExpenseslistView: false,
    ExpenseslistAdd: false,
    ExpenseslistEdit: false,
    ExpenseslistDelete: false,

    IncomeStatmentView: false,

    AtpRoleView: false,
    AtpRoleAdd: false,
    AtpRoleEdit: false,
    AtpRoleDelete: false,

    StaffPayRoleView: false,
    StaffPayRoleAdd: false,
    StaffPayRoleEdit: false,
    StaffPayRoleDelete: false,

    hcuTcSupportView: false,
    hcuTcSupportEdit: false,

    atpTcSupportView: false,
    atpTcSupportEdit: false,

    userTcSupportView: false,
    userTcSupportEdit: false,

    enquiryView: false,
    enquiryDelete: false,
    enquiryEdit: false,

    chatView: false,
    chatAdd: false,
    chatEdit: false,

    BannerView: false,

    bannersView: false,
    bannersAdd: false,
    bannersEdit: false,
    bannersDelete: false,

    suBannersView: false,
    suBannersAdd: false,
    suBannersEdit: false,
    suBannersDelete: false,

    couponsView: false,
    couponsAdd: false,
    couponsEdit: false,
    couponsDelete: false,

    notificationsView: false,
    notificationsAdd: false,
    notificationsDelete: false,

    stateView: false,
    stateAdd: false,
    stateEdit: false,
    stateDelete: false,

    distrctView: false,
    distrctAdd: false,
    distrctEdit: false,
    distrctDelete: false,

    cityView: false,
    cityAdd: false,
    cityEdit: false,
    cityDelete: false,

    manualAdd: false,
    manualView: false,
    manualEdit: false,
    manualDelete: false,

    cgsCornerView: false,
    cgsCornerAdd: false,
    cgsCornerEdit: false,
    cgsCornerDelete: false,

    SettingsView: false,

    BlogView: false,
    BlogAdd: false,
    BlogEdit: false,
    BlogDelete: false,

    ChargesView: false,
    ChargesEdit: false,

    UserProfileView: false,
    UserProfileEdit: false,

    settingsAdd: false,
    settingsEdit: false,
    settingsView: false,
    settingsDelete: false,
  })

  const handleChange1s = e => {
    const myUser = { ...ras }
    myUser[e.target.name] = e.target.checked
    setras(myUser)
  }

  const [form, setform] = useState([])

  const handleSubmit = e => {
    e.preventDefault()
    Addrole()
  }

  const check = {
    Dashview: ras.Dashview,

    zonesView: ras.zonesView,
    zonesAdd: ras.zonesAdd,
    zonesEdit: ras.zonesEdit,
    zonesDelete: ras.zonesDelete,

    EmployeeManagmentView: ras.EmployeeManagmentView,

    departementView: ras.departementView,
    departementAdd: ras.departementAdd,
    departementeEdit: ras.departementeEdit,
    departementBlock: ras.departementBlock,

    rolesAndPermissionView: ras.rolesAndPermissionView,
    rolesAndPermissionAdd: ras.rolesAndPermissionAdd,
    rolesAndPermissionEdit: ras.rolesAndPermissionEdit,
    rolesAndPermissionDelete: ras.rolesAndPermissionDelete,

    staffView: ras.staffView,
    staffAdd: ras.staffAdd,
    staffEdit: ras.staffEdit,
    staffBlock: ras.staffBlock,

    AllHospitalView: ras.AllHospitalView,

    allHospitalDashboardView: ras.allHospitalDashboardView,

    hospitalListView: ras.hospitalListView,
    hospitalListAdd: ras.hospitalListAdd,
    hospitalListEdit: ras.hospitalListEdit,
    hospitalListBlock: ras.hospitalListBlock,

    ticketRaisingListView: ras.ticketRaisingListView,
    ticketRaisingListEdit: ras.ticketRaisingListEdit,
    ticketRaisingListDelete: ras.ticketRaisingListDelete,

    hospitalMonitoringView: ras.hospitalMonitoringView,

    hospitalProfileRequestView: ras.hospitalProfileRequestView,
    hospitalProfileRequestEdit: ras.hospitalProfileRequestEdit,

    ATPView: ras.ATPView,

    atpListView: ras.atpListView,
    atpListAdd: ras.atpListAdd,
    atpListEdit: ras.atpListEdit,
    atpListBlock: ras.atpListBlock,

    atpSlotMakingView: ras.atpSlotMakingView,
    atpSlotMakingAdd: ras.atpSlotMakingAdd,
    atpSlotMakingEdit: ras.atpSlotMakingEdit,
    atpSlotMakingDelete: ras.atpSlotMakingDelete,

    atpMonitoringView: ras.atpMonitoringView,

    atpSlotWiseMonitoringView: ras.atpSlotWiseMonitoringView,

    recruitWaitingListView: ras.recruitWaitingListView,
    recruitWaitingListEdit: ras.recruitWaitingListEdit,

    leaveRequestView: ras.leaveRequestView,
    leaveRequestEdit: ras.leaveRequestEdit,

    UserListView: ras.UserListView,

    userListView: ras.userListView,
    userListAdd: ras.userListAdd,
    userListEdit: ras.userListEdit,
    userListBlock: ras.userListBlock,

    TestsAtHomeView: ras.TestsAtHomeView,

    homeTestListView: ras.homeTestListView,
    homeTestListAdd: ras.homeTestListAdd,
    homeTestListEdit: ras.homeTestListEdit,
    homeTestListDelete: ras.homeTestListDelete,

    newTestsRequestView: ras.newTestsRequestView,
    newTestsRequestEdit: ras.newTestsRequestEdit,

    packagesRequestView: ras.packagesRequestView,
    packagesRequestEdit: ras.packagesRequestEdit,

    InvestigationsAtHCUView: ras.InvestigationsAtHCUView,

    investigationsRequestsView: ras.investigationsRequestsView,
    investigationsRequestsAdd: ras.investigationsRequestsAdd,
    investigationsRequestsEdit: ras.investigationsRequestsEdit,
    investigationsRequestsDelete: ras.investigationsRequestsDelete,

    newInvestigationRequestView: ras.newInvestigationRequestView,
    newInvestigationRequestEdit: ras.newInvestigationRequestEdit,

    DoctorConsultationView: ras.DoctorConsultationView,

    consultationPriceRequestView: ras.consultationPriceRequestView,
    consultationPriceRequestEdit: ras.consultationPriceRequestEdit,
    consultationPriceRequestAdd: ras.consultationPriceRequestAdd,
    consultationPriceRequestDelete: ras.consultationPriceRequestDelete,

    pescreptiondecipheringView: ras.pescreptiondecipheringView,
    pescreptiondecipheringEdit: ras.pescreptiondecipheringEdit,

    HomeServiceView: ras.HomeServiceView,

    AccountsView: ras.AccountsView,

    ExpensesCategoryView: ras.ExpensesCategoryView,
    ExpensesCategoryAdd: ras.ExpensesCategoryAdd,
    ExpensesCategoryEdit: ras.ExpensesCategoryEdit,
    ExpensesCategoryDelete: ras.ExpensesCategoryDelete,

    ExpenseslistView: ras.ExpenseslistView,
    ExpenseslistAdd: ras.ExpenseslistAdd,
    ExpenseslistEdit: ras.ExpenseslistEdit,
    ExpenseslistDelete: ras.ExpenseslistDelete,

    IncomeStatmentView: ras.IncomeStatmentView,

    AtpRoleView: ras.AtpRoleView,
    AtpRoleAdd: ras.AtpRoleAdd,
    AtpRoleEdit: ras.AtpRoleEdit,
    AtpRoleDelete: ras.AtpRoleDelete,

    StaffPayRoleView: ras.StaffPayRoleView,
    StaffPayRoleAdd: ras.StaffPayRoleAdd,
    StaffPayRoleEdit: ras.StaffPayRoleEdit,
    StaffPayRoleDelete: ras.StaffPayRoleDelete,

    homeServiceListView: ras.homeServiceListView,
    homeServiceListAdd: ras.homeServiceListAdd,
    homeServiceListEdit: ras.homeServiceListEdit,
    homeServiceListDelete: ras.homeServiceListDelete,

    newHomeServiceRequestView: ras.newHomeServiceRequestView,
    newHomeServiceRequestEdit: ras.newHomeServiceRequestEdit,

    BookingManagmentView: ras.BookingManagmentView,

    homeBookingListView: ras.homeBookingListView,
    homeBookingListEdit: ras.homeBookingListEdit,

    emergencyBookingListView: ras.emergencyBookingListView,
    emergencyBookingListEdit: ras.emergencyBookingListEdit,

    actInstantBookingListView: ras.actInstantBookingListView,
    actInstantBookingListEdit: ras.actInstantBookingListEdit,

    PackageBookingListView: ras.PackageBookingListView,
    PackageBookingListEdit: ras.PackageBookingListEdit,

    CancellationListView: ras.CancellationListView,

    CancellationBookingListView: ras.CancellationBookingListView,
    CancellationBookingListEdit: ras.CancellationBookingListEdit,

    investigationAtHcuView: ras.investigationAtHcuView,
    investigationAtHcuEdit: ras.investigationAtHcuEdit,

    consultationView: ras.consultationView,
    consultationEdit: ras.consultationEdit,

    homeServiceView: ras.homeServiceView,
    homeServiceEdit: ras.homeServiceEdit,

    paymentsView: ras.paymentsView,
    paymentsEdit: ras.paymentsEdit,

    PaymentsReportsView: ras.PaymentsReportsView,

    homeTestReportView: ras.homeTestReportView,

    investigationAtHcuReportView: ras.investigationAtHcuReportView,

    consultationReportView: ras.consultationReportView,
    homeServiceReportView: ras.homeServiceReportView,

    TechinicalSupportView: ras.hcuTcSupportView,

    hcuTcSupportView: ras.hcuTcSupportView,
    hcuTcSupportEdit: ras.hcuTcSupportEdit,

    atpTcSupportView: ras.atpTcSupportView,
    atpTcSupportEdit: ras.atpTcSupportEdit,

    userTcSupportView: ras.userTcSupportView,
    userTcSupportEdit: ras.userTcSupportEdit,

    CRMView: ras.CRMView,

    enquiryView: ras.enquiryView,
    enquiryDelete: ras.enquiryDelete,
    enquiryEdit: ras.enquiryEdit,

    chatView: ras.chatView,
    chatAdd: ras.chatAdd,
    chatEdit: ras.chatEdit,

    BannerView: ras.BannerView,

    bannersView: ras.bannersView,
    bannersAdd: ras.bannersAdd,
    bannersEdit: ras.bannersEdit,
    bannersDelete: ras.bannersDelete,

    suBannersView: ras.suBannersView,
    suBannersAdd: ras.suBannersAdd,
    suBannersEdit: ras.suBannersEdit,
    suBannersDelete: ras.suBannersDelete,

    couponsView: ras.couponsView,
    couponsAdd: ras.couponsAdd,
    couponsEdit: ras.couponsEdit,
    couponsDelete: ras.couponsDelete,

    notificationsView: ras.notificationsView,
    notificationsAdd: ras.notificationsAdd,
    notificationsDelete: ras.notificationsDelete,

    SettingsView: ras.SettingsView,

    stateView: ras.stateView,
    stateAdd: ras.stateAdd,
    stateEdit: ras.stateEdit,
    stateDelete: ras.stateDelete,

    distrctView: ras.distrctView,
    distrctAdd: ras.distrctAdd,
    distrctEdit: ras.distrctEdit,
    distrctDelete: ras.distrctDelete,

    cityView: ras.cityView,
    cityAdd: ras.cityAdd,
    cityEdit: ras.cityEdit,
    cityDelete: ras.cityDelete,

    manualAdd: ras.manualAdd,
    manualView: ras.manualView,
    manualEdit: ras.manualEdit,
    manualDelete: ras.manualDelete,

    cgsCornerView: ras.cgsCornerView,
    cgsCornerAdd: ras.cgsCornerAdd,
    cgsCornerEdit: ras.cgsCornerEdit,
    cgsCornerDelete: ras.cgsCornerDelete,

    BlogView: ras.BlogView,
    BlogAdd: ras.BlogAdd,
    BlogEdit: ras.BlogEdit,
    BlogDelete: ras.BlogDelete,

    ChargesView: ras.ChargesView,
    ChargesEdit: ras.ChargesEdit,

    UserProfileView: ras.UserProfileView,
    UserProfileEdit: ras.UserProfileEdit,

    settingsAdd: ras.settingsAdd,
    settingsEdit: ras.settingsEdit,
    settingsView: ras.settingsView,
    settingsDelete: ras.settingsDelete,
  }

  const Addrole = () => {
    const token = datas

    const data = {
      // departmentId: form.departmentId,
      roleName: form.roleName,
      rolesPermissions: check,
    }
    axios
      .post(URLS.AddRole, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push("/RolesPremissions")
            sessionStorage.setItem("tost", "Roles has been Added Successfully")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  function handleChange(e) {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const history = useHistory()

  const [dep, setdep] = useState([])

  useEffect(() => {
    Getalldep()
  }, [])

  const Getalldep = () => {
    var token = datas
    axios
      .post(
        URLS.GetDepartment,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setdep(res.data.departments)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  useEffect(() => {
    GetOneActins()
  }, [])

  const Actinid = sessionStorage.getItem("Roleids")

  const GetOneActins = () => {
    const data = {
      _id: Actinid,
    }

    var token = datas
    axios
      .post(URLS.GetOneRole, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.roleResult)
        setras(res.data.roleResult.rolesAndPermission[0])
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Add Roles" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader className="bg-white mt-2">
                  <CardTitle>Role & Permissions</CardTitle>
                </CardHeader>

                <CardBody>
                  <Form
                    onSubmit={e => {
                      handleSubmit(e)
                    }}
                  >
                    <Row>
                      <Col md={4}>
                        <Label for="basicpill-firstname-input1">
                          Role <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-firstname-input1"
                          placeholder="Enter Role  Name"
                          required
                          value={form.roleName}
                          name="roleName"
                          onChange={e => {
                            handleChange(e)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className=" mt-3">
                      <h5 className="mt-4 mb-3">Dashboard:</h5>
                      <Col md={2}>
                        {" "}
                        <p className="">Dashboard: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="Dashview"
                            defaultChecked={ras.Dashview}
                            value={ras.Dashview}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="read">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Zones:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Zones : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="zonesView"
                            defaultChecked={ras.zonesView}
                            value={ras.zonesView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="zonesAdd"
                            defaultChecked={ras.zonesAdd}
                            value={ras.zonesAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="zonesEdit"
                            defaultChecked={ras.zonesEdit}
                            value={ras.zonesEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="zonesDelete"
                            defaultChecked={ras.zonesDelete}
                            value={ras.zonesDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Delete
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Employee Managment:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Employee Managment View: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="EmployeeManagmentView"
                            defaultChecked={ras.EmployeeManagmentView}
                            value={ras.EmployeeManagmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Department : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementView"
                            defaultChecked={ras.departementView}
                            value={ras.departementView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementAdd"
                            defaultChecked={ras.departementAdd}
                            value={ras.departementAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementeEdit"
                            defaultChecked={ras.departementeEdit}
                            value={ras.departementeEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="departementBlock"
                            defaultChecked={ras.departementBlock}
                            value={ras.departementBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Roles & Premissions : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionView"
                            defaultChecked={ras.rolesAndPermissionView}
                            value={ras.rolesAndPermissionView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionAdd"
                            defaultChecked={ras.rolesAndPermissionAdd}
                            value={ras.rolesAndPermissionAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionEdit"
                            defaultChecked={ras.rolesAndPermissionEdit}
                            value={ras.rolesAndPermissionEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="rolesAndPermissionDelete"
                            defaultChecked={ras.rolesAndPermissionDelete}
                            value={ras.rolesAndPermissionDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Delete
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Staff : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffView"
                            defaultChecked={ras.staffView}
                            value={ras.staffView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffAdd"
                            defaultChecked={ras.staffAdd}
                            value={ras.staffAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffEdit"
                            defaultChecked={ras.staffEdit}
                            value={ras.staffEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="staffBlock"
                            defaultChecked={ras.staffBlock}
                            value={ras.staffBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">All Hospital:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">All Hospital View: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="AllHospitalView"
                            defaultChecked={ras.AllHospitalView}
                            value={ras.AllHospitalView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">All Hospital Dashboard : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="allHospitalDashboardView"
                            defaultChecked={ras.allHospitalDashboardView}
                            value={ras.allHospitalDashboardView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Hospitals List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalListView"
                            defaultChecked={ras.hospitalListView}
                            value={ras.hospitalListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalListAdd"
                            defaultChecked={ras.hospitalListAdd}
                            value={ras.hospitalListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalListEdit"
                            defaultChecked={ras.hospitalListEdit}
                            value={ras.hospitalListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalListBlock"
                            defaultChecked={ras.hospitalListBlock}
                            value={ras.hospitalListBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Ticket Rasing List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ticketRaisingListView"
                            defaultChecked={ras.ticketRaisingListView}
                            value={ras.ticketRaisingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ticketRaisingListEdit"
                            defaultChecked={ras.ticketRaisingListEdit}
                            value={ras.ticketRaisingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ticketRaisingListDelete"
                            defaultChecked={ras.ticketRaisingListDelete}
                            value={ras.ticketRaisingListDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Hospital Monitoring : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalMonitoringView"
                            defaultChecked={ras.hospitalMonitoringView}
                            value={ras.hospitalMonitoringView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Hospital Profile Request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalProfileRequestView"
                            defaultChecked={ras.hospitalProfileRequestView}
                            value={ras.hospitalProfileRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hospitalProfileRequestEdit"
                            defaultChecked={ras.hospitalProfileRequestEdit}
                            value={ras.hospitalProfileRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">ATP:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">ATP View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ATPView"
                            defaultChecked={ras.ATPView}
                            value={ras.ATPView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">ATP list : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpListView"
                            defaultChecked={ras.atpListView}
                            value={ras.atpListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpListAdd"
                            defaultChecked={ras.atpListAdd}
                            value={ras.atpListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpListEdit"
                            defaultChecked={ras.atpListEdit}
                            value={ras.atpListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpListBlock"
                            defaultChecked={ras.atpListBlock}
                            value={ras.atpListBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">ATP Slot Making : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpSlotMakingView"
                            defaultChecked={ras.atpSlotMakingView}
                            value={ras.atpSlotMakingView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpSlotMakingAdd"
                            defaultChecked={ras.atpSlotMakingAdd}
                            value={ras.atpSlotMakingAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpSlotMakingEdit"
                            defaultChecked={ras.atpSlotMakingEdit}
                            value={ras.atpSlotMakingEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpSlotMakingDelete"
                            defaultChecked={ras.atpSlotMakingDelete}
                            value={ras.atpSlotMakingDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">ATP Monitoring : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpMonitoringView"
                            defaultChecked={ras.atpMonitoringView}
                            value={ras.atpMonitoringView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">ATP Slot Wise Monitoring </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpSlotWiseMonitoringView"
                            defaultChecked={ras.atpSlotWiseMonitoringView}
                            value={ras.atpSlotWiseMonitoringView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Recruit Waiting List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="recruitWaitingListView"
                            defaultChecked={ras.recruitWaitingListView}
                            value={ras.recruitWaitingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="recruitWaitingListEdit"
                            defaultChecked={ras.recruitWaitingListEdit}
                            value={ras.recruitWaitingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Leave Request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="leaveRequestView"
                            defaultChecked={ras.leaveRequestView}
                            value={ras.leaveRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="leaveRequestEdit"
                            defaultChecked={ras.leaveRequestEdit}
                            value={ras.leaveRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">User List :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">User List View: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="UserListView"
                            defaultChecked={ras.UserListView}
                            value={ras.UserListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">User List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userListView"
                            defaultChecked={ras.userListView}
                            value={ras.userListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userListAdd"
                            defaultChecked={ras.userListAdd}
                            value={ras.userListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userListEdit"
                            defaultChecked={ras.userListEdit}
                            value={ras.userListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userListBlock"
                            defaultChecked={ras.userListBlock}
                            value={ras.userListBlock}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Block
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Tests At Home :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Tests At Home View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="TestsAtHomeView"
                            defaultChecked={ras.TestsAtHomeView}
                            value={ras.TestsAtHomeView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Tests List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListView"
                            defaultChecked={ras.homeTestListView}
                            value={ras.homeTestListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListAdd"
                            defaultChecked={ras.homeTestListAdd}
                            value={ras.homeTestListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListEdit"
                            defaultChecked={ras.homeTestListEdit}
                            value={ras.homeTestListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestListDelete"
                            defaultChecked={ras.homeTestListDelete}
                            value={ras.homeTestListDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">New Tests Request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newTestsRequestView"
                            defaultChecked={ras.newTestsRequestView}
                            value={ras.newTestsRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newTestsRequestEdit"
                            defaultChecked={ras.newTestsRequestEdit}
                            value={ras.newTestsRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Packages request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="packagesRequestView"
                            defaultChecked={ras.packagesRequestView}
                            value={ras.packagesRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="packagesRequestEdit"
                            defaultChecked={ras.packagesRequestEdit}
                            value={ras.packagesRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Investigations At HCU :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Investigations At HCU View: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="InvestigationsAtHCUView"
                            defaultChecked={ras.InvestigationsAtHCUView}
                            value={ras.InvestigationsAtHCUView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Investigations Request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsView"
                            defaultChecked={ras.investigationsRequestsView}
                            value={ras.investigationsRequestsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsAdd"
                            defaultChecked={ras.investigationsRequestsAdd}
                            value={ras.investigationsRequestsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsEdit"
                            defaultChecked={ras.investigationsRequestsEdit}
                            value={ras.investigationsRequestsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationsRequestsDelete"
                            defaultChecked={ras.investigationsRequestsDelete}
                            value={ras.investigationsRequestsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className=""> New Investigations Request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newInvestigationRequestView"
                            defaultChecked={ras.newInvestigationRequestView}
                            value={ras.newInvestigationRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newInvestigationRequestEdit"
                            defaultChecked={ras.newInvestigationRequestEdit}
                            value={ras.newInvestigationRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Doctor Consultation :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Doctor Consultation View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="DoctorConsultationView"
                            defaultChecked={ras.DoctorConsultationView}
                            value={ras.DoctorConsultationView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Doctor Speciality : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationPriceRequestAdd"
                            defaultChecked={ras.consultationPriceRequestAdd}
                            value={ras.consultationPriceRequestAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationPriceRequestEdit"
                            defaultChecked={ras.consultationPriceRequestEdit}
                            value={ras.consultationPriceRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationPriceRequestView"
                            defaultChecked={ras.consultationPriceRequestView}
                            value={ras.consultationPriceRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationPriceRequestDelete"
                            defaultChecked={ras.consultationPriceRequestDelete}
                            value={ras.consultationPriceRequestDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">
                          Prescription <br></br>Deciphering :{" "}
                        </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="pescreptiondecipheringView"
                            defaultChecked={ras.pescreptiondecipheringView}
                            value={ras.pescreptiondecipheringView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="pescreptiondecipheringEdit"
                            defaultChecked={ras.pescreptiondecipheringEdit}
                            value={ras.pescreptiondecipheringEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Home Services :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Service View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="HomeServiceView"
                            defaultChecked={ras.HomeServiceView}
                            value={ras.HomeServiceView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Service List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListView"
                            defaultChecked={ras.homeServiceListView}
                            value={ras.homeServiceListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListAdd"
                            defaultChecked={ras.homeServiceListAdd}
                            value={ras.homeServiceListAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListEdit"
                            defaultChecked={ras.homeServiceListEdit}
                            value={ras.homeServiceListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceListDelete"
                            defaultChecked={ras.homeServiceListDelete}
                            value={ras.homeServiceListDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">New Home Service Request : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newHomeServiceRequestView"
                            defaultChecked={ras.newHomeServiceRequestView}
                            value={ras.newHomeServiceRequestView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="newHomeServiceRequestEdit"
                            defaultChecked={ras.newHomeServiceRequestEdit}
                            value={ras.newHomeServiceRequestEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3"> Booking Managment :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Booking Managment View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BookingManagmentView"
                            defaultChecked={ras.BookingManagmentView}
                            value={ras.BookingManagmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Booking List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeBookingListView"
                            defaultChecked={ras.homeBookingListView}
                            value={ras.homeBookingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeBookingListEdit"
                            defaultChecked={ras.homeBookingListEdit}
                            value={ras.homeBookingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Emergency Booking List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="emergencyBookingListView"
                            defaultChecked={ras.emergencyBookingListView}
                            value={ras.emergencyBookingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="emergencyBookingListEdit"
                            defaultChecked={ras.emergencyBookingListEdit}
                            value={ras.emergencyBookingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">ACT-INstant Booking List </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="actInstantBookingListView"
                            defaultChecked={ras.actInstantBookingListView}
                            value={ras.actInstantBookingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="actInstantBookingListEdit"
                            defaultChecked={ras.actInstantBookingListEdit}
                            value={ras.actInstantBookingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Packages Booking List </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="PackageBookingListView"
                            defaultChecked={ras.PackageBookingListView}
                            value={ras.PackageBookingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="PackageBookingListEdit"
                            defaultChecked={ras.PackageBookingListEdit}
                            value={ras.PackageBookingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Investigations at HCU : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationAtHcuView"
                            defaultChecked={ras.investigationAtHcuView}
                            value={ras.investigationAtHcuView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationAtHcuEdit"
                            defaultChecked={ras.investigationAtHcuEdit}
                            value={ras.investigationAtHcuEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Consultation : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationView"
                            defaultChecked={ras.consultationView}
                            value={ras.consultationView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationEdit"
                            defaultChecked={ras.consultationEdit}
                            value={ras.consultationEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Service: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceView"
                            defaultChecked={ras.homeServiceView}
                            value={ras.homeServiceView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceEdit"
                            defaultChecked={ras.homeServiceEdit}
                            value={ras.homeServiceEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Cancellation Bookings :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Cancellation Bookings View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CancellationListView"
                            defaultChecked={ras.CancellationListView}
                            value={ras.CancellationListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Cancellation Bookings : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CancellationBookingListView"
                            defaultChecked={ras.CancellationBookingListView}
                            value={ras.CancellationBookingListView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CancellationBookingListEdit"
                            defaultChecked={ras.CancellationBookingListEdit}
                            value={ras.CancellationBookingListEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Accounts:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Accounts View: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="AccountsView"
                            defaultChecked={ras.AccountsView}
                            value={ras.AccountsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Expenses Category : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpensesCategoryView"
                            defaultChecked={ras.ExpensesCategoryView}
                            value={ras.ExpensesCategoryView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpensesCategoryAdd"
                            defaultChecked={ras.ExpensesCategoryAdd}
                            value={ras.ExpensesCategoryAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpensesCategoryEdit"
                            defaultChecked={ras.ExpensesCategoryEdit}
                            value={ras.ExpensesCategoryEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpensesCategoryDelete"
                            defaultChecked={ras.ExpensesCategoryDelete}
                            value={ras.ExpensesCategoryDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Expenses List : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpenseslistView"
                            defaultChecked={ras.ExpenseslistView}
                            value={ras.ExpenseslistView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpenseslistAdd"
                            defaultChecked={ras.ExpenseslistAdd}
                            value={ras.ExpenseslistAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpenseslistEdit"
                            defaultChecked={ras.ExpenseslistEdit}
                            value={ras.ExpenseslistEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ExpenseslistDelete"
                            defaultChecked={ras.ExpenseslistDelete}
                            value={ras.ExpenseslistDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Income Statment : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="IncomeStatmentView"
                            defaultChecked={ras.IncomeStatmentView}
                            value={ras.IncomeStatmentView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Staff Pay Role : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="StaffPayRoleView"
                            defaultChecked={ras.StaffPayRoleView}
                            value={ras.StaffPayRoleView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="StaffPayRoleAdd"
                            defaultChecked={ras.StaffPayRoleAdd}
                            value={ras.StaffPayRoleAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="StaffPayRoleEdit"
                            defaultChecked={ras.StaffPayRoleEdit}
                            value={ras.StaffPayRoleEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="StaffPayRoleDelete"
                            defaultChecked={ras.StaffPayRoleDelete}
                            value={ras.StaffPayRoleDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Atp Pay Role : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="AtpRoleView"
                            defaultChecked={ras.AtpRoleView}
                            value={ras.AtpRoleView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="AtpRoleAdd"
                            defaultChecked={ras.AtpRoleAdd}
                            value={ras.AtpRoleAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="AtpRoleEdit"
                            defaultChecked={ras.AtpRoleEdit}
                            value={ras.AtpRoleEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="AtpRoleDelete"
                            defaultChecked={ras.AtpRoleDelete}
                            value={ras.AtpRoleDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Payments :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Payments : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="paymentsView"
                            defaultChecked={ras.paymentsView}
                            value={ras.paymentsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="paymentsEdit"
                            defaultChecked={ras.paymentsEdit}
                            value={ras.paymentsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Payments Reports :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Payments Reports View : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="PaymentsReportsView"
                            defaultChecked={ras.PaymentsReportsView}
                            value={ras.PaymentsReportsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Test Report : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeTestReportView"
                            defaultChecked={ras.homeTestReportView}
                            value={ras.homeTestReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Investigations at HCU Report : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="investigationAtHcuReportView"
                            defaultChecked={ras.investigationAtHcuReportView}
                            value={ras.investigationAtHcuReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Consultation Report : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="consultationReportView"
                            defaultChecked={ras.consultationReportView}
                            value={ras.consultationReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Home Service Report : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="homeServiceReportView"
                            defaultChecked={ras.homeServiceReportView}
                            value={ras.homeServiceReportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Techinical Support :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">
                          Techinical <br></br> Support View :{" "}
                        </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="TechinicalSupportView"
                            defaultChecked={ras.TechinicalSupportView}
                            value={ras.TechinicalSupportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">HCU TC Support : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hcuTcSupportView"
                            defaultChecked={ras.hcuTcSupportView}
                            value={ras.hcuTcSupportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="hcuTcSupportEdit"
                            defaultChecked={ras.hcuTcSupportEdit}
                            value={ras.hcuTcSupportEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Doctor TC Support : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpTcSupportView"
                            defaultChecked={ras.atpTcSupportView}
                            value={ras.atpTcSupportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="atpTcSupportEdit"
                            defaultChecked={ras.atpTcSupportEdit}
                            value={ras.atpTcSupportEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">User TC Support : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userTcSupportView"
                            defaultChecked={ras.userTcSupportView}
                            value={ras.userTcSupportView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="userTcSupportEdit"
                            defaultChecked={ras.userTcSupportEdit}
                            value={ras.userTcSupportEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">CRM :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">CRM : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="CRMView"
                            defaultChecked={ras.CRMView}
                            value={ras.CRMView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Enquiry : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="enquiryView"
                            defaultChecked={ras.enquiryView}
                            value={ras.enquiryView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="enquiryDelete"
                            defaultChecked={ras.enquiryDelete}
                            value={ras.enquiryDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="enquiryEdit"
                            defaultChecked={ras.enquiryEdit}
                            value={ras.enquiryEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Chat : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="chatView"
                            defaultChecked={ras.chatView}
                            value={ras.chatView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="chatAdd"
                            defaultChecked={ras.chatAdd}
                            value={ras.chatAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="chatEdit"
                            defaultChecked={ras.chatEdit}
                            value={ras.chatEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Banners :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Banners View: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BannerView"
                            defaultChecked={ras.BannerView}
                            value={ras.BannerView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Banners: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersView"
                            defaultChecked={ras.bannersView}
                            value={ras.bannersView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersAdd"
                            defaultChecked={ras.bannersAdd}
                            value={ras.bannersAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersEdit"
                            defaultChecked={ras.bannersEdit}
                            value={ras.bannersEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="bannersDelete"
                            defaultChecked={ras.bannersDelete}
                            value={ras.bannersDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Sub Banners: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersView"
                            defaultChecked={ras.suBannersView}
                            value={ras.suBannersView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersAdd"
                            defaultChecked={ras.suBannersAdd}
                            value={ras.suBannersAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersEdit"
                            defaultChecked={ras.suBannersEdit}
                            value={ras.suBannersEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="suBannersDelete"
                            defaultChecked={ras.suBannersDelete}
                            value={ras.suBannersDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Coupons :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Coupons : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsView"
                            defaultChecked={ras.couponsView}
                            value={ras.couponsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsAdd"
                            defaultChecked={ras.couponsAdd}
                            value={ras.couponsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsEdit"
                            defaultChecked={ras.couponsEdit}
                            value={ras.couponsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="couponsDelete"
                            defaultChecked={ras.couponsDelete}
                            value={ras.couponsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Notifications:</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Notifications : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationsView"
                            defaultChecked={ras.notificationsView}
                            value={ras.notificationsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationsAdd"
                            defaultChecked={ras.notificationsAdd}
                            value={ras.notificationsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="notificationsDelete"
                            defaultChecked={ras.notificationsDelete}
                            value={ras.notificationsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="bookinglist">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <h5 className="mt-3 mb-3">Settings :</h5>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Settings: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="SettingsView"
                            defaultChecked={ras.SettingsView}
                            value={ras.SettingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Blog: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BlogView"
                            defaultChecked={ras.BlogView}
                            value={ras.BlogView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BlogAdd"
                            defaultChecked={ras.BlogAdd}
                            value={ras.BlogAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BlogEdit"
                            defaultChecked={ras.BlogEdit}
                            value={ras.BlogEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="BlogDelete"
                            defaultChecked={ras.BlogDelete}
                            value={ras.BlogDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Charges: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ChargesView"
                            defaultChecked={ras.ChargesView}
                            value={ras.ChargesView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="ChargesEdit"
                            defaultChecked={ras.ChargesEdit}
                            value={ras.ChargesEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">User Profile: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="UserProfileView"
                            defaultChecked={ras.UserProfileView}
                            value={ras.UserProfileView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="UserProfileEdit"
                            defaultChecked={ras.UserProfileEdit}
                            value={ras.UserProfileEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">State: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="stateView"
                            defaultChecked={ras.stateView}
                            value={ras.stateView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="stateAdd"
                            defaultChecked={ras.stateAdd}
                            value={ras.stateAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="stateEdit"
                            defaultChecked={ras.stateEdit}
                            value={ras.stateEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="stateDelete"
                            defaultChecked={ras.stateDelete}
                            value={ras.stateDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">District : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="distrctView"
                            defaultChecked={ras.distrctView}
                            value={ras.distrctView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="distrctAdd"
                            defaultChecked={ras.distrctAdd}
                            value={ras.distrctAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="distrctEdit"
                            defaultChecked={ras.distrctEdit}
                            value={ras.distrctEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="distrctDelete"
                            defaultChecked={ras.distrctDelete}
                            value={ras.distrctDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">City : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cityView"
                            defaultChecked={ras.cityView}
                            value={ras.cityView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cityAdd"
                            defaultChecked={ras.cityAdd}
                            value={ras.cityAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cityEdit"
                            defaultChecked={ras.cityEdit}
                            value={ras.cityEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cityDelete"
                            defaultChecked={ras.cityDelete}
                            value={ras.cityDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className=""> Manual : </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="manualAdd"
                            defaultChecked={ras.manualAdd}
                            value={ras.manualAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label">Add</Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="manualView"
                            defaultChecked={ras.manualView}
                            value={ras.manualView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label">View</Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="manualEdit"
                            defaultChecked={ras.manualEdit}
                            value={ras.manualEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="manualEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="manualDelete"
                            defaultChecked={ras.manualDelete}
                            value={ras.manualDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="custread32">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">CGHS Corner: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cgsCornerView"
                            defaultChecked={ras.cgsCornerView}
                            value={ras.cgsCornerView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            className="form-check-label"
                            for="empView"
                          >
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cgsCornerAdd"
                            defaultChecked={ras.cgsCornerAdd}
                            value={ras.cgsCornerAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empAdd">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cgsCornerEdit"
                            defaultChecked={ras.cgsCornerEdit}
                            value={ras.cgsCornerEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empEdit">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check me-3 me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="cgsCornerDelete"
                            defaultChecked={ras.cgsCornerDelete}
                            value={ras.cgsCornerDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="empl3">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md={2}>
                        {" "}
                        <p className="">Settings: </p>
                      </Col>{" "}
                      <Col md={1}></Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsAdd"
                            defaultChecked={ras.settingsAdd}
                            value={ras.settingsAdd}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Add
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsEdit"
                            defaultChecked={ras.settingsEdit}
                            value={ras.settingsEdit}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Edit
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsView"
                            defaultChecked={ras.settingsView}
                            value={ras.settingsView}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            View
                          </Label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="form-check  me-lg-5">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            name="settingsDelete"
                            defaultChecked={ras.settingsDelete}
                            value={ras.settingsDelete}
                            onClick={e => {
                              handleChange1s(e)
                            }}
                            id="read"
                          />
                          <Label className="form-check-label" for="laneview">
                            Delete
                          </Label>
                        </div>
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                    <div className="mt-3" style={{ float: "right" }}>
                      <button
                        type="submit"
                        style={{ width: "120px" }}
                        className="btn btn-primary m-1"
                      >
                        Submit{" "}
                        <i
                          className="fa fa-check-circle-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>{" "}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Roles
