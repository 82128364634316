import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
  Button,
  Form,
  Label,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { toast, ToastContainer } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  const [isLoading, setIsLoading] = useState(true)
  const [Actin, setActin] = useState([])
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetAllPendingCancellationBookings,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.payments)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [modal_small1, setmodal_small1] = useState(false)

  const [form2, setform2] = useState([])

  const handleChange2 = e => {
    let myUser = { ...form2 }
    myUser[e.target.name] = e.target.value
    setform2(myUser)
  }

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const getpopup1 = data => {
    setform2(data)
    tog_small1()
  }

  const handleSubmit2 = e => {
    e.preventDefault()
    Editstate1()
  }

  const [files, setFiles] = useState([])

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG"
    ) {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  const Editstate1 = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("bookingId", form2.bookingId)
    dataArray.append("cancelCharges", form2.cancellationCharge)
    dataArray.append("actinAmount", form2.actinFinalAmount)
    dataArray.append("userAmount", form2.userFinalAmount)
    dataArray.append("transactionId", form2.transactionId)
    dataArray.append("description", form2.description)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("attachedFile", files[i])
    }

    axios
      .post(URLS.AddPendingCancellationBookings, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setFiles({
              image: "",
            })
            setform2({
              description: "",
              transactionId: "",
            })

            setmodal_small1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="ACTIN"
            breadcrumbItem="Pending Cancellation Bookings"
          />

          <Row>
            <Col>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 ">
                          <thead>
                            <tr className="text-center">
                              <th>S.No</th>
                              <th>Booking Date</th>
                              <th>User Code</th>
                              <th>User Name</th>
                              <th>User Phone</th>
                              <th>Booking Type</th>
                              <th>Booking Code</th>
                              <th>Appointment Charge</th>
                              <th>Coupon Discount</th>
                              <th>Member Card</th>
                              <th>Sample Charge</th>
                              <th>Sanitation Charge</th>
                              <th>Gst</th>
                              <th>Transaction Charge</th>
                              <th>Total Amount</th>
                              <th>Actin Cancellation Charge</th>
                              <th>User To be Refund</th>
                              <th>Booking Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.bookingDate}</td>
                                <td>{data.actinUserCode}</td>
                                <td>{data.actinUserName}</td>
                                <td>{data.actinUserPhone}</td>
                                <td>{data.bookingType}</td>
                                <td>{data.actinBookingCode}</td>
                                <td>{data.appointmentCharge}</td>
                                <td>{data.couponDiscount}</td>
                                <td>{data.memberCards}</td>
                                <td>{data.sampleCharge}</td>
                                <td>{data.sannitationCharge}</td>
                                <td>{data.gst}</td>
                                <td>{data.transactionCharge}</td>
                                <td>{data.totalAmount}</td>
                                <td>{data.actinFinalAmount}</td>
                                <td>{data.userFinalAmount}</td>
                                <td>
                                  {data.bookingStatus == "canceled" ? (
                                    <>
                                      <span className="badge bg-danger">
                                        {data.bookingStatus}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      getpopup1(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="success"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-repeat"
                                    ></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>

          <Modal
            size="md"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Paid
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                onSubmit={e => {
                  handleSubmit2(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Cancellation Charges :<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Cancellation Charges"
                    required
                    value={form2.cancellationCharge}
                    name="cancellationCharge"
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Actin Amount :<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Actin Amount"
                    required
                    value={form2.actinFinalAmount}
                    name="actinFinalAmount"
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    User Amount :<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter User Amount"
                    required
                    value={form2.userFinalAmount}
                    name="userFinalAmount"
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Transaction Id :<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Transaction Id"
                    required
                    value={form2.transactionId}
                    name="transactionId"
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>

                <div className="mb-2">
                  <Label for="basicpill-firstname-input1">File</Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter image"
                    name="image"
                    onChange={changeHandler}
                  />
                </div>

                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    rows="4"
                    className="form-control "
                    id="basicpill-firstname-input1"
                    placeholder="Enter Description"
                    required
                    value={form2.description}
                    name="description"
                    onChange={e => {
                      handleChange2(e)
                    }}
                  />
                </div>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
