import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Button,
  Table,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "../../Url"
import { Link, useHistory } from "react-router-dom"
import gig from "../../assets/images/loders.gif"
import jsPDF from "jspdf"
import "jspdf-autotable"

function State() {
  const [Actin, setActin] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
    datass()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetHomeServicetest,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.homeservice)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const Actinid = data => {
    sessionStorage.setItem("Investigationid", data._id)
    history.push("/EditHomeServiceList")
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to InActive?")
    if (confirmBox === true) {
      Delete(data)
    }
  }

  const Delete = data => {
    var token = datas
    var remid = data._id
    const gs = {
      status: "inactive",
    }
    axios
      .put(URLS.DeleteOneHomeServicetest + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHospitals()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete1 = data => {
    const confirmBox = window.confirm("Do you really want to Active?")
    if (confirmBox === true) {
      Delete1(data)
    }
  }

  const Delete1 = data => {
    var token = datas
    var remid = data._id

    const gs = {
      status: "active",
    }

    axios
      .put(URLS.DeleteOneHomeServicetest + remid, gs, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetHospitals()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  //view instructions ,information
  const [info, setinfo] = useState(false)
  const [ins, setins] = useState(false)
  const [Instructions, setInstructions] = useState([])
  const [Information, setInformation] = useState([])

  function infos() {
    setinfo(!info)
  }

  function inst() {
    setins(!ins)
  }

  const getinfo = data => {
    setInformation(data.information)
    infos()
  }

  const getinc = data => {
    setInstructions(data.instructions)
    inst()
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetHomeServicetestSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.homeservice)
      })
  }

  const exportPDF = () => {
    const unit = "pt"
    const size = "A3"
    const orientation = "portrait"
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const headers = [
      [
        "SlNo",
        "ACTIN_Service_Id",
        "ACTIN_Service_Name",
        "Information",
        "Status",
      ],
    ]

    const data = Actin.map((elt, i) => [
      i + 1,
      elt.testid,
      elt.title,
      elt.information,
      elt.status,
    ])

    let content = {
      startY: 50,
      head: headers,
      body: data,
    }
    doc.autoTable(content)
    doc.save("Home_Service_list.pdf")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="Home Service LIST" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Col>
                        {Roles?.homeServiceListAdd === true ||
                        Roles?.accessAll === true ? (
                          <>
                            <Link to="/AddHomeServiceList">
                              <Button color="primary">
                                New Service{" "}
                                <i className="bx bx-plus-circle"></i>
                              </Button>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                        <Button
                          type="button"
                          className="btn btn-info m-2"
                          onClick={exportPDF}
                        >
                          <i className="fas fa-file-pdf"></i> Pdf
                        </Button>
                      </Col>
                      <Row>
                        <Col md={12}>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                        </Col>
                      </Row>

                      <div>
                        <div
                          className="table-responsive"
                          style={{ height: "480px" }}
                        >
                          <Table className="table table-bordered mb-2 mt-3">
                            <thead>
                              <tr className="text-center">
                                <th>SlNo</th>
                                <th>ACTIN Service Image</th>
                                <th>ACTIN Service Id</th>
                                <th>ACTIN Service Name</th>
                                <th>Instruction</th>
                                <th>Information</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>
                                    <img
                                      src={URLS.Base + data.testImage}
                                      height="60px"
                                      width="80px"
                                    ></img>
                                  </td>
                                  <td>{data.testid}</td>
                                  <td>{data.title}</td>
                                  <td className="text-center">
                                    <Button
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="warning"
                                      onClick={() => {
                                        getinfo(data)
                                      }}
                                    >
                                      <i className="bx bxs-file"></i> View
                                    </Button>
                                  </td>
                                  <td className="text-center">
                                    <Button
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="warning"
                                      onClick={() => {
                                        getinc(data)
                                      }}
                                    >
                                      <i className="bx bxs-file"></i> View
                                    </Button>
                                  </td>
                                  <td>
                                    {data.status == "active" ? (
                                      <>
                                        <span className="badge bg-success ">
                                          {data.status}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="badge bg-danger ">
                                          {data.status}
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    {Roles?.homeServiceListEdit ===
                                      true || Roles?.accessAll === true ? (
                                      <>
                                        <Button
                                          onClick={() => {
                                            Actinid(data)
                                          }}
                                          size="sm"
                                          className="m-1"
                                          outline
                                          color="success"
                                        >
                                          <i
                                            style={{ fontSize: " 14px" }}
                                            className="bx bx-edit"
                                          ></i>
                                        </Button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {Roles?.homeServiceListDelete ===
                                      true || Roles?.accessAll === true ? (
                                      <>
                                        {data.status == "active" ? (
                                          <Button
                                            onClick={() => {
                                              manageDelete(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="danger"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="fas fa-user-lock"
                                            ></i>
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => {
                                              manageDelete1(data)
                                            }}
                                            size="sm"
                                            className="m-1"
                                            outline
                                            color="success"
                                          >
                                            <i
                                              style={{ fontSize: " 14px" }}
                                              className="fas fa-user-check"
                                            ></i>
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <ToastContainer />

        <Modal
          size="lg"
          isOpen={info}
          toggle={() => {
            infos()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Information :-
            </h5>
            <button
              onClick={() => {
                setinfo(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="pt-2">
              <i className="bx bxs-send" style={{ color: "#B30706" }}></i>
              {Information}
            </p>
          </div>
        </Modal>

        <Modal
          size="lg"
          isOpen={ins}
          toggle={() => {
            inst()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              View Instruction :-
            </h5>
            <button
              onClick={() => {
                setins(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {Instructions.map((data, i) => (
              <>
                <p key={i} className="pt-2">
                  <i className="bx bxs-send" style={{ color: "#B30706" }}></i>{" "}
                  {data.instructions}
                </p>
                <hr></hr>
              </>
            ))}
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default State
