import React, { useState, useEffect } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Input,
  Table,
  Form,
  Label,
  Button,
  Modal,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import gig from "../../assets/images/loders.gif"
import { useHistory } from "react-router-dom"
import ReactPaginate from "react-paginate"
import { URLS } from "../../Url"
import axios from "axios"

function Support() {
  const [Actin, setActin] = useState([])
  const [modal_small, setmodal_small] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  function tog_small() {
    setmodal_small(!modal_small)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    GetHospitals()
  }, [])

  const GetHospitals = () => {
    var token = datas
    axios
      .post(
        URLS.GetUserTicket,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.tickets)
        setIsLoading(false)
      })
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = Actin.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Actin.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const [form1, setform1] = useState([])

  const getpopup = data => {
    setform1(data)
    tog_small()
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
    Editstate()
  }

  const getTimeString = () => {
    const now = new Date()

    const timeString = now.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })

    const dateString = now
      .toLocaleDateString("en-IN", {
        timeZone: "Asia/Kolkata",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-")

    return { date: dateString, time: timeString }
  }

  const { date, time } = getTimeString()

  const Editstate = () => {
    var token = datas

    var formid = form1._id
    const dataArray = new FormData()
    dataArray.append("status", form1.status)
    dataArray.append("date", date)
    dataArray.append("time", time)
    dataArray.append("personId", form1.personId)

    axios
      .put(URLS.UpdateUserTicket + "/" + formid, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            GetHospitals()
            toast(res.data.message)
            setmodal_small(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const Actinid1 = data => {
    var token = datas
    axios
      .post(
        URLS.chatCount,
        { ticketId: data._id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            sessionStorage.setItem("chatid", data.userId)
            sessionStorage.setItem("ticketid", data._id)
            history.push("/chat")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )

    // sessionStorage.setItem("chatid", data.userId)
    // sessionStorage.setItem("ticketid", data._id)
    // history.push("/chat")
  }

  const [zones, setZones] = useState([])

  useEffect(() => {
    GetZones()
  }, [])

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetStaff,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setZones(res.data.staff)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [Searchs, setSearchs] = useState([])

  const Search = e => {
    let myUser = { ...Searchs }
    myUser[e.target.name] = e.target.value
    setSearchs(myUser)
    var token = datas
    axios
      .post(
        URLS.GetUserTicketSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setActin(res.data.tickets)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="ACTIN" breadcrumbItem="User  Support" />

          <Row>
            <Col md={12}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <div>
                        <div className="table-responsive" style={{height:"480px"}}>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={Searchs.search}
                              onChange={Search}
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                            />
                          </div>
                          <Table className="table table-bordered mb-4 mt-5">
                            <thead>
                              <tr className="text-center">
                                <th>S.No</th>
                                <th>Ticket Id</th>
                                <th>Date / Time</th>
                                <th>User Id</th>
                                <th>User Name</th>
                                <th>Title</th>
                                <th>Screen Shot</th>
                                <th>Description</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lists.map((data, key) => (
                                <tr key={key} className="text-center">
                                  <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                  <td>{data.ticketid}</td>
                                  <td>
                                    {data.date}/{data.time}
                                  </td>
                                  <td>{data.userCode}</td>
                                  <td>{data.userName}</td>
                                  <td>{data.title}</td>
                                  <td>
                                    <img
                                      style={{ width: "100px" }}
                                      src={URLS.Base + data.image}
                                    />
                                  </td>
                                  <td>{data.description}</td>
                                  <td>
                                  {data.status == "resolved" ? <>
                                    <span className="badge bg-success">
                                      {data.status}
                                    </span>
                                    </>:<>
                                    <span className="badge bg-danger">
                                      {data.status}
                                    </span>
                                    </>}
                                  </td>
                                  <td>
                                    {data.newMessageCount == 0 ? (
                                      ""
                                    ) : (
                                      <span className="badge rounded-pill bg-danger float-end">
                                        {data.newMessageCount}
                                      </span>
                                    )}
                                    <Button
                                      onClick={() => {
                                        Actinid1(data)
                                      }}
                                      size="sm"
                                      className="m-1"
                                      outline
                                      color="info"
                                    >
                                      <i
                                        style={{ fontSize: " 14px" }}
                                        className="fas fa-eye"
                                      ></i>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>

                          <div className="mt-3" style={{ float: "right" }}>
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"pagination"}
                              previousLinkClassName={"previousBttn"}
                              nextLinkClassName={"nextBttn"}
                              disabledClassName={"disabled"}
                              activeClassName={"active"}
                              total={lists.length}
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="mySmallModalLabel">
              Support Status
            </h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                handleSubmit1(e)
              }}
            >
              <Col md={12}>
                <div className="mb-3">
                  <Label> Staff</Label> <span className="text-danger">*</span>
                  <select
                    value={form1.personId}
                    name="personId"
                    onChange={e => {
                      handleChange1(e)
                    }}
                    required
                    className="form-select"
                  >
                    <option value="">Select</option>
                    {zones.map((data, key) => {
                      return (
                        <option key={key} value={data._id}>
                          {data.firstName} {data.lastName}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Label for="basicpill-firstname-input3">
                    Status <span className="text-danger">*</span>
                  </Label>

                  <select
                    className="form-select"
                    required
                    value={form1.status}
                    name="status"
                    onChange={e => {
                      handleChange1(e)
                    }}
                  >
                    <option value=""> Select </option>
                    <option value="assigned">assigned</option>
                  </select>
                </div>
              </Col>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                >
                  Cancel <i className="fas fa-times-circle"></i>
                </Button>
                <Button className="m-1" color="primary" type="submit">
                  Submit <i className="fas fa-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Support
