import React, { useEffect, useState } from "react"
import {
  CardBody,
  Container,
  Row,
  Col,
  Card,
  Form,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function PaymentReport() {
  const [isLoading, setIsLoading] = useState(true)

  const [Actin, setActin] = useState([])

  useEffect(() => {
    ActinReport()
  }, [])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const currentDate = new Date().toISOString().split("T")[0]

  const ActinReport = () => {
    var token = datas
    const data = { fromDate: "1970-01-01", toDate: currentDate }

    axios
      .post(URLS.GetIncomeStatement, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setIsLoading(false)
        setActin(res.data)
      })
  }

  const [filter, setfilter] = useState(false)

  const [filters, setfilters] = useState({
    fromDate: "",
    toDate: "",
  })

  const getfilter = e => {
    e.preventDefault()
    GetActinFiliter()
  }

  const GetActinFiliter = () => {
    var token = datas
    const data = {
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    }
    axios
      .post(URLS.GetAllBookingTestReports, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setActin(res.data)
        hidefilter()
        setfilters("")
      })
  }

  const hidefilter = () => setfilter(false)

  const handleChangeflt = e => {
    let myUser = { ...filters }
    myUser[e.target.name] = e.target.value
    setfilters(myUser)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="ACTIN" breadcrumbItem="Income Statement" />

            {filter ? (
              <>
                <Card>
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        getfilter(e)
                      }}
                    >
                      <Row>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              From Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="fromDate"
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="mb-3">
                            <Label for="basicpill-declaration-input10">
                              To Date <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="basicpill-Declaration-input10"
                              onChange={e => {
                                handleChangeflt(e)
                              }}
                              name="toDate"
                              value={filters.toDate}
                            />
                          </div>
                        </Col>

                        <Col lg="3">
                          <div className="mt-4">
                            <Button type="submit" className="m-1" color="info">
                              <i className="fas fa-check-circle"></i> search
                            </Button>
                            <Button
                              onClick={hidefilter}
                              className="m-1"
                              color="danger"
                            >
                              <i className="fas fa-times-circle"></i> Cancel
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}

            <Card>
              <CardBody>
                <div>
                  <div className="col-sm-12">
                    <Row>
                      <Col>
                        <div style={{ float: "right" }}>
                          <Button
                            className="m-1"
                            onClick={() => {
                              setfilter(!filter)
                            }}
                            color="info"
                          >
                            <i className="fas fa-filter"></i> Filter
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="mt-3">
                  {isLoading == true ? (
                    <>
                      <div
                        style={{ zIndex: "9999999999999", height: "420px" }}
                        className="text-center mt-5 pt-5"
                      >
                        <img src={gig} height="140px"></img>
                        <div>Loading......</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Row>
                        <Col md={12}>
                          <h4 className="invoice-company text-inverse">
                            Income Statement
                          </h4>
                          <div className="table-responsive" style={{height:"480px"}}>
                            <Table
                              id="empTable"
                              className="table table-bordered mb-3 mt-5"
                            >
                              <thead className="text-center">
                                <tr>
                                  <th>Type</th>
                                  <th>Month</th>
                                  <th>Year</th>
                                  <th>Amount (XOF)</th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                <tr>
                                  <td>Revenue</td>
                                  <td>All</td>
                                  <td>All</td>
                                  <td>{Actin.totalEarnings}</td>
                                </tr>
                                <tr>
                                  <td>Expense</td>
                                  <td>All</td>
                                  <td>All</td>
                                  <td>{Actin.totalExpenses}</td>
                                </tr>
                              </tbody>
                            </Table>

                            <div className="invoice-price mt-5">
                              <div className="invoice-price-left">
                                <div className="invoice-price-row">
                                  <div className="sub-price">
                                    <small>Revenue (XOF)</small>
                                    {Actin.totalEarnings}{" "}
                                  </div>
                                  <div className="sub-price">
                                    <i className="fa fa-minus"></i>
                                  </div>
                                  <div className="sub-price">
                                    <small>Expense (XOF)</small>
                                    {Actin.totalExpenses}
                                  </div>
                                </div>
                              </div>
                              <div className="invoice-price-right">
                                <small>TOTAL (XOF)</small> {Actin.subTotal}{" "}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PaymentReport
