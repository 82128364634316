import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Input, Button, Table } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

function Ventures() {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token
  const history = useHistory()
  const [zones, setZones] = useState([])

  const [listPerPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(0)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    datass()
    GetZones()
  }, [])

  const GetZones = () => {
    var token = datas
    axios
      .post(
        URLS.GetallPayRole,
        { month: "All" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setZones(res.data.payrolls)
          setIsLoading(false)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const pagesVisited = pageNumber * listPerPage
  const lists = zones.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(zones.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [forms, setforms] = useState([])

  const handlechanges = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetallPayRoleSearch + `${e.target.value}`,
        { month: "All" },

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setZones(res.data.payrolls)
      })
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  const ZoneIdz = data => {
    sessionStorage.setItem("staffid", data._id)
    history.push("/EditPayRole")
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var Roles = data?.rolesAndPermission[0]


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="ACTIN" breadcrumbItem="Pay Role" />
          {isLoading == true ? (
            <>
              <div
                style={{ zIndex: "9999999999999", height: "420px" }}
                className="text-center mt-5 pt-5"
              >
                <img src={gig} height="140px"></img>
                <div>Loading......</div>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                            {Roles?.StaffPayRoleAdd === true ||
                            Roles?.accessAll === true ? (
                              <>
                                <Link to="/AddPayRole">
                                <Button color="primary">
                                  Add Pay Role{" "}
                                  <i className="bx bx-plus-circle"></i>
                                </Button>
                                </Link>{" "}
                              </>
                            ) : (
                              ""
                            )}
                        </Col>

                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              type="search"
                              name="search"
                              value={forms.search}
                              onChange={handlechanges}
                              className="form-control"
                              placeholder="Search.."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div
                        className="table-rep-plugin mt-4 table-responsive"
                        style={{ height: "480px" }}
                      >
                        <Table hover className="table table-bordered mb-4 mt-5">
                          <thead>
                            <tr className="text-center">
                              <th>SlNo</th>
                              <th>Month</th>
                              <th>Type</th>
                              <th>Employee</th>
                              <th>Total Days</th>
                              <th>Working Days</th>
                              <th>Leaves</th>
                              <th>Basic Salary</th>
                              <th>Hra</th>
                              <th>Conveyence Allowance</th>
                              <th>Special Allowance</th>
                              <th>Others</th>
                              <th>Earning Total</th>
                              <th>PF</th>
                              <th>ESI</th>
                              <th>Loan</th>
                              <th>Tax</th>
                              <th>Advance Amount</th>
                              <th>Leaves Amount</th>
                              <th>Net Salary</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key} className="text-center">
                                <td>{(pageNumber - 1) * 20 + key + 21}</td>
                                <td>{data.month}</td>
                                <td>{data.staffType}</td>
                                <td>{data.staffName}</td>
                                <td>{data.totalDays}</td>
                                <td>{data.workingDays}</td>
                                <td>{data.leaves}</td>
                                <td>{data.basicSalary}</td>
                                <td>{data.houseRentAllownce}</td>
                                <td>{data.conveyenceAllowance}</td>
                                <td>{data.specialAllowance}</td>
                                <td>{data.otherAllowance}</td>
                                <td>{data.totalEarning}</td>
                                <td>{data.providentFund}</td>
                                <td>{data.emplStateInsurance}</td>
                                <td>{data.taxDeductOnSalary}</td>
                                <td>{data.professionalTax}</td>
                                <td>{data.advanceAmount}</td>
                                <td>{data.leavesAmount}</td>
                                <td>{data.totalSalary}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}

          <ToastContainer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Ventures
